<div [@fade] class="bg-light-blue">
  <div class="w-full">
    <app-header
      headerClasses="mx-6 mt-4 flex justify-between items-center text-white mb-4"
    ></app-header>
    <div>
      <div class="flex gap-4 w-4/5 mx-auto">
        <img class="h-16" src="assets/icons/svg/logo-4.svg" />
        <div class="text-white font-semibold">
          <p>Mon espace pro</p>
          <p
            *ngIf="user.user_type == 'PROFESSIONNEL'"
            class="bg-gray-300 rounded-full p-1 text-center"
          >
            {{ user.agency_name }}
          </p>
        </div>
      </div>
      <p class="text-white w-11/12 mx-auto font-semibold text-xl mb-7">
        Bonjour, {{ user.firstname }}
      </p>
    </div>
  </div>
  <div style="max-width: 500px;" class="bg-white rounded-t-xl absolute bottom-0 w-full">
    <div class="flex flex-col my-auto justify-around w-full">
      <div class="grid grid-cols-2 grid-rows-2 gap-4 px-4 pt-3">
        <app-secondary-card
          imgPath="assets/icons/svg/info.svg"
          imgClasses="h-10 mx-auto"
          imgBlockClasses="bg-extra-light-blue rounded-xl p-3 w-2/5 mx-auto"
          cardClasses="bg-extra-ligth-gray rounded-xl text-center mx-auto py-3"
          text="Info. perso"
          textClasses="txt-bold-gray font-semibold text-sm mb-2 w-full mx-auto"
          text_button="Paramétrer"
          buttonClasses="rounded-full text-black bg-white p-1 text-sm text-center mx-auto w-4/5 mt-2"
          (IsClicked)="infoPerso()"
        ></app-secondary-card>
        <app-secondary-card
          imgPath="assets/icons/svg/calendar.svg"
          imgClasses="h-10 mx-auto"
          imgBlockClasses="bg-extra-light-blue rounded-xl p-3 w-2/5 mx-auto"
          cardClasses="bg-extra-ligth-gray rounded-xl text-center mx-auto py-3"
          text="Rendez-vous"
          textClasses="txt-bold-gray font-semibold text-sm mb-2 w-full mx-auto"
          text_button="Formaliser"
          buttonClasses="rounded-full text-black bg-white p-1 text-sm text-center mx-auto w-4/5 mt-2"
          (IsClicked)="appointment()"
        ></app-secondary-card>
        <app-secondary-card
          *ngIf="user_type === 'PARTICULIER'"
          imgPath="assets/icons/svg/unlike.svg"
          imgClasses="h-10 mx-auto"
          imgBlockClasses="bg-extra-light-blue rounded-xl p-3 w-2/5 mx-auto"
          cardClasses="bg-extra-ligth-gray rounded-xl text-center mx-auto py-3"
          text="Coups de coeur"
          textClasses="txt-bold-gray font-semibold text-sm mb-2 w-full mx-auto"
          text_button="Acheter / Louer"
          buttonClasses="rounded-full text-black bg-white p-1 text-sm text-center mx-auto w-4/5 mt-2"
          (IsClicked)="getFavoritesListProperty('sale')"
        ></app-secondary-card>
        <app-secondary-card
          *ngIf="user_type === 'PARTICULIER'"
          imgPath="assets/icons/svg/house.svg"
          imgClasses="h-10 mx-auto"
          imgBlockClasses="bg-extra-light-blue rounded-xl p-3 w-2/5 mx-auto"
          cardClasses="bg-extra-ligth-gray rounded-xl text-center mx-auto py-3"
          text="Mes biens"
          textClasses="txt-bold-gray font-semibold text-sm mb-2 w-full mx-auto"
          text_button="Vendre / Louer"
          buttonClasses="rounded-full text-black bg-white p-1 text-sm text-center mx-auto w-4/5 mt-2"
          (IsClicked)="getMyProperty('sale')"
        ></app-secondary-card>
        <app-secondary-card
          *ngIf="user_type === 'PROFESSIONNEL'"
          imgPath="assets/icons/svg/euro.svg"
          imgClasses="h-10 mx-auto"
          imgBlockClasses="bg-extra-light-blue rounded-xl p-3 w-2/5 mx-auto"
          cardClasses="bg-extra-ligth-gray rounded-xl text-center mx-auto py-3"
          text="Biens en vente"
          textClasses="txt-bold-gray font-semibold text-sm mb-2 w-full mx-auto"
          text_button="Gérer"
          buttonClasses="rounded-full text-black bg-white p-1 text-sm text-center mx-auto w-4/5 mt-2"
          (IsClicked)="getMyProperty('sale')"
        ></app-secondary-card>
        <app-secondary-card
          *ngIf="user_type === 'PROFESSIONNEL'"
          imgPath="assets/icons/svg/clock.svg"
          imgClasses="h-10 mx-auto"
          imgBlockClasses="bg-extra-light-blue rounded-xl p-3 w-2/5 mx-auto"
          cardClasses="bg-extra-ligth-gray rounded-xl text-center mx-auto py-3"
          text="Biens en location"
          textClasses="txt-bold-gray font-semibold text-sm mb-2 w-full mx-auto"
          text_button="Gérer"
          buttonClasses="rounded-full text-black bg-white p-1 text-sm text-center mx-auto w-4/5 mt-2"
          (IsClicked)="getMyProperty('rent')"
        ></app-secondary-card>
      </div>
      <div
        (click)="logout()"
        class="
          border border-solid
          w-11/12
          p-2
          mx-auto
          mt-2
          text-center
          cursor-pointer
          mb-6
        "
      >
        <p>Déconnexion</p>
      </div>
    </div>
  </div>
</div>
