import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
})
export class InputFileComponent implements OnInit {
  @Input() text = 'Send File Input';
  @Input() inputClasses = '';
  @Input() name = 'file';
  @Input() multiple = 'false';
  @Input() inputFileClasses = '';
  @Input() inputLabelClasses = '';
  @Input() accept = '';
  @Output() Changed = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
