import { environment } from './../../../environments/environment';
import { PropertyService } from 'src/app/shared/services/property.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-user-interested',
  templateUrl: './user-interested.component.html',
  styleUrls: ['./user-interested.component.scss'],
})
export class UserInterestedComponent implements OnInit {
  @Input() current_step: string;
  @Input() username: string = 'prénom nom';
  @Input() projection_level: string = 'Intéressé(e)';
  @Input() offer: string = "Aucune offre n'a été présentée";
  @Input() candidate_id: string;
  public candidate_offer: string = null;
  public candidate_email: string;
  public candidate_phone_number: string;
  public property_id: string;
  public service_type: string;
  public proof_of_identity: string = "Justificatif d'identité";
  public tax_notice: string = "Avis d'imposition";
  public employment_contract: string = 'Contrat de travail';
  public rent_receipt_1: string = '1 ère quittance logement précédent';
  public rent_receipt_2: string = '2 ème quittance logement précédent';
  public rent_receipt_3: string = '3 ème quittance logement précédent';
  public proof_of_identity_guarantor: string = "Justificatif d'identité";
  public tax_notice_guarantor: string = "Avis d'imposition";
  public employment_contract_guarantor: string = 'Contrat de travail';
  public electricity_bill: string = 'Facture EDF';
  public kbis_or_extract: string = 'Kbis ou extrait de K';
  public family_record_book: string = 'Livret de famille';
  public marriage_or_pacs_certificate: string = 'Attestation mariage, pacs';
  public proof_of_the_funds_making_up_the_contribution: string =
    "Justificatif des fonds composant l'apport";
  public conditions_precedent_if_loan: string =
    'Conditions suspensives si emprunt';
  public rental_investment: string = 'Investissement locatif';
  public my_property_id: string;
  @Output() onBack = new EventEmitter<any>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private propertyService: PropertyService
  ) {
    this.my_property_id = this.route.snapshot.paramMap.get('property_id');
    this.property_id = this.route.snapshot.paramMap.get('id');
    this.service_type = this.route.snapshot.paramMap.get('service_type');
  }

  ngOnInit(): void {
    this.transformProjectionLevel();
    this.getCandidateFile();
  }

  /**
   * transformProjectionLevel
   */
  public transformProjectionLevel() {
    switch (this.projection_level) {
      case 'INTERESSE':
        this.projection_level = 'Intéressé(e)';
        break;
      case 'PRET_A_FAIRE_UNE_OFFRE':
        this.projection_level = 'Prêt à faire une offre';
        break;
      case 'SOUHAITE_UNE_VISITE':
        this.projection_level = 'Souhaite une visite';
        break;
      case 'SOUHAITE_PLUS_DE_PHOTOS':
        this.projection_level = 'Souhaite plus de photos';
        break;
      default:
        break;
    }
  }

  /**
   * back
   */
  public back($event) {
    this.onBack.emit($event);
  }

  /**
   * getCandidateFile
   */
  public getCandidateFile() {
    console.log('this.candidate_id', this.candidate_id);
    this.propertyService
      .getCandidateFile(this.candidate_id)
      .subscribe((res) => {
        console.log(res);
        const data = res.data.getCandidateFileData;
        if (res.data.getCandidateFileData) {
          this.candidate_email = 'mailto:' + data.candidate_email;
          this.username =
            data.candidate_firstname + ' ' + data.candidate_lastname;
          this.candidate_phone_number =
            data.candidate_phone_number !== 'Non renseigné'
              ? 'tel:' + data.candidate_phone_number
              : null;
          this.conditions_precedent_if_loan =
            data.conditions_precedent_if_loan !== 'Non renseigné'
              ? environment.cloudfront_url + data.conditions_precedent_if_loan
              : null;
          this.electricity_bill =
            data.electricity_bill !== 'Non renseigné'
              ? environment.cloudfront_url + data.electricity_bill
              : null;
          this.employment_contract =
            data.employment_contract !== 'Non renseigné'
              ? environment.cloudfront_url + data.employment_contract
              : null;

          this.employment_contract_guarantor =
            data.employment_contract_guarantor !== 'Non renseigné'
              ? environment.cloudfront_url + data.employment_contract_guarantor
              : null;
          this.family_record_book =
            data.family_record_book !== 'Non renseigné'
              ? environment.cloudfront_url + data.family_record_book
              : null;
          this.kbis_or_extract =
            data.kbis_or_extract !== 'Non renseigné'
              ? environment.cloudfront_url + data.kbis_or_extract
              : null;
          this.marriage_or_pacs_certificate =
            data.marriage_or_pacs_certificate !== 'Non renseigné'
              ? environment.cloudfront_url + data.marriage_or_pacs_certificate
              : null;
          this.proof_of_identity =
            data.proof_of_identity !== 'Non renseigné'
              ? environment.cloudfront_url + data.proof_of_identity
              : null;
          this.proof_of_identity_guarantor =
            data.proof_of_identity_guarantor !== 'Non renseigné'
              ? environment.cloudfront_url + data.proof_of_identity_guarantor
              : null;
          this.proof_of_the_funds_making_up_the_contribution =
            data.proof_of_the_funds_making_up_the_contribution !==
            'Non renseigné'
              ? environment.cloudfront_url +
                data.proof_of_the_funds_making_up_the_contribution
              : null;
          this.rent_receipt_1 =
            data.rent_receipt_1 !== 'Non renseigné'
              ? environment.cloudfront_url + data.rent_receipt_1
              : null;
          this.rent_receipt_2 =
            data.rent_receipt_2 !== 'Non renseigné'
              ? environment.cloudfront_url + data.rent_receipt_2
              : null;
          this.rent_receipt_3 =
            data.rent_receipt_3 !== 'Non renseigné'
              ? environment.cloudfront_url + data.rent_receipt_3
              : null;
          this.rental_investment =
            data.rental_investment !== 'Non renseigné'
              ? environment.cloudfront_url + data.rental_investment
              : null;
          this.tax_notice =
            data.tax_notice !== 'Non renseigné'
              ? environment.cloudfront_url + data.tax_notice
              : null;
          this.tax_notice_guarantor =
            data.tax_notice_guarantor !== 'Non renseigné'
              ? environment.cloudfront_url + data.tax_notice_guarantor
              : null;
        }
      });
  }

  /**
   * getFile
   */
  public getFile($file_key) {
    if ($file_key) {
      window.open($file_key, 'blank');
    }
  }
}
