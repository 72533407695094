<div [ngClass]="colorClasses" class="rounded-t-3xl relative mt-36">
  <img
    *ngIf="appointment_status == 'CONFIRMATION'"
    [src]="
      appointment_status == 'CONFIRMATION'
        ? 'assets/icons/svg/valid-card.svg'
        : ''
    "
    class="h-40 left-0 right-0 absolute -top-28 mx-auto"
  />
  <img
    *ngIf="appointment_status == 'ANNULE'"
    [src]="
      appointment_status == 'ANNULE'
        ? 'assets/icons/svg/invalid-card.svg'
        : ''
    "
    class="h-40 left-0 right-0 absolute -top-28 mx-auto"
  />
  <img
    *ngIf="appointment_status == 'CONTRE_PROPOSITION_CONFIRMATION'"
    [src]="
      appointment_status == 'CONTRE_PROPOSITION_CONFIRMATION'
        ? 'assets/icons/svg/more-card.svg'
        : ''
    "
    class="h-40 left-0 right-0 absolute -top-28 mx-auto"
  />
  <div class="mx-6 pt-12 mt-4 mb-10">
    <app-text
      textClasses="text-white mx-auto text-center font-extrabold mt-8 mb-3"
      [text]="title"
    ></app-text>
    <app-text
      textClasses="text-white mx-auto text-center text-sm mb-6"
      [text]="subtitle"
    ></app-text>
  </div>
  <div class="rounded-t-3xl h-full bg-white pt-6 relative">
    <app-text
      textClasses="bg-secondary-blue p-1 text-center text-sm font-extrabold tracking-wide w-16 mx-auto absolute rounded-md txt-percian-green -top-3 left-0 right-0"
      [text]="appointment_hour"
    ></app-text>
    <div class="mx-auto">
      <app-text
        textClasses="txt-bold-gray text-center text-sm mx-6 my-6 capitalize"
        [text]="appointment_date"
      ></app-text>
      <app-text
        textClasses="txt-bold-gray text-md font-extrabold text-center mx-6"
        [text]="appointment_reason"
      ></app-text>
      <app-text
        textClasses="txt-bold-gray text-base text-center mx-6 mt-3 mb-3"
        [text]="appointment_type"
      ></app-text>
      <app-text
        *ngIf="isOnCreatedAppointmentPage"
        textClasses="txt-bold-gray text-sm font-bold text-center mx-6 mt-3 mb-3"
        text="avec"
      ></app-text>
      <app-text
        *ngIf="isOnCreatedAppointmentPage"
        textClasses="text-white bg-light-blue text-sm rounded-xl mx-auto font-bold text-center mt-3 mb-6 w-max p-2"
        [text]="appointment_guest"
      ></app-text>
      <app-text
        textClasses="txt-bold-gray text-sm font-light text-center mx-6 mt-3 mb-6"
        text="Vous recevrez un mail de confirmation avec le détail de votre rendez-vous"
      ></app-text>
    </div>
    <div class="mx-auto mt-6 mb-7 flex gap-3 justify-center">
      <app-primary-button
        (IsClicked)="back($event)"
        text="Annuler"
        textPosition="right"
        buttonClasses="bg-white border-secondary-blue txt-secondary-blue border items-center w-40"
        firstImgSrc="assets/icons/svg/cross.svg"
        secondImgSrc="assets/icons/svg/cross.svg"
        [disabled]="false"
      ></app-primary-button>
      <app-primary-button
        (IsClicked)="onClick()"
        text="Valider"
        buttonClasses="bg-bold-blue text-white items-center border border-light-blue w-40"
        firstImgSrc="assets/icons/svg/arrow-right.svg"
        secondImgSrc="assets/icons/svg/arrow-right.svg"
        [disabled]="false"
      ></app-primary-button>
    </div>
  </div>
</div>
