export class MandateModel {
  ID: any;
  User_id: any;
  Property_id: any;
  File: any;

  public mapToModel(data: any): MandateModel {
    const mandateModel = new MandateModel();
    mandateModel.ID = data.id;
    mandateModel.User_id = data.user_id;
    mandateModel.Property_id = data.property_id;
    mandateModel.File = data.file;

    return mandateModel;
  }
}
