<div [@fade] class="w-full mx-auto bg-white overflow-auto">
  <div class="border-b border-solid pb-2">
    <div class="mx-6">
      <app-secondary-header
        (IsClicked)="back()"
        headerClasses=" pt-4 flex justify-between items-center mb-2"
      ></app-secondary-header>
      <div class="mx-auto text-center">
        <p class="font-bold text-xl txt-bold-gray">
          Paramétrer mes rendez-vous
        </p>
      </div>
    </div>
  </div>
  <div class="pt-12 lg:mb-0 px-8 w-full bg-white">
    <div class="parameters-page">
      <div class="parameters-element-1 md:mr-4">
        <div
          class="
              rounded-md
              p-4
              my-3
              cursor-pointer
              text-sm
              parameters-btn
              {{
            selectedType === 'APPEL_TELEPHONIQUE'
              ? 'bg-light-blue text-white shadow-inner'
              : 'bg-white txt-bold-gray border border-light-blue shadow-md'
          }}
            "
          (click)="changeParameterType('APPEL_TELEPHONIQUE')"
        >
          Mes rendez-vous téléphonique
        </div>
        <div
          class="
              rounded-md
              p-4
              my-3
              cursor-pointer
              text-sm
              parameters-btn
              {{
            selectedType === 'VISITE_DU_BIEN_PAR_UN_EXPERT'
              ? 'bg-light-blue text-white shadow-inner'
              : 'bg-white txt-bold-gray border border-light-blue shadow-md'
          }}
            "
          (click)="changeParameterType('VISITE_DU_BIEN_PAR_UN_EXPERT')"
        >
          Les visites du bien par un expert
        </div>
        <div
          class="
              rounded-md
              p-4
              my-3
              cursor-pointer
              text-sm
              parameters-btn
              {{
            selectedType === 'VISITE_DU_BIEN_AVEC_UN_ACHETEUR'
              ? 'bg-light-blue text-white shadow-inner'
              : 'bg-white txt-bold-gray border border-light-blue shadow-md'
          }}
            "
          (click)="changeParameterType('VISITE_DU_BIEN_AVEC_UN_ACHETEUR')"
        >
          Les visites du bien avec un acheteur
        </div>
        <div
          class="
            rounded-md
            p-4
            my-3
            cursor-pointer
            text-sm
            parameters-btn
            {{
            selectedType === 'RENDEZ_VOUS_EN_AGENCE'
              ? 'bg-light-blue text-white shadow-inner'
              : 'bg-white txt-bold-gray border border-light-blue shadow-md'
          }}
          "
          (click)="changeParameterType('RENDEZ_VOUS_EN_AGENCE')"
        >
          Mes rendez-vous en agence
        </div>
        <app-primary-button
          (IsClicked)="validateParameters()"
          text="Valider mes paramètres"
          buttonClasses="bg-light-blue text-white items-center shadow-md w-max mx-auto mb-3"
          [disabled]="false"
        ></app-primary-button>
      </div>
      <div
        [formGroup]="parametersData"
        class="parameters-element-2 bg-white rounded-md p-4 mb-12"
      >
        <div class="mb-4 txt-bold-gray text-md">
          Programmer mes jours de rendez-vous {{ titles[selectedType] }} ?
        </div>
        <div class="md:flex my-2">
          <div class="flex items-center parameters-checkbox">
            <mat-checkbox
              [checked]="parametersData.value.frequency === '7j/s'"
              (change)="onFrequencyChange('7j/s')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">Tous les jours</div>
          </div>
          <div class="flex items-center parameters-checkbox">
            <mat-checkbox
              [checked]="parametersData.value.frequency === '1j/s'"
              (change)="onFrequencyChange('1j/s')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">
              1 fois par semaine
            </div>
          </div>
          <div class="flex items-center parameters-checkbox">
            <mat-checkbox
              [checked]="parametersData.value.frequency === '2j/s'"
              (change)="onFrequencyChange('2j/s')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">
              2 fois par semaine
            </div>
          </div>
        </div>
        <div class="md:flex my-2">
          <div class="flex items-center parameters-checkbox">
            <mat-checkbox
              [checked]="parametersData.value.frequency === '3j/s'"
              (change)="onFrequencyChange('3j/s')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">
              3 fois par semaine
            </div>
          </div>
          <div class="flex items-center parameters-checkbox">
            <mat-checkbox
              [checked]="parametersData.value.frequency === '4j/s'"
              (change)="onFrequencyChange('4j/s')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">
              4 fois par semaine
            </div>
          </div>
          <div class="flex items-center parameters-checkbox">
            <mat-checkbox
              [checked]="parametersData.value.frequency === '5j/s'"
              (change)="onFrequencyChange('5j/s')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">
              5 fois par semaine
            </div>
          </div>
        </div>
        <div class="md:flex my-2">
          <div class="flex items-center parameters-checkbox">
            <mat-checkbox
              [checked]="parametersData.value.frequency === '6j/s'"
              (change)="onFrequencyChange('6j/s')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">
              6 fois par semaine
            </div>
          </div>
          <div class="flex items-center parameters-checkbox">
            <mat-checkbox
              [checked]="parametersData.value.frequency === '2s/m'"
              (change)="onFrequencyChange('2s/m')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">1 semaine sur 2</div>
          </div>
          <div class="flex items-center parameters-checkbox">
            <mat-checkbox
              [checked]="parametersData.value.frequency === '1s/m'"
              (change)="onFrequencyChange('1s/m')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">
              1 semaine par mois
            </div>
          </div>
        </div>

        <div class="mt-8 mb-4 txt-bold-gray text-md">
          Quel(s) jour(s) sont concerné(s) par les rendez-vous
          {{ titles[selectedType] }} ?
        </div>
        <div class="md:flex my-2">
          <div class="flex items-center parameters-checkbox-sm">
            <mat-checkbox
              [checked]="isDaySelected('Mon')"
              (change)="onDaysChange('Mon')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">Lundi</div>
          </div>
          <div class="flex items-center parameters-checkbox-sm">
            <mat-checkbox
              [checked]="isDaySelected('Tue')"
              (change)="onDaysChange('Tue')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">Mardi</div>
          </div>
          <div class="flex items-center parameters-checkbox-sm">
            <mat-checkbox
              [checked]="isDaySelected('Wed')"
              (change)="onDaysChange('Wed')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">Mercredi</div>
          </div>
          <div class="flex items-center parameters-checkbox-sm">
            <mat-checkbox
              [checked]="isDaySelected('Thu')"
              (change)="onDaysChange('Thu')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">Jeudi</div>
          </div>
        </div>
        <div class="md:flex my-2">
          <div class="flex items-center parameters-checkbox-sm">
            <mat-checkbox
              [checked]="isDaySelected('Fri')"
              (change)="onDaysChange('Fri')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">Vendredi</div>
          </div>
          <div class="flex items-center parameters-checkbox-sm">
            <mat-checkbox
              [checked]="isDaySelected('Sat')"
              (change)="onDaysChange('Sat')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">Samedi</div>
          </div>
          <div class="flex items-center parameters-checkbox-sm">
            <mat-checkbox
              [checked]="isDaySelected('Sun')"
              (change)="onDaysChange('Sun')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">Dimanche</div>
          </div>
        </div>

        <div class="mt-8 mb-4 txt-bold-gray text-md">
          Définissez la durée par défaut d’un rendez-vous
          {{ titles[selectedType] }}
        </div>
        <div class="md:flex my-2">
          <div class="flex items-center parameters-checkbox-sm">
            <mat-checkbox
              [checked]="parametersData.value.duration === '15 minutes'"
              (change)="onTimeChange('15 minutes')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">15 mins</div>
          </div>
          <div class="flex items-center parameters-checkbox-sm">
            <mat-checkbox
              [checked]="parametersData.value.duration === '20 minutes'"
              (change)="onTimeChange('20 minutes')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">20 mins</div>
          </div>
          <div class="flex items-center parameters-checkbox-sm">
            <mat-checkbox
              [checked]="parametersData.value.duration === '25 minutes'"
              (change)="onTimeChange('25 minutes')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">25 mins</div>
          </div>
          <div class="flex items-center parameters-checkbox-sm">
            <mat-checkbox
              [checked]="parametersData.value.duration === '30 minutes'"
              (change)="onTimeChange('30 minutes')"
            ></mat-checkbox>
            <div class="txt-bold-gray text-md ml-2 mt-1">30 mins</div>
          </div>
        </div>

        <div [formGroup]="parametersData2">
          <div class="mt-8 mb-4 txt-bold-gray text-md">
            Sélectionner une plage horaire
          </div>

          <div class="mb-4 txt-bold-gray text-md">Matin</div>

          <div class="flex items-center">
            <div class="mr-4 txt-bold-gray text-md">De</div>
            <select
              formControlName="start_time_am"
              class="appointment-select-input txt-bold-gray mr-4"
            >
              <option value="" disabled selected hidden>-</option>
              <option value="06:00" class="txt-bold-gray">06:00</option>
              <option value="06:30" class="txt-bold-gray">06:30</option>
              <option value="07:00" class="txt-bold-gray">07:00</option>
              <option value="07:30" class="txt-bold-gray">07:30</option>
              <option value="08:00" class="txt-bold-gray">08:00</option>
              <option value="08:30" class="txt-bold-gray">08:30</option>
              <option value="09:00" class="txt-bold-gray">09:00</option>
            </select>
            <div class="mr-4 txt-bold-gray text-md">À</div>
            <select
              formControlName="end_time_am"
              class="appointment-select-input txt-bold-gray mr-4"
            >
              <option value="" disabled selected hidden>-</option>
              <option value="09:00" class="txt-bold-gray">09:00</option>
              <option value="09:30" class="txt-bold-gray">09:30</option>
              <option value="10:00" class="txt-bold-gray">10:00</option>
              <option value="10:30" class="txt-bold-gray">10:30</option>
              <option value="11:00" class="txt-bold-gray">11:00</option>
              <option value="11:30" class="txt-bold-gray">11:30</option>
              <option value="12:00" class="txt-bold-gray">12:00</option>
              <option value="12:30" class="txt-bold-gray">12:30</option>
              <option value="13:00" class="txt-bold-gray">13:00</option>
            </select>
          </div>

          <div class="mt-4 mb-4 txt-bold-gray text-md">Soir</div>

          <div class="flex items-center">
            <div class="mr-4 txt-bold-gray text-md">De</div>
            <select
              formControlName="start_time_pm"
              class="appointment-select-input txt-bold-gray mr-4"
            >
              <option value="" disabled selected hidden>-</option>
              <option value="13:00" class="txt-bold-gray">13:00</option>
              <option value="13:30" class="txt-bold-gray">13:30</option>
              <option value="14:00" class="txt-bold-gray">14:00</option>
              <option value="14:30" class="txt-bold-gray">14:30</option>
              <option value="15:00" class="txt-bold-gray">15:00</option>
              <option value="15:30" class="txt-bold-gray">15:30</option>
              <option value="16:00" class="txt-bold-gray">16:00</option>
            </select>
            <div class="mr-4 txt-bold-gray text-md">À</div>
            <select
              formControlName="end_time_pm"
              class="appointment-select-input txt-bold-gray mr-4"
            >
              <option value="" disabled selected hidden>-</option>
              <option value="16:30" class="txt-bold-gray">16:30</option>
              <option value="17:00" class="txt-bold-gray">17:00</option>
              <option value="17:30" class="txt-bold-gray">17:30</option>
              <option value="18:00" class="txt-bold-gray">18:00</option>
              <option value="18:30" class="txt-bold-gray">18:30</option>
              <option value="19:00" class="txt-bold-gray">19:00</option>
              <option value="19:30" class="txt-bold-gray">19:30</option>
              <option value="20:00" class="txt-bold-gray">20:00</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
