<div
  *ngIf="!answered"
  class="flex justify-between cookie-banner absolute bottom-0 z-50"
>
  <div class="w-1/2 md:w-auto self-center mx-6 text-white">
    Ce site utilise des cookies pour améliorer votre expérience.
    <span class="mx-2 underline"
      ><a (click)="privacyPolicies()" href="" target="_blank"
        >En savoir plus</a
      ></span
    >
  </div>
  <div class="w-1/2 md:w-auto md:flex">
    <div (click)="onDecline()" class="self-center justify-end cookie-btn">
      Décliner
    </div>
    <div (click)="onAccept()" class="self-center justify-end cookie-btn">
      Autoriser les cookies
    </div>
  </div>
</div>
<div
  (click)="onChangeAnswer()"
  *ngIf="answered"
  class="minimized-banner z-50 fixed bottom-0"
></div>
