import { AgencyModel } from './../models/agency-model';
import { AddressModel } from './../models/address-model';
import { UserModel } from './../models/user-model';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(public http: HttpClient) {}

  public signUp(userModel: UserModel): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          signUp(
            email: "${userModel.Email}", 
            password: "${userModel.Password}"
            ) {
            id
            username
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public confirmSignUp(
    userModel: UserModel,
    otp_code: String
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation  {
          validateEmail(
            codeOtp: "${otp_code}",
            email: "${userModel.Email}"
            ) {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public createIndividualAccount(userModel: UserModel): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation  {
          createAccount(
            input: {
              id: "${userModel.ID}",
              email: "${userModel.Email}",
              firstname: "${userModel.Firstname}",
              status: INDEPENDANT, 
              state: ACTIF
              lastname: "${userModel.Lastname}"
              user_type: PARTICULIER
            }) {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public createProfessionalAccountWithAgencyId(
    userModel: UserModel,
    agencyModel: AgencyModel
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation  {
          createAccount(
            input: {
              id: "${userModel.ID}",
              email: "${userModel.Email}",
              firstname: "${userModel.Firstname}", 
              user_type: PROFESSIONNEL,
              status: AGENT,
              state: ACTIF, 
              lastname: "${userModel.Lastname}"
            },
            agency_id: "${agencyModel.ID}"
            ) {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public createProfessionalAccountWithAgencyFields(
    userModel: UserModel,
    addressModel: AddressModel,
    agencyModel: AgencyModel
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          createAccount(
            input: {
              id: "${userModel.ID}",
              email: "${userModel.Email}",
              firstname: "${userModel.Firstname}", 
              user_type: PROFESSIONNEL,
              status: ADMINISTRATEUR,
              state: ACTIF, 
              lastname: "${userModel.Lastname}"
            }, 
            address: {
              address_location: "${addressModel.Address_location}", 
              address_type: BUREAU, 
              city: "${addressModel.City}", 
              postal_code: "${addressModel.Postal_code}"
            }, 
            agency: {
              agency_name: "${agencyModel.Agency_name}", 
              agency_scale_of_fees: "${agencyModel.Agency_scale_of_fees}", 
              siret: "${agencyModel.Siret}"
            }) {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public login(userModel: UserModel): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        query {
          login(email: "${userModel.Email}", password: "${userModel.Password}") {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public logout(): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          logout {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public getUser(email: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        query {
          getUser(
            email: "${email}") 
            {
              address_id
              address_location
              address_type
              agency_id
              agency_name
              city
              email
              email_verified
              firstname
              id
              lastname
              phone_number
              postal_code
              property_list_id
              siret
              state
              status
              user_type
              agency_scale_of_fees
            }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public editPersonalInformations(userModel: UserModel): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
      mutation {
        updatePersonalInformations(
          input: {
            id: "${userModel.ID}", 
            firstname: "${userModel.Firstname}", 
            lastname: "${userModel.Lastname}", 
            phone_number: "${userModel.Phone_number}"
          }) {
          code
        }
      }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public editProfesionalInformations(userModel: UserModel): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          updateProfesionalInformations(
            input: {
              status: ${userModel.Status}, 
              siret: "${userModel.Siret}", 
              postal_code: "${userModel.Postal_code}", 
              city: "${userModel.City}", 
              agency_name: "${userModel.Agency_name}", 
              address_location: "${userModel.Address_location}", 
              id: "${userModel.ID}"
            }) {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public editEmail(email: string, id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          updateEmail(
            email: "${email}", 
            id: "${id}"
            ) {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public validateNewAddressEmail(id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          validateNewAddressEmail(
            id: "${id}") {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public addFile(
    candidate_id: string,
    field_name: string,
    file: string,
    filename: string
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          addFile(
            candidate_id: "${candidate_id}", 
            field_name: "${field_name}", 
            file: "${file}", 
            filename: "${filename}"
            ) {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public getCandidateFileData(candidate_id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        query  {
          getCandidateFileData(candidate_id: "${candidate_id}") {
            candidate_email
            candidate_firstname
            candidate_lastname
            candidate_phone_number
            conditions_precedent_if_loan
            electricity_bill
            employment_contract
            employment_contract_guarantor
            family_record_book
            id
            kbis_or_extract
            marriage_or_pacs_certificate
            offer
            proof_of_identity
            proof_of_identity_guarantor
            proof_of_the_funds_making_up_the_contribution
            rent_receipt_1
            rent_receipt_2
            rent_receipt_3
            rental_investment
            tax_notice
            tax_notice_guarantor
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public removeFile(
    file_key: string,
    field_name: string,
    id: string
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          removeFile(
            id: "${id}", 
            field_name: "${field_name}", 
            file_key: "${file_key}"
            ) {
            code
          }
        }      
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }
}
