import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements OnInit {
  @Input() labelClasses = '';
  @Input() label_text = '';
  @Input() input_value = '';
  @Input() label_name = '';
  @Input() type = 'text';
  @Input() id = this.label_name;
  @Input() value = '';
  @Output() Changed = new EventEmitter<any>();
  @Output() IsClicked = new EventEmitter<any>();
  @Input() showErrors = true;
  @Input() model: any;
  public hasError = false;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() readonly = 'null';
  @Input() icon_visibility = false;
  @Input() icon = '';
  @Input() errorWidth = '';
  @Input() borderClasses = 'border-white';
  @Input() borderColor = 'input-border';
  @Input() additionnalClasses = '';
  @Input() textColor = '';
  @Input() maxlength = '';
  @Input() formFields: FormGroup = this.formBuilder.group({
    description: ['', Validators.required],
  });
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {}

  onChange($event: any): void {
    this.checkInput();
  }

  onClick($event: any, $event_2: any): void {
    const data = {
      label_name: this.label_name,
    };
    if ($event === 'email' || $event === 'password') {
      this.IsClicked.emit(data);
    } else {
      if ($event == this.label_name && $event_2 == 'readonly') {
        console.log('xxxxxxx');
        this.readonly = 'null';
        this.additionnalClasses = 'border-light-blue txt-light-blue';
      } else if ($event == this.label_name && $event_2 == 'null') {
        this.readonly = 'readonly';
        this.additionnalClasses = '';
      }
    }
  }

  checkInput(): void {
    if (this.value === 'DESCRIPTION') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        this.Changed.emit(data);
      }
    }
  }
}
