import { trigger, transition, style, animate } from '@angular/animations';
import { CustomPopupComponent } from 'src/app/shared/components/custom-popup/custom-popup.component';
import { LoaderPopupComponent } from './../../shared/components/loader-popup/loader-popup.component';
import { AgencyModel } from './../../shared/models/agency-model';
import { AddressModel } from './../../shared/models/address-model';
import { UserModel } from 'src/app/shared/models/user-model';
import { AgencyService } from './../../shared/services/agency.service';
import { LocalStorageService } from './../../shared/services/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from './../../shared/services/user.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
/* Configure Amplify resources */
Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-3',

    // REQUIRED - Amazon Cognito User Pool ID
    userPoolId: environment.userPoolId,

    // REQUIRED - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: environment.userPoolWebClientId,
  },
});

@Component({
  selector: 'app-personal-informations',
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
    ]),
  ],
  templateUrl: './personal-informations.component.html',
  styleUrls: ['./personal-informations.component.scss'],
})
export class PersonalInformationsComponent implements OnInit {
  public userModel = new UserModel();
  public addressModel = new AddressModel();
  public agencyModel = new AgencyModel();
  public disabled = true;
  @Input() current_state: string = 'PERSONAL_INFORMATIONS';
  public email_update: boolean = false;
  @Input() label_name: string = '';
  @Input() additionnalClasses = '';
  @Input() readonly = 'null';
  @Input() month: any;
  @Input() current_sorting: any;
  public myForm: FormGroup = this.formBuilder.group({
    formFields: this.formBuilder.group({
      // create nested formgroup to pass to child
      email: ['', Validators.required],
      new_email: ['', Validators.required],
      password: ['', Validators.required],
      password_confirm: ['', Validators.required],
      old_password: ['', Validators.required],
      new_password: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      agency_name: ['', Validators.required],
      address_location: ['', Validators.required],
      postal_code: ['', Validators.required],
      city: ['', Validators.required],
      siret: ['', Validators.required],
      phone_number: ['', Validators.required],
      status: ['', Validators.required],
    }),
    otpFormFields: this.formBuilder.group({
      // create nested formgroup to pass to child
      otp_code_1: ['', Validators.required],
      otp_code_2: ['', Validators.required],
      otp_code_3: ['', Validators.required],
      otp_code_4: ['', Validators.required],
      otp_code_5: ['', Validators.required],
      otp_code_6: ['', Validators.required],
    }),
    selectFormFields: this.formBuilder.group({
      user_status: ['', Validators.required],
    }),
    selectDateFormFields: this.formBuilder.group({
      month: ['', Validators.required],
      year: ['', Validators.required],
    }),
    sorting_type: ['', Validators.required],
  });
  sortingTypeArray: Array<Object> = [
    { name: 'Activité de la semaine' },
    { name: 'Activité du mois' },
  ];
  public user_id: string;
  public user_type: string;

  constructor(
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService
  ) {
    this.user_type = this.localStorageService.getCurrentUser().user_type;
    this.user_id = this.localStorageService.getCurrentUser().id;
    this.getUser();
  }

  ngOnInit(): void {
    this.current_sorting = 'Activité du mois';
    this.cdRef.detectChanges();
  }

  // Get user
  getUser() {
    try {
      const email = this.localStorageService.getCurrentUser().email;
      this.userService.getUser(email).subscribe((res: any) => {
        if (res && res.data) {
          console.log('RES', res);
          this.localStorageService.setCurrentUser(res.data.getUser);
          this.userModel = new UserModel().mapToModel(
            this.localStorageService.getCurrentUser()
          );
        }
      });
    } catch (err) {
      throw new Error(err);
    }
  }

  // Back function
  back($event: any) {
    this.router.navigate([$event]);
  }

  // CURRENT STATE SWITCH FUNCTION
  switchCurrentState($event: string) {
    switch ($event) {
      case 'PROFESIONAL_INFORMATIONS':
        this.current_state = 'PROFESIONAL_INFORMATIONS';
        break;
      case 'STATS':
        this.current_state = 'STATS';
        break;
      case 'CANDIDATE_FILE':
        this.current_state = 'CANDIDATE_FILE';
        break;
      default:
        this.current_state = 'PERSONAL_INFORMATIONS';
        break;
    }
  }

  // Check user status Admin or agent
  checkUserStatus() {}

  // Fields edition
  onChange($event: any): void {
    if ($event.value === 'FIRSTNAME') {
      if (this.userModel.Firstname !== $event.model) {
        this.disabled = false;
      }
      this.userModel.Firstname = $event.model;
    }
    if ($event.value === 'LASTNAME') {
      if (this.userModel.Lastname !== $event.model) {
        this.disabled = false;
      }
      this.userModel.Lastname = $event.model;
    }
    if ($event.value === 'PHONE_NUMBER') {
      console.log($event);
      console.log(this.userModel.Phone_number);

      if (this.userModel.Phone_number !== $event.model) {
        this.disabled = false;
      }
      this.userModel.Phone_number = $event.model;
    }
    if ($event.value === 'EMAIL') {
      if (this.userModel.Email !== $event.model) {
        this.disabled = false;
        this.email_update = true;
      }
      this.userModel.Email = $event.model;
    }
    if ($event.value === 'OLD_PASSWORD') {
      this.userModel.Old_password = $event.model;
    }
    if ($event.value === 'NEW_PASSWORD') {
      this.userModel.New_password = $event.model;
    }
    if ($event.value === 'PASSWORD_CONFIRM') {
      console.log($event);
      if ($event.model == this.userModel.New_password) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    }
    if ($event.value === 'AGENCY_NAME') {
      if (this.userModel.Agency_name !== $event.model) {
        this.disabled = false;
      }
      this.userModel.Agency_name = $event.model;
    }
    if ($event.value === 'SIRET') {
      if (this.userModel.Siret !== $event.model) {
        this.disabled = false;
      }
      this.userModel.Siret = $event.model;
    }
    if ($event.value === 'STATUS') {
      if (this.userModel.Status !== $event.model) {
        this.disabled = false;
      }
      this.userModel.Status = $event.model;
    }
    if ($event.value === 'ADDRESS_LOCATION') {
      if (this.userModel.Address_location !== $event.model) {
        this.disabled = false;
      }
      this.userModel.Address_location = $event.model;
    }
    if ($event.value === 'POSTAL_CODE') {
      if (this.userModel.Postal_code !== $event.model) {
        this.disabled = false;
      }
      this.userModel.Postal_code = $event.model;
      // if ($event.model.length > 3) {
      //   this.getAddressAgency();
      // }
    }
    if ($event.value === 'CITY') {
      if (this.userModel.City !== $event.model) {
        this.disabled = false;
      }
      this.userModel.City = $event.model;
    }
    if ($event.value === 'NEW_EMAIL') {
      console.log($event);
      if ($event.model !== '' && $event.model !== this.userModel.Email) {
        this.disabled = false;
      }
      this.userModel.New_email = $event.model;
    }
    if ($event.value === 'OTP_CODE') {
      console.log($event);
      this.disabled = false;
      this.userModel.Otp_code = $event.model;
    }
    if ($event.value === 'DATE_SORT') {
      console.log($event);
    }
    if ($event === 'Activité de la semaine' || $event === 'Activité du mois') {
      console.log($event);
    }
  }

  // Edit personal informations
  editPersonalInformations() {
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Veuillez patienter ... ',
        isLoader: true,
      },
    });
    // IF CURRENT STATE == 'PERSONAL_INFORMATIONS'
    if (
      this.current_state === 'PERSONAL_INFORMATIONS' &&
      !this.email_update &&
      this.userModel.phoneValid()
    ) {
      this.userService
        .editPersonalInformations(this.userModel)
        .subscribe((res: any) => {
          if (res && res.data.updatePersonalInformations) {
            console.log('RES', res.data.updatePersonalInformations);
            this.dialog.closeAll();
            const successDialog = this.dialog.open(CustomPopupComponent, {
              disableClose: true,
              hasBackdrop: true,
              height: '70%',
              panelClass: 'custom-modalbox',
              data: {
                status: 'success',
                title: 'Modification(s) effectuée(s)',
                message:
                  'Vos informations personnelles ont été mis à jour avec succès.',
              },
            });
            successDialog.afterClosed().subscribe(() => {
              this.dialog.closeAll();
              window.location.reload();
            });
          } else {
            this.dialog.closeAll();
            const errorDialog = this.dialog.open(CustomPopupComponent, {
              disableClose: true,
              hasBackdrop: true,
              height: '70%',
              panelClass: 'custom-modalbox',
              data: {
                status: 'error',
                title: "Une erreur s'est produite",
                message:
                  'Nous sommes désolé. Veuillez vérifier si vos informations sont correctes ou réessayer ultérieurement.',
              },
            });
          }
        });
    }
    // IF CURRENT STATE == 'PROFESIONAL_INFORMATIONS'
    if (this.current_state === 'PROFESIONAL_INFORMATIONS') {
      this.userModel.transformStatus();
      this.userService
        .editProfesionalInformations(this.userModel)
        .subscribe((res: any) => {
          console.log(res);

          if (res && res.data) {
            console.log('RES', res.data);
            this.dialog.closeAll();
            const successDialog = this.dialog.open(CustomPopupComponent, {
              disableClose: true,
              hasBackdrop: true,
              height: '70%',
              panelClass: 'custom-modalbox',
              data: {
                status: 'success',
                title: 'Modification(s) effectuée(s)',
                message:
                  'Vos informations personnelles ont été mis à jour avec succès.',
              },
            });
            successDialog.afterClosed().subscribe(() => {
              this.dialog.closeAll();
              window.location.reload();
            });
          } else {
            this.dialog.closeAll();
            const errorDialog = this.dialog.open(CustomPopupComponent, {
              disableClose: true,
              hasBackdrop: true,
              height: '70%',
              panelClass: 'custom-modalbox',
              data: {
                status: 'error',
                title: "Une erreur s'est produite",
                message:
                  'Nous sommes désolé. Veuillez vérifier si vos informations sont correctes ou réessayer ultérieurement.',
              },
            });
          }
        });
    }
    // IF CURRENT STATE == 'PROFESIONAL_INFORMATIONS'
    if (this.current_state === 'UPDATE_PASSWORD') {
      this.changePassword();
    }
    // IF CURRENT STATE == 'PROFESIONAL_INFORMATIONS'
    if (this.current_state === 'UPDATE_EMAIL') {
      this.cognitoUpdateEmail();
    }
    // IF CURRENT STATE == 'VALIDATE NEW EMAIL'
    if (this.current_state === 'VALIDATE_NEW_EMAIL') {
      this.validateNewEmail();
    }
    // else if (
    //   this.current_state === 'PERSONAL_INFORMATIONS' &&
    //   this.email_update
    // ) {
    //   this.userService.editEmail(this.userModel).subscribe((res: any) => {
    //     if (res && res.data.updateEmail) {
    //       console.log('RES', res.data.updateEmail);
    //     }
    //   });
    // } else if (
    //   this.current_state === 'PROFESIONAL_INFORMATIONS'
    // ) {
    //   this.userService.editEmail(this.userModel).subscribe((res: any) => {
    //     if (res && res.data.updateEmail) {
    //       console.log('RES', res.data.updateEmail);
    //     }
    //   });
    // }
  }

  // EDIT EMAIL OR PASSWORD
  toggleReadOnly($event: any): void {
    if ($event.label_name === 'email') {
      this.current_state = 'UPDATE_EMAIL';
    } else if ($event.label_name === 'password') {
      this.current_state = 'UPDATE_PASSWORD';
    }
  }

  // Change password function
  changePassword() {
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Veuillez patienter ... ',
        isLoader: true,
      },
    });
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(
          user,
          this.userModel.Old_password,
          this.userModel.New_password
        );
      })
      .then((data) => {
        console.log('SUCCESS', data);
        const successDialog = this.dialog.open(CustomPopupComponent, {
          disableClose: true,
          hasBackdrop: true,
          height: '70%',
          panelClass: 'custom-modalbox',
          data: {
            status: 'success',
            title: 'Mot de passe',
            message: 'Votre mot de passe a été modifié avec succès',
          },
        });

        successDialog.afterClosed().subscribe(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        console.log('ERROR ' + error);
        this.dialog.closeAll();
        this.dialog.open(CustomPopupComponent, {
          disableClose: true,
          hasBackdrop: true,
          height: '70%',
          panelClass: 'custom-modalbox',
          data: {
            status: 'error',
            title: "Une erreur s'est produite",
            message:
              'Nous sommes désolé. Veuillez vérifier votre mot de passe ou réessayer ultérieurement.',
          },
        });
      });
  }

  // Cognito update Email
  async cognitoUpdateEmail() {
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Veuillez patienter ... ',
        isLoader: true,
      },
    });
    let attributes = {
      email: this.userModel.New_email,
      email_verified: false,
    };
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log(user);
        Auth.updateUserAttributes(user, attributes).then((data) => {
          console.log(data);
          this.updateEmail();
        });
      })
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        if (
          err ==
          'UsernameExistsException: An account with the given email already exists.'
        ) {
          console.log('ERROR ALREADY EXISTS' + err);
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: "Une erreur s'est produite",
              message:
                "L'adresse email que vous avez choisi est déjà utilisée. Veuillez en choisir une autre.",
            },
          });
        } else {
          console.log('error updateEmail: ', err);
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: "Une erreur s'est produite",
              message: 'Nous sommes désolé. Veuillez réessayer ultérieurement.',
            },
          });
        }
      });
  }

  // Update Email function
  updateEmail() {
    this.userService
      .editEmail(this.userModel.New_email, this.userModel.ID)
      .subscribe((res: any) => {
        console.log(res);
        if (res && res.data.updateEmail) {
          console.log(res);
          this.dialog.closeAll();
          const successDialog = this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'success',
              title: 'Code OTP',
              message:
                'Un code de sécurité vous a été envoyé sur votre nouvelle adresse email.',
            },
          });

          successDialog.afterClosed().subscribe(() => {
            this.current_state = 'VALIDATE_NEW_EMAIL';
            const user = this.localStorageService.getCurrentUser();
            user.email = this.userModel.New_email;
            this.localStorageService.setCurrentUser(user);
          });
        } else {
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: "Une erreur s'est produite",
              message:
                'Nous sommes désolé. Veuillez vérifier votre adresse email ou réessayer ultérieurement.',
            },
          });
        }
      });
  }

  // Validate New Email
  validateNewEmail() {
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Veuillez patienter ... ',
        isLoader: true,
      },
    });
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.verifyUserAttributeSubmit(
          user,
          'email',
          this.userModel.Otp_code.join(', ').replaceAll(', ', '')
        );
      })
      .then((data) => {
        console.log(data);
        this.userService
          .validateNewAddressEmail(this.userModel.ID)
          .subscribe((res: any) => {
            console.log(res);

            if (res && res.data.validateNewAddressEmail) {
              console.log(res);
              this.dialog.closeAll();
              const successDialog = this.dialog.open(CustomPopupComponent, {
                disableClose: true,
                hasBackdrop: true,
                height: '70%',
                panelClass: 'custom-modalbox',
                data: {
                  status: 'success',
                  title: 'Adresse email',
                  message:
                    'Votre adresse email a été modifié avec succès. Veuillez vous reconnecter.',
                },
              });

              successDialog.afterClosed().subscribe(() => {
                this.router.navigate(['/my-account']);
              });
            } else {
              this.dialog.closeAll();
              this.dialog.open(CustomPopupComponent, {
                disableClose: true,
                hasBackdrop: true,
                height: '70%',
                panelClass: 'custom-modalbox',
                data: {
                  status: 'error',
                  title: "Une erreur s'est produite",
                  message:
                    'Nous sommes désolé. Veuillez vérifier votre adresse email ou réessayer ultérieurement.',
                },
              });
            }
          });
      })
      .catch((err) => {
        console.log('ERROR verifyUserAttributeSubmit: ', err);
        this.dialog.closeAll();
        this.dialog.open(CustomPopupComponent, {
          disableClose: true,
          hasBackdrop: true,
          height: '70%',
          panelClass: 'custom-modalbox',
          data: {
            status: 'error',
            title: "Une erreur s'est produite",
            message:
              'Nous sommes désolé. Veuillez vérifier votre code de sécurité.',
          },
        });
      });
  }
}
