import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-day-calendar',
  templateUrl: './day-calendar.component.html',
  styleUrls: ['./day-calendar.component.scss'],
})
export class DayCalendarComponent implements OnInit {
  @Input() day_name: string;
  @Input() day_number: string;
  @Input() date: any;
  @Input() daysClasses = '';
  @Input() selectDate = '';
  @Output() IsClicked = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  /**
   * selectedDate
   */
  public selectedDate($date: any) {
    const data = {
      day: this.day_name,
      number: this.day_number,
      date: this.date,
      selectedDate: this.selectDate,
    };
    this.IsClicked.emit(data);
  }
}
