<div [@fade] class="bg-white h-screen px-6 overflow-auto">
  <div class="pt-4 mb-8">
    <app-back-button (IsClicked)="back($event)"></app-back-button>
  </div>
  <form [formGroup]="myForm">
    <!-- (EMAIL => INSCRIPTION) OR (EMAIL AND PASSWORD => CONNEXION)   -->
    <div [@fade] *ngIf="current_step == 'EMAIL'">
      <app-title
        *ngIf="authentication_choice == 'INSCRIPTION'"
        titleClasses="txt-bold-gray text-left w-4/6 mb-16"
        text="Bienvenue sur Infiny Patrimoine"
      ></app-title>
      <app-title
        *ngIf="authentication_choice == 'CONNEXION'"
        titleClasses="txt-bold-gray text-left w-4/6 mb-16"
        text="Bon retour parmis nous !"
      ></app-title>
      <div
        class="
          flex
          justify-between
          rounded-3xl
          bg-extra-ligth-gray 
          w-full
          mx-auto
          mb-16
        "
      >
        <app-primary-button
          (IsClicked)="authenticationChoice('INSCRIPTION')"
          text="Inscription"
          [buttonClasses]="
            authentication_choice == 'INSCRIPTION'
              ? 'bg-light-blue text-white items-center w-40 py-3'
              : 'bg-extra-ligth-gray txt-bold-gray items-center w-40 py-3'
          "
          [disabled]="false"
          [icon_visibility]="false"
        ></app-primary-button>
        <app-primary-button
          (IsClicked)="authenticationChoice('CONNEXION')"
          text="Connexion"
          [buttonClasses]="
            authentication_choice == 'CONNEXION'
              ? 'bg-light-blue text-white items-center w-40 py-3'
              : 'bg-extra-ligth-gray txt-bold-gray items-center w-40 py-3'
          "
          [disabled]="false"
          [icon_visibility]="false"
        ></app-primary-button>
      </div>

      <app-text
        *ngIf="authentication_choice == 'INSCRIPTION'"
        textClasses="txt-percian-gray  mb-16"
        text="Pour rejoindre l’aventure à l’Infiny, saisissez votre email. 
    Pas de doute, c’est sur et sécurisé !"
      ></app-text>
      <app-text
        *ngIf="authentication_choice == 'CONNEXION'"
        textClasses="txt-percian-gray  mb-16"
        text="Pour vous authentifier, saisissez votre email et votre mot de passe."
      ></app-text>
      <div class="mx-auto mt-12">
        <app-input
          [formFields]="myForm.controls.formFields"
          label_text="Adresse email"
          [model]="userModel.Email"
          label_name="email"
          name="email"
          placeholder="toto@exemple.com"
          (Changed)="onChange($event)"
          value="EMAIL"
        ></app-input>
      </div>
      <div *ngIf="authentication_choice == 'CONNEXION'" class="mx-auto mt-4">
        <app-input
          label_text="Mot de passe"
          [model]="userModel.Password"
          type="password"
          label_name="password"
          name="password"
          (Changed)="onChange($event)"
          value="PASSWORD"
        ></app-input>
        <span
          (click)="switchCurrentStep('MISSING_PASSWORD')"
          class="
            txt-light-blue
            underline
            font-semibold
            text-xs
            cursor-pointer
          "
          >Mot de passe oublié ?</span
        >
      </div>
      <div
        *ngIf="authentication_choice == 'INSCRIPTION'"
        class="mt-7 mx-auto flex gap-4 items-center justify-end"
      >
        <div class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="userModel.Email ? false : true"
          (IsClicked)="switchCurrentStep('PASSWORD')"
        ></app-next-button>
      </div>
      <div
        *ngIf="authentication_choice == 'CONNEXION'"
        class="mt-7 mx-auto flex justify-end"
      >
        <app-next-button
          [disabled]="userModel.Email && userModel.Password ? false : true"
          (IsClicked)="login()"
        ></app-next-button>
      </div>
      <p
        *ngIf="authentication_choice == 'INSCRIPTION'"
        class="txt-percian-gray text-xs text-center mt-8"
      >
        En vous inscrivant, vous acceptez les
        <a href="#" class="underline font-semibold">CGU</a> à l’utilisation de
        notre plateforme. Consultez notre
        <a href="#" class="underline font-semibold"
          >Politique de confidentialité</a
        >
        au sujet de la gestion des vos données personnelles
      </p>
      <div *ngIf="authentication_choice == 'CONNEXION'">
        <app-text-cgu></app-text-cgu>
      </div>
    </div>
    <!-- (PASSWORD AND PASSWORD CONFIRM => INSCRIPTION)   -->
    <div
      [@fade]
      *ngIf="
        current_step == 'PASSWORD' && authentication_choice == 'INSCRIPTION'
      "
    >
      <div class="mx-auto mb-16">
        <app-img
          imgPath="assets/icons/svg/lock.svg"
          imgClasses="h-20"
        ></app-img>
      </div>
      <app-title
        titleClasses="txt-bold-gray text-left w-4/6 mb-16"
        text="Saisissez un mot de passe"
      ></app-title>
      <app-text
        textClasses="txt-percian-gray mb-16"
        text="Astuces mot de passe sécurisé :
      Pensez à entrer un majuscule, une minuscule et un caractère spécial."
      ></app-text>
      <div class="mx-auto mt-12">
        <app-input
          [formFields]="myForm.controls.formFields"
          label_text="Votre mot de passe"
          model="{{ userModel.Password }}"
          type="password"
          label_name="password"
          name="password"
          (Changed)="onChange($event)"
          value="PASSWORD"
        ></app-input>
      </div>
      <div class="mx-auto mt-4">
        <app-input
          [formFields]="myForm.controls.formFields"
          label_text="Confirmer votre mot de passe"
          model="{{ userModel.Password_confirm }}"
          type="password"
          label_name="password_confirm"
          name="password_confirm"
          (Changed)="onChange($event)"
          value="PASSWORD_CONFIRM"
        ></app-input>
      </div>
      <div class="mt-8 mx-auto gap-4 items-center flex justify-end">
        <div *ngIf="authentication_choice == 'INSCRIPTION'" class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="
            userModel.Password_confirm === userModel.Password ? false : true
          "
          (IsClicked)="switchCurrentStep('OTP_CONFIRM')"
        ></app-next-button>
      </div>
      <app-text-cgu></app-text-cgu>
    </div>
    <!-- (OTP CODE CONFIRM => INSCRIPTION)   -->
    <div
      [@fade]
      *ngIf="
        current_step == 'OTP_CONFIRM' && authentication_choice == 'INSCRIPTION'
      "
    >
      <div class="mx-auto mb-16">
        <app-img
          imgPath="assets/icons/svg/lock.svg"
          imgClasses="h-20"
        ></app-img>
      </div>
      <app-title
        titleClasses="txt-bold-gray text-left w-4/6 mb-16"
        text="Entrer votre code de confirmation"
      ></app-title>
      <app-text
        textClasses="txt-percian-gray  mb-16"
        text="Où se trouve mon code de confirmation ?
        Vous allez recevoir un mail contenant votre code sécurisé. Saisissez le."
      ></app-text>
      <app-input-otp
        [otpFormFields]="myForm.controls.otpFormFields"
        (Changed)="onChange($event)"
        value="OTP_CODE"
      ></app-input-otp>
      <span
        (click)="resendSignUp()"
        class="
          txt-light-blue
          underline
          font-semibold
          text-xs
          cursor-pointer
          mx-4
        "
        >Renvoyer le code</span
      >
      <div class="mt-16 mx-auto flex gap-4 items-center justify-end">
        <div *ngIf="authentication_choice == 'INSCRIPTION'" class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="userModel.Otp_code ? false : true"
          (IsClicked)="switchCurrentStep('USERNAME')"
        ></app-next-button>
      </div>
      <app-text-cgu cguClasses="mt-12"></app-text-cgu>
    </div>
    <!-- USERNAME => INSCRIPTION -->
    <div
      [@fade]
      *ngIf="
        current_step == 'USERNAME' && authentication_choice == 'INSCRIPTION'
      "
    >
      <div class="mx-auto mb-16">
        <app-img
          imgPath="assets/icons/svg/profile.svg"
          imgClasses="h-20"
        ></app-img>
      </div>
      <app-title
        titleClasses="txt-bold-gray text-left w-4/6 mb-16"
        text="Comment vous appelez-vous ?"
      ></app-title>
      <app-text
        textClasses="txt-percian-gray  mb-16"
        text="Complétez les informations de votre compte."
      ></app-text>
      <div class="mx-auto mt-12">
        <app-input
          [formFields]="myForm.controls.formFields"
          label_text="Prénom"
          model="{{ userModel.Firstname }}"
          type="text"
          label_name="firstname"
          name="firstname"
          (Changed)="onChange($event)"
          value="FIRSTNAME"
        ></app-input>
      </div>
      <div class="mx-auto mt-4">
        <app-input
          [formFields]="myForm.controls.formFields"
          label_text="Nom"
          model="{{ userModel.Lastname }}"
          type="text"
          label_name="lastname"
          name="lastname"
          (Changed)="onChange($event)"
          value="LASTNAME"
        ></app-input>
      </div>
      <div class="mt-8 mx-auto flex gap-4 items-center justify-end">
        <div *ngIf="authentication_choice == 'INSCRIPTION'" class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          *ngIf="user_type !== 'PROFESSIONNEL'"
          [disabled]="userModel.Firstname && userModel.Lastname ? false : true"
          (IsClicked)="switchCurrentStep('CREATE_ACCOUNT')"
        ></app-next-button>
        <app-next-button
          *ngIf="user_type === 'PROFESSIONNEL'"
          [disabled]="userModel.Firstname && userModel.Lastname ? false : true"
          (IsClicked)="switchCurrentStep('AGENCY')"
        ></app-next-button>
      </div>
      <app-text-cgu></app-text-cgu>
    </div>
    <!-- AGENCY => INSCRIPTION && USER_TYPE => PROFESSIONNEL -->
    <div
      [@fade]
      *ngIf="current_step == 'AGENCY' && authentication_choice == 'INSCRIPTION'"
    >
      <div class="mx-auto mb-16">
        <app-img
          imgPath="assets/icons/svg/case.svg"
          imgClasses="h-20"
        ></app-img>
      </div>
      <app-title
        titleClasses="txt-bold-gray text-left w-4/6 mb-16"
        text="Souhaitez-vous vous associer à une agence ?"
      ></app-title>
      <app-text
        textClasses="txt-percian-gray  mb-16"
        text="Sélectionner ou ajouter une agence. "
      ></app-text>
      <!-- INPUT RECHERCHE AGENCE -->
      <div *ngIf="!agencyModel.ID">
        <app-input-search
          [formFields]="myForm.controls.agencyID"
          placeholder="Rechercher une agence"
          model="{{ agencyModel.ID }}"
          label_name="agency_id"
          name="agency_id"
          (Changed)="onSearchTextChange($event)"
          value="AGENCY_ID"
        ></app-input-search>
      </div>
      <!-- LISTE AGENCES RECHERCHEES -->
      <div *ngIf="agencyListArray && agencyListArray.length > 0">
        <div class="my-8 mx-8">
          <div
            *ngIf="agencyModel.ID"
            class="flex flex-col items-center justify-center gap-2 mx-4"
          >
            <app-title
              titleClasses="txt-bold-gray text-center w-full mx-auto mb-8"
              text="Vous êtes désormais associer à l’agence :"
            ></app-title>
            <app-tertiary-card
              title="{{ agencyName }}"
              cardClasses="bg-card-green-gradient"
              titleClasses="text-center text-white font-bold uppercase"
            ></app-tertiary-card>
          </div>
          <div *ngIf="!agencyName">
            <div
              *ngFor="let agency of agencyListArrayFiltered"
              class="cursor-pointer rounded-full bg-gray-100 p-2 overflow-auto"
            >
              <div
                (click)="onSelectAgency(agency.ID, agency.Agency_name)"
                class="flex items-center justify-between mx-4"
              >
                <p class="txt-percian-gray p-2">
                  {{ agency.Agency_name }}
                </p>
                <img class="h-7" src="assets/icons/svg/add-cross.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- AGENCY NOT FOUND -->
      <div
        *ngIf="
          agencyListArrayFiltered &&
          agencyListArrayFiltered.length === 0 &&
          agencyListArray.length !== 0
        "
        class="w-full"
      >
        <div class="flex pb-6 mx-8">
          <p class="txt-bold-gray">
            Aucune agence ne correspond à cette recherche
          </p>
        </div>
      </div>
      <!-- SPAN => AJOUTER UNE AGENCE -->
      <span
        *ngIf="!agencyModel.ID"
        (click)="switchCurrentStep('CREATE_AGENCY')"
        class="
          txt-light-blue
          underline
          font-semibold
          text-xs
          cursor-pointer
          mx-4
        "
        >Ajouter une agence</span
      >
      <!-- SPAN CHANGE AGENCY -->
      <span
        *ngIf="agencyModel.ID"
        (click)="removeAgencyId()"
        class="
          txt-light-blue
          underline
          font-semibold
          text-xs
          cursor-pointer
          mx-4
        "
        >Changer d'agence</span
      >
      <div class="mt-4 mx-auto flex gap-4 items-center justify-end">
        <div *ngIf="authentication_choice == 'INSCRIPTION'" class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="
            this.userModel.Firstname && this.userModel.Lastname ? false : true
          "
          (IsClicked)="switchCurrentStep('CREATE_ACCOUNT')"
        ></app-next-button>
      </div>
      <app-text-cgu></app-text-cgu>
    </div>
    <!-- CREATE_AGENCY => INSCRIPTION && USER_TYPE => PROFESSIONNEL -->
    <div
      [@fade]
      *ngIf="
        current_step == 'CREATE_AGENCY' &&
        authentication_choice == 'INSCRIPTION'
      "
    >
      <div class="mx-auto mb-16">
        <app-img
          imgPath="assets/icons/svg/case.svg"
          imgClasses="h-20"
        ></app-img>
      </div>
      <app-title
        titleClasses="txt-bold-gray text-left w-4/6 mb-16"
        text="Ajouter une agence"
      ></app-title>
      <div class="mx-auto mt-12">
        <app-input
          [formFields]="myForm.controls.formFields"
          label_text="Nom de l'agence"
          model="{{ agencyModel.Agency_name }}"
          type="text"
          label_name="agency_name"
          name="agency_name"
          (Changed)="onChange($event)"
          value="AGENCY_NAME"
        ></app-input>
      </div>
      <div class="mx-auto mt-4">
        <app-input
          [formFields]="myForm.controls.formFields"
          label_text="SIRET"
          model="{{ agencyModel.Siret }}"
          type="text"
          label_name="siret"
          name="siret"
          (Changed)="onChange($event)"
          value="SIRET"
          maxlength="17"
        ></app-input>
      </div>
      <div class="mt-8 mx-auto flex gap-4 items-center justify-end">
        <div *ngIf="authentication_choice == 'INSCRIPTION'" class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="
            agencyModel.Siret && agencyModel.Agency_name ? false : true
          "
          (IsClicked)="switchCurrentStep('CREATE_AGENCY_ADDRESS')"
        ></app-next-button>
      </div>
      <app-text-cgu></app-text-cgu>
    </div>
    <!-- CREATE_AGENCY_ADDRESS => INSCRIPTION && USER_TYPE => PROFESSIONNEL -->
    <div
      [@fade]
      *ngIf="
        current_step == 'CREATE_AGENCY_ADDRESS' &&
        authentication_choice == 'INSCRIPTION'
      "
    >
      <div class="mx-auto mb-16">
        <app-img
          imgPath="assets/icons/svg/marker.svg"
          imgClasses="h-20"
        ></app-img>
      </div>
      <app-title
        titleClasses="txt-bold-gray text-left w-4/6 mb-16"
        text="Ajouter l'adresse de l'agence"
      ></app-title>
      <div class="mx-auto mt-12">
        <app-input
          [formFields]="myForm.controls.formFields"
          label_text="Adresse postal"
          model="{{ addressModel.Address_location }}"
          type="text"
          label_name="address_location"
          name="address_location"
          (Changed)="onChange($event)"
          value="ADDRESS_LOCATION"
        ></app-input>
      </div>
      <div class="mx-auto mt-4">
        <app-input
          [formFields]="myForm.controls.formFields"
          label_text="Code postal"
          model="{{ addressModel.Postal_code }}"
          type="text"
          label_name="postal_code"
          name="postal_code"
          (Changed)="onChange($event)"
          value="POSTAL_CODE"
          maxlength="5"
        ></app-input>
      </div>
      <div class="mx-auto mt-4">
        <app-input
          [formFields]="myForm.controls.formFields"
          label_text="Ville"
          model="{{ addressModel.City }}"
          type="text"
          label_name="city"
          name="city"
          (Changed)="onChange($event)"
          value="CITY"
          maxlength="50"
        ></app-input>
      </div>
      <div class="mt-8 mx-auto flex gap-4 items-center justify-end">
        <div *ngIf="authentication_choice == 'INSCRIPTION'" class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="
            addressModel.Address_location &&
            addressModel.Postal_code &&
            addressModel.City
              ? false
              : true
          "
          (IsClicked)="switchCurrentStep('CREATE_ACCOUNT')"
        ></app-next-button>
      </div>
      <app-text-cgu></app-text-cgu>
    </div>
    <!-- CREATE_ACCOUNT => INSCRIPTION  -->
    <div
      [@fade]
      *ngIf="
        current_step == 'CREATE_ACCOUNT' &&
        authentication_choice == 'INSCRIPTION'
      "
    >
      <div class="mx-auto mb-16">
        <app-img
          imgPath="assets/icons/svg/profile.svg"
          imgClasses="h-32 mx-auto"
        ></app-img>
      </div>
      <app-title
        titleClasses="txt-bold-gray text-center w-4/6 mx-auto mb-16"
        text="Création de votre compte"
      ></app-title>
      <app-text
        textClasses="txt-percian-gray  mx-auto mb-16 text-center"
        text="Votre compte a été créé avec succès. Veuillez accepter les Politiques de confidentialité."
      ></app-text>
      <div class="mx-auto mt-12">
        <app-checkbox
          text_content="Je confirme avoir lu et accepte les
        <span class='font-semibold underline'>Politiques de confidentialité</span>
        de Infiny Patrimoine"
          (Changed)="onChange($event)"
          value="PRIVACY_POLICY"
        ></app-checkbox>
      </div>
      <div class="mt-8 mx-auto flex justify-center mb-4">
        <app-primary-button
          [disabled]="privacy_policy_accepted ? false : true"
          text="Continuer"
          buttonClasses="bg-light-blue text-white items-center"
          firstImgSrc="assets/icons/svg/arrow-right.svg"
          secondImgSrc="assets/icons/svg/arrow-right.svg"
          (IsClicked)="switchCurrentStep('GO_HOME')"
        ></app-primary-button>
      </div>
      <app-text-cgu></app-text-cgu>
    </div>
    <!-- (MISSING_PASSWORD => EMAIL FIRST_STEP )  -->
    <div [@fade] *ngIf="current_step == 'MISSING_PASSWORD'">
      <app-title
        titleClasses="txt-bold-gray text-left w-4/6 mb-7"
        text="Mot de passe oublié !"
      ></app-title>
      <app-text
        textClasses="txt-percian-gray mb-16"
        text="Veuillez entrer l'adresse email associée au mot de passe oublié."
      ></app-text>
      <div class="mx-auto">
        <app-input
          [formFields]="myForm.controls.formFields"
          label_text="Adresse email"
          model="{{ userModel.Email }}"
          label_name="email"
          name="email"
          placeholder="toto@exemple.com"
          (Changed)="onChange($event)"
          value="EMAIL"
        ></app-input>
      </div>
      <div class="mt-6 mb-7 mx-auto flex justify-end">
        <app-next-button
          [disabled]="userModel.Email ? false : true"
          (IsClicked)="switchCurrentStep('CHANGE_MISSING_PASSWORD')"
        ></app-next-button>
      </div>
      <app-text-cgu></app-text-cgu>
    </div>
    <!-- (MISSING_PASSWORD => OTP CODE SECOND_STEP )  -->
    <div [@fade] *ngIf="current_step == 'CHANGE_MISSING_PASSWORD'">
      <app-title
        titleClasses="txt-bold-gray text-left w-4/6 mb-8"
        text="Mot de passe oublié !"
      ></app-title>
      <app-text
        textClasses="txt-percian-gray  mb-16"
        text="Où se trouve mon code de confirmation ?
          Vous allez recevoir un mail contenant votre code sécurisé. Saisissez le ainsi que votre adresse email et votre nouveau mot de passe."
      ></app-text>
      <app-input-otp
        [otpFormFields]="myForm.controls.otpFormFields"
        (Changed)="onChange($event)"
        value="OTP_CODE"
      ></app-input-otp>
      <div class="mx-auto mt-12">
        <app-input
          [formFields]="myForm.controls.formFields"
          label_text="Votre nouveau mot de passe"
          model="{{ userModel.Password }}"
          type="password"
          label_name="password"
          name="password"
          (Changed)="onChange($event)"
          value="PASSWORD"
        ></app-input>
      </div>
      <div class="mx-auto mt-4">
        <app-input
          [formFields]="myForm.controls.formFields"
          label_text="Confirmer votre nouveau mot de passe"
          model="{{ userModel.Password_confirm }}"
          type="password"
          label_name="password_confirm"
          name="password_confirm"
          (Changed)="onChange($event)"
          value="PASSWORD_CONFIRM"
        ></app-input>
      </div>
      <div class="mt-6 mx-auto flex justify-end">
        <app-next-button
          [disabled]="
            userModel.Otp_code &&
            userModel.Password_confirm === userModel.Password
              ? false
              : true
          "
          (IsClicked)="changeMissingPassword()"
        ></app-next-button>
      </div>
      <app-text-cgu></app-text-cgu>
    </div>
  </form>
</div>
