<div
(click)="onClick($event)"
  [ngClass]="cardClasses"
  class="relative flex flex-col justify-center rounded-3xl w-40 h-40 mx-auto"
>
  <img *ngIf="img_visibility" [ngClass]="imgPathClasses" class="absolute right-0 -top-2 h-4" [src]="imgPath" />
  <img *ngIf="icon_visibility" [ngClass]="imgClasses" [src]="imgSrc" />
  <p [ngClass]="titleClasses">
    {{ title }}
  </p>
  <p
    *ngIf="subtitleClasses !== '' ? 'block' : 'hidden'"
    [ngClass]="subtitleClasses"
    class="text-center text-white font-semibold"
  >
    {{ subtitle }}
  </p>
</div>
