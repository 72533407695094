import { CookieService } from './../../services/cookie.service';
import { environment } from './../../../../environments/environment.prod';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
})
export class CookieBannerComponent implements OnInit {
  public answered: boolean = false;
  public url = environment.url;

  constructor(private cookiesService: CookieService) {}

  ngOnInit(): void {
    this.answered = this.cookiesService.hasAnswered() ? true : false;
  }

  onAccept = () => {
    this.cookiesService.accept();
    this.answered = true;
  };

  onDecline = () => {
    this.cookiesService.decline();
    this.answered = true;
  };

  onChangeAnswer = () => {
    this.answered = false;
  };

  privacyPolicies = () => {
    window.open(
      environment.url + 'assets-upload/Politique_de_cookies.pdf',
      '_blank'
    );
  };
}
