<div
  [ngClass]="property_IsSelected ? 'border border-light-blue' : ''"
  class="bg-white shadow-md flex flex-col mb-6 rounded-2xl cursor-pointer"
  (click)="onClick($event)"
>
  <!-- Property main photo -->
  <div class="relative">
    <!-- SWIPER -->
    <swiper
      [grabCursor]="true"
      [effect]="'creative'"
      [creativeEffect]="{
        prev: {
          shadow: true,
          translate: [0, 0, -400]
        },
        next: {
          translate: ['100%', 0, 0]
        }
      }"
      [pagination]="{
        dynamicBullets: true
      }"
      class="mySwiper"
      [navigation]="true"
    >
      <!-- SLIDES -->
      <ng-template [ngClass]="[imgBlockClasses]" swiperSlide>
        <img
          [src]="assets_path"
          style="object-fit: cover !important"
          [ngClass]="[imgClasses]"
          class="w-full rounded-xl overflow-auto"
        />
      </ng-template>
    </swiper>
    <div
      class="absolute flex justify-start left-4 top-3 opacity-50 gap-3"
      style="z-index: 999"
    >
      <img
        (click)="likeOrUnlike($event)"
        *ngIf="isFavorite"
        src="assets/icons/svg/like.svg"
        class="h-6 cursor-pointer"
      />
      <img
        (click)="likeOrUnlike($event)"
        *ngIf="!isFavorite"
        src="assets/icons/svg/unlike.svg"
        class="h-6 cursor-pointer"
      />
    </div>
    <!-- COUNT -->
    <div
      class="absolute flex justify-start left-4 bottom-8 opacity-50 gap-3"
      style="z-index: 999"
    >
      <p
        class="p-2 w-8 rounded-full bg-white txt-bold-gray text-xxs text-center"
      >
        {{ assets_length }}
      </p>
      <p class="p-2 rounded-xl bg-white txt-bold-gray text-xxs">
        {{ published_date }}
      </p>
    </div>
  </div>
  <!-- Property attibutes -->
  <div class="flex flex-wrap gap-4 mx-4 mb-4">
    <div class="flex items-center gap-3">
      <img
        [src]="attributes_assets_path"
        class="h-4"
        [ngClass]="[imgAttributesClasses]"
      />
      <p [ngClass]="[txtClasses]">{{ price }}</p>
    </div>
    <div class="flex items-center gap-3">
      <img
        [src]="attributes_assets_path"
        class="h-4"
        [ngClass]="[imgAttributesClasses]"
      />
      <p [ngClass]="[txtClasses]">{{ pieces_number }}</p>
    </div>
    <div class="flex items-center gap-3">
      <img
        [src]="attributes_assets_path"
        class="h-4"
        [ngClass]="[imgAttributesClasses]"
      />
      <p [ngClass]="[txtClasses]">{{ total_area }}</p>
    </div>
    <div class="flex items-center gap-3">
      <img
        [src]="attributes_assets_path"
        class="h-4"
        [ngClass]="[imgAttributesClasses]"
      />
      <p [ngClass]="[txtClasses]">{{ property_subtype }}</p>
    </div>
    <div class="flex items-center gap-3">
      <img
        [src]="attributes_assets_path"
        class="h-4"
        [ngClass]="[imgAttributesClasses]"
      />
      <p [ngClass]="[txtClasses]">{{ location }}</p>
    </div>
  </div>
</div>
