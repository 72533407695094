<div [@fade] class="h-screen w-full mx-auto bg-white overflow-auto">
  <div class="border-b border-solid pb-2">
    <div class="mx-6">
      <app-secondary-header
        (IsClicked)="back()"
        headerClasses=" pt-4 flex justify-between items-cente mb-2"
      ></app-secondary-header>
      <div class="mx-auto text-center">
        <p class="font-bold text-xl txt-bold-gray">
          {{ title }}
        </p>
      </div>
    </div>
  </div>
  <form>
    <!-- SALE OR RENT STEP -->
    <div
      [@fade]
      *ngIf="current_step === 'SALE' || current_step === 'RENT'"
      class="mx-6"
    >
      <app-text
        textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
        text="Quel type de bien souhaitez-vous mettre en vente ? *"
      ></app-text>
      <div class="grid grid-cols-2 gap-3">
        <div *ngFor="let property of propertyTypeArray">
          <app-form-card
            [control_name]="property.controlName"
            [imgPath]="property.path_img"
            imgClasses="h-16 mx-auto"
            cardClasses="default"
            [model]="property.item_value"
            subtitleClasses="font-semibold text-center text-base w-full mx-auto"
            (IsClicked)="onChange($event)"
            [value]="property.type"
            [item_id]="property.item_value_db"
            [property_item]="propertyModel.Property_type"
          ></app-form-card>
        </div>
        <app-text
          textClasses="txt-percian-gray text-xxs mx-auto text-left"
          text="Champs requis*"
        ></app-text>
      </div>
      <div class="mx-auto items-center gap-3 mt-6 mb-7 flex justify-end w-full">
        <div class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="propertyModel.Property_type ? false : true"
          (click)="switchStep()"
        ></app-next-button>
      </div>
    </div>
    <!-- HOUSE STEP -->
    <div [@fade] *ngIf="current_step === 'HOUSE'" class="mx-6">
      <app-text
        textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
        text="Quel type de maison/villa mettez-vous en vente ? *"
      ></app-text>
      <div class="grid grid-cols-2 gap-3">
        <div *ngFor="let property of houseStepArray">
          <app-form-card
            control_name="{{ property.controlName }}"
            imgPath="{{ property.path_img }}"
            imgClasses="h-16 mx-auto"
            cardClasses="default"
            model="{{ property.item_value }}"
            subtitleClasses="font-semibold text-center text-base w-4/5 mx-auto"
            (IsClicked)="onChange($event)"
            value="{{ property.type }}"
            item_id="{{ property.item_value_db }}"
            property_item="{{ propertyModel.Property_subtype }}"
          ></app-form-card>
        </div>
        <app-text
          textClasses="txt-percian-gray text-xxs mx-auto text-left"
          text="Champs requis*"
        ></app-text>
      </div>
      <div class="mx-auto gap-3 items-center flex justify-end mb-7 mt-6">
        <div class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="propertyModel.Property_subtype ? false : true"
          (click)="switchStep()"
        ></app-next-button>
      </div>
    </div>
    <!-- APPARTMENT STEP -->
    <div [@fade] *ngIf="current_step === 'APPARTMENT'" class="h-full mx-6">
      <app-text
        textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
        text="Quel type d'appartement mettez-vous en vente ? *"
      ></app-text>
      <div class="grid grid-cols-2 gap-3">
        <div *ngFor="let property of appartmentStepArray">
          <app-form-card
            control_name="{{ property.controlName }}"
            imgPath="{{ property.path_img }}"
            imgClasses="h-16 mx-auto"
            cardClasses="default"
            model="{{ property.item_value }}"
            subtitleClasses="font-semibold text-center text-base w-4/5 mx-auto"
            (IsClicked)="onChange($event)"
            value="{{ property.type }}"
            item_id="{{ property.item_value_db }}"
            property_item="{{ propertyModel.Property_subtype }}"
          ></app-form-card>
        </div>
        <app-text
          textClasses="txt-percian-gray text-xxs mx-auto text-left"
          text="Champs requis*"
        ></app-text>
      </div>
      <div class="mx-auto gap-3 flex items-center justify-end mb-7 mt-6">
        <div class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="propertyModel.Property_subtype ? false : true"
          (click)="switchStep()"
        ></app-next-button>
      </div>
    </div>
    <!-- OFFICE STEP -->
    <div [@fade] *ngIf="current_step === 'OFFICE'" class="gap-3 mx-6">
      <app-text
        textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
        text="Quel type de bureaux mettez-vous en vente ? *"
      ></app-text>
      <div class="grid grid-cols-2 gap-3">
        <div *ngFor="let property of officeStepArray">
          <app-form-card
            control_name="{{ property.controlName }}"
            imgPath="{{ property.path_img }}"
            imgClasses="h-16 mx-auto"
            cardClasses="default"
            model="{{ property.item_value }}"
            subtitleClasses="font-semibold text-center text-base w-4/5 min-h-min-h-6 mx-auto"
            (IsClicked)="onChange($event)"
            value="{{ property.type }}"
            item_id="{{ property.item_value_db }}"
            property_item="{{ propertyModel.Property_subtype }}"
          ></app-form-card>
        </div>
        <app-text
          textClasses="txt-percian-gray text-xxs mx-auto text-left"
          text="Champs requis*"
        ></app-text>
      </div>
      <div class="mx-auto gap-3 flex items-center justify-end mb-7 mt-6">
        <div class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="propertyModel.Property_subtype ? false : true"
          (click)="switchStep()"
        ></app-next-button>
      </div>
    </div>
    <!-- LAND STEP -->
    <div [@fade] *ngIf="current_step === 'LAND'" class="gap-3 mx-6">
      <app-text
        textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
        text="Quel type de terrain mettez-vous en vente ? *"
      ></app-text>
      <div class="grid grid-cols-2 gap-3">
        <div *ngFor="let property of landStepArray">
          <app-form-card
            control_name="{{ property.controlName }}"
            imgPath="{{ property.path_img }}"
            imgClasses="h-16 mx-auto"
            cardClasses="default"
            model="{{ property.item_value }}"
            subtitleClasses="font-semibold text-center text-base min-h-min-h-6 w-4/5 mx-auto"
            (IsClicked)="onChange($event)"
            value="{{ property.type }}"
            item_id="{{ property.item_value_db }}"
            property_item="{{ propertyModel.Property_subtype }}"
          ></app-form-card>
        </div>
        <app-text
          textClasses="txt-percian-gray text-xxs mx-auto text-left"
          text="Champs requis*"
        ></app-text>
      </div>
      <div class="mx-auto gap-3 flex items-center justify-end mb-7 mt-6">
        <div class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="propertyModel.Property_subtype ? false : true"
          (click)="switchStep()"
        ></app-next-button>
      </div>
    </div>
    <!-- PROPERTY_STATE -->
    <div [@fade] *ngIf="current_step === 'PROPERTY_STATE'" class="gap-3 mx-6">
      <app-text
        textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
        text="Quel est l’état actuel du bien ? *"
      ></app-text>
      <div class="grid grid-cols-2 gap-3">
        <div *ngFor="let property of propertyStepArray">
          <app-form-card
            control_name="{{ property.controlName }}"
            imgPath="{{ property.path_img }}"
            imgClasses="h-16 mx-auto"
            cardClasses="default"
            model="{{ property.item_value }}"
            subtitleClasses="font-semibold text-center text-base w-4/5 mx-auto"
            (IsClicked)="onChange($event)"
            value="{{ property.type }}"
            item_id="{{ property.item_value_db }}"
            property_item="{{ propertyModel.Property_state }}"
          ></app-form-card>
        </div>
        <app-text
          textClasses="txt-percian-gray text-xxs mx-auto text-left"
          text="Champs requis*"
        ></app-text>
      </div>
      <div class="mx-auto gap-3 flex items-center justify-end mb-7 mt-6">
        <div class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="propertyModel.Property_state ? false : true"
          (click)="switchStep()"
        ></app-next-button>
      </div>
    </div>
    <!-- LAND_STATE -->
    <div [@fade] *ngIf="current_step === 'LAND_STATE'" class="gap-3 mx-6">
      <app-text
        textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
        text="Quel type de terrain mettez-vous en vente ? *"
      ></app-text>
      <div class="grid grid-cols-2 gap-3">
        <div *ngFor="let property of land_stepArray">
          <app-form-card
            control_name="{{ property.controlName }}"
            imgPath="{{ property.path_img }}"
            imgClasses="h-16 mx-auto"
            cardClasses="default"
            model="{{ property.item_value }}"
            subtitleClasses="font-semibold text-center text-base min-h-min-h-6 w-4/5 mx-auto"
            (IsClicked)="onChange($event)"
            value="{{ property.type }}"
            item_id="{{ property.item_value_db }}"
            property_item="{{ propertyModel.Property_state }}"
          ></app-form-card>
        </div>
      </div>
      <app-text
        textClasses="txt-percian-gray text-xxs mx-auto text-left mt-3"
        text="Champs requis*"
      ></app-text>
      <div class="mx-auto gap-3 flex items-center justify-end mb-7 mt-6">
        <div class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="propertyModel.Property_state ? false : true"
          (click)="switchStep()"
        ></app-next-button>
      </div>
    </div>
    <!-- SALE_PRICE STEP -->
    <div [@fade] *ngIf="current_step === 'SALE_PRICE'" class="gap-3 mx-6">
      <app-text
        textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
        text="A quel montant estimez-vous la valeur du bien ?"
      ></app-text>
      <div class="mx-auto flex justify-center">
        <app-img
          imgPath="assets/icons/svg/logo-1.svg"
          imgClasses="h-40"
        ></app-img>
      </div>
      <div class="mx-auto mt-16">
        <app-input
          label_text="Estimation du bien *"
          labelClasses="font-extrabold"
          [model]="propertyModel.Price"
          type="number"
          label_name="price"
          name="price"
          placeholder="Ex : 180 000 €"
          (Changed)="onChange($event)"
          value="PRICE"
          maxlength="10"
        ></app-input>
        <div class="mx-auto mt-6">
          <app-checkbox
            text_content="Je ne sais pas quel sera le montant du bien"
            contentClasses="txt-percian-gray text-xs gap-3 my-0 text-left"
            (Changed)="onChange($event)"
            value="WITHOUT_PRICE"
          ></app-checkbox>
        </div>
        <app-text
          textClasses="txt-percian-gray text-xxs mx-auto text-left mt-6"
          text="Champs requis*"
        ></app-text>
      </div>
      <div class="mx-auto gap-3 flex items-center justify-end mb-7">
        <div class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="propertyModel.Price ? false : true"
          (click)="switchStep()"
        ></app-next-button>
      </div>
    </div>
    <!-- RENT_PRICE STEP -->
    <div [@fade] *ngIf="current_step === 'RENT_PRICE'" class="gap-3 mx-6">
      <app-text
        textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
        text="A quel montant estimez-vous la valeur du bien ?"
      ></app-text>
      <div class="mx-auto flex justify-center">
        <app-img
          imgPath="assets/icons/svg/logo-1.svg"
          imgClasses="h-40"
        ></app-img>
      </div>
      <div class="mx-auto mt-16">
        <app-input
          label_text="Montant du loyer *"
          labelClasses="font-extrabold"
          [model]="propertyModel.Price"
          type="number"
          label_name="price"
          name="price"
          placeholder="Ex : 180 000 €"
          (Changed)="onChange($event)"
          value="PRICE"
          maxlength="10"
        ></app-input>
      </div>
      <div class="mx-auto mt-6">
        <app-input
          label_text="Montant des charges *"
          labelClasses="font-extrabold"
          [model]="propertyModel.Amount_of_charges"
          type="number"
          label_name="amount_of_charges"
          name="amount_of_charges"
          placeholder="Ex : 60 €"
          (Changed)="onChange($event)"
          value="AMOUNT_OF_CHARGES"
          maxlength="5"
        ></app-input>
        <div class="mx-auto mt-6">
          <app-checkbox
            text_content="Je ne sais pas quel sera le montant du bien"
            contentClasses="txt-percian-gray text-xs my-0 text-left"
            (Changed)="onChange($event)"
            value="WITHOUT_PRICE"
          ></app-checkbox>
        </div>
        <app-text
          textClasses="txt-percian-gray text-xxs mx-auto text-left mt-6"
          text="Champs requis*"
        ></app-text>
      </div>
      <div class="mx-auto gap-3 flex items-center justify-end mb-7">
        <div class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="
            propertyModel.Price && propertyModel.Amount_of_charges
              ? false
              : true
          "
          (click)="switchStep()"
        ></app-next-button>
      </div>
    </div>
    <!-- POSTAL CODE AND CITY STEP -->
    <div
      [@fade]
      *ngIf="current_step === 'POSTAL_CODE_AND_CITY'"
      class="gap-3 mx-6"
    >
      <app-text
        textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
        text="Ou est localisé le bien ?"
      ></app-text>
      <div class="mx-auto flex justify-center">
        <app-img
          imgPath="assets/icons/svg/logo-1.svg"
          imgClasses="h-40"
        ></app-img>
      </div>
      <div class="mx-auto mt-16">
        <div class="mx-auto mt-0">
          <app-input
            label_text="Code postal *"
            [model]="propertyModel.Postal_code"
            type="text"
            label_name="postal_code"
            name="postal_code"
            (Changed)="onChange($event)"
            value="POSTAL_CODE"
            placeholder="97200"
            maxlength="5"
          ></app-input>
        </div>
        <div class="mx-auto mt-6">
          <app-input
            label_text="Ville *"
            [model]="propertyModel.City"
            type="text"
            label_name="city"
            name="city"
            (Changed)="onChange($event)"
            value="CITY"
            placeholder="Fort-de-France"
            maxlength="50"
          ></app-input>
        </div>
        <app-text
          textClasses="txt-percian-gray text-xxs mx-auto text-left mt-3"
          text="Champs requis*"
        ></app-text>
      </div>
      <div class="mx-auto gap-3 flex items-center justify-end mb-7">
        <div class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="
            propertyModel.Postal_code && propertyModel.City ? false : true
          "
          (click)="switchStep()"
        ></app-next-button>
      </div>
    </div>
    <!-- PIECES_NUMBER STEP -->
    <div [@fade] *ngIf="current_step === 'PIECES_NUMBER'" class="gap-3 mx-6">
      <app-text
        textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
        text="De combien de pièces dispose votre bien ? *"
      ></app-text>
      <app-text
        textClasses="txt-percian-gray text-sm mx-auto text-center font-bold mt-16 mb-6"
        text="Salle de bain, dressing, cuisine, garage sont à exclure du nombre de pièces."
      ></app-text>
      <div class="mx-auto flex justify-center">
        <app-img
          imgPath="assets/icons/svg/logo-1.svg"
          imgClasses="h-40"
        ></app-img>
      </div>
      <div class="mx-auto mt-16 flex justify-center gap-2">
        <div *ngFor="let number of piecesNumberStepArray">
          <input
            type="text"
            class=""
            [ngClass]="
              propertyModel.Number_of_pieces !== number.item_value
                ? 'cursor-pointer border-2 border-solid mx-1 w-12 h-16 rounded-md text-center appearance-none outline-none txt-bold-gray'
                : 'cursor-pointer border-2 border-solid mx-1 w-12 h-16 rounded-md text-center appearance-none outline-none selected'
            "
            value="{{ number.item_value }}"
            (click)="getItemValue(number)"
            readonly
          />
        </div>
      </div>
      <div class="mx-auto">
        <app-text
          textClasses="txt-percian-gray text-xxs mx-auto text-left mt-3"
          text="Champs requis*"
        ></app-text>
      </div>
      <div class="mx-auto gap-3 flex items-center justify-end mb-7">
        <div class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="propertyModel.Number_of_pieces ? false : true"
          (click)="switchStep()"
        ></app-next-button>
      </div>
    </div>
    <!-- SUPERFICY STEP -->
    <div [@fade] *ngIf="current_step === 'SUPERFICY'" class="gap-3 mx-6">
      <app-text
        textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
        text="Quelle est la superficie de votre bien ? *"
      ></app-text>
      <div class="mx-auto flex justify-center">
        <app-img
          imgPath="assets/icons/svg/logo-1.svg"
          imgClasses="h-40"
        ></app-img>
      </div>
      <div class="mx-auto mt-16">
        <div class="mx-auto">
          <app-input
            [model]="propertyModel.Total_area"
            type="number"
            label_name="superficy"
            name="superficy"
            (Changed)="onChange($event)"
            value="SUPERFICY"
            placeholder="Ex : 70 m²"
            maxlength="4"
          ></app-input>
          <div class="mx-auto mt-6">
            <app-checkbox
              text_content="Je ne connais pas exactement la superficie du bien"
              contentClasses="txt-percian-gray text-xs my-0 text-left"
              (Changed)="onChange($event)"
              value="WITHOUT_SUPERFICY"
            ></app-checkbox>
          </div>
          <app-text
            textClasses="txt-percian-gray text-xxs mx-auto text-left mt-6"
            text="Champs requis*"
          ></app-text>
        </div>
      </div>
      <div class="mx-auto gap-3 flex items-center justify-end mt-9 mb-7">
        <div class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="propertyModel.Total_area ? false : true"
          (click)="switchStep()"
        ></app-next-button>
      </div>
    </div>
    <!-- CHARACTERISTICS STEP -->
    <div
      [@fade]
      *ngIf="
        current_step === 'CHARACTERISTICS_APPARTMENT' ||
        current_step === 'CHARACTERISTICS_OFFICE' ||
        current_step === 'CHARACTERISTICS_LAND'
      "
      class="mx-6"
    >
      <app-text
        textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
        text="Ajouter plus de filtres"
      ></app-text>
      <app-text
        textClasses="txt-percian-gray text-sm mx-auto text-center font-bold mb-6"
        text="Précisez votre annonce pour permettre aux acquéreurs de la trouver efficacement."
      ></app-text>
      <div class="mx-auto mb-16">
        <app-text
          textClasses="txt-percian-gray text-sm mx-auto text-left uppercase font-semibold mb-3"
          text="environnement"
        ></app-text>
        <!-- APPARTMENT OR HOUSE VIEW CHARACTERISTICS -->
        <div
          *ngIf="
            current_step === 'CHARACTERISTICS_APPARTMENT' ||
            current_step === 'CHARACTERISTICS_LAND'
          "
          class="flex flex-wrap justify-start gap-3"
        >
          <app-items
            type="text"
            model="Sans vis-à-vis"
            label_name="not_overlooked"
            name="not_overlooked"
            (Changed)="onChange($event)"
            value="NOT_OVERLOOKED"
            [isSelected]="propertyModel.Not_overlooked"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            type="text"
            model="Non - mitoyen"
            label_name="non_adjoining"
            name="non_adjoining"
            (Changed)="onChange($event)"
            value="NON_ADJOINING"
            [isSelected]="propertyModel.Non_adjoining"
            inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            type="text"
            model="Aperçu mer"
            label_name="overview_sea"
            name="overview_sea"
            (Changed)="onChange($event)"
            value="OVERVIEW_SEA"
            [isSelected]="propertyModel.Overview_sea"
            inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            type="text"
            model="Vue mer"
            label_name="sea_view"
            name="sea_view"
            (Changed)="onChange($event)"
            value="SEA_VIEW"
            [isSelected]="propertyModel.Sea_view"
            inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            type="text"
            model="Vue panoramique"
            label_name="panoramic_view"
            name="panoramic_view"
            (Changed)="onChange($event)"
            value="PANORAMIC_VIEW"
            [isSelected]="propertyModel.Panoramic_view"
            inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
        </div>
        <!-- OFFICE VIEW CHARACTERISTICS -->
        <div
          *ngIf="current_step === 'CHARACTERISTICS_OFFICE'"
          class="flex flex-wrap justify-start gap-3"
        >
          <app-items
            type="text"
            model="Zone industrielle"
            label_name="industrial_estate"
            name="industrial_estate"
            (Changed)="onChange($event)"
            value="INDUSTRIAL_ESTATE"
            [isSelected]="propertyModel.Industrial_estate"
            inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            type="text"
            model="Quartier d'affaire"
            label_name="business_district"
            name="business_district"
            (Changed)="onChange($event)"
            value="BUSINESS_DISTRICT"
            [isSelected]="propertyModel.Business_district"
            inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            type="text"
            model="Zone franche ZFU"
            label_name="zfu"
            name="zfu"
            (Changed)="onChange($event)"
            value="ZFU"
            [isSelected]="propertyModel.Zfu"
            inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
        </div>
        <!-- APPARTMENT CONFIG CHARACTERISTICS -->
        <div *ngIf="current_step === 'CHARACTERISTICS_APPARTMENT'" class="mt-6">
          <app-text
            textClasses="txt-percian-gray text-sm mx-auto text-left uppercase font-semibold mb-3"
            text="configuration du bien"
          ></app-text>
          <div class="mx-auto">
            <app-input
              label_text="Supercie extérieur 1"
              labelClasses="font-extrabold"
              [model]="propertyModel.Outdoor_area_1"
              type="number"
              label_name="outdoor_area_1"
              name="outdoor_area_1"
              placeholder="Ex : 25m²"
              (Changed)="onChange($event)"
              value="OUTDOOR_AREA_1"
              maxlength="10"
            ></app-input>
          </div>
          <div class="mx-auto mt-6">
            <app-input
              label_text="Supercie extérieur 2"
              labelClasses="font-extrabold"
              [model]="propertyModel.Outdoor_area_2"
              type="number"
              label_name="outdoor_area_2"
              name="outdoor_area_2"
              placeholder="Ex : 20m²"
              (Changed)="onChange($event)"
              value="OUTDOOR_AREA_2"
              maxlength="10"
            ></app-input>
          </div>
          <div class="mx-auto mt-6">
            <app-input
              label_text="Nombre de chambres"
              labelClasses="font-extrabold"
              [model]="propertyModel.Bedroom_number"
              type="number"
              label_name="bedroom_number"
              name="bedroom_number"
              placeholder="Ex : 2"
              (Changed)="onChange($event)"
              value="BEDROOM_NUMBER"
              maxlength="2"
            ></app-input>
          </div>
          <div class="mx-auto mt-6">
            <app-input
              label_text="Nombre de salle de bains"
              labelClasses="font-extrabold"
              [model]="propertyModel.Bathroom_number"
              type="number"
              label_name="bathroom_number"
              name="bathroom_number"
              placeholder="Ex : 1"
              (Changed)="onChange($event)"
              value="BATHROOM_NUMBER"
              maxlength="2"
            ></app-input>
          </div>
        </div>
        <!-- OFFICE CONFIG CHARACTERISTICS -->
        <div *ngIf="current_step === 'CHARACTERISTICS_OFFICE'" class="mt-6">
          <app-text
            textClasses="txt-percian-gray text-sm mx-auto text-left uppercase font-semibold mb-3"
            text="configuration du bien"
          ></app-text>
          <div class="mx-auto">
            <app-input
              label_text="Nombre de bureau individuel"
              labelClasses="font-extrabold"
              [model]="propertyModel.Individual_office_number"
              type="number"
              label_name="individual_office_number"
              name="individual_office_number"
              placeholder="Ex : 2"
              (Changed)="onChange($event)"
              value="INDIVIDUAL_OFFICE_NUMBER"
              maxlength="2"
            ></app-input>
          </div>
          <div class="mx-auto mt-6">
            <app-input
              label_text="Nombre de places assises"
              labelClasses="font-extrabold"
              [model]="propertyModel.Seating_places_number"
              type="number"
              label_name="seating_places_number"
              name="seating_places_number"
              placeholder="Ex : 38"
              (Changed)="onChange($event)"
              value="SEATING_PLACES_NUMBER"
              maxlength="4"
            ></app-input>
          </div>
          <div class="mx-auto mt-6">
            <app-input
              label_text="Nombre de salle de réunion"
              labelClasses="font-extrabold"
              [model]="propertyModel.Meeting_room_number"
              type="number"
              label_name="meeting_room_number"
              name="meeting_room_number"
              placeholder="Ex : 2"
              (Changed)="onChange($event)"
              value="MEETING_ROOM_NUMBER"
              maxlength="2"
            ></app-input>
          </div>
        </div>
        <!-- SERVICES ARRAY CHARACTERISTICS -->
        <div
          *ngIf="
            current_step === 'CHARACTERISTICS_OFFICE' ||
            current_step === 'CHARACTERISTICS_APPARTMENT'
          "
          class="mt-6"
        >
          <app-text
            textClasses="txt-percian-gray text-sm mx-auto text-left uppercase font-semibold mb-3"
            text="prestations"
          ></app-text>
          <div class="flex flex-wrap justify-start gap-3">
            <app-items
              type="text"
              model="Toilettes séparées"
              label_name="separate_toilet"
              name="separate_toilet"
              (Changed)="onChange($event)"
              value="SEPARATE_TOILET"
              [isSelected]="propertyModel.Separate_toilet"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              *ngIf="propertyModel.Property_type === 'MAISON_VILLA'"
              type="text"
              model="Piscine"
              label_name="swimming_pool"
              name="swimming_pool"
              (Changed)="onChange($event)"
              value="SWIMMING_POOL"
              [isSelected]="propertyModel.Swimming_pool"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              type="text"
              model="Climatisation"
              label_name="air_conditioning"
              name="air_conditioning"
              (Changed)="onChange($event)"
              value="AIR_CONDITIONING"
              [isSelected]="propertyModel.Air_conditioning"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              type="text"
              model="Fibre déployée"
              label_name="fibre"
              name="fibre"
              (Changed)="onChange($event)"
              value="FIBRE"
              [isSelected]="propertyModel.Fibre"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              *ngIf="propertyModel.Property_type === 'MAISON_VILLA'"
              type="text"
              model="Recharge véhicule électrique"
              label_name="electric_vehicle_charging"
              name="electric_vehicle_charging"
              (Changed)="onChange($event)"
              value="ELECTRIC_VEHICLE_CHARGING"
              [isSelected]="propertyModel.Electric_vehicule_charging"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              *ngIf="propertyModel.Property_type === 'MAISON_VILLA'"
              type="text"
              model="Portail électrique"
              label_name="electric_gate"
              name="electric_gate"
              (Changed)="onChange($event)"
              value="ELECTRIC_GATE"
              [isSelected]="propertyModel.Electric_gate"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              *ngIf="propertyModel.Property_type === 'MAISON_VILLA'"
              type="text"
              model="Portail manuel"
              label_name="manual_gate"
              name="manual_gate"
              (Changed)="onChange($event)"
              value="MANUAL_GATE"
              [isSelected]="propertyModel.Manual_gate"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              type="text"
              model="Accès handicapé"
              label_name="disabled_access"
              name="disabled_access"
              (Changed)="onChange($event)"
              value="DISABLED_ACCESS"
              [isSelected]="propertyModel.Disabled_access"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              type="text"
              model="Cuisine équipée"
              label_name="equipped_kitchen"
              name="equipped_kitchen"
              (Changed)="onChange($event)"
              value="EQUIPPED_KITCHEN"
              [isSelected]="propertyModel.Equipped_kitchen"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              type="text"
              model="Cuisine aménagée"
              label_name="fitted_kitchen"
              name="fitted_kitchen"
              (Changed)="onChange($event)"
              value="FITTED_KITCHEN"
              [isSelected]="propertyModel.Fitted_kitchen"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              *ngIf="propertyModel.Property_type !== 'MAISON_VILLA'"
              type="text"
              model="Digicode"
              label_name="digital_lock"
              name="digital_lock"
              (Changed)="onChange($event)"
              value="DIGITAL_LOCK"
              [isSelected]="propertyModel.Digital_lock"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              *ngIf="propertyModel.Property_type !== 'MAISON_VILLA'"
              type="text"
              model="Ascenseur"
              label_name="elevator"
              name="elevator"
              (Changed)="onChange($event)"
              value="ELEVATOR"
              [isSelected]="propertyModel.Elevator"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              *ngIf="propertyModel.Property_type === 'APPARTEMENT'"
              type="text"
              model="Meublé"
              label_name="furnished"
              name="furnished"
              (Changed)="onChange($event)"
              value="FURNISHED"
              [isSelected]="propertyModel.Furnished"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              *ngIf="propertyModel.Property_type === 'APPARTEMENT'"
              type="text"
              model="Conciergerie"
              label_name="caritaker"
              name="caritaker"
              (Changed)="onChange($event)"
              value="CARITAKER"
              [isSelected]="propertyModel.Caritaker"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              *ngIf="propertyModel.Property_type === 'BUREAUX'"
              type="text"
              model="Espace cuisine"
              label_name="kitchen_area"
              name="kitchen_area"
              (Changed)="onChange($event)"
              value="KITCHEN_AREA"
              [isSelected]="propertyModel.Kitchen_area"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              *ngIf="propertyModel.Property_type !== 'MAISON_VILLA'"
              type="text"
              model="Interphone"
              label_name="intercom"
              name="intercom"
              (Changed)="onChange($event)"
              value="INTERCOM"
              [isSelected]="propertyModel.Intercom"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
          </div>
        </div>
        <!-- KITCHEN TYPE CLASS ARRAY -->
        <div *ngIf="current_step === 'CHARACTERISTICS_APPARTMENT'" class="mt-6">
          <app-text
            textClasses="txt-percian-gray text-sm mx-auto text-left uppercase font-semibold mb-3"
            text="type de cuisine"
          ></app-text>
          <div class="flex flex-wrap justify-start gap-3">
            <app-items
              type="text"
              model="Cuisine séparée"
              label_name="separate_kitchen"
              name="separate_kitchen"
              (Changed)="onChange($event)"
              value="SEPARATE_KITCHEN"
              [isSelected]="propertyModel.Separate_kitchen"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              type="text"
              model="Cuisine ouverte"
              label_name="open_kitchen"
              name="open_kitchen"
              (Changed)="onChange($event)"
              value="OPEN_KITCHEN"
              [isSelected]="propertyModel.Open_kitchen"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              type="text"
              model="Cuisine semi-ouverte"
              label_name="semi_open_kitchen"
              name="semi_open_kitchen"
              (Changed)="onChange($event)"
              value="SEMI_OPEN_KITCHEN"
              [isSelected]="propertyModel.Semi_open_kitchen"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              *ngIf="propertyModel.Property_type === 'MAISON_VILLA'"
              type="text"
              model="Cuisine extérieure"
              label_name="outdoor_kitchen"
              name="outdoor_kitchen"
              (Changed)="onChange($event)"
              value="OUTDOOR_KITCHEN"
              [isSelected]="propertyModel.Outdoor_kitchen"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
          </div>
        </div>
        <!-- ADDITIONAL SURFACES ARRAY -->
        <div
          *ngIf="
            current_step === 'CHARACTERISTICS_APPARTMENT' ||
            current_step === 'CHARACTERISTICS_OFFICE'
          "
          class="mt-6"
        >
          <app-text
            textClasses="txt-percian-gray text-sm mx-auto text-left uppercase font-semibold mb-3"
            text="surface(s) annexe(s)"
          ></app-text>
          <div class="flex flex-wrap justify-start gap-3">
            <app-items
              type="text"
              model="Balcon"
              label_name="balcony"
              name="balcony"
              (Changed)="onChange($event)"
              value="BALCONY"
              [isSelected]="propertyModel.Balcony"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              type="text"
              model="Terrasse"
              label_name="terrace"
              name="terrace"
              (Changed)="onChange($event)"
              value="TERRACE"
              [isSelected]="propertyModel.Terrace"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              *ngIf="propertyModel.Property_type !== 'BUREAUX'"
              type="text"
              model="Jardin"
              label_name="garden"
              name="garden"
              (Changed)="onChange($event)"
              value="GARDEN"
              [isSelected]="propertyModel.Garden"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              type="text"
              model="Parking privée"
              label_name="private_parking"
              name="private_parking"
              (Changed)="onChange($event)"
              value="PRIVATE_PARKING"
              [isSelected]="propertyModel.Private_parking"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              *ngIf="propertyModel.Property_type === 'MAISON_VILLA'"
              type="text"
              model="Garage"
              label_name="garage"
              name="garage"
              (Changed)="onChange($event)"
              value="GARAGE"
              [isSelected]="propertyModel.Garage"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              *ngIf="propertyModel.Property_type === 'MAISON_VILLA'"
              type="text"
              model="Dépendance"
              label_name="outbuilding"
              name="outbuilding"
              (Changed)="onChange($event)"
              value="OUTBUILDING"
              [isSelected]="propertyModel.Outbuilding"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
          </div>
        </div>
        <!-- ENERGETIC CLASS ARRAY -->
        <div class="mx-auto mt-6">
          <app-text
            textClasses="txt-percian-gray text-sm mx-auto text-left uppercase font-semibold mb-3"
            text="classe énergétique *"
          ></app-text>
          <div class="mx-auto flex flex-wrap justify-start gap-3">
            <div *ngFor="let energeticClass of energeticClassArray">
              <p
                (click)="getItemValue(energeticClass)"
                class="
                  rounded-3xl
                  text-center text-xs
                  p-2
                  outline-none
                  cursor-pointer
                  w-16
                "
                [ngClass]="
                  propertyModel.Energetic_class !== energeticClass.item_value
                    ? 'bg-ligth-gray txt-bold-gray'
                    : 'bg-light-blue text-white'
                "
              >
                {{ energeticClass.item_value }}
              </p>
            </div>
          </div>
        </div>
        <!-- GES ARRAY -->
        <div class="mx-auto mt-6">
          <app-text
            textClasses="txt-percian-gray text-sm mx-auto text-left uppercase font-semibold mb-3"
            text="ges *"
          ></app-text>
          <div class="mx-auto flex flex-wrap justify-start gap-3">
            <div *ngFor="let gas of gesArray">
              <p
                (click)="getItemValue(gas)"
                class="
                  rounded-3xl
                  text-center text-xs
                  p-2
                  outline-none
                  cursor-pointer
                  w-16
                "
                [ngClass]="
                  propertyModel.Gas_emissions !== gas.item_value
                    ? 'bg-ligth-gray txt-bold-gray'
                    : 'bg-light-blue text-white'
                "
              >
                {{ gas.item_value }}
              </p>
            </div>
          </div>
        </div>
        <!-- INVESTMENT TYPE -->
        <div
          *ngIf="
            propertyModel.Property_type === 'MAISON_VILLA' ||
            propertyModel.Property_type === 'APPARTEMENT'
          "
          class="mx-auto mt-6"
        >
          <app-text
            textClasses="txt-percian-gray text-sm mx-auto text-left uppercase font-semibold mb-3"
            text="TYPE D'INVESTISSEMENT"
          ></app-text>
          <div class="flex flex-wrap justify-start gap-3">
            <!-- Viagers -->
            <app-items
              type="text"
              model="Viagers"
              label_name="life_annuity"
              name="life_annuity"
              (Changed)="onChange($event)"
              value="LIFE_ANNUITY"
              [isSelected]="propertyModel.Life_annuity"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="LIFE_ANNUITY"
            ></app-items>
            <!-- Eligible loi pinel -->
            <app-items
              type="text"
              model="Eligible loi pinel"
              label_name="eligible_pinel_law"
              name="eligible_pinel_law"
              (Changed)="onChange($event)"
              value="ELIGIBLE_PINEL_LAW"
              [isSelected]="propertyModel.Eligible_pinel_law"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="ELIGIBLE_PINEL_LAW"
            ></app-items>
            <!-- Résidence sénior -->
            <app-items
              type="text"
              model="Résidence sénior"
              label_name="senior_residence"
              name="senior_residence"
              (Changed)="onChange($event)"
              value="SENIOR_RESIDENCE"
              [isSelected]="propertyModel.Senior_residence"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="SENIOR_RESIDENCE"
            ></app-items>
            <!-- Résidence étudiant -->
            <app-items
              type="text"
              model="Résidence étudiant"
              label_name="student_residence"
              name="student_residence"
              (Changed)="onChange($event)"
              value="STUDENT_RESIDENCE"
              [isSelected]="propertyModel.Student_residence"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="STUDENT_RESIDENCE"
            ></app-items>
          </div>
        </div>
        <app-text
          textClasses="txt-percian-gray text-xxs mx-auto text-left mt-3 mb-6"
          text="Champs requis*"
        ></app-text>
        <div
          *ngIf="!edition_mode"
          class="mx-auto gap-3 flex items-center justify-end mb-7"
        >
          <div class="flex gap-1">
            <img
              *ngFor="let step of stepsArray"
              [src]="
                step.step == stepNumber
                  ? 'assets/icons/svg/bar-active.svg'
                  : 'assets/icons/svg/bar.svg'
              "
              class="h-1"
            />
          </div>
          <app-next-button
            [disabled]="
              propertyModel.Gas_emissions && propertyModel.Energetic_class
                ? false
                : true
            "
            (click)="switchStep()"
          ></app-next-button>
        </div>
        <!-- Edition mode => true / Edition button -->
        <div *ngIf="edition_mode" class="mx-auto flex justify-center mb-7">
          <app-primary-button
            (IsClicked)="updateProperty()"
            text="Modifier"
            buttonClasses="bg-light-blue text-white items-center w-40"
            firstImgSrc="assets/icons/svg/arrow-right.svg"
            secondImgSrc="assets/icons/svg/arrow-right.svg"
            [disabled]="false"
          ></app-primary-button>
        </div>
      </div>
    </div>
    <!-- PHOTOS STEP -->
    <div [@fade] *ngIf="current_step === 'PHOTOS'" class="gap-3 h-full mx-6">
      <app-text
        textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
        text="Ajouter des photos"
      ></app-text>
      <app-text
        textClasses="txt-percian-gray text-sm mx-auto text-center font-bold mt-16 mb-6"
        text="Les annonces avec photos attirent davantage les acquéreurs potentiels.
        Assurez-vous d’avoir une bonne luminosité et un bon cadrage. "
      ></app-text>
      <!-- INPUT FOR FIRST PHOTO -->
      <div class="mx-auto mt-4">
        <!-- INPUT FILE FOR FIRST UPLOAD -->
        <div class="mb-16">
          <app-input-file
            inputLabelClasses="bg-ligth-gray rounded-3xl p-2 cursor-pointer block bg-theme bg-20 bg-no-repeat bg-center min-h-200"
            inputFileClasses="bottom-6 text-center absolute"
            *ngIf="array_property_assets.length < 1"
            (change)="uploadPhotos($event)"
            text="Ajouter une photo"
            accept="image/*"
            multiple="false"
          ></app-input-file>
        </div>
        <!-- PHOTOS CAROUSEL  -->
        <div *ngIf="array_property_assets.length" class="bg-transparent">
          <!-- CAROUSEL -->
          <div [@fade] class="relative">
            <swiper
              [grabCursor]="true"
              [effect]="'creative'"
              [creativeEffect]="{
                prev: {
                  shadow: true,
                  translate: [0, 0, -400]
                },
                next: {
                  translate: ['100%', 0, 0]
                }
              }"
              [pagination]="{
                dynamicBullets: true
              }"
              class="mySwiper"
              [navigation]="true"
            >
              <ng-template
                *ngFor="let imgPath of array_property_assets"
                swiperSlide
              >
                <img
                  src="{{ imgPath.value }}"
                  style="object-fit: cover !important"
                  class="w-full rounded-xl overflow-auto"
                />
                <div
                  (click)="removePropertyPhoto(imgPath.key)"
                  [ngClass]="
                    array_property_assets.length >= 1
                      ? 'top-4 opacity-50'
                      : '-top-24 opacity-20'
                  "
                  class="
                    bg-white
                    rounded-full
                    p-1
                    flex
                    justify-end
                    right-4
                    cursor-pointer
                    absolute
                  "
                  style="z-index: 9999999"
                >
                  <mat-icon class="txt-bold-gray">close</mat-icon>
                </div>
              </ng-template>
            </swiper>
            <div
              [ngClass]="
                array_property_assets.length >= 1
                  ? 'bottom-8 opacity-50'
                  : '-bottom-16  opacity-20'
              "
              class="bg-white rounded-xl absolute flex justify-start left-4"
              style="z-index: 99999"
            >
              <p class="p-2 txt-bold-gray text-xxs">
                {{ array_property_assets.length }} / 10
              </p>
            </div>
          </div>
        </div>
        <!-- PREVIEW PHOTOS THUMBNAILS -->
        <!-- <div [@fade] *ngIf="array_property_assets.length" class="my-8 mx-auto">
          <div class="flex gap-3 overflow-scroll">
            <img
              *ngFor="let imgPath of array_property_assets"
              src="https://{{ imgPath.value }}"
              class="mx-auto align-middle rounded-2xl border-2 h-20 w-20"
            />
          </div>
        </div> -->
        <!-- MORE PHOTOS BUTTON -->
        <div
          [@fade]
          *ngIf="array_property_assets.length >= 1"
          class="
            w-3/5
            h-12
            flex
            bg-ligth-gray
            rounded-lg
            justify-center
            items-center
            cursor-pointer
            mt-2
            fileUpload
          "
        >
          <label
            for="file"
            class="items-center w-full h-full py-3 flex cursor-pointer"
          >
            <app-img
              imgPath="assets/icons/svg/logo-1.svg"
              imgClasses="h-6 ml-6"
            ></app-img>
            <input
              type="file"
              name="file"
              id="file"
              accept="image/*"
              multiple="false"
              (change)="uploadPhotos($event)"
            />
            <span class="txt-bold-gray text-sm ml-2">Plus de photos</span>
          </label>
        </div>
      </div>
      <!-- NEXT STEP -->
      <div class="mx-auto gap-3 flex items-center justify-end mt-9 mb-7">
        <div class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="array_property_assets.length >= 1 ? false : true"
          (click)="switchStep()"
        ></app-next-button>
      </div>
    </div>
    <!-- DESCRIPTION STEP -->
    <div [@fade] *ngIf="current_step === 'DESCRIPTION'" class="gap-3 mx-6">
      <app-text
        textClasses="txt-bold-gray mx-auto text-center font-bold my-16"
        text="Un complément d’informations ?
        Mettez en valeur votre bien."
      ></app-text>
      <div class="mx-auto mt-28">
        <div class="mx-auto mt-0">
          <app-textarea
            label_text="Description de l'annonce *"
            labelClasses="font-extrabold"
            [text]="propertyModel.Description"
            label_name="description"
            name="description"
            (Changed)="onChange($event)"
            value="DESCRIPTION"
            placeholder="Ex : Cette propriété se trouve dans un quartier très calme..."
            maxlength="2000"
          ></app-textarea>

          <app-text
            textClasses="txt-percian-gray text-xxs mx-auto text-left"
            text="Champs requis*"
          ></app-text>
        </div>
      </div>
      <div class="mx-auto gap-3 flex items-center justify-end">
        <div class="flex gap-1">
          <img
            *ngFor="let step of stepsArray"
            [src]="
              step.step == stepNumber
                ? 'assets/icons/svg/bar-active.svg'
                : 'assets/icons/svg/bar.svg'
            "
            class="h-1"
          />
        </div>
        <app-next-button
          [disabled]="propertyModel.Description ? false : true"
          (click)="switchStep()"
        ></app-next-button>
      </div>
    </div>
    <!-- CLIENT_REQUEST STEP -->
    <div [@fade] *ngIf="current_step === 'CLIENT_REQUEST'" class="h-full mx-6">
      <app-text
        *ngIf="user_type === 'PROFESSIONNEL'"
        textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
        text="Quels sont les besoins de vos clients ? *"
      ></app-text>
      <app-text
        *ngIf="user_type === 'PARTICULIER'"
        textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
        text="Quels sont vos besoins en tant que client ? *"
      ></app-text>
      <app-text
        *ngIf="
          propertyModel.Service_type === 'VENTE' &&
          user_type === 'PROFESSIONNEL'
        "
        textClasses="txt-percian-gray text-sm mx-auto text-center font-bold mt-16 mb-6"
        text="Indiquez comment allez-vous l’accompagner pour cette mise en vente "
      ></app-text>
      <app-text
        *ngIf="
          propertyModel.Service_type === 'LOCATION' &&
          user_type === 'PROFESSIONNEL'
        "
        textClasses="txt-percian-gray text-sm mx-auto text-center font-bold mt-16 mb-6"
        text="Indiquez comment allez-vous l’accompagner pour cette mise en location "
      ></app-text>
      <app-text
        *ngIf="
          propertyModel.Service_type === 'VENTE' && user_type === 'PARTICULIER'
        "
        textClasses="txt-percian-gray text-sm mx-auto text-center font-bold mt-16 mb-6"
        text="Indiquez comment souhaitez-vous être accompagner pour cette mise en vente "
      ></app-text>
      <app-text
        *ngIf="
          propertyModel.Service_type === 'LOCATION' &&
          user_type === 'PARTICULIER'
        "
        textClasses="txt-percian-gray text-sm mx-auto text-center font-bold mt-16 mb-6"
        text="Indiquez comment souhaitez-vous être accompagner pour cette mise en location "
      ></app-text>
      <div class="mx-auto mt-8">
        <!-- LIST OPTION -->
        <div class="flex flex-col justify-center gap-3">
          <app-items
            icon_visibility="true"
            type="text"
            model="Confier la gestion globale"
            label_name="global_management"
            name="global_management"
            (Changed)="onChange($event)"
            value="GLOBAL_MANAGEMENT"
            [isSelected]="propertyModel.Global_management"
            inputClasses="rounded-lg text-center  text-sm bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-lg text-center  text-sm bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none"
          ></app-items>
          <app-items
            *ngIf="propertyModel.Service_type === 'VENTE'"
            icon_visibility="true"
            type="text"
            model="Vendre dans un délai court"
            label_name="sell_quickly"
            name="sell_quickly"
            (Changed)="onChange($event)"
            value="SELL_QUICKLY"
            [isSelected]="propertyModel.Sell_quickly"
            inputClasses="rounded-lg text-center  text-sm bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-lg text-center  text-sm bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none"
          ></app-items>
          <app-items
            icon_visibility="true"
            type="text"
            model="Avoir plus de visibilité et de visites"
            label_name="more_visibility_and_visits"
            name="more_visibility_and_visits"
            (Changed)="onChange($event)"
            value="MORE_VISIBILITY_AND_VISITS"
            [isSelected]="propertyModel.More_visibility_and_visits"
            inputClasses="rounded-lg text-center  text-sm bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-lg text-center  text-sm bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none"
          ></app-items>
          <app-items
            *ngIf="propertyModel.Service_type === 'VENTE'"
            icon_visibility="true"
            type="text"
            model="Estimer le coût des travaux"
            label_name="estimate_cost_of_work"
            name="estimate_cost_of_work"
            (Changed)="onChange($event)"
            value="ESTIMATE_COST_OF_WORK"
            [isSelected]="propertyModel.Estimate_cost_of_work"
            inputClasses="rounded-lg text-center  text-sm bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-lg text-center  text-sm bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none"
          ></app-items>
          <app-items
            *ngIf="propertyModel.Service_type === 'VENTE'"
            icon_visibility="true"
            type="text"
            model="Estimer la valeur du bien"
            label_name="estimate_value_property"
            name="estimate_value_property"
            (Changed)="onChange($event)"
            value="ESTIMATE_VALUE_PROPERTY"
            [isSelected]="propertyModel.Estimate_value_property"
            inputClasses="rounded-lg text-center  text-sm bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-lg text-center  text-sm bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none"
          ></app-items>
          <app-items
            *ngIf="propertyModel.Service_type === 'VENTE'"
            icon_visibility="true"
            type="text"
            model="Estimer la superficie du bien"
            label_name="estimate_area_property"
            name="estimate_area_property"
            (Changed)="onChange($event)"
            value="ESTIMATE_AREA_PROPERTY"
            [isSelected]="propertyModel.Estimate_area_property"
            inputClasses="rounded-lg text-center  text-sm bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-lg text-center  text-sm bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none"
          ></app-items>
          <app-items
            *ngIf="propertyModel.Service_type === 'LOCATION'"
            icon_visibility="true"
            type="text"
            model="Louer le plus vite possible"
            label_name="rent_quickly"
            name="rent_quickly"
            (Changed)="onChange($event)"
            value="RENT_QUICKLY"
            [isSelected]="propertyModel.Rent_quickly"
            inputClasses="rounded-lg text-center  text-sm bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-lg text-center  text-sm bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none"
          ></app-items>
          <app-items
            *ngIf="propertyModel.Service_type === 'LOCATION'"
            icon_visibility="true"
            type="text"
            model="Être conseiller pour le montant du loyer"
            label_name="estimate_amount_of_rent"
            name="estimate_amount_of_rent"
            (Changed)="onChange($event)"
            value="ESTIMATE_AMOUNT_OF_RENT"
            [isSelected]="propertyModel.Estimate_amount_of_rent"
            inputClasses="rounded-lg text-center  text-sm bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-lg text-center  text-sm bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none"
          ></app-items>
        </div>
        <app-text
          textClasses="txt-percian-gray text-xxs mx-auto text-left"
          text="Champs requis*"
        ></app-text>
      </div>
      <div class="mx-auto flex justify-center pb-7">
        <app-primary-button
          (IsClicked)="switchStep()"
          text="Continuer"
          buttonClasses="bg-light-blue text-white items-center w-40"
          firstImgSrc="assets/icons/svg/arrow-right.svg"
          secondImgSrc="assets/icons/svg/arrow-right.svg"
          [disabled]="false"
        ></app-primary-button>
      </div>
    </div>
    <!-- CREATE_PROPERTY_FINAL STEP -->
    <div
      [@fade]
      *ngIf="current_step === 'CREATE_PROPERTY_FINAL'"
      class="h-full"
    >
      <app-text
        textClasses="txt-light-blue mx-auto text-center font-bold mt-16 mb-6"
        text="Résumé de l'annonce"
      ></app-text>
      <div class="rounded-t-3xl bg-extra-ligth-gray relative mt-36">
        <div class="w-10/12 left-0 right-0 absolute -top-28 mx-auto">
          <swiper
            [grabCursor]="true"
            [effect]="'creative'"
            [creativeEffect]="{
              prev: {
                shadow: true,
                translate: [0, 0, -400]
              },
              next: {
                translate: ['100%', 0, 0]
              }
            }"
            [pagination]="{
              dynamicBullets: true
            }"
            class="mySwiper"
            [navigation]="true"
          >
            <ng-template
              *ngFor="let imgPath of array_property_assets"
              swiperSlide
            >
              <img
                src="{{ imgPath.value }}"
                style="object-fit: cover !important"
                class="w-full rounded-xl overflow-auto"
              />
            </ng-template>
          </swiper>
        </div>
        <div class="grid grid-cols-2 gap-y-4 mx-6 pt-28 mt-4 mb-7">
          <app-input-txt
            label_name="service_type"
            label_text="Type de service"
            labelClasses="text-xs"
            model="{{ serviceType }}"
          ></app-input-txt>
          <app-input-txt
            label_name="address"
            label_text="Adresse"
            labelClasses="text-xs"
            model="{{ propertyModel.City + ', ' + propertyModel.Postal_code }}"
          ></app-input-txt>
          <app-input-txt
            label_name="property_type"
            label_text="Type de bien"
            labelClasses="text-xs"
            model="{{ propertyType }}"
          ></app-input-txt>
          <app-input-txt
            label_name="total_area"
            [label_text]="
              propertyModel.Property_type !== 'TERRAIN'
                ? 'Surface habitable'
                : 'Surface du terrain'
            "
            labelClasses="text-xs"
            model="{{ propertyModel.Total_area + ' m²' }}"
          ></app-input-txt>
          <app-input-txt
            label_name="property_state"
            label_text="Etat du bien"
            labelClasses="text-xs"
            model="{{ propertyState }}"
          ></app-input-txt>
          <app-input-txt
            label_name="price"
            label_text="Prix de vente"
            labelClasses="text-xs"
            model="{{ propertyModel.Price + ' €' }}"
          ></app-input-txt>
        </div>
        <div class="rounded-t-3xl bg-white py-6 relative">
          <p
            class="
              bg-ligth-gray
              p-1
              text-center text-xs
              w-2/5
              mx-auto
              absolute
              rounded-md
              txt-bold-gray
              -top-3
              left-0
              right-0
            "
          >
            {{ datetime }}
          </p>
          <div class="mx-auto">
            <app-text
              textClasses="txt-bold-gray text-center mx-6 my-6"
              text="Votre annonce sera envoyé à l’administrateur Infiny Patrimoine pour validation."
            ></app-text>
          </div>
          <div class="mx-auto mt-6 mb-7 flex gap-3 justify-center">
            <app-primary-button
              (IsClicked)="back()"
              text="Annuler"
              [textPosition]="right"
              buttonClasses="bg-white border-pink txt-pink border items-center w-40"
              firstImgSrc="assets/icons/svg/cross.svg"
              secondImgSrc="assets/icons/svg/cross.svg"
              [disabled]="false"
            ></app-primary-button>
            <app-primary-button
              (IsClicked)="createProperty()"
              text="Valider"
              buttonClasses="bg-light-blue text-white items-center border border-light-blue w-40"
              firstImgSrc="assets/icons/svg/arrow-right.svg"
              secondImgSrc="assets/icons/svg/arrow-right.svg"
              [disabled]="false"
            ></app-primary-button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
