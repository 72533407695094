import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
// let map: google.maps.Map;
// let marker: google.maps.Marker;
// let geocoder: google.maps.Geocoder;
// let responseDiv: HTMLDivElement;
// let response: HTMLPreElement;
// let service = new google.maps.places.PlacesService(document.createElement('div'));

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  constructor(public http: HttpClient) {}
  public lat;
  public lng;

  // public initMap(): void {
  //   map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
  //     zoom: 8,
  //     center: { lat: -34.397, lng: 150.644 },
  //     mapTypeControl: false,
  //   });
  //   geocoder = new google.maps.Geocoder();

  //   const inputText = document.createElement('input');

  //   inputText.type = 'text';
  //   inputText.placeholder = 'Enter a location';

  //   const submitButton = document.createElement('input');

  //   submitButton.type = 'button';
  //   submitButton.value = 'Geocode';
  //   submitButton.classList.add('button', 'button-primary');

  //   const clearButton = document.createElement('input');

  //   clearButton.type = 'button';
  //   clearButton.value = 'Clear';
  //   clearButton.classList.add('button', 'button-secondary');

  //   response = document.createElement('pre');
  //   response.id = 'response';
  //   response.innerText = '';

  //   responseDiv = document.createElement('div');
  //   responseDiv.id = 'response-container';
  //   responseDiv.appendChild(response);

  //   const instructionsElement = document.createElement('p');

  //   instructionsElement.id = 'instructions';

  //   instructionsElement.innerHTML =
  //     '<strong>Instructions</strong>: Enter an address in the textbox to geocode or click on the map to reverse geocode.';

  //   map.controls[google.maps.ControlPosition.TOP_LEFT].push(inputText);
  //   map.controls[google.maps.ControlPosition.TOP_LEFT].push(submitButton);
  //   map.controls[google.maps.ControlPosition.TOP_LEFT].push(clearButton);
  //   map.controls[google.maps.ControlPosition.LEFT_TOP].push(
  //     instructionsElement
  //   );
  //   map.controls[google.maps.ControlPosition.LEFT_TOP].push(responseDiv);

  //   marker = new google.maps.Marker({
  //     map,
  //   });

  //   map.addListener('click', (e: google.maps.MapMouseEvent) => {
  //     this.geocode({ location: e.latLng });
  //   });

  //   submitButton.addEventListener('click', () =>
  //     this.geocode({ address: inputText.value })
  //   );

  //   clearButton.addEventListener('click', () => {
  //     this.clear();
  //   });

  //   this.clear();
  // }

  // public getCoordinates($event) {
  //   this.geocode({ address: $event });
  // }

  public getCoordinates(address: String): void {
    this.http
      .get(
        'https://maps.googleapis.com/maps/api/geocode/json?address=' +
          address +
          '&key=AIzaSyDTbRf4gD1raBonoP80Y9-WepL2uJZu7Vk'
      )
      .subscribe((results: any) => {
        console.log(results);
        
        if (results.status === 'OK') {
          this.lat = results.results[0].geometry.location.lat;
          this.lng = results.results[0].geometry.location.lng;
          return this.lat, this.lng;
        } else {
          throw new Error(results.status + ': ' + results.error_message);
        }
      });
  }

  // public clear() {
  //   marker.setMap(null);
  //   responseDiv.style.display = 'none';
  // }

  
  // public geocode(address): void {
  //   // this.clear();
  //   geocoder = new google.maps.Geocoder();
  //   geocoder.geocode({ address: address }, function (results, status) {
  //     if (status == 'OK') {
  //       console.log(results);
  //     } else {
  //       alert(
  //         'Geocode was not successful for the following reason: ' + status
  //       );
  //     }
  //   });
  // }
}
