<div [@fade] class="w-full mx-auto bg-white overflow-auto">
  <!-- Header -->
  <div *ngIf="isOnLikePage" class="border-b border-solid pb-2">
    <div class="mx-6">
      <app-secondary-header
        (IsClicked)="back()"
        headerClasses=" pt-4 flex justify-between items-center mb-2"
      ></app-secondary-header>
      <div class="mx-auto text-center">
        <p class="font-bold text-xl txt-bold-gray">Rechercher un bien</p>
      </div>
    </div>
  </div>

  <div class="mx-6 mt-3 mb-16">
    <div
      *ngIf="!property.length && !hasError"
      class="w-full flex justify-center mx-auto"
      id="waiting_data"
    >
      <mat-spinner diameter="50"></mat-spinner>
    </div>
    <!-- No Result text -->
    <p *ngIf="!property.length && hasError" class="text-center txt-light-blue">
      Aucun résultats
    </p>
    <app-property-card
      *ngFor="let property of property"
      [assets_path]="property.Photos[0]"
      [assets_length]="property.Photos.length"
      imgClasses="rounded-2xl overflow-auto"
      attributes_assets_path="assets/icons/svg/logo-1.svg"
      txtClasses="text-xs txt-bold-gray font-extrabold"
      [price]="property.Price + ' €'"
      [pieces_number]="property.Number_of_pieces + ' pièces'"
      [total_area]="property.Total_area + ' m²'"
      [property_subtype]="property.Property_subtype"
      [isFavorite]="property.IsFavorite"
      [location]="property.City"
      [published_date]="property.Published_date"
    ></app-property-card>
  </div>
  <app-text
    textClasses="txt-percian-gray text-sm text-center font-bold mb-3 mx-6"
    text="Comment souhaitez-vous positionner sur cette offre ?"
  ></app-text>
  <div class="flex flex-col gap-3 mx-6">
    <div
      *ngFor="let status of status_array"
      class="flex gap-3 items-center rounded-lg p-3 cursor-pointer"
      [ngClass]="
        like_status == status.status_db ? 'bg-light-blue' : 'bg-percian-gray'
      "
      (click)="isSelected(status.status_db)"
    >
      <img [src]="status.imgSrc" class="h-6" />
      <app-text
        [textClasses]="
          like_status == status.status_db
            ? 'txt-white text-sm mx-auto text-center font-bold'
            : 'txt-bold-gray text-sm mx-auto text-center font-bold'
        "
        [text]="status.status_name"
      ></app-text>
    </div>
  </div>
  <div class="my-6 flex justify-center mx-6">
    <app-primary-button
      (IsClicked)="addOrRemoveFavoriteProperty()"
      text="Enregistrer"
      buttonClasses="bg-bold-blue text-white text-sm items-center py-2"
      [icon_visibility]="false"
      [disabled]="false"
    ></app-primary-button>
  </div>
</div>
