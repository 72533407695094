import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import SwiperCore, { EffectCreative, Pagination, Navigation } from 'swiper';

// install Swiper modules
SwiperCore.use([EffectCreative, Pagination, Navigation]);

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.scss'],
})
export class PropertyCardComponent implements OnInit {
  @Input() assets_path: string;
  @Input() attributes_assets_path: string;
  @Input() price: string;
  @Input() pieces_number: string;
  @Input() total_area: string;
  @Input() property_subtype: string;
  @Input() location: string;
  @Input() published_date: string;
  @Input() assets_length: string;
  @Input() imgBlockClasses: string = '';
  @Input() imgClasses: string = '';
  @Input() imgAttributesClasses: string = '';
  @Input() txtClasses: string = '';
  @Output() IsClicked = new EventEmitter<any>();
  @Output() IsLikeOrUnlike = new EventEmitter<any>();
  @Input() isFavorite: boolean = false;
  @Input() property_IsSelected: boolean = false;

  constructor() {}

  ngOnInit(): void {}
  
  /**
   * onClick function
   */
  public onClick($event) {
    this.IsClicked.emit($event);
  }

  /**
   * likeOrUnlike
   */
  public likeOrUnlike($event) {
    this.IsLikeOrUnlike.emit($event)
  }
}
