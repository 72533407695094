import { AppointmentModel } from 'src/app/shared/models/appointment-model';
import { AppointmentService } from 'src/app/shared/services/appointment.service';
import { Router } from '@angular/router';
import { LocalStorageService } from './../../shared/services/local-storage.service';
import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-home-page',
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
    ]),
    trigger('flyInOut', [
      transition('void => *', [
        style({ transform: 'translateY(150%)' }),
        animate(1000),
      ]),
      transition('* => void', [
        animate(400, style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  public isLoggedIn: boolean = false;
  public service_type: string = 'sale';
  public flag_appointment_to_come: boolean = true;
  public preview_appointment_to_come: boolean = false;
  public appointment_to_come: boolean = false;
  public appointment_to_come_number: any = 0;
  public user_id: string;
  public appointments_list: AppointmentModel[] = [];
  public appointments_list_filtered: AppointmentModel[] = [];
  public hasError = false;
  public today: any;
  constructor(
    private appointmentService: AppointmentService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private renderer: Renderer2
  ) {
    this.chekIfUserLoggedIn();
    this.today = new Date();
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    this.today = this.today.toLocaleDateString('fr-FR', options);
    this.user_id = this.localStorageService.getCurrentUser().id;
    this.renderer.setStyle(document.body, 'background-color', '#b9dbf1');
    this.service_type = 'sale';
    this.getAllUsersAppointment();
  }

  ngOnInit(): void {}

  /**
   * selectServiceType
   */
  public selectServiceType($service_type) {
    this.service_type = $service_type;
  }

  /**
   * chekIfUserLoggedIn
   */
  public chekIfUserLoggedIn() {
    const user = this.localStorageService.getCurrentUser().id;
    if (user) {
      this.isLoggedIn = true;
    }
  }

  /**
   * seePreviewAppointment
   */
  public seePreviewAppointment() {
    this.preview_appointment_to_come = !this.preview_appointment_to_come;
    this.flag_appointment_to_come = !this.flag_appointment_to_come;
  }

  onClick($event: string) {
    this.chekIfUserLoggedIn();
    switch ($event) {
      case 'DEPOT':
        if (this.isLoggedIn) {
          this.router.navigate([`/new-property/${this.service_type}`]);
        } else {
          this.router.navigate(['/account/authentication']);
        }
        break;
      case 'RECHERCHE':
        this.router.navigate([`/property-list/${this.service_type}`]);
        break;
      default:
        break;
    }
  }

  /**
   * getAllUsersAppointment
   */
  public getAllUsersAppointment() {
    this.appointmentService
      .getAllUserAppointments(this.user_id)
      .subscribe((res) => {
        console.log(res);
        if (res && res.data.getAllUserAppointments) {
          const data = res.data.getAllUserAppointments;
          for (let index = 0; index < data.length; index++) {
            let dateToCheck = new Date(
              parseInt(data[index].appointment_date)
            ).getTime();
            console.log('index', dateToCheck);
            // Si le rendez-vous à une date supérieur ou égal à celle d'aujourd'hui pousser le rendez-vous dans le tableau
            if (dateToCheck >= new Date().getTime() && data[index].status === "A_VENIR") {
              this.appointments_list.push(data[index]);
            }
          }
          this.appointment_to_come_number = this.appointments_list.length;
          this.appointments_list.forEach((element) => {
            const appointment = new AppointmentModel().mapToModel(element);
            this.appointments_list_filtered.push(appointment);
          });
          console.log(this.appointments_list_filtered);
        } else if (res.errors.message) {
          this.hasError = true;
          console.log(res.errors.message);
        } else {
          this.hasError = true;
          console.log('ERREUR SERVEUR');
        }
      });
  }

  /**
   * propertyManagement
   */
  public propertyManagement() {
    this.router.navigate([`/my-property/${this.service_type}`]);
  }

  /**
   * getUserAppointment
   */
  public getUserAppointment($appointment_id) {
    this.router.navigate([`appointment/${$appointment_id}`]);
  }
}
