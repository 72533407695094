<div [@fade] class="gap-3">
  <app-text
    textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
    [text]="subtitle"
  ></app-text>
  <div class="mx-auto mt-16">
    <div [ngClass]="daysClasses">
      <div class="flex gap-3 items-center">
        <img src="assets/icons/svg/logo-1.svg" class="h-4" />
        <app-select-date
          form_control_name_month="month"
          form_control_name_year="year"
          selectClasses="items-center txt-bold-gray font-bold appearance-none bg-transparent"
          yearClasses="items-center text-xs txt-percian-gray bg-transparent"
          (Changed)="selectMonthAndYear($event)"
          value="DATE_SORT"
        ></app-select-date>
      </div>
      <app-text
        *ngIf="!user_has_parameters"
        textClasses="txt-light-blue mx-auto text-center font-bold mt-16 mb-6"
        text="Veuillez définir des paramètres pour ce type de rendez-vous"
      ></app-text>
      <div
        *ngIf="user_has_parameters"
        class="my-6 flex flex-nowrap gap-3 overflow-x-scroll no-scrollbar"
      >
        <app-day-calendar
          *ngFor="let day of days"
          [day_name]="day.name"
          [day_number]="day.number"
          [date]="day.date"
          [daysClasses]="
            date !== day.selectedDate
              ? 'bg-white txt-light-blue border-light-blue'
              : 'bg-light-blue txt-white border-light-blue'
          "
          (IsClicked)="selectDate(day)"
        ></app-day-calendar>
      </div>
    </div>
    <div
      [@fade]
      *ngIf="isDaySelected"
      [ngClass]="hoursTitleClasses"
      class="flex items-center gap-3"
    >
      <img src="assets/icons/svg/logo-1.svg" class="h-4" />
      <p class="txt-bold-gray font-bold">Heure</p>
    </div>
    <div
      [@fade]
      *ngIf="isDaySelected && user_has_parameters"
      [ngClass]="hoursClasses"
      class="grid grid-cols-4 justify-center"
    >
      <div
        *ngFor="let hour of timeWindows"
        (click)="selectTimeWindow(hour)"
        class="time-picker-window text-xs"
        [ngClass]="
          hourSelected !== hour.time && !hour.isUnavalaible
            ? 'bg-white txt-light-blue border-light-blue'
            : 'bg-light-blue txt-white border-light-blue'
        "
      >
        {{ hour.time }}
      </div>
    </div>
  </div>
</div>
