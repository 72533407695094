import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Input, Inject } from '@angular/core';

@Component({
  selector: 'app-custom-popup',
  templateUrl: './custom-popup.component.html',
  styleUrls: ['./custom-popup.component.scss'],
})
export class CustomPopupComponent implements OnInit {
  @Input() id: string | number;
  @Input() status: string;
  @Input() message: string;
  @Input() title: string;

  constructor(
    public dialogRef: MatDialogRef<CustomPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public options: {
      id: string | number;
      status: string;
      title: string;
      message: string;
    }
  ) {
    this.id = options.id;
    this.status = options.status;
    this.title = options.title;
    this.message = options.message;
  }

  ngOnInit(): void {}

  close(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    this.dialogRef.close(true);
  }
}
