import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-candidate-file',
  templateUrl: './candidate-file.component.html',
  styleUrls: ['./candidate-file.component.scss'],
})
export class CandidateFileComponent implements OnInit {
  @Input() imgClasses = '';
  @Input() ImgClasses = '';
  @Output() IsClicked = new EventEmitter<any>();
  @Output() Clicked = new EventEmitter<any>();
  @Input() imgSrc = '';
  @Input() txtClasses = '';
  @Input() text = '';
  @Input() Key: any;
  @Input() file_key: any; // Si j'ai un fichier de présent lui passer le file key pour la suppression
  @Input() field_name: string;
  constructor() {}

  ngOnInit(): void {}

  /**
   * onClick
   */
  public onClick($event) {
    this.IsClicked.emit(this.file_key);
  }

  /**
   * isClicked
   */
  public isClicked($event: any) {
    const data = {
      key: this.file_key,
      file: $event,
      field_name: this.field_name,
    };
    this.Clicked.emit(data);
  }
}
