import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss'],
})
export class FilterButtonComponent implements OnInit {
  @Input() text = '';
  @Input() textClasses = '';
  @Input() buttonClasses = '';
  @Input() model: any;
  @Input() item_id: String = '';
  @Output() IsClicked = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onClick($event: any): void {
    this.IsClicked.emit($event);
  }
}
