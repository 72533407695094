import { Router, ActivatedRoute } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-property',
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
    ]),
  ],
  templateUrl: './search-property.component.html',
  styleUrls: ['./search-property.component.scss'],
})
export class SearchPropertyComponent implements OnInit {
  public current_step: string = 'MAIN_SEARCH';
  @Input() filter_attributes: any;
  @Input() filtered_array: any;
  public service_type: string;
  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.service_type = this.route.snapshot.paramMap.get('service_type');
  }

  ngOnInit(): void {}

  /**
   * back
   */
  public back() {
    this.router.navigate(['/home']);
  }

  /**
   * getCurrentStep
   */
  public getCurrentStep($current_step) {
    this.switchStep($current_step);
  }

  /**
   * switchStep
   */
  public switchStep($event) {
    switch ($event) {
      case 'MAIN_SEARCH':
        break;
      case 'SEARCH_FILTER':
        this.current_step = $event;
        break;
      case 'PROPERTY_LIST':
        this.current_step = $event;
        break;
      case 'PROPERTY':
        break;
      case 'ON_LIKE':
        break;
      default:
        break;
    }
  }

  /**
   * getFilterAttributes
   */
  public getFilterAttributes($event) {
    this.filter_attributes = $event.filter_attributes;
    this.filtered_array = $event.array_filter;
    console.log('FILTERED_ARRAY', this.filtered_array);

    // this.switchStep($event.current_step);
    this.router.navigate([`property-list/${this.service_type}`]);
  }
}
