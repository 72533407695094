import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-appointment-confirmation',
  templateUrl: './appointment-confirmation.component.html',
  styleUrls: ['./appointment-confirmation.component.scss'],
})
export class AppointmentConfirmationComponent implements OnInit {
  @Output() IsClicked = new EventEmitter<any>();
  @Output() Clicked = new EventEmitter<any>();
  @Input() title: any = 'Rendez-vous planifié !';
  @Input() subtitle: any =
    'Votre interlocuteur devrait bientôt vous répondre !';
  @Input() appointment_hour: any = '00:00';
  @Input() appointment_date: any = 'Mer 03 oct 2022';
  @Input() appointment_type: any = 'Type de rendez-vous';
  @Input() appointment_guest: any = 'Nicolas Burlac';
  @Input() appointment_reason: any = 'Motif du rendez-vous';
  @Input() appointment_status: any;
  @Input() isOnCreatedAppointmentPage: boolean = true;
  @Input() colorClasses = 'bg-percian-gray';
  constructor() {
    
    
  }

  ngOnInit(): void {
    console.log(this.appointment_status);
  }

  /**
   * onClick Funtion
   */
  public onClick() {
    const data = {
      appointment_hour: this.appointment_hour,
      appointment_date: this.appointment_date,
      appointment_type: this.appointment_type,
      appointment_guest: this.appointment_guest,
      appointment_reason: this.appointment_reason,
    };
    this.IsClicked.emit(data);
  }

  /**
   * back
   */
  public back($event) {
    this.Clicked.emit($event);
  }
}
