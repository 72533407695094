export class AppointmentModel {
  ID: any;
  Property_id: any;
  Appointment_type: any;
  AppointmentType: any;
  Appointment_reason: any;
  Appointment_date: any;
  Appointment_hour: any;
  Appointment_guest: any;
  Appointment_status: any;
  User_id: any;

  public mapToModel(data: any): AppointmentModel {
    const appointmentModel = new AppointmentModel();
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    appointmentModel.ID = data.id;
    appointmentModel.Property_id = data.property_id;
    appointmentModel.AppointmentType = data.type;
    switch (data.type) {
      case 'VISITE_DU_BIEN_PAR_UN_EXPERT':
        appointmentModel.Appointment_type = 'Visite du bien par un expert';
        break;
      case 'VISITE_DU_BIEN_AVEC_UN_ACHETEUR':
        appointmentModel.Appointment_type = 'Visite du bien avec un acheteur';
        break;
      case 'APPEL_TELEPHONIQUE':
        appointmentModel.Appointment_type = 'Appel téléphonique';
        break;
      case 'RENDEZ_VOUS_EN_AGENCE':
        appointmentModel.Appointment_type = 'Rendez-vous en agence';
        break;
      default:
        break;
    }
    switch (data.status) {
      case 'ANNULE':
        appointmentModel.Appointment_status = 'annulé';
        break;
      case 'A_VENIR':
        appointmentModel.Appointment_status = 'à venir';
        break;
      case 'EFFECTUE':
        appointmentModel.Appointment_status = 'effectué';
        break;
      case 'EN_ATTENTE':
        appointmentModel.Appointment_status = 'en attente';
        break;
      case 'EN_COURS':
        appointmentModel.Appointment_status = 'en cours';
        break;
      default:
        break;
    }
    appointmentModel.Appointment_reason = data.appointment_reason;
    appointmentModel.Appointment_date = new Date(
      parseInt(data.appointment_date)
    );
    appointmentModel.Appointment_hour =
      appointmentModel.Appointment_date.getHours() +
      ':' +
      (appointmentModel.Appointment_date.getMinutes() == 0
        ? '0' + appointmentModel.Appointment_date.getMinutes()
        : appointmentModel.Appointment_date.getMinutes());
    appointmentModel.Appointment_date =
      appointmentModel.Appointment_date.toLocaleDateString('fr-FR', options);
    appointmentModel.Appointment_guest = data.appointment_guest;
    appointmentModel.User_id = data.user_id;

    return appointmentModel;
  }
}
