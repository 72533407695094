<div class="container">
  <div class="round flex gap-4 items-center">
    <input
      [(ngModel)]="model"
      (change)="onChange($event)"
      type="checkbox"
      checked
      id="checkbox"
    />
    <label for="checkbox" class="txt-percian-gray w-11/12 mx-auto"></label>
    <app-text-inner-html
      textClasses="{{ contentClasses }}"
      content="{{ text_content }}"
    ></app-text-inner-html>
  </div>
</div>
