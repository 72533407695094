import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor() {}

  accept = () => {
    localStorage.setItem('cookie-consent', 'true');
  };

  decline = () => {
    localStorage.setItem('cookie-consent', 'false');
  };

  hasConsented = () => {
    const consent = localStorage.getItem('cookie-consent');
    if (consent === 'true') return true;
    else return false;
  };

  hasAnswered = () => {
    const consent = localStorage.getItem('cookie-consent');
    if (consent !== null) return true;
    else return false;
  };

  getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts: any = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    else return undefined;
  };
}
