import { FavoritesPropertyListComponent } from './pages/favorites-property-list/favorites-property-list.component';
import { LikePageComponent } from './pages/like-page/like-page.component';
import { SearchPropertyComponent } from './pages/search-property/search-property.component';
import { AppointmentManagementComponent } from './pages/appointment-management/appointment-management.component';
import { UserInterestedComponent } from './pages/user-interested/user-interested.component';
import { CreateAppointmentComponent } from './pages/create-appointment/create-appointment.component';
import { MyPropertyComponent } from './pages/my-property/my-property.component';
import { PropertyComponent } from './pages/property/property.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountProfileComponent } from './pages/account-profile/account-profile.component';
import { AccountAuthenticationComponent } from './pages/account-authentication/account-authentication.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { PersonalInformationsComponent } from './pages/personal-informations/personal-informations.component';
import { PutAPostComponent } from './pages/put-a-post/put-a-post.component';
import { FilterComponent } from './pages/filter/filter.component';
import { PropertyListComponent } from './pages/property-list/property-list.component';
import { AppointmentComponent } from './pages/appointment/appointment.component';
import { MyAppointmentsComponent } from './pages/my-appointments/my-appointments.component';
import { SingleAppointmentComponent } from './pages/single-appointment/single-appointment.component';

const routes: Routes = [
  { path: 'welcome', component: AccountProfileComponent },
  { path: 'home', component: HomePageComponent },
  { path: 'account/authentication', component: AccountAuthenticationComponent },
  { path: 'my-account', component: MyAccountComponent },
  { path: 'personal-informations', component: PersonalInformationsComponent },
  { path: 'new-property/:service_type', component: PutAPostComponent },
  { path: 'edit-property/:service_type/:id', component: PutAPostComponent },
  { path: 'property-list/:service_type', component: PropertyListComponent },
  { path: 'search-property/:service_type', component: SearchPropertyComponent },
  { path: 'filter-search/:service_type', component: FilterComponent },
  { path: 'my-property/:service_type', component: MyPropertyComponent },
  { path: 'my-property/:service_type/:property_id', component: PropertyComponent },
  { path: 'property/:service_type/:id', component: PropertyComponent },
  { path: 'appointment', component: AppointmentComponent },
  { path: 'appointment-management', component: AppointmentManagementComponent },
  { path: 'create-appointment', component: CreateAppointmentComponent },
  { path: 'my-appointments', component: MyAppointmentsComponent },
  { path: 'appointment/:id', component: SingleAppointmentComponent },
  { path: 'user-interested/:id', component: UserInterestedComponent },
  {
    path: 'favorite-property/:service_type/:user_id/:property_id',
    component: LikePageComponent,
  },
  {
    path: 'favorite-property-list/:service_type',
    component: FavoritesPropertyListComponent,
  },
  { path: '**', redirectTo: 'welcome', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
