import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss'],
})
export class InputSearchComponent implements OnInit {
  @Input() value = '';
  @Output() Changed = new EventEmitter<any>();
  @Input() showErrors = true;
  public model: any;
  public hasError = false;
  @Input() disabled = false;
  @Input() errorWidth = '';
  @Input() borderClasses = 'border-white';
  @Input() borderColor = 'input-border';
  @Input() additionnalClasses = '';
  @Input() textColor = 'txt-white';
  @Input() input_code: String = '';
  @Input() placeholder: string = '';
  @Input() searchClasses = '';
  @Input() agencyID: FormGroup = this.formBuilder.group({
    agency_id: [''],
  });
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {}

  onChange($event: any): void {
    this.checkInput();
  }

  checkInput(): void {
    if (this.value === 'AGENCY_ID') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'USERNAME') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        this.Changed.emit(data);
      }
    }
  }
}
