// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  userPoolId: 'eu-west-3_otgaIICGm',
  userPoolWebClientId: '6l1tkl9h1j7s6a20n0fdl8njri',
  url: 'https://dev.noprod.sasinfiny.com/',
  cloudfront_url: 'https://d2syntlnu2rlg9.cloudfront.net/',
  apiKey: 'da2-lb4mqv25ovcvxjdktsr3mbejna',
  baseUrl:
    'https://k7zlbj44kjhddi2ta6ppdbwiqy.appsync-api.eu-west-3.amazonaws.com/graphql',
  AWS_COGNITO_IDENTITY_POOL_ID:
    'eu-west-3:06a2bb57-91ec-4f82-b18e-cb9f76ddc687',
  AWS_COGNITO_REGION: 'eu-west-3',
  google_api_access_token: 'AIzaSyDTbRf4gD1raBonoP80Y9-WepL2uJZu7Vk',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
