import { AppointmentService } from './../../shared/services/appointment.service';
import { AppointmentModel } from './../../shared/models/appointment-model';
import { LocalStorageService } from './../../shared/services/local-storage.service';
import { ParametersService } from './../../shared/services/parameters.service';
import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-appointment-date',
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
    ]),
  ],
  templateUrl: './appointment-date.component.html',
  styleUrls: ['./appointment-date.component.scss'],
})
export class AppointmentDateComponent implements OnInit {
  public days: any[];
  @Output() SelectTime = new EventEmitter<any>();
  @Input() date: any;
  @Input() pro_id: string;
  public timeWindows: any[] = [];
  @Input() hourIsSelected = false;
  @Input() hourSelected;
  @Input() current_selected_year;
  @Input() current_selected_month;
  public month_array = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'aôut',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ];
  public selectedDate;
  @Input() savedData = {
    APPEL_TELEPHONIQUE: {},
    VISITE_DU_BIEN_PAR_UN_EXPERT: {},
    VISITE_DU_BIEN_AVEC_UN_ACHETEUR: {},
    RENDEZ_VOUS_EN_AGENCE: {},
  };
  public start: number = 9;
  public end: number = 17;
  @Input() daysClasses = '';
  @Input() hoursClasses = '';
  @Input() hoursTitleClasses = '';
  @Input() subtitle =
    'A quelle date, souhaitez-vous reporter ce rendez-vous ? *';
  @Input() appointmentType: string = '';
  public isDaySelected: boolean = false;
  @Input() user_has_parameters: boolean;
  @Input() available_days_array: any[] = [];
  @Input() appointments_list: AppointmentModel[] = [];
  public user_appointments: boolean;
  public appointments_filtered: AppointmentModel[] = [];
  public unavalaible_date_array: any[] = [];

  constructor(
    private localStorageService: LocalStorageService,
    private cd: ChangeDetectorRef,
    private parametersService: ParametersService,
    private appointmentService: AppointmentService,
  ) {
    this.current_selected_month = new Date().getMonth();
    this.current_selected_year = new Date().getFullYear();
  }

  ngOnInit(): void {
    console.log("ASJNDUIBNSIUCQ", this.appointmentType);
    
    this.getAllUserAppointments();
    var days = this.getDaysInMonth(
      this.current_selected_year,
      this.current_selected_month
    );
    this.getFrDaysInMonth(days);
    // This line is here for remove error Expression has changed after it was checked (this.days array)
    this.cd.detectChanges();
  }

  /**
   * getDaysInMonth
   */
  public getFrDaysInMonth($days) {
    this.days = [];
    for (let index = 0; index < $days.length; index++) {
      this.date = new Date($days[index]);
      console.log(this.date);

      var day = this.date.toLocaleString('fr-FR', { weekday: 'short' });
      let month = this.date.toLocaleString('fr-FR', { month: 'short' });
      var day_number =
        this.date.getDate() < 10
          ? '0' + this.date.getDate()
          : this.date.getDate();
      var date_string =
        day + ' ' + day_number + ' ' + month + ' ' + this.current_selected_year;
      this.days.push({
        number: day_number,
        name: day,
        date: date_string,
        selectedDate: this.date.toDateString(),
      });
      console.log('days', this.days);
    }
  }

  /**
   * selectMonthAndYear
   */
  public selectMonthAndYear($event) {
    if ($event.value === 'DATE_SORT') {
      console.log($event);
      var month_number: any = this.month_array.indexOf(
        $event.current_month.toLowerCase()
      );
      this.getParametersByUser();
      var days_list = this.getDaysInMonth($event.current_year, month_number);
      this.current_selected_year = $event.current_year;
      this.getFrDaysInMonth(days_list);
    }
  }
  /**
   * selectDate
   */
  public selectDate($date) {
    console.log('DATE', $date);
    this.date = $date.selectedDate;
    this.appointments_filtered = this.appointments_list.filter((element) => {
      return (
        new Date(element.Appointment_date).getTime() ===
        new Date(this.date).getTime()
      );
    });
    this.unavalaible_date_array = [];
    this.appointments_filtered.forEach((element) => {
      var hourExplode = element.Appointment_hour.split(':');
      var hourToPush =
        hourExplode[0] +
        ' : ' +
        (hourExplode[1] == '0' ? (hourExplode[1] = '00') : hourExplode[1]);
      this.unavalaible_date_array.push(hourToPush);
    });
    if (this.date) {
      this.parametersService
        .getParametersByProfessionnel(this.pro_id)
        .subscribe((res) => {
          console.log(res);

          if (res.data && res.data.ParameterByUser) {
            console.log(res);

            const params = res.data.ParameterByUser.items;
            Array.from(params).forEach((param: any) => {
              if (param.appointment_type === 'APPEL_TELEPHONIQUE') {
                this.savedData.APPEL_TELEPHONIQUE = param;
              } else if (
                param.appointment_type === 'VISITE_DU_BIEN_PAR_UN_EXPERT'
              ) {
                this.savedData.VISITE_DU_BIEN_PAR_UN_EXPERT = param;
              } else if (
                param.appointment_type === 'VISITE_DU_BIEN_AVEC_UN_ACHETEUR'
              ) {
                this.savedData.VISITE_DU_BIEN_AVEC_UN_ACHETEUR = param;
              } else if (param.appointment_type === 'RENDEZ_VOUS_EN_AGENCE') {
                this.savedData.RENDEZ_VOUS_EN_AGENCE = param;
              }
            });
            this.fillTimeWindows();
          }
        });
      this.isDaySelected = true;
    }
  }

  /**
   * getParametersByUser
   */
  public async getParametersByUser() {
    await this.parametersService
      .getParametersByProfessionnel(this.pro_id)
      .subscribe((res) => {
        console.log(res);
        if (res.data && res.data.ParameterByUser) {
          console.log(res);
          console.log('APPOINTMENT_TYPE', this.appointmentType);
          const params = res.data.ParameterByUser.items;
          Array.from(params).forEach((param: any) => {
            if (param.appointment_type === this.appointmentType) {
              this.savedData.APPEL_TELEPHONIQUE = param;
            } else if (param.appointment_type === this.appointmentType) {
              this.savedData.VISITE_DU_BIEN_PAR_UN_EXPERT = param;
            } else if (param.appointment_type === this.appointmentType) {
              this.savedData.VISITE_DU_BIEN_AVEC_UN_ACHETEUR = param;
            } else if (param.appointment_type === this.appointmentType) {
              this.savedData.RENDEZ_VOUS_EN_AGENCE = param;
            }
          });
          console.log('this.savedData', this.savedData);
          console.log('this.available_days_array', this.available_days_array);
          this.user_has_parameters = true;
        } else {
          this.user_has_parameters = false;
        }
      });
  }

  /**
   * @param {int} The month number, 0 based
   * @param {int} The year, not zero based, required to account for leap years
   * @return {Date[]} List with date objects for each day of the month
   */
  public getDaysInMonth(year: number, month: number) {
    var date = new Date(year, month, 1);
    var days = [];
    var today = new Date().getTime();
    console.log(
      'this.available_days_array.length',
      this.available_days_array.length
    );
    this.user_has_parameters = false;
    if (this.available_days_array.length) {
      this.user_has_parameters = true;
      while (date.getMonth() === month) {
        const _date = new Date(date);
        var day = _date.toLocaleString('en-EN', { weekday: 'short' });
        this.available_days_array.filter((element) => {
          console.log('DAAAAAAYYYY', day, element);
          if (element.includes(day) && new Date(date).getTime() >= today) {
            days.push(new Date(date));
          }
        });
        date.setDate(date.getDate() + 1);
      }
      return days;
    }
  }

  /**
   * fillTimeWindows
   */
  public fillTimeWindows = () => {
    this.hourSelected = null
    if (this.date) {
      this.timeWindows = [];
      const type = this.appointmentType;
      console.log('type', type);

      const date = this.date;
      console.log('date', date);

      const saved = this.savedData[type];
      console.log('OBJECT.KEYS', saved);
      let start: string;
      let end: string;
      let duration: number;
      if (Object.keys(saved).length > 0) {
        console.log('OBJECT.KEYS', saved);
        start = saved.time_slots_am.split('-')[0];
        end = saved.time_slots_pm.split('-')[1];
        duration = parseInt(saved.duration);
      } else {
        start = '09:00';
        end = '17:00';
        duration = 30;
      }

      const today = new Date(date);
      console.log('TODAY', today);

      var m = moment(today.toDateString(), 'ddd MMM D YYYY HH:mm:ss ZZ');
      m.set({
        h: parseInt(start.split(':')[0]),
        m: parseInt(start.split(':')[1]),
      });

      let time = `${
        m.toDate().getHours() < 10
          ? '0' + m.toDate().getHours()
          : m.toDate().getHours()
      }:${
        m.toDate().getMinutes() < 10
          ? '0' + m.toDate().getMinutes()
          : m.toDate().getMinutes()
      }`;
      console.log("TIME", parseInt(time.split(':')[0]), parseInt(time.split(':')[1]));
      
      var HOUR = parseInt(time.split(':')[0]) < 10 ? '0'+parseInt(time.split(':')[0]) : parseInt(time.split(':')[0]);
      var MINUTES = parseInt(time.split(':')[1]) == 0 ? '0'+parseInt(time.split(':')[1]) : parseInt(time.split(':')[1]);
      var DATETIME = HOUR + ' : ' + MINUTES;
      console.log("DATETIME", DATETIME);

      this.timeWindows.push({ time: DATETIME, isUnavalaible: '' });

      while (
        parseInt(time.split(':')[0]) < parseInt(end.split(':')[0]) ||
        parseInt(time.split(':')[1]) < parseInt(end.split(':')[1])
      ) {
        m.add(duration, 'm');
        time = `${
          m.toDate().getHours() < 10
            ? '0' + m.toDate().getHours()
            : m.toDate().getHours()
        } : ${
          m.toDate().getMinutes() < 10
            ? '0' + m.toDate().getMinutes()
            : m.toDate().getMinutes()
        }`;

        this.timeWindows.push({
          time: time,
          isUnavalaible: '',
        });
      }
      const timeArray = this.timeWindows.map((element) => {
        console.log('map', element);
        this.unavalaible_date_array.map((data) => {
          if (element.time === data) {
            console.log('MAP', element);
            element.isUnavalaible = true;
          }
        });
      });
      console.log('map1', timeArray);
    }

    console.log('HOURS', this.timeWindows);
  };

  /**
   * selectTimeWindow
   */
  public selectTimeWindow(hour: any) {
    console.log("hour", hour);
    
    if (!hour.isUnavalaible || hour.isUnavalaible == '') {
      this.hourSelected = hour.time;
      this.hourIsSelected = true;
      let hourSelected = this.hourSelected + ':00';
      let hourReplace = hourSelected.replace(/ /g, '');
      const timeArray = hourReplace.split(':');
      this.selectedDate = this.date + ' ' + hourReplace;
      if (this.selectedDate) {
        const data = {
          hour: this.hourSelected,
          date: this.date,
          datetime: this.selectedDate,
        };
        this.SelectTime.emit(data);
      }
    }
  }

  /**
   * getAllUserAppointments
   */
  public getAllUserAppointments() {
    console.log(this.pro_id);

    this.appointmentService
      .getAllUserAppointments(this.pro_id)
      .subscribe((res) => {
        console.log(res);
        if (res && res.data.getAllUserAppointments) {
          const data = res.data.getAllUserAppointments;
          // Transform date for filter function
          data.forEach((element) => {
            const appointmentsList = new AppointmentModel().mapToModel(element);
            console.log('test', appointmentsList.Appointment_type);

            this.appointments_list.push(appointmentsList);
          });
          console.log(this.appointments_list);
        } else if (res.errors.message) {
          this.user_appointments = true;
          console.log(res.errors.message);
        } else {
          this.user_appointments = false;
          console.log('ERREUR SERVEUR');
        }
      });
  }
}
