<div [@fade]>
  <app-text
    textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
    text="Quel rendez-vous souhaitez-vous proposer ? *"
  ></app-text>
  <div class="grid grid-cols-2 row-2 gap-3">
    <div *ngFor="let appointment of appointmentTypeArray">
      <app-form-card
        [imgPath]="appointment.path_img"
        imgClasses="h-16 mx-auto"
        cardClasses="default"
        [model]="appointment.item_value"
        subtitleClasses="font-semibold text-center text-base w-full h-full px-3"
        (IsClicked)="onClick($event)"
        [value]="appointment.type"
        [item_id]="appointment.item_value_db"
        [property_item]="appointmentModel.Appointment_type"
      ></app-form-card>
    </div>
    <app-text
      textClasses="txt-percian-gray text-xxs mx-auto text-left"
      text="Champs requis*"
    ></app-text>
  </div>
</div>
