import { CustomPopupComponent } from './../../shared/components/custom-popup/custom-popup.component';
import { LoaderPopupComponent } from './../../shared/components/loader-popup/loader-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { PropertyModel } from 'src/app/shared/models/property-model';
import { PropertyService } from './../../shared/services/property.service';
import { LocalStorageService } from './../../shared/services/local-storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from './../../shared/services/data.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-like-page',
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
    ]),
  ],
  templateUrl: './like-page.component.html',
  styleUrls: ['./like-page.component.scss'],
})
export class LikePageComponent implements OnInit {
  @Input() property_id: string;
  @Input() user_id: string;
  @Input() property: PropertyModel[] = [];
  @Input() service_type: string;
  @Input() like_status;
  @Input() status_array = [
    {
      status_name: 'Intéressé(e)',
      status_db: 'INTERESSE',
      imgSrc: 'assets/icons/svg/interested.svg',
    },
    {
      status_name: 'Souhaite plus de photos',
      status_db: 'SOUHAITE_PLUS_DE_PHOTOS',
      imgSrc: 'assets/icons/svg/photos_device.svg',
    },
    {
      status_name: 'Souhaite une visite',
      status_db: 'SOUHAITE_UNE_VISITE',
      imgSrc: 'assets/icons/svg/eyes.svg',
    },
    {
      status_name: 'Prêt(e) à faire une offre',
      status_db: 'PRET_A_FAIRE_UNE_OFFRE',
      imgSrc: 'assets/icons/svg/hands.svg',
    },
  ];
  @Input() property_user_id: any;
  @Input() isOnLikePage: boolean = true;
  @Input() favorite_property_status: any[] = [];
  public hasError: boolean = false;

  constructor(
    private dialog: MatDialog,
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private propertyService: PropertyService
  ) {
    this.service_type = this.route.snapshot.paramMap.get('service_type');
    this.user_id = this.localStorageService.getCurrentUser().id;
    console.log('user_id', this.user_id);
    this.property_id = this.route.snapshot.paramMap.get('property_id');
    console.log('PROPERTY_ID', this.property_id);

    this.like_status = 'INTERESSE';
  }

  ngOnInit(): void {
    this.getProperty(this.property_id);
    this.getFavoritePropetyStatus();
  }

  /**
   * back
   */
  public back() {
    this.router.navigate([`/property-list/${this.service_type}`]);
  }

  /**
   * getProperty
   */
  public getProperty($property_id) {
    this.propertyService.getOneProperty($property_id).subscribe((res) => {
      console.log('RES: ', res);
      if (res && res.data.getOneProperty) {
        res.data.getOneProperty.forEach((element) => {
          const property = new PropertyModel().mapToModel(element);
          this.property_user_id = property.User_id;
          for (let i = 0; i < this.favorite_property_status.length; i++) {
            console.log(
              this.favorite_property_status[i].property_id,
              property.ID
            );
            if (this.favorite_property_status[i].property_id === property.ID) {
              console.log('FAVORITE IS TRUE');

              property.IsFavorite = true;
            }
          }
          this.property.push(property);
        });
      }
    });
  }

  /**
   * isSelected
   */
  public isSelected($like_status) {
    console.log('likestatus', $like_status);
    this.like_status = $like_status;
  }

  /**
   * getFavoritePropetyStatus
   */
  public getFavoritePropetyStatus() {
    this.propertyService
      .getFavoriteProperty(
        this.localStorageService.getCurrentUser().id,
        this.property_id
      )
      .subscribe((res) => {
        console.log(res);
        if (res && res.data.listFavoritesPropertys.items) {
          this.favorite_property_status = res.data.listFavoritesPropertys.items;
          console.log(this.favorite_property_status);
        } else {
          this.hasError = true;
        }
      });
  }

  /**
   * addOrRemoveFavoriteProperty
   */
  public async addOrRemoveFavoriteProperty() {
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Veuillez patienter ... ',
        isLoader: true,
      },
    });
    await this.propertyService
      .addOrRemoveFavoriteProperty(
        this.user_id,
        this.like_status,
        this.property_id
      )
      .subscribe((res) => {
        console.log(res);
        if (res && res.data.addOrRemoveFavoriteProperty) {
          const successDialog = this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'success',
              title: 'Bien enregistré',
              message: 'Ce bien a été enregistré dans vos favoris',
            },
          });
          successDialog.afterClosed().subscribe(() => {
            this.dialog.closeAll();
            this.router.navigate([`/property-list/${this.service_type}`]);
          });
        } else if (res.errors.message) {
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: "Une erreur s'est produite",
              message:
                'Nous sommes désolé, un problème est survenu. Veuillez réessayer ultérieurement.',
            },
          });
        } else {
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: "Une erreur s'est produite",
              message:
                'Nous sommes désolé, un problème est survenu. Veuillez réessayer ultérieurement.',
            },
          });
        }
      });
  }
}
