<!-- Back button -->
<app-back-button
  (IsClicked)="back($event)"
  buttonClasses="flex gap-3 my-6 items-center ml-6"
  imgSrc="assets/icons/svg/back-button-blue.svg"
  imgClasses="h-6"
  text="Les candidats"
  txtClasses="txt-light-blue text-sm"
></app-back-button>
<div class="flex gap-4 items-end border-b mb-6 pb-6">
  <img src="assets/icons/svg/logo-1.svg" class="h-12 ml-6" />
  <app-text
    textClasses="txt-bold-gray uppercase text-xs font-bold"
    [text]="username"
  ></app-text>
</div>
<div class="mt-3 flex justify-between border-b pb-6">
  <div class="flex flex-col gap-3">
    <app-text
      textClasses="txt-light-blue uppercase text-xs font-extrabold mx-6"
      text="Niveau de projection"
    ></app-text>
    <app-text
      textClasses="txt-bold-gray text-xs font-bold mx-6"
      [text]="projection_level"
    ></app-text>
  </div>
  <div class="flex gap-3 mx-6">
    <div>
      <a [href]="candidate_phone_number"
        ><img src="assets/icons/svg/phone.svg" class="h-12"
      /></a>
    </div>

    <div>
      <a [href]="candidate_email"
        ><img src="assets/icons/svg/email.svg" class="h-12"
      /></a>
    </div>
  </div>
</div>
<div class="mt-3 flex justify-between border-b pb-6">
  <div class="flex flex-col gap-3">
    <app-text
      textClasses="txt-light-blue uppercase text-xs font-extrabold mx-6"
      text="Offre du candidat"
    ></app-text>
    <app-text
      textClasses="txt-bold-gray text-xs font-bold mx-6"
      [text]="
        !candidate_offer
          ? 'Aucune offre n\'a été présentée'
          : 'Consulter l\'offre du candidat'
      "
    ></app-text>
  </div>
  <div class="flex gap-3 mx-6">
    <img
      src="assets/icons/svg/file.svg"
      class="h-12"
      (click)="getFile(candidate_offer)"
    />
  </div>
</div>
<div class="mt-3 flex justify-between pb-6">
  <div class="flex flex-col gap-3">
    <app-text
      textClasses="txt-light-blue uppercase text-xs font-extrabold mx-6"
      text="Dossier de candidature"
    ></app-text>
    <app-text
      textClasses="txt-bold-gray text-xs font-bold mx-6"
      text="Documents et justificatifs du candidats"
    ></app-text>
  </div>
</div>
<app-text
  *ngIf="service_type === 'rent'"
  textClasses="txt-light-blue text-xs font-extrabold mx-6"
  text="Candidat"
></app-text>
<!-- JUSTIFICATIF IDENTITE -->
<div class="mt-3 flex justify-between pb-6">
  <div class="flex flex-col gap-3">
    <app-text
      textClasses="txt-light-blue uppercase text-xs font-extrabold mx-6"
      text="Identité"
    ></app-text>
    <div class="flex gap-3 items-center mx-6">
      <img
        [src]="
          !proof_of_identity
            ? 'assets/icons/svg/cancel.svg'
            : 'assets/icons/svg/valid.svg'
        "
        class="h-4"
      />
      <app-text
        textClasses="txt-bold-gray text-xs font-bold"
        text="Justificatif d'identité"
      ></app-text>
    </div>
  </div>
  <div class="flex gap-3 mx-6">
    <img
      src="assets/icons/svg/file.svg"
      class="h-12"
      (click)="getFile(proof_of_identity)"
    />
  </div>
</div>
<!-- LIVRET DE FAMILLE -->
<div *ngIf="service_type === 'sale'" class="mt-3 flex justify-between flex-col">
  <app-text
    textClasses="txt-light-blue uppercase text-xs font-extrabold mx-6"
    text="Justificatif de situation matrimoniale"
  ></app-text>
  <div class="flex items-center justify-between mx-6">
    <div class="flex flex-col gap-3">
      <div class="flex gap-3 items-center">
        <img
          [src]="
            !family_record_book
              ? 'assets/icons/svg/cancel.svg'
              : 'assets/icons/svg/valid.svg'
          "
          class="h-4"
        />
        <app-text
          textClasses="txt-bold-gray text-xs font-bold"
          text="Livret de famille"
        ></app-text>
      </div>
    </div>
    <div class="flex gap-3">
      <img
        src="assets/icons/svg/file.svg"
        class="h-12"
        (click)="getFile(family_record_book)"
      />
    </div>
  </div>
</div>
<!-- MARIAGE PACS-->
<div *ngIf="service_type === 'sale'" class="flex justify-between pb-6 mt-3">
  <div class="flex gap-3 items-center mx-6">
    <img
      [src]="
        !marriage_or_pacs_certificate
          ? 'assets/icons/svg/cancel.svg'
          : 'assets/icons/svg/valid.svg'
      "
      class="h-4"
    />
    <app-text
      textClasses="txt-bold-gray text-xs font-bold"
      text="Attestation mariage, pacs"
    ></app-text>
  </div>
  <div class="flex gap-3 mx-6">
    <img
      src="assets/icons/svg/file.svg"
      class="h-12"
      (click)="getFile(marriage_or_pacs_certificate)"
    />
  </div>
</div>
<!-- AVIS IMPOSITION -->
<div
  *ngIf="service_type === 'rent'"
  class="mt-3 flex flex-col justify-between align-top pb-6"
>
  <app-text
    textClasses="txt-light-blue uppercase text-xs font-extrabold mx-6"
    text="Justificatif des ressources"
  ></app-text>
  <div class="flex mx-6 justify-between">
    <div class="flex flex-col gap-3">
      <div class="flex gap-3 items-center">
        <img
          [src]="
            !tax_notice
              ? 'assets/icons/svg/cancel.svg'
              : 'assets/icons/svg/valid.svg'
          "
          class="h-4"
        />
        <app-text
          textClasses="txt-bold-gray text-xs font-bold"
          text="Avis d\'imposition"
        ></app-text>
      </div>
    </div>
    <div class="flex gap-3">
      <img
        src="assets/icons/svg/file.svg"
        class="h-12"
        (click)="getFile(tax_notice)"
      />
    </div>
  </div>
</div>

<!-- JUSTIFICATIF ACTIVITE PRO -->
<div
  *ngIf="service_type === 'rent'"
  class="mt-3 flex flex-col justify-between pb-6"
>
  <app-text
    textClasses="txt-light-blue uppercase text-xs font-extrabold mx-6"
    text="Justificatif d'activité professionnelle"
  ></app-text>
  <div class="flex gap-3 justify-between mx-6">
    <div class="flex gap-3 items-center">
      <img
        [src]="
          !employment_contract
            ? 'assets/icons/svg/cancel.svg'
            : 'assets/icons/svg/valid.svg'
        "
        class="h-4"
      />
      <app-text
        textClasses="txt-bold-gray text-xs font-bold"
        text="Contrat de travail"
      ></app-text>
    </div>
    <div class="flex gap-3">
      <img
        src="assets/icons/svg/file.svg"
        class="h-12"
        (click)="getFile(employment_contract)"
      />
    </div>
  </div>
</div>

<!-- JUSTIFICATIF DOMICILE -->
<div *ngIf="service_type === 'sale'" class="mt-3 flex flex-col gap-3 pb-6">
  <app-text
    textClasses="txt-light-blue uppercase text-xs font-extrabold mx-6"
    text="Justificatif de domicile"
  ></app-text>
  <div class="flex items-center justify-between gap-3 mx-6">
    <div class="flex gap-3 items-center">
      <img
        [src]="
          !tax_notice
            ? 'assets/icons/svg/cancel.svg'
            : 'assets/icons/svg/valid.svg'
        "
        class="h-4"
      />
      <app-text
        textClasses="txt-bold-gray text-xs font-bold"
        text="Avis d'imposition"
      ></app-text>
    </div>
    <div>
      <img
        src="assets/icons/svg/file.svg"
        class="h-12"
        (click)="getFile(tax_notice)"
      />
    </div>
  </div>
  <div class="flex items-center justify-between gap-3 mx-6">
    <div class="flex gap-3 items-center">
      <img
        [src]="
          !electricity_bill
            ? 'assets/icons/svg/cancel.svg'
            : 'assets/icons/svg/valid.svg'
        "
        class="h-4"
      />
      <app-text
        textClasses="txt-bold-gray text-xs font-bold"
        text="Facture EDF"
      ></app-text>
    </div>
    <div>
      <img
        src="assets/icons/svg/file.svg"
        class="h-12"
        (click)="getFile(electricity_bill)"
      />
    </div>
  </div>
</div>

<!-- QUITTANCE LOYER -->
<div *ngIf="service_type === 'rent'" class="mt-3 flex flex-col pb-6 align-top">
  <app-text
    textClasses="txt-light-blue uppercase text-xs font-extrabold mx-6"
    text="Justificatif de domicile"
  ></app-text>
  <div class="flex justify-between mx-6 gap-3">
    <div class="flex gap-3 items-center">
      <img
        [src]="
          !rent_receipt_1
            ? 'assets/icons/svg/cancel.svg'
            : 'assets/icons/svg/valid.svg'
        "
        class="h-4"
      />
      <app-text
        textClasses="txt-bold-gray text-xs font-bold"
        text="Quittance de loyer 1"
      ></app-text>
    </div>
    <div class="flex gap-3">
      <img
        src="assets/icons/svg/file.svg"
        class="h-12"
        (click)="getFile(rent_receipt_1)"
      />
    </div>
  </div>
  <div class="flex justify-between mx-6 gap-3">
    <div class="flex gap-3 items-center">
      <img
        [src]="
          !rent_receipt_2
            ? 'assets/icons/svg/cancel.svg'
            : 'assets/icons/svg/valid.svg'
        "
        class="h-4"
      />
      <app-text
        textClasses="txt-bold-gray text-xs font-bold"
        text="Quittance de loyer 2"
      ></app-text>
    </div>
    <div class="flex gap-3">
      <img
        src="assets/icons/svg/file.svg"
        class="h-12"
        (click)="getFile(rent_receipt_2)"
      />
    </div>
  </div>
  <div class="flex justify-between mx-6 gap-3">
    <div class="flex gap-3 items-center">
      <img
        [src]="
          !rent_receipt_3
            ? 'assets/icons/svg/cancel.svg'
            : 'assets/icons/svg/valid.svg'
        "
        class="h-4"
      />
      <app-text
        textClasses="txt-bold-gray text-xs font-bold"
        text="Quittance de loyer 3"
      ></app-text>
    </div>
    <div class="flex gap-3">
      <img
        src="assets/icons/svg/file.svg"
        class="h-12"
        (click)="getFile(rent_receipt_3)"
      />
    </div>
  </div>
</div>

<div
  *ngIf="service_type === 'sale'"
  class="mt-3 flex flex-col justify-betweenalign-top"
>
  <app-text
    textClasses="txt-light-blue uppercase text-xs font-extrabold mx-6"
    text="Attestation de financement"
  ></app-text>
  <div class="flex justify-between mx-6">
    <div class="flex items-center gap-3">
      <img
        [src]="
          !proof_of_the_funds_making_up_the_contribution
            ? 'assets/icons/svg/cancel.svg'
            : 'assets/icons/svg/valid.svg'
        "
        class="h-4"
      />
      <app-text
        textClasses="txt-bold-gray text-xs font-bold"
        text="Justificatif des fonds composant l’apport"
      ></app-text>
    </div>
    <div class="flex gap-3">
      <img
        src="assets/icons/svg/file.svg"
        class="h-12"
        (click)="getFile(proof_of_the_funds_making_up_the_contribution)"
      />
    </div>
  </div>
</div>
<div *ngIf="service_type === 'sale'">
  <div class="flex justify-between mx-6 mt-3">
    <div class="flex items-center gap-3">
      <img
        [src]="
          !conditions_precedent_if_loan
            ? 'assets/icons/svg/cancel.svg'
            : 'assets/icons/svg/valid.svg'
        "
        class="h-4"
      />
      <app-text
        textClasses="txt-bold-gray text-xs font-bold"
        text="Conditions suspensives si emprunt"
      ></app-text>
    </div>
    <div class="flex gap-3">
      <img
        src="assets/icons/svg/file.svg"
        class="h-12"
        (click)="getFile(conditions_precedent_if_loan)"
      />
    </div>
  </div>
</div>

<div *ngIf="service_type === 'sale'" class="mt-3 flex flex-col pb-6 align-top">
  <app-text
    textClasses="txt-light-blue uppercase text-xs font-extrabold mx-6"
    text="Justificatif entreprise"
  ></app-text>
  <div class="flex justify-between mx-6 gap-3">
    <div class="flex gap-3 items-center">
      <img
        [src]="
          !kbis_or_extract
            ? 'assets/icons/svg/cancel.svg'
            : 'assets/icons/svg/valid.svg'
        "
        class="h-4"
      />
      <app-text
        textClasses="txt-bold-gray text-xs font-bold"
        text="Kbis ou extrait de K"
      ></app-text>
    </div>
    <div class="flex gap-3">
      <img
        src="assets/icons/svg/file.svg"
        class="h-12"
        (click)="getFile(kbis_or_extract)"
      />
    </div>
  </div>
</div>

<div *ngIf="service_type === 'sale'" class="mt-3 flex flex-col pb-6 align-top">
  <app-text
    textClasses="txt-light-blue uppercase text-xs font-extrabold mx-6"
    text="Finalité de l'achat"
  ></app-text>
  <div class="flex justify-between mx-6 gap-3">
    <div class="flex gap-3 items-center">
      <img
        [src]="
          !rental_investment
            ? 'assets/icons/svg/cancel.svg'
            : 'assets/icons/svg/valid.svg'
        "
        class="h-4"
      />
      <app-text
        textClasses="txt-bold-gray text-xs font-bold"
        text="Investissment locatif"
      ></app-text>
    </div>
    <div class="flex gap-3">
      <img
        src="assets/icons/svg/file.svg"
        class="h-12"
        (click)="getFile(rental_investment)"
      />
    </div>
  </div>
</div>

<app-text
  *ngIf="service_type === 'rent'"
  textClasses="txt-light-blue text-xs font-extrabold mx-6"
  text="Garant"
></app-text>

<div *ngIf="service_type === 'rent'" class="mt-3 flex flex-col pb-6 align-top">
  <app-text
    textClasses="txt-light-blue uppercase text-xs font-extrabold mx-6"
    text="Identité"
  ></app-text>
  <div class="flex justify-between mx-6 gap-3">
    <div class="flex gap-3 items-center">
      <img
        [src]="
          !proof_of_identity_guarantor
            ? 'assets/icons/svg/cancel.svg'
            : 'assets/icons/svg/valid.svg'
        "
        class="h-4"
      />
      <app-text
        textClasses="txt-bold-gray text-xs font-bold"
        text="Justificatifs d’identité"
      ></app-text>
    </div>
    <div class="flex gap-3">
      <img
        src="assets/icons/svg/file.svg"
        class="h-12"
        (click)="getFile(proof_of_identity_guarantor)"
      />
    </div>
  </div>
</div>

<div *ngIf="service_type === 'rent'" class="mt-3 flex flex-col pb-6 align-top">
  <app-text
    textClasses="txt-light-blue uppercase text-xs font-extrabold mx-6"
    text="Identité morale"
  ></app-text>
  <div class="flex justify-between mx-6 gap-3">
    <div class="flex gap-3 items-center">
      <img
        [src]="
          !kbis_or_extract
            ? 'assets/icons/svg/cancel.svg'
            : 'assets/icons/svg/valid.svg'
        "
        class="h-4"
      />
      <app-text
        textClasses="txt-bold-gray text-xs font-bold"
        text="Kbis ou extrait de K"
      ></app-text>
    </div>
    <div class="flex gap-3">
      <img
        src="assets/icons/svg/file.svg"
        class="h-12"
        (click)="getFile(kbis_or_extract)"
      />
    </div>
  </div>
</div>

<div *ngIf="service_type === 'rent'" class="mt-3 flex flex-col pb-6 align-top">
  <app-text
    textClasses="txt-light-blue uppercase text-xs font-extrabold mx-6"
    text="Justificatif des ressources"
  ></app-text>
  <div class="flex justify-between mx-6 gap-3">
    <div class="flex gap-3 items-center">
      <img
        [src]="
          !tax_notice_guarantor
            ? 'assets/icons/svg/cancel.svg'
            : 'assets/icons/svg/valid.svg'
        "
        class="h-4"
      />
      <app-text
        textClasses="txt-bold-gray text-xs font-bold"
        text="Avis d'imposition"
      ></app-text>
    </div>
    <div class="flex gap-3">
      <img
        src="assets/icons/svg/file.svg"
        class="h-12"
        (click)="getFile(tax_notice_guarantor)"
      />
    </div>
  </div>
</div>

<div *ngIf="service_type === 'rent'" class="mt-3 flex flex-col pb-6 align-top">
  <app-text
    textClasses="txt-light-blue uppercase text-xs font-extrabold mx-6"
    text="Justificatif d'activité professionnelle"
  ></app-text>
  <div class="flex justify-between mx-6 gap-3">
    <div class="flex gap-3 items-center">
      <img
        [src]="
          !employment_contract_guarantor
            ? 'assets/icons/svg/cancel.svg'
            : 'assets/icons/svg/valid.svg'
        "
        class="h-4"
      />
      <app-text
        textClasses="txt-bold-gray text-xs font-bold"
        text="Contrat de travail"
      ></app-text>
    </div>
    <div class="flex gap-3">
      <img
        src="assets/icons/svg/file.svg"
        class="h-12"
        (click)="getFile(employment_contract_guarantor)"
      />
    </div>
  </div>
</div>

<div *ngIf="service_type === 'rent'" class="mt-3 flex flex-col pb-6 align-top">
  <app-text
    textClasses="txt-light-blue uppercase text-xs font-extrabold mx-6"
    text="Justificatif de domicile"
  ></app-text>
  <div class="flex justify-between mx-6 gap-3">
    <div class="flex gap-3 items-center">
      <img
        [src]="
          !electricity_bill
            ? 'assets/icons/svg/cancel.svg'
            : 'assets/icons/svg/valid.svg'
        "
        class="h-4"
      />
      <app-text
        textClasses="txt-bold-gray text-xs font-bold"
        text="Facture EDF"
      ></app-text>
    </div>
    <div class="flex gap-3">
      <img
        src="assets/icons/svg/file.svg"
        class="h-12"
        (click)="getFile(electricity_bill)"
      />
    </div>
  </div>
</div>
