<div class="flex justify-between items-center mb-3">
  <div class="flex gap-3 items-center">
    <img
      [ngClass]="imgClasses"
      (click)="onClick($event)"
      src="assets/icons/svg/file.svg"
      class="h-10"
    />
    <p [ngClass]="txtClasses" class="txt-bold-gray text-xs">{{ text }}</p>
  </div>
  <div>
    <label [for]="field_name" class="cursor-pointer">
      <img [ngClass]="ImgClasses" [src]="imgSrc" class="h-10" />
      <input
        [id]="field_name"
        type="file"
        accept=".pdf"
        class="hidden"
        (change)="isClicked($event)"
      />
    </label>
  </div>
</div>
