<div
  [formGroup]="otpFormFields"
  class="flex w-max-content justify-center mx-auto my-8"
>
  <input
    formControlName="otp_code_1"
    type="text"
    maxlength="1"
    class="
      border-2 border-solid
      mx-1
      w-12
      h-16
      rounded-md
      text-center
      appearance-none
      focus:outline-none
      txt-bold-gray
      required
    "
    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
    [(ngModel)]="confirmationCode[0]"
    (keyup)="onChange($event)"
    id="tab{{ 0 }}"
    (input)="onInputEntry($event, 'tab', 0)"
  />
  <input
    formControlName="otp_code_2"
    type="text"
    maxlength="1"
    class="
      border-2 border-solid
      mx-1
      w-12
      h-16
      rounded-md
      text-center
      appearance-none
      focus:outline-none
      txt-bold-gray
      required
    "
    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
    [(ngModel)]="confirmationCode[1]"
    (keyup)="onChange($event)"
    id="tab{{ 1 }}"
    (input)="onInputEntry($event, 'tab', 1)"
  />
  <input
    formControlName="otp_code_3"
    type="text"
    maxlength="1"
    class="
      border-2 border-solid
      mx-1
      w-12
      h-16
      rounded-md
      text-center
      appearance-none
      focus:outline-none
      txt-bold-gray
      required
    "
    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
    [(ngModel)]="confirmationCode[2]"
    (keyup)="onChange($event)"
    id="tab{{ 2 }}"
    (input)="onInputEntry($event, 'tab', 2)"
  />
  <input
    formControlName="otp_code_4"
    type="text"
    maxlength="1"
    class="
      border-2 border-solid
      mx-1
      w-12
      h-16
      rounded-md
      text-center
      appearance-none
      focus:outline-none
      txt-bold-gray
      required
    "
    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
    [(ngModel)]="confirmationCode[3]"
    (keyup)="onChange($event)"
    id="tab{{ 3 }}"
    (input)="onInputEntry($event, 'tab', 3)"
  />
  <input
    formControlName="otp_code_5"
    type="text"
    maxlength="1"
    class="
      border-2 border-solid
      mx-1
      w-12
      h-16
      rounded-md
      text-center
      appearance-none
      focus:outline-none
      txt-bold-gray
      required
    "
    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
    [(ngModel)]="confirmationCode[4]"
    (keyup)="onChange($event)"
    id="tab{{ 4 }}"
    (input)="onInputEntry($event, 'tab', 4)"
  />
  <input
    formControlName="otp_code_6"
    type="text"
    maxlength="1"
    class="
      border-2 border-solid
      mx-1
      w-12
      h-16
      rounded-md
      text-center
      appearance-none
      focus:outline-none
      txt-bold-gray
      required
    "
    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
    [(ngModel)]="confirmationCode[5]"
    (keyup)="onChange($event)"
    id="tab{{ 5 }}"
    (input)="onInputEntry($event, 'tab', 5)"
  />
</div>
