import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-card',
  templateUrl: './form-card.component.html',
  styleUrls: ['./form-card.component.scss'],
})
export class FormCardComponent implements OnInit {
  @Input() text = '';
  @Input() textClasses = '';
  @Input() cardClasses = '';
  @Input() imgPath = '';
  @Input() imgClasses = '';
  @Input() subtitleClasses = '';
  @Input() subtitle = '';
  @Input() control_name = '';
  @Input() value = '';
  @Input() property_item: any;
  @Input() model: any;
  @Input() item_id: String = '';
  public hasError = false;
  public myForm: FormGroup = this.formBuilder.group({
    user_id: ['', Validators.required],
    city: ['', Validators.required],
    postal_code: ['', Validators.required],
    property_type: ['', Validators.required],
    property_subtype: ['', Validators.required],
    property_state: ['', Validators.required],
    service_type: ['', Validators.required],
    description: ['', Validators.required],
    total_area: ['', Validators.required],
    living_area: ['', Validators.required],
    access_type: ['', Validators.required],
    number_of_pieces: ['', Validators.required],
    energy_consumption: ['', Validators.required],
    greenHouse_gas_emissions: ['', Validators.required],
    bedroom_number: ['', Validators.required],
    digital_lock: ['', Validators.required],
    intercom: ['', Validators.required],
    separate_toilet: ['', Validators.required],
    fibre: ['', Validators.required],
    electric_vehicule_charging: ['', Validators.required],
    swimming_pool: ['', Validators.required],
    jaccuzi: ['', Validators.required],
    disabled_access: ['', Validators.required],
    separate_kitchen: ['', Validators.required],
    air_conditioning: ['', Validators.required],
    sea_view: ['', Validators.required],
    overview_sea: ['', Validators.required],
    not_overlooked: ['', Validators.required],
    balcony: ['', Validators.required],
    terrace: ['', Validators.required],
    garden: ['', Validators.required],
    opened_parking: ['', Validators.required],
    closed_parking: ['', Validators.required],
    price: ['', Validators.required],
    state: ['', Validators.required],
    cautions: ['', Validators.required],
    published_date: ['', Validators.required],
    land: ['', Validators.required],
    house: ['', Validators.required],
    office: ['', Validators.required],
    appartment: ['', Validators.required],
  });
  @Output() IsClicked = new EventEmitter<any>();
  @Input() isSelected = false;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {}

  onClick($event: any): void {
    this.checkInput();
  }

  checkInput(): void {
    if (this.value === 'SERVICE_TYPE') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
          id: '',
        };
        this.IsClicked.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
          id: this.item_id,
        };
        this.IsClicked.emit(data);
      }
    }
    if (this.value === 'PROPERTY_TYPE') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
          id: '',
        };
        this.IsClicked.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
          id: this.item_id,
        };
        this.IsClicked.emit(data);
      }
    }
    if (this.value === 'PROPERTY_SUBTYPE') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
          id: '',
        };
        this.IsClicked.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
          id: this.item_id,
        };
        this.IsClicked.emit(data);
      }
    }
    if (this.value === 'PROPERTY_STATE') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
          id: '',
        };
        this.IsClicked.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
          id: this.item_id,
        };
        this.IsClicked.emit(data);
      }
    }
    if (this.value === 'MAISON_VILLA') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
          id: '',
        };
        this.IsClicked.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
          id: this.item_id,
        };
        this.IsClicked.emit(data);
      }
    }
    if (this.value === 'APPARTEMENT') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
          id: '',
        };
        this.IsClicked.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
          id: this.item_id,
        };
        this.IsClicked.emit(data);
      }
    }
    if (this.value === 'BUREAUX') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
          id: '',
        };
        this.IsClicked.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
          id: this.item_id,
        };
        this.IsClicked.emit(data);
      }
    }
    if (this.value === 'TERRAIN') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
          id: '',
        };
        this.IsClicked.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
          id: this.item_id,
        };
        this.IsClicked.emit(data);
      }
    }
    if (this.value === 'APPOINTMENT_TYPE') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
          id: '',
        };
        this.IsClicked.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
          id: this.item_id,
        };
        this.IsClicked.emit(data);
      }
    }
  }
}
