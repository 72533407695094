import { AppointmentModel } from './../../shared/models/appointment-model';
import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-appointment-reason',
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
    ]),
  ],
  templateUrl: './appointment-reason.component.html',
  styleUrls: ['./appointment-reason.component.scss'],
})
export class AppointmentReasonComponent implements OnInit {
  @Input() appointmentModel = new AppointmentModel();
  @Output() IsClicked = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  /**
   * onChange
   */
  public onChange($appointment_reason) {
    console.log('REASON', $appointment_reason);
    this.IsClicked.emit($appointment_reason);
  }
}
