<app-text
  textClasses="txt-bold-gray text-center font-bold mt-16 mb-6"
  text="Qui souhaitez-vous inviter à ce rendez-vous ?"
></app-text>

<app-text
  *ngIf="!users_interested"
  textClasses="txt-light-blue text-center font-bold mt-16 mb-6"
  text="Vous n'avez aucun utilisateur intéressé par ce bien"
></app-text>
<div *ngIf="users_interested">
  <!-- Buttons list -->
  <div class="pr-6 flex gap-x-1 overflow-x-scroll">
    <app-filter-button
      (IsClicked)="switchStep('NAME')"
      text="Nom prénom"
      [textClasses]="
        current_step !== 'NAME'
          ? 'txt-bold-gray text-xs text-center px-3 py-2'
          : 'text-white text-xs text-center px-3 py-2'
      "
      [buttonClasses]="
        current_step !== 'NAME'
          ? 'rounded-3xl bg-ligth-gray'
          : 'rounded-3xl bg-light-blue'
      "
    ></app-filter-button>
    <app-filter-button
      (IsClicked)="switchStep('EMAIL')"
      text="Adresse mail"
      [textClasses]="
        current_step !== 'EMAIL'
          ? 'txt-bold-gray text-xs text-center px-3 py-2'
          : 'text-white text-xs text-center px-3 py-2'
      "
      [buttonClasses]="
        current_step !== 'EMAIL'
          ? 'rounded-3xl bg-ligth-gray'
          : 'rounded-3xl bg-light-blue'
      "
    ></app-filter-button>
  </div>
  <!-- NAME STEP -->
  <app-input-search
    *ngIf="current_step !== 'EMAIL'"
    placeholder="Nom prénom"
    searchClasses="w-full mx-auto"
    label_name="name_id"
    name="name_id"
    (Changed)="onSearchTextChange($event)"
    value="USERNAME"
  ></app-input-search>
  <!-- SEARCH RESULTS USERNAME -->
  <div
    [ngClass]="
      current_step !== 'EMAIL' && array_users_filtered.length
        ? 'bg-ligth-gray rounded-xl p-3'
        : 'hidden'
    "
    *ngIf="!firstname && !lastname"
  >
    <app-input-txt
      *ngFor="let user of array_users_filtered"
      [label_text]="
        user.user_interested_firstname + ' ' + user.user_interested_lastname
      "
      labelClasses="text-sm txt-bold-gray"
      (IsClicked)="userSelected(user)"
    ></app-input-txt>
  </div>
  <div *ngIf="current_step !== 'EMAIL' && firstname && lastname">
    <app-tertiary-card
      [icon_visibility]="true"
      [title]="firstname"
      [subtitle]="lastname"
      cardClasses="bg-white shadow-lg"
      titleClasses="text-center txt-bold-gray font-extrabold mt-3"
      subtitleClasses="text-center txt-bold-gray font-extralight"
      imgSrc="assets/icons/svg/logo-1.svg"
      imgClasses="h-16 mx-auto"
      [img_visibility]="guest_confirmed"
      imgPath="assets/icons/svg/confirm.svg"
    ></app-tertiary-card>
    <p
      class="txt-light-blue text-xs underline cursor-pointer text-center mt-3"
      (click)="changeGuest()"
    >
      Changer d'invité
    </p>
    <p class="text-sm txt-bold-gray mt-6 text-center">
      Vous souhaitez inviter {{ firstname }} {{ lastname }} à votre prochain
      rendez-vous. Valider pour confirmer votre choix.
    </p>
    <app-primary-button
      (IsClicked)="confirmAppointmentGuest()"
      text="Valider"
      buttonClasses="bg-light-blue border-secondary-blue text-white border items-center mx-auto mt-6 w-max py-1"
      paragraphClasses="w-full mx-auto"
      [disabled]="false"
      [icon_visibility]="false"
    ></app-primary-button>
  </div>

  <!-- ADD AN EXTERNAL SPEAKER -->
  <app-text
    [textClasses]="
      current_step !== 'NAME' ? 'txt-bold-gray font-bold mt-16 mb-6' : 'hidden'
    "
    text="Saisissez votre adresse mail"
  ></app-text>
  <div [ngClass]="current_step !== 'NAME' ? '' : 'hidden'" class="mx-0 mt-4">
    <app-input
      [icon_visibility]="false"
      [readonly]="false"
      placeholder="Entrer une adresse mail"
      [text]="external_speaker_email"
      [model]="external_speaker_email"
      type="text"
      label_name="email"
      name="email"
      (Changed)="addAnExternalSpeaker($event)"
      value="EMAIL"
      maxlength="50"
    ></app-input>
  </div>
  <!-- IF EMAIL IS CORRECT HIDDEN:FALSE -->
  <div
    *ngIf="current_step !== 'NAME' && external_speaker_email_valid"
    class="mt-16"
  >
    <app-input-txt
      [label_text]="external_speaker_email"
      labelClasses="text-sm txt-bold-gray"
      blockClasses="bg-ligth-gray p-3 w-full mx-auto rounded-xl"
      [img_visibility]="external_speaker_confirmed"
      imgPath="assets/icons/svg/confirm.svg"
    ></app-input-txt>
    <div class="flex justify-end gap-3 mt-3 items-center">
      <p
        class="txt-light-blue text-xs underline cursor-pointer"
        (click)="changeExternalSpeaker()"
      >
        Changer d'invité
      </p>
      <app-primary-button
        *ngIf="current_step !== 'NAME' && external_speaker_email"
        (IsClicked)="confirmExternalSpeaker()"
        text="Valider"
        buttonClasses="bg-light-blue border-secondary-blue text-white border items-center"
        paragraphClasses="w-full mx-auto"
        [disabled]="false"
        [icon_visibility]="false"
      ></app-primary-button>
    </div>
    <p
      *ngIf="current_step !== 'NAME' && external_speaker_email"
      class="text-sm txt-bold-gray mt-6 text-center"
    >
      Vous souhaitez inviter {{ firstname }} {{ lastname }} à votre prochain
      rendez-vous. Valider pour confirmer votre choix.
    </p>
  </div>
</div>
