import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss'],
})
export class PrimaryButtonComponent implements OnInit {
  @Input() text = 'Primary button';
  @Input() buttonClasses = '';
  @Input() paragraphClasses = '';
  @Input() disabled = true;
  @Input() firstImgSrc = 'assets/icons/svg/arrow-right-blue.svg';
  @Input() secondImgSrc = '';
  @Input() icon_visibility = true;
  @Input() textPosition = 'left';
  @Output() IsClicked = new EventEmitter<string>();
  @Output() Clicked = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  onClick($event: any): void {
    this.IsClicked.emit($event);
  }
}
