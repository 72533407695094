export class OfferModel {
  ID: any;
  User_id: any;
  Property_id: any;
  File: any;

  public mapToModel(data: any): OfferModel {
    const offerModel = new OfferModel();
    offerModel.ID = data.id;
    offerModel.User_id = data.user_id;
    offerModel.Property_id = data.property_id;
    offerModel.File = data.file;

    return offerModel;
  }
}
