<div [@fade] class="h-screen bg-white overflow-auto">
  <div class="border-b border-solid pb-2">
    <div class="mx-6">
      <app-secondary-header
        (IsClicked)="back('/my-account')"
        headerClasses="pt-4 flex justify-between items-cente mb-2"
      ></app-secondary-header>
      <div class="mx-auto text-center">
        <p class="font-bold txt-bold-gray">Mes informations</p>
      </div>
    </div>
  </div>
  <div *ngIf="!user_id" class="flex justify-center mx-auto" id="waiting_data">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
  <div *ngIf="user_id">
    <!-- NAV BAR -->
    <div class="flex justify-between rounded-2xl bg-ligth-gray mx-6 my-4">
      <app-primary-button
        (IsClicked)="switchCurrentState('PERSONAL_INFORMATIONS')"
        text="Personnelles"
        [buttonClasses]="
          current_state == 'PERSONAL_INFORMATIONS' ||
          current_state == 'UPDATE_EMAIL' ||
          current_state == 'UPDATE_PASSWORD'
            ? 'bg-light-blue text-white items-center text-sm'
            : 'bg-ligth-gray txt-percian-gray items-center text-sm'
        "
        [icon_visibility]="false"
        [disabled]="false"
      ></app-primary-button>
      <div
        *ngIf="
          userModel.User_type === 'PROFESSIONNEL' &&
          userModel.Status === 'Administrateur'
        "
      >
        <app-primary-button
          (IsClicked)="switchCurrentState('PROFESIONAL_INFORMATIONS')"
          text="Professionnelles"
          [buttonClasses]="
            current_state == 'PROFESIONAL_INFORMATIONS'
              ? 'bg-light-blue text-white items-center text-sm'
              : 'bg-ligth-gray txt-percian-gray items-center text-sm'
          "
          [icon_visibility]="false"
          [disabled]="false"
        ></app-primary-button>
      </div>
      <app-primary-button
        *ngIf="user_type === 'PROFESSIONNEL'"
        (IsClicked)="switchCurrentState('STATS')"
        text="Statistiques"
        [buttonClasses]="
          current_state == 'STATS'
            ? 'bg-light-blue text-white items-center text-sm'
            : 'bg-ligth-gray txt-percian-gray items-center text-sm'
        "
        [icon_visibility]="false"
        [disabled]="false"
      ></app-primary-button>
      <app-primary-button
        *ngIf="user_type === 'PARTICULIER'"
        (IsClicked)="switchCurrentState('CANDIDATE_FILE')"
        text="Dossier de candidature"
        [buttonClasses]="
          current_state == 'CANDIDATE_FILE'
            ? 'bg-light-blue text-white items-center text-sm'
            : 'bg-ligth-gray txt-percian-gray items-center text-sm'
        "
        [icon_visibility]="false"
        [disabled]="false"
      ></app-primary-button>
    </div>
    <!-- FORM -->
    <form [formGroup]="myForm">
      <!-- PERSONAL INFORMATIONS -->
      <div [@fade] *ngIf="current_state === 'PERSONAL_INFORMATIONS'">
        <!-- FIRSTNAME -->
        <div class="mx-6 mt-12">
          <app-input
            [icon_visibility]="true"
            readonly="readonly"
            [formFields]="myForm.controls.formFields"
            label_text="Prénom"
            labelClasses="uppercase text-xs font-extrabold"
            [text]="userModel.Firstname"
            model="{{ userModel.Firstname }}"
            type="text"
            label_name="firstname"
            name="firstname"
            (Changed)="onChange($event)"
            value="FIRSTNAME"
            maxlength="50"
          ></app-input>
        </div>
        <!-- LASTNAME -->
        <div class="mx-6 mt-4">
          <app-input
            [icon_visibility]="true"
            readonly="readonly"
            [formFields]="myForm.controls.formFields"
            label_text="Nom"
            labelClasses="uppercase text-xs font-extrabold"
            [text]="userModel.Lastname"
            model="{{ userModel.Lastname }}"
            type="text"
            label_name="lastname"
            name="lastname"
            (Changed)="onChange($event)"
            value="LASTNAME"
            maxlength="50"
          ></app-input>
        </div>
        <!-- PHONE_NUMBER -->
        <div class="mx-6 mt-4">
          <app-input
            [icon_visibility]="true"
            readonly="readonly"
            [formFields]="myForm.controls.formFields"
            label_text="Numéro de mobile"
            labelClasses="uppercase text-xs font-extrabold"
            [text]="userModel.Phone_number"
            model="{{ userModel.Phone_number }}"
            type="text"
            label_name="phone_number"
            name="phone_number"
            (Changed)="onChange($event)"
            value="PHONE_NUMBER"
            maxlength="13"
          ></app-input>
        </div>
        <!-- EMAIL -->
        <div class="mx-6 mt-4">
          <app-input
            [icon_visibility]="true"
            readonly="readonly"
            [formFields]="myForm.controls.formFields"
            label_text="Adresse email"
            labelClasses="uppercase text-xs font-extrabold"
            [text]="userModel.Email"
            model="{{ userModel.Email }}"
            type="text"
            label_name="email"
            name="email"
            (Changed)="onChange($event)"
            (IsClicked)="toggleReadOnly($event)"
            value="EMAIL"
            maxlength="50"
          ></app-input>
        </div>
        <!-- PASSWORD -->
        <div class="mx-6 mt-4">
          <app-input
            [icon_visibility]="true"
            readonly="readonly"
            [formFields]="myForm.controls.formFields"
            label_text="Mot de passe"
            labelClasses="uppercase text-xs font-extrabold"
            [text]="userModel.Password"
            model="1235678"
            type="password"
            label_name="password"
            name="password"
            (Changed)="onChange($event)"
            (IsClicked)="toggleReadOnly($event)"
            value="PASSWORD"
            maxlength="20"
          ></app-input>
        </div>
      </div>
      <!-- UPDATE EMAIL-->
      <div [@fade] *ngIf="current_state === 'UPDATE_EMAIL'">
        <app-title
          titleClasses="txt-bold-gray text-center w-4/6 mx-auto mb-8"
          text="Modifier votre adresse email"
        ></app-title>
        <!-- OLD EMAIL -->
        <div class="mx-6 mt-12">
          <app-input
            readonly="readonly"
            [formFields]="myForm.controls.formFields"
            label_text="Ancienne adresse email"
            labelClasses="uppercase text-xs font-extrabold"
            [text]="userModel.Email"
            model="{{ userModel.Email }}"
            type="text"
            label_name="email"
            name="email"
            (Changed)="onChange($event)"
            value="OLD_EMAIL"
          ></app-input>
        </div>
        <!-- NEW EMAIL -->
        <div class="mx-6 mt-4">
          <app-input
            [formFields]="myForm.controls.formFields"
            label_text="Nouvelle adresse email"
            labelClasses="uppercase text-xs font-extrabold"
            [text]="userModel.New_email"
            type="text"
            label_name="new_email"
            name="new_email"
            (Changed)="onChange($event)"
            value="NEW_EMAIL"
            maxlength="50"
          ></app-input>
        </div>
      </div>
      <!-- VALIDATE NEW EMAIL-->
      <div [@fade] *ngIf="current_state === 'VALIDATE_NEW_EMAIL'">
        <app-title
          titleClasses="txt-bold-gray text-left w-4/6 mx-4 mb-4"
          text="Entrer votre code de confirmation"
        ></app-title>
        <app-text
          textClasses="txt-percian-gray mx-6 mx-4 mb-4"
          text="Où se trouve mon code de confirmation ?
        Vous allez recevoir un mail contenant votre code sécurisé sur votre nouvelle adresse email. Saisissez le."
        ></app-text>
        <app-input-otp
          [otpFormFields]="myForm.controls.otpFormFields"
          (Changed)="onChange($event)"
          value="OTP_CODE"
        ></app-input-otp>
      </div>
      <!-- UPDATE PASSWORD-->
      <div [@fade] *ngIf="current_state === 'UPDATE_PASSWORD'">
        <app-title
          titleClasses="txt-bold-gray text-center w-4/6 mx-auto mb-8"
          text="Modifier votre mot de passe"
        ></app-title>
        <div class="mx-6 mt-12">
          <app-input
            [formFields]="myForm.controls.formFields"
            label_text="Ancien mot de passe"
            [text]="userModel.Old_password"
            type="password"
            label_name="old_password"
            name="old_password"
            (Changed)="onChange($event)"
            value="OLD_PASSWORD"
            maxlength="20"
          ></app-input>
        </div>
        <div class="mx-6 mt-4">
          <app-input
            [formFields]="myForm.controls.formFields"
            label_text="Nouveau mot de passe"
            [text]="userModel.New_password"
            type="password"
            label_name="new_password"
            name="new_password"
            (Changed)="onChange($event)"
            value="NEW_PASSWORD"
            maxlength="20"
          ></app-input>
        </div>
        <div class="mx-6 mt-4">
          <app-input
            [formFields]="myForm.controls.formFields"
            label_text="Confirmer nouveau mot de passe"
            [text]="userModel.Password_confirm"
            type="password"
            label_name="password_confirm"
            name="password_confirm"
            (Changed)="onChange($event)"
            value="PASSWORD_CONFIRM"
          ></app-input>
        </div>
      </div>
      <!-- PROFESIONAL INFORMATIONS -->
      <div [@fade] *ngIf="current_state === 'PROFESIONAL_INFORMATIONS'">
        <!-- AGENCY_NAME -->
        <div class="mx-6 mt-12">
          <app-input
            [icon_visibility]="true"
            readonly="readonly"
            [formFields]="myForm.controls.formFields"
            label_text="Nom de l'agence"
            labelClasses="uppercase text-xs font-extrabold"
            [text]="userModel.Agency_name"
            model="{{ userModel.Agency_name }}"
            type="text"
            label_name="agency_name"
            name="agency_name"
            (Changed)="onChange($event)"
            value="AGENCY_NAME"
            maxlength="50"
          ></app-input>
        </div>
        <!-- STATUS => SELECT -->
        <div class="mx-6 mt-4">
          <app-select
            selectClasses="w-full items-center border-b-2"
            [formFields]="myForm.controls.selectFormFields"
            form_control_name="user_status"
            label_text="statut"
            labelClasses="uppercase text-xs font-extrabold"
            model="{{ userModel.Status }}"
            (Changed)="onChange($event)"
            value="STATUS"
          >
          </app-select>
        </div>
        <!-- SIRET NUMBER -->
        <div class="mx-6 mt-4">
          <app-input
            [icon_visibility]="true"
            readonly="readonly"
            [formFields]="myForm.controls.formFields"
            label_text="SIRET"
            labelClasses="uppercase text-xs font-extrabold"
            [text]="userModel.Siret"
            model="{{ userModel.Siret }}"
            type="text"
            label_name="siret"
            name="siret"
            (Changed)="onChange($event)"
            value="SIRET"
            maxlength="17"
          ></app-input>
        </div>
        <!-- POSTAL_ADDRESS -->
        <div class="mx-6 mt-4">
          <app-input
            [icon_visibility]="true"
            readonly="readonly"
            labelClasses="uppercase text-xs font-extrabold"
            [formFields]="myForm.controls.formFields"
            label_text="Adresse postal"
            [text]="userModel.Address_location"
            model="{{ userModel.Address_location }}"
            type="text"
            label_name="address_location"
            name="address_location"
            (Changed)="onChange($event)"
            value="ADDRESS_LOCATION"
            maxlength="50"
          ></app-input>
        </div>
        <!-- POSTAL_CODE -->
        <div class="mx-6 mt-4">
          <app-input
            [icon_visibility]="true"
            readonly="readonly"
            labelClasses="uppercase text-xs font-extrabold"
            [formFields]="myForm.controls.formFields"
            label_text="Code postal"
            [text]="userModel.Postal_code"
            model="{{ userModel.Postal_code }}"
            type="text"
            label_name="postal_code"
            name="postal_code"
            (Changed)="onChange($event)"
            value="POSTAL_CODE"
            maxlength="5"
          ></app-input>
        </div>
        <!-- CITY -->
        <div class="mx-6 mt-4">
          <app-input
            [icon_visibility]="true"
            readonly="readonly"
            labelClasses="uppercase text-xs font-extrabold"
            [formFields]="myForm.controls.formFields"
            label_text="Ville"
            [text]="userModel.City"
            model="{{ userModel.City }}"
            type="text"
            label_name="city"
            name="city"
            (Changed)="onChange($event)"
            value="CITY"
            maxlength="50"
          ></app-input>
        </div>
      </div>
      <!-- STATS -->
      <div [@fade] *ngIf="current_state === 'STATS'">
        <!-- STATS CARDS -->
        <div class="flex justify-end mx-6">
          <app-select-date
            [formFields]="myForm.controls.selectDateFormFields"
            form_control_name_month="month"
            form_control_name_year="year"
            selectClasses="items-center txt-bold-gray font-bold appearance-none bg-transparent"
            yearClasses="items-center text-xs txt-percian-gray bg-transparent"
            (Changed)="onChange($event)"
            value="DATE_SORT"
          ></app-select-date>
        </div>
        <div class="mx-6 mt-4">
          <select
            formControlName="sorting_type"
            [(ngModel)]="current_sorting"
            class="
              txt-percian-gray
              uppercase
              text-xs
              font-bold
              mx-auto
              outline-none
              bg-transparent
            "
            (ngModelChange)="onChange(current_sorting)"
          >
            <option
              *ngFor="let sort of sortingTypeArray"
              [value]="sort.name"
              class="font-bold"
            >
              {{ sort.name }}
            </option>
          </select>
        </div>
        <div class="mx-6 mt-4 mb-8 grid gap-3 grid-cols-2">
          <app-tertiary-card
            title="11"
            titleClasses="text-4xl text-center text-white font-bold uppercase"
            subtitle="Ventes réussies"
            cardClasses="bg-card-red-gradient"
          ></app-tertiary-card>
          <app-tertiary-card
            title="8"
            titleClasses="text-4xl text-center text-white font-bold uppercase"
            subtitle="Locations réussies"
            cardClasses="bg-card-gray-gradient"
          ></app-tertiary-card>
        </div>
        <app-text
          textClasses="txt-percian-gray uppercase text-xs font-semibold mx-6 mx-4"
          text="Vue d'ensemble"
        ></app-text>
        <app-text
          textClasses="txt-bold-gray mx-6 text-xs mx-4 mb-2"
          text="Park immobilier"
        ></app-text>
        <div class="mx-6 grid gap-3 grid-cols-2">
          <app-tertiary-card
            title="30"
            titleClasses="text-4xl text-center text-white font-bold uppercase"
            subtitle="Biens en vente"
            cardClasses="bg-card-purple-gradient"
          ></app-tertiary-card>
          <app-tertiary-card
            title="12"
            titleClasses="text-4xl text-center text-white font-bold uppercase"
            subtitle="Biens en location"
            cardClasses="bg-card-green-gradient"
          ></app-tertiary-card>
        </div>
        <div class="flex flex-col justify-center text-center mx-auto my-4">
          <app-text
            textClasses="txt-percian-gray uppercase text-xs font-bold w-full mx-auto"
            text="Dernière mise à jour"
          ></app-text>
          <app-text
            textClasses="txt-bold-gray uppercase text-xxs w-full mx-auto"
            text="25 octobre 2021 à 08 : 02"
          ></app-text>
        </div>
      </div>
      <div class="flex flex-col mx-4 justify-center">
        <pre
          style="
            height: fit-content;
            max-height: 5rem !important;
            overflow-y: auto;
          "
          id="result"
        ></pre>
      </div>

      <!-- SUBMIT BUTTON -->
      <div
        *ngIf="current_state !== 'STATS' && current_state !== 'CANDIDATE_FILE'"
        class="mx-6 pb-6 flex justify-center"
      >
        <app-primary-button
          (IsClicked)="editPersonalInformations()"
          text="Enregistrer"
          [firstImgSrc]="
            !disabled
              ? 'assets/icons/svg/arrow-right.svg'
              : 'assets/icons/svg/arrow-rigth-disabled.svg'
          "
          [secondImgSrc]="
            !disabled
              ? 'assets/icons/svg/arrow-right.svg'
              : 'assets/icons/svg/arrow-rigth-disabled.svg'
          "
          [buttonClasses]="
            !disabled
              ? 'bg-light-blue text-white items-center w-40 mt-3'
              : 'bg-gray-100 txt-bold-gray items-center w-40 mt-3'
          "
          [disabled]="false"
        ></app-primary-button>
      </div>
    </form>
    <div *ngIf="current_state === 'CANDIDATE_FILE'" class="mx-6">
      <app-file-page [step]="current_state"></app-file-page>
    </div>
  </div>
</div>
