<div [@fadeSlideInOut] class="w-full mx-auto bg-white relative">
  <!-- Header -->
  <div *ngIf="header_visible" class="border-b border-solid pb-2">
    <div class="mx-6">
      <app-secondary-header
        (IsClicked)="back()"
        headerClasses=" pt-4 flex justify-between items-cente mb-2"
      ></app-secondary-header>
      <div class="mx-auto text-center">
        <p class="font-bold text-xl txt-bold-gray">Filtres</p>
      </div>
    </div>
  </div>
  <!-- Property_type choice for search -->
  <div *ngIf="current_step === 'DEFAULT'" class="mx-6">
    <app-text
      textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
      text="Quel type de bien recherchez-vous ? *"
    ></app-text>
    <div class="grid grid-cols-2 gap-3">
      <app-secondary-card
        *ngFor="let property of propertyTypeArray"
        [imgPath]="property.path_img"
        imgClasses="h-16 mx-auto"
        imgBlockClasses="bg-extra-light-blue rounded-xl mx-auto p-3"
        cardClasses="bg-extra-light-blue rounded-xl text-center mx-auto py-3"
        textClasses="txt-bold-gray font-semibold text-sm mb-2 w-full mx-auto"
        [text_button]="property.item_value"
        buttonClasses="rounded-full txt-bold-gray bg-white tracking-tighter p-1 text-sm text-center mx-auto w-4/5 mt-2"
        (IsClicked)="propertyByType(property)"
      ></app-secondary-card>
    </div>
  </div>
  <div [@fadeSlideInOut] *ngIf="current_step === 'FILTERS'" class="relative">
    <div
      class="flex justify-between mx-6 bg-white py-3 items-center sticky top-0"
    >
      <!-- Category return -->
      <app-text
        (IsClicked)="switchStep()"
        textClasses="txt-bold-blue text-xs font-bold cursor-pointer"
        text="Retour aux catégories"
      ></app-text>
      <!-- Result button -->
      <app-primary-button
        *ngIf="attributes_sort_property"
        (IsClicked)="getFilteredProperty()"
        [text]="
          attributes_length === 2
            ? 'Voir résultats ' + '(' + filtered_array.length + ')'
            : 'Voir résultats ' + '(' + second_filtered_array.length + ')'
        "
        buttonClasses="bg-bold-blue text-white text-xs items-center py-2"
        [icon_visibility]="false"
        [disabled]="false"
      ></app-primary-button>
    </div>

    <!-- FILTERS OTHERS BLOCKS -->
    <div
      *ngIf="
        property_filter.House ||
        property_filter.Appartment ||
        property_filter.Office ||
        property_filter.Land
      "
    >
      <!-- Property Budget -->
      <div class="mx-6 mt-6">
        <app-text
          textClasses="txt-bold-gray text-sm font-bold text-left w-4/6 mb-3"
          text="POUR UN BUDGET DE..."
        ></app-text>
        <div class="flex justify-center gap-3">
          <app-input
            label_text=""
            type="Number"
            label_name="min_budget"
            [model]="property_filter.Min_budget"
            name="min_budget"
            placeholder="Budget min"
            (Changed)="onChange($event)"
            value="MIN_BUDGET"
            additionnalClasses="text-sm"
          ></app-input>
          <app-input
            label_text=""
            type="Number"
            label_name="max_budget"
            [model]="property_filter.Max_budget"
            name="max_budget"
            placeholder="Budget max"
            (Changed)="onChange($event)"
            value="MAX_BUDGET"
            additionnalClasses="text-sm"
          ></app-input>
        </div>
      </div>
      <!-- Property characteristics -->
      <div
        *ngIf="
          property_filter.House ||
          property_filter.Appartment ||
          property_filter.Office
        "
        class="mx-6 mt-6"
      >
        <app-text
          textClasses="txt-bold-gray text-sm font-bold text-left w-4/6 mb-3 uppercase"
          text="caractéristiques du bien"
        ></app-text>
        <div class="flex flex-wrap justify-center gap-3">
          <!-- Houses characteristics -->
          <div
            *ngIf="property_filter.House === 'MAISON_VILLA'"
            class="flex flex-wrap justify-start gap-3"
          >
            <app-items
              type="text"
              model="Maison plain pieds"
              label_name="single_story_house"
              name="single_story_house"
              (Changed)="onChange($event)"
              value="MAISON_PLAIN_PIEDS"
              [isSelected]="property_filter.Single_story_house"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="MAISON_PLAIN_PIEDS"
            ></app-items>
            <app-items
              type="text"
              model="Maison à étage"
              label_name="storey_house"
              name="storey_house"
              (Changed)="onChange($event)"
              value="MAISON_A_ETAGE"
              [isSelected]="property_filter.Storey_house"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="MAISON_A_ETAGE"
            ></app-items>
            <app-items
              type="text"
              model="Maison avec comble"
              label_name="house_with_roof"
              name="house_with_roof"
              (Changed)="onChange($event)"
              value="MAISON_AVEC_COMBLE"
              [isSelected]="property_filter.House_with_roof"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="MAISON_AVEC_COMBLE"
            ></app-items>
            <app-items
              type="text"
              model="Maison avec sous-sol"
              label_name="house_with_basement"
              name="house_with_basement"
              (Changed)="onChange($event)"
              value="MAISON_AVEC_SOUS_SOL"
              [isSelected]="property_filter.House_with_basement"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="MAISON_AVEC_SOUS_SOL"
            ></app-items>
          </div>
          <!-- Appartments characteristics -->
          <div
            *ngIf="property_filter.Appartment === 'APPARTEMENT'"
            class="flex flex-wrap justify-start gap-3"
          >
            <app-items
              type="text"
              model="Duplex"
              label_name="duplex"
              name="duplex"
              (Changed)="onChange($event)"
              value="DUPLEX"
              [isSelected]="property_filter.Duplex"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="DUPLEX"
            ></app-items>
            <app-items
              type="text"
              model="Triplex"
              label_name="triplex"
              name="triplex"
              (Changed)="onChange($event)"
              value="TRIPLEX"
              [isSelected]="property_filter.Triplex"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="TRIPLEX"
            ></app-items>
            <app-items
              type="text"
              model="Rooftop"
              label_name="rooftop"
              name="rooftop"
              (Changed)="onChange($event)"
              value="ROOFTOP"
              [isSelected]="property_filter.Rooftop"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="ROOFTOP"
            ></app-items>
          </div>
          <!-- Offices characteristics -->
          <div
            *ngIf="property_filter.Office === 'BUREAUX'"
            class="flex flex-wrap justify-start gap-3"
          >
            <app-items
              type="text"
              model="Bureau en open space"
              label_name="open_space_office"
              name="open_space_office"
              (Changed)="onChange($event)"
              value="BUREAU_EN_OPEN_SPACE"
              [isSelected]="property_filter.Open_space_office"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="BUREAU_EN_OPEN_SPACE"
            ></app-items>
            <app-items
              type="text"
              model="Bureau appartement"
              label_name="office_appartment"
              name="office_appartment"
              (Changed)="onChange($event)"
              value="BUREAU_APPARTEMENT"
              [isSelected]="property_filter.Office_appartment"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="BUREAU_APPARTEMENT"
            ></app-items>
            <app-items
              type="text"
              model="Bureau en coworking"
              label_name="coworking_office"
              name="coworking_office"
              (Changed)="onChange($event)"
              value="BUREAU_EN_COWORKING"
              [isSelected]="property_filter.Coworking_office"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="BUREAU_EN_COWORKING"
            ></app-items>
          </div>
        </div>
      </div>
      <!-- Property Amenagement -->
      <div *ngIf="service_type_search === 'rent'" class="mx-6 mt-6">
        <app-text
          textClasses="txt-bold-gray text-sm font-bold text-left w-4/6 mb-3 uppercase"
          text="aménagement"
        ></app-text>
        <div class="flex flex-wrap justify-start gap-3">
          <app-items
            type="text"
            model="Meublé"
            label_name="furnished"
            name="furnished"
            (Changed)="onChange($event)"
            value="FURNISHED"
            [isSelected]="property_filter.Furnished"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
            item_id="FURNISHED"
          ></app-items>
          <app-items
            type="text"
            model="Non meublé"
            label_name="not_furnished"
            name="not_furnished"
            (Changed)="onChange($event)"
            value="NOT_FURNISHED"
            [isSelected]="property_filter.Not_furnished"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
            item_id="NOT_FURNISHED"
          ></app-items>
        </div>
      </div>
      <!-- Property State -->
      <div *ngIf="service_type_search === 'sale'" class="mx-6 mt-6">
        <app-text
          textClasses="txt-bold-gray text-sm font-bold text-left w-4/6 mb-3 uppercase"
          text="état du bien"
        ></app-text>
        <div class="flex justify-center flex-wrap gap-3">
          <!-- Houses OR Appartment OR Office State -->
          <div
            *ngIf="
              property_filter.House ||
              property_filter.Appartment ||
              property_filter.Office
            "
            class="flex flex-wrap justify-start gap-3"
          >
            <app-items
              type="text"
              model="Neuf"
              label_name="new_property"
              name="new_property"
              (Changed)="onChange($event)"
              value="BIEN_NEUF"
              [isSelected]="property_filter.New_property"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="BIEN_NEUF"
            ></app-items>
            <app-items
              type="text"
              model="Ancien"
              label_name="old_property"
              name="old_property"
              (Changed)="onChange($event)"
              value="BIEN_ANCIEN"
              [isSelected]="property_filter.Old_property"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="BIEN_ANCIEN"
            ></app-items>
            <app-items
              type="text"
              model="Sans travaux à prévoir"
              label_name="no_work_required"
              name="no_work_required"
              (Changed)="onChange($event)"
              value="SANS_TRAVAUX_A_PREVOIR"
              [isSelected]="property_filter.No_work_required"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="REFAIT_A_NEUF"
            ></app-items>
            <app-items
              type="text"
              model="Avec travaux à prévoir"
              label_name="with_work_required"
              name="with_work_required"
              (Changed)="onChange($event)"
              value="TRAVAUX_A_PREVOIR"
              [isSelected]="property_filter.With_work_required"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="AVEC_TRAVAUX"
            ></app-items>
          </div>
          <!-- Land State -->
          <div
            *ngIf="property_filter.Land"
            class="flex flex-wrap justify-center gap-3"
          >
            <app-items
              type="text"
              model="Constructible pour particulier"
              label_name="building_for_private"
              name="building_for_private"
              (Changed)="onChange($event)"
              value="TERRAIN_CONSTRUCTIBLE_PARTICULIER"
              [isSelected]="property_filter.Building_for_private"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="TERRAIN_CONSTRUCTIBLE_PARTICULIER"
            ></app-items>
            <app-items
              type="text"
              model="Constructible pour professionnel"
              label_name="building_for_professional"
              name="building_for_professional"
              (Changed)="onChange($event)"
              value="TERRAIN_CONSTRUCTIBLE_PROFESSIONNEL"
              [isSelected]="property_filter.Building_for_professional"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="TERRAIN_CONSTRUCTIBLE_PROFESSIONNELLE"
            ></app-items>
            <app-items
              type="text"
              model="Non viabilisé"
              label_name="unserviced"
              name="unserviced"
              (Changed)="onChange($event)"
              value="NON_VIABILISE"
              [isSelected]="property_filter.Unserviced"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="NON_VIABILISE"
            ></app-items>
            <app-items
              type="text"
              model="Viabilisé"
              label_name="serviced"
              name="serviced"
              (Changed)="onChange($event)"
              value="VIABILISE"
              [isSelected]="property_filter.Serviced"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="VIABILISE"
            ></app-items>
            <app-items
              type="text"
              model="Destiné à l'agriculture"
              label_name="intented_for_agriculture"
              name="intented_for_agriculture"
              (Changed)="onChange($event)"
              value="TERRAIN_AGRICOLE"
              [isSelected]="property_filter.Intented_for_agriculture"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
              item_id="TERRAIN_AGRICOLE"
            ></app-items>
          </div>
        </div>
      </div>
      <!-- Property environment -->
      <div class="mx-6 mt-6">
        <app-text
          textClasses="txt-bold-gray text-sm font-bold text-left w-4/6 mb-3 uppercase"
          text="environnement"
        ></app-text>
        <!-- Appartment and House environment characteristics -->
        <div
          *ngIf="property_filter.House || property_filter.Appartment"
          class="flex flex-wrap justify-start gap-3"
        >
          <app-items
            type="text"
            model="Sans vis-à-vis"
            label_name="not_overlooked"
            name="not_overlooked"
            (Changed)="onChange($event)"
            value="NOT_OVERLOOKED"
            [isSelected]="property_filter.Not_overlooked"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            *ngIf="property_filter.House === 'MAISON_VILLA'"
            type="text"
            model="Non - mitoyen"
            label_name="non_adjoining"
            name="non_adjoining"
            (Changed)="onChange($event)"
            value="NON_ADJOINING"
            [isSelected]="property_filter.Non_adjoining"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            type="text"
            model="Aperçu mer"
            label_name="overview_sea"
            name="overview_sea"
            (Changed)="onChange($event)"
            value="OVERVIEW_SEA"
            [isSelected]="property_filter.Overview_sea"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            type="text"
            model="Vue mer"
            label_name="sea_view"
            name="sea_view"
            (Changed)="onChange($event)"
            value="SEA_VIEW"
            [isSelected]="property_filter.Sea_view"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            type="text"
            model="Vue panoramique"
            label_name="panoramic_view"
            name="panoramic_view"
            (Changed)="onChange($event)"
            value="PANORAMIC_VIEW"
            [isSelected]="property_filter.Panoramic_view"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
        </div>
        <!-- Office views characteristics -->
        <div
          *ngIf="property_filter.Office"
          class="flex flex-wrap justify-start gap-3"
        >
          <app-items
            type="text"
            model="Zone industrielle"
            label_name="industrial_estate"
            name="industrial_estate"
            (Changed)="onChange($event)"
            value="INDUSTRIAL_ESTATE"
            [isSelected]="property_filter.Industrial_estate"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            type="text"
            model="Quartier d'affaire"
            label_name="business_district"
            name="business_district"
            (Changed)="onChange($event)"
            value="BUSINESS_DISTRICT"
            [isSelected]="property_filter.Business_district"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            type="text"
            model="Zone franche ZFU"
            label_name="zfu"
            name="zfu"
            (Changed)="onChange($event)"
            value="ZFU"
            [isSelected]="property_filter.Zfu"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
        </div>
      </div>
      <!-- Property Distance Localisation -->
      <!-- <div class="mx-6 mt-6">
        <app-text
          textClasses="txt-bold-gray text-sm font-bold text-left w-4/6 mb-3"
          text="LOCALISATION"
        ></app-text>
        <div class="flex flex-col mb-3">
          <div class="flex justify-between items-start">
            <app-checkbox
              text_content="Distance par rapport à un lieu donnée"
              contentClasses="w-full txt-bold-gray font-bold"
              (Changed)="onChange($event)"
              value="PLACE_LOCATION"
            ></app-checkbox>
            <select
              [disabled]="!property_filter.Location ? true : false"
              [(ngModel)]="current_sorting"
              class="txt-bold-gray text-xs outline-none"
              (change)="onChange(current_sorting)"
            >
              <option
                *ngFor="let sort of sortingTypeArray"
                [value]="sort.name"
                class="font-bold"
              >
                {{ sort.name }}
              </option>
            </select>
          </div>
          <app-input
            label_text=""
            type="text"
            label_name="place_location"
            [model]="property_filter.Place_location"
            name="place_location"
            placeholder="Adresse"
            (Changed)="onChange($event)"
            value="PLACE_LOCATION"
            additionnalClasses="text-sm mt-4"
            [disabled]="!property_filter.Location ? true : false"
          ></app-input>
        </div>
      </div> -->
      <!-- Property Configuration -->
      <div class="mx-6 mt-6">
        <app-text
          textClasses="txt-bold-gray text-sm font-bold text-left w-4/6 mb-3"
          text="CONFIGURATION DU BIEN"
        ></app-text>
        <div class="flex flex-col mb-3 gap-y-3">
          <!-- Area min max -->
          <div class="grid grid-cols-4 gap-3 items-center">
            <app-text
              class="col-start-1 col-span-2"
              textClasses="txt-bold-gray text-sm col-start-1 col-span-2"
              text="Surface habitable"
            ></app-text>
            <app-input
              label_text=""
              type="Number"
              label_name="min_area"
              [model]="property_filter.Min_area"
              name="min_area"
              placeholder="min"
              (Changed)="onChange($event)"
              value="MIN_AREA"
              additionnalClasses="text-sm col-start-3 col-span-1"
            ></app-input>
            <app-input
              label_text=""
              type="Number"
              label_name="max_area"
              [model]="property_filter.Max_area"
              name="max_area"
              placeholder="max"
              (Changed)="onChange($event)"
              value="MAX_AREA"
              additionnalClasses="text-sm col-start-4 col-span-1"
            ></app-input>
          </div>
          <!-- Land area min max -->
          <div
            *ngIf="
              property_filter.Land === 'TERRAIN' ||
              property_filter.House === 'MAISON_VILLA'
            "
            class="grid grid-cols-4 gap-3 items-center"
          >
            <app-text
              class="col-start-1 col-span-2"
              textClasses="txt-bold-gray text-sm col-start-1 col-span-2"
              text="Surface du terrain"
            ></app-text>
            <app-input
              label_text=""
              type="Number"
              label_name="min_land_area"
              [model]="property_filter.Min_land_area"
              name="min_land_area"
              placeholder="min"
              (Changed)="onChange($event)"
              value="MIN_LAND_AREA"
              additionnalClasses="text-sm col-start-3 col-span-1"
            ></app-input>
            <app-input
              label_text=""
              type="Number"
              label_name="max_land_area"
              [model]="property_filter.Max_land_area"
              name="max_land_area"
              placeholder="max"
              (Changed)="onChange($event)"
              value="MAX_LAND_AREA"
              additionnalClasses="text-sm col-start-4 col-span-1"
            ></app-input>
          </div>
          <!-- Pieces number min max -->
          <div class="grid grid-cols-4 gap-3 items-center">
            <app-text
              class="col-start-1 col-span-2"
              textClasses="txt-bold-gray text-sm col-start-1 col-span-2"
              text="Nombre de pièces"
            ></app-text>
            <app-input
              label_text=""
              type="Number"
              label_name="min_pieces_number"
              [model]="property_filter.Min_pieces_number"
              name="min_pieces_number"
              placeholder="min"
              (Changed)="onChange($event)"
              value="MIN_PIECES_NUMBER"
              additionnalClasses="text-sm col-start-3 col-span-1"
            ></app-input>
            <app-input
              label_text=""
              type="Number"
              label_name="max_pieces_number"
              [model]="property_filter.Max_pieces_number"
              name="max_pieces_number"
              placeholder="max"
              (Changed)="onChange($event)"
              value="MAX_PIECES_NUMBER"
              additionnalClasses="text-sm col-start-4 col-span-1"
            ></app-input>
          </div>
          <!-- Bedrooms number min max -->
          <div class="grid grid-cols-4 gap-3 items-center">
            <app-text
              class="col-start-1 col-span-2"
              textClasses="txt-bold-gray text-sm col-start-1 col-span-2"
              text="Nombre de chambres"
            ></app-text>
            <app-input
              label_text=""
              type="Number"
              label_name="min_bedrooms_number"
              [model]="property_filter.Min_bedrooms_number"
              name="min_bedrooms_number"
              placeholder="min"
              (Changed)="onChange($event)"
              value="MIN_BEDROOMS_NUMBER"
              additionnalClasses="text-sm col-start-3 col-span-1"
            ></app-input>
            <app-input
              label_text=""
              type="Number"
              label_name="max_bedrooms_number"
              [model]="property_filter.Max_bedrooms_number"
              name="max_bedrooms_number"
              placeholder="max"
              (Changed)="onChange($event)"
              value="MAX_BEDROOMS_NUMBER"
              additionnalClasses="text-sm col-start-4 col-span-1"
            ></app-input>
          </div>
          <!-- Bathrooms number min max-->
          <div class="grid grid-cols-4 gap-3 items-center">
            <app-text
              class="col-start-1 col-span-2"
              textClasses="txt-bold-gray text-sm col-start-1 col-span-2"
              text="Nombre de salles de bain"
            ></app-text>
            <app-input
              label_text=""
              type="Number"
              label_name="min_bathrooms_number"
              [model]="property_filter.Min_bathrooms_number"
              name="min_bathrooms_number"
              placeholder="min"
              (Changed)="onChange($event)"
              value="MIN_BATHROOMS_NUMBER"
              additionnalClasses="text-sm col-start-3 col-span-1"
            ></app-input>
            <app-input
              label_text=""
              type="Number"
              label_name="max_bathrooms_number"
              [model]="property_filter.Max_bathrooms_number"
              name="max_bathrooms_number"
              placeholder="max"
              (Changed)="onChange($event)"
              value="MAX_BATHROOMS_NUMBER"
              additionnalClasses="text-sm col-start-4 col-span-1"
            ></app-input>
          </div>
          <!-- Office configuration -->
          <div *ngIf="property_filter.Office === 'BUREAUX'">
            <!-- Individual offices number min max -->
            <div class="grid grid-cols-4 gap-3 items-center">
              <app-text
                class="col-start-1 col-span-2"
                textClasses="txt-bold-gray text-sm col-start-1 col-span-2"
                text="Nombre de bureaux individuel"
              ></app-text>
              <app-input
                label_text=""
                type="Number"
                label_name="min_individual_offices_number"
                [model]="property_filter.Min_individual_offices_number"
                name="min_individual_offices_number"
                placeholder="min"
                (Changed)="onChange($event)"
                value="MIN_INDIVIDUAL_OFFICES_NUMBER"
                additionnalClasses="text-sm col-start-3 col-span-1"
              ></app-input>
              <app-input
                label_text=""
                type="Number"
                label_name="max_individual_offices_number"
                [model]="property_filter.Max_individual_offices_number"
                name="max_individual_offices_number"
                placeholder="max"
                (Changed)="onChange($event)"
                value="MAX_INDIVIDUAL_OFFICES_NUMBER"
                additionnalClasses="text-sm col-start-4 col-span-1"
              ></app-input>
            </div>
            <!-- Seats number min max -->
            <div class="grid grid-cols-4 gap-3 items-center">
              <app-text
                class="col-start-1 col-span-2"
                textClasses="txt-bold-gray text-sm col-start-1 col-span-2"
                text="Nombre de places assises"
              ></app-text>
              <app-input
                label_text=""
                type="Number"
                label_name="min_seats_number"
                [model]="property_filter.Min_seats_number"
                name="min_seats_number"
                placeholder="min"
                (Changed)="onChange($event)"
                value="MIN_SEATS_NUMBER"
                additionnalClasses="text-sm col-start-3 col-span-1"
              ></app-input>
              <app-input
                label_text=""
                type="Number"
                label_name="max_seats_number"
                [model]="property_filter.Max_seats_number"
                name="max_seats_number"
                placeholder="max"
                (Changed)="onChange($event)"
                value="MAX_SEATS_NUMBER"
                additionnalClasses="text-sm col-start-4 col-span-1"
              ></app-input>
            </div>
            <!-- Meeting rooms number min max -->
            <div class="grid grid-cols-4 gap-3 items-center">
              <app-text
                class="col-start-1 col-span-2"
                textClasses="txt-bold-gray text-sm col-start-1 col-span-2"
                text="Nombre de salles de réunion"
              ></app-text>
              <app-input
                label_text=""
                type="Number"
                label_name="min_meeting_rooms_number"
                [model]="property_filter.Min_meeting_rooms_number"
                name="min_meeting_rooms_number"
                placeholder="min"
                (Changed)="onChange($event)"
                value="MIN_MEETING_ROOMS_NUMBER"
                additionnalClasses="text-sm col-start-3 col-span-1"
              ></app-input>
              <app-input
                label_text=""
                type="Number"
                label_name="max_meeting_rooms_number"
                [model]="property_filter.Max_meeting_rooms_number"
                name="max_meeting_rooms_number"
                placeholder="max"
                (Changed)="onChange($event)"
                value="MAX_MEETING_ROOMS_NUMBER"
                additionnalClasses="text-sm col-start-4 col-span-1"
              ></app-input>
            </div>
          </div>
        </div>
      </div>
      <!-- Property prestations-->
      <div class="mx-6 mt-6">
        <app-text
          textClasses="txt-bold-gray text-sm font-bold text-left w-4/6 mb-3"
          text="PRESTATIONS"
        ></app-text>
        <div class="flex flex-wrap justify-start gap-3">
          <app-items
            type="text"
            model="Toilettes séparées"
            label_name="separate_toilet"
            name="separate_toilet"
            (Changed)="onChange($event)"
            value="SEPARATE_TOILET"
            [isSelected]="property_filter.Separate_toilet"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            *ngIf="property_filter.House === 'MAISON_VILLA'"
            type="text"
            model="Piscine"
            label_name="swimming_pool"
            name="swimming_pool"
            (Changed)="onChange($event)"
            value="SWIMMING_POOL"
            [isSelected]="property_filter.Swimming_pool"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            type="text"
            model="Climatisation"
            label_name="air_conditioning"
            name="air_conditioning"
            (Changed)="onChange($event)"
            value="AIR_CONDITIONING"
            [isSelected]="property_filter.Air_conditioning"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            type="text"
            model="Fibre déployée"
            label_name="fibre"
            name="fibre"
            (Changed)="onChange($event)"
            value="FIBRE"
            [isSelected]="property_filter.Fibre"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            *ngIf="property_filter.House === 'MAISON_VILLA'"
            type="text"
            model="Recharge véhicule électrique"
            label_name="electric_vehicle_charging"
            name="electric_vehicle_charging"
            (Changed)="onChange($event)"
            value="ELECTRIC_VEHICLE_CHARGING"
            [isSelected]="property_filter.Electric_vehicule_charging"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            type="text"
            model="Accès handicapé"
            label_name="disabled_access"
            name="disabled_access"
            (Changed)="onChange($event)"
            value="DISABLED_ACCESS"
            [isSelected]="property_filter.Disabled_access"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            *ngIf="
              property_filter.Appartment === 'APPARTEMENT' ||
              property_filter.Office === 'BUREAUX'
            "
            type="text"
            model="Digicode"
            label_name="digital_lock"
            name="digital_lock"
            (Changed)="onChange($event)"
            value="DIGITAL_LOCK"
            [isSelected]="property_filter.Digital_lock"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            *ngIf="
              property_filter.Appartment === 'APPARTEMENT' ||
              property_filter.Office === 'BUREAUX'
            "
            type="text"
            model="Interphone"
            label_name="intercom"
            name="intercom"
            (Changed)="onChange($event)"
            value="INTERCOM"
            [isSelected]="property_filter.Intercom"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            *ngIf="property_filter.House === 'MAISON_VILLA'"
            type="text"
            model="Portail électrique"
            label_name="electric_gate"
            name="electric_gate"
            (Changed)="onChange($event)"
            value="ELECTRIC_GATE"
            [isSelected]="property_filter.Electric_gate"
            inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            *ngIf="property_filter.House === 'MAISON_VILLA'"
            type="text"
            model="Portail manuel"
            label_name="manual_gate"
            name="manual_gate"
            (Changed)="onChange($event)"
            value="MANUAL_GATE"
            [isSelected]="property_filter.Manual_gate"
            inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            *ngIf="
              property_filter.Appartment === 'APPARTEMENT' ||
              property_filter.Office === 'BUREAUX'
            "
            type="text"
            model="Ascenseur"
            label_name="elevator"
            name="elevator"
            (Changed)="onChange($event)"
            value="ELEVATOR"
            [isSelected]="property_filter.Elevator"
            inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            *ngIf="property_filter.Appartment === 'APPARTEMENT'"
            type="text"
            model="Conciergerie"
            label_name="caritaker"
            name="caritaker"
            (Changed)="onChange($event)"
            value="CARITAKER"
            [isSelected]="property_filter.Caritaker"
            inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            *ngIf="property_filter.Office === 'BUREAUX'"
            type="text"
            model="Cuisine équipée"
            label_name="equipped_kitchen"
            name="equipped_kitchen"
            (Changed)="onChange($event)"
            value="EQUIPPED_KITCHEN"
            [isSelected]="property_filter.Equipped_kitchen"
            inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            *ngIf="property_filter.Office === 'BUREAUX'"
            type="text"
            model="Cuisine aménagée"
            label_name="fitted_kitchen"
            name="fitted_kitchen"
            (Changed)="onChange($event)"
            value="FITTED_KITCHEN"
            [isSelected]="property_filter.Fitted_kitchen"
            inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            *ngIf="property_filter.Office === 'BUREAUX'"
            type="text"
            model="Espace cuisine"
            label_name="kitchen_area"
            name="kitchen_area"
            (Changed)="onChange($event)"
            value="KITCHEN_AREA"
            [isSelected]="property_filter.Kitchen_area"
            inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
        </div>
      </div>
      <!-- Property Kitchen Type -->
      <div class="mx-6 mt-6">
        <app-text
          textClasses="txt-bold-gray text-sm font-bold text-left w-4/6 mb-3"
          text="TYPE DE CUISINE"
        ></app-text>
        <div class="flex flex-wrap justify-start gap-3">
          <app-items
            type="text"
            model="Cuisine séparée"
            label_name="separate_kitchen"
            name="separate_kitchen"
            (Changed)="onChange($event)"
            value="SEPARATE_KITCHEN"
            [isSelected]="property_filter.Separate_kitchen"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            type="text"
            model="Cuisine ouverte"
            label_name="open_kitchen"
            name="open_kitchen"
            (Changed)="onChange($event)"
            value="OPEN_KITCHEN"
            [isSelected]="property_filter.Open_kitchen"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            type="text"
            model="Cuisine semi-ouverte"
            label_name="semi_open_kitchen"
            name="semi_open_kitchen"
            (Changed)="onChange($event)"
            value="SEMI_OPEN_KITCHEN"
            [isSelected]="property_filter.Semi_open_kitchen"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
          <app-items
            *ngIf="property_filter.House === 'MAISON_VILLA'"
            type="text"
            model="Cuisine extérieure"
            label_name="outdoor_kitchen"
            name="outdoor_kitchen"
            (Changed)="onChange($event)"
            value="OUTDOOR_KITCHEN"
            [isSelected]="property_filter.Outdoor_kitchen"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
          ></app-items>
        </div>
      </div>
      <!-- Property additional surfaces -->
      <div class="mx-6 mt-6">
        <div>
          <app-text
            textClasses="txt-bold-gray text-sm font-bold text-left w-4/6 uppercase mb-3"
            text="surface(s) annexe(s)"
          ></app-text>
          <div class="flex flex-wrap justify-start gap-3">
            <app-items
              type="text"
              model="Balcon"
              label_name="balcony"
              name="balcony"
              (Changed)="onChange($event)"
              value="BALCONY"
              [isSelected]="property_filter.Balcony"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              type="text"
              model="Terrasse"
              label_name="terrace"
              name="terrace"
              (Changed)="onChange($event)"
              value="TERRACE"
              [isSelected]="property_filter.Terrace"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              type="text"
              model="Jardin"
              label_name="garden"
              name="garden"
              (Changed)="onChange($event)"
              value="GARDEN"
              [isSelected]="property_filter.Garden"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              type="text"
              model="Parking privée"
              label_name="private_parking"
              name="private_parking"
              (Changed)="onChange($event)"
              value="PRIVATE_PARKING"
              [isSelected]="property_filter.Private_parking"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              *ngIf="property_filter.House === 'MAISON_VILLA'"
              type="text"
              model="Dépendance"
              label_name="outbuilding"
              name="outbuilding"
              (Changed)="onChange($event)"
              value="OUTBUILDING"
              [isSelected]="property_filter.Outbuilding"
              inputClasses="rounded-3xl text-center  text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center  text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              type="text"
              model="Garage"
              label_name="garage"
              name="garage"
              (Changed)="onChange($event)"
              value="GARAGE"
              [isSelected]="property_filter.Garage"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
            <app-items
              type="text"
              model="Box"
              label_name="lock_up_garage"
              name="lock_up_garage"
              (Changed)="onChange($event)"
              value="LOCK_UP_GARAGE"
              [isSelected]="property_filter.Lock_up_garage"
              inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
              inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
              readonly="readonly"
              txtClasses="w-full bg-transparent outline-none border-none text-center"
            ></app-items>
          </div>
        </div>
      </div>
      <!-- Property energetic class-->
      <div class="mx-6 mt-6">
        <app-text
          textClasses="txt-bold-gray text-sm font-bold text-left w-4/6 uppercase mb-3"
          text="classe énergétique"
        ></app-text>
        <div class="mx-auto flex flex-wrap justify-start gap-3">
          <input
            *ngFor="let energeticClass of energeticClassArray"
            type="text"
            class="
              rounded-3xl
              text-center text-xs
              border-2
              p-2
              outline-none
              cursor-pointer
              w-16
            "
            [ngClass]="
              property_filter.Energetic_class_A == energeticClass.item_value ||
              property_filter.Energetic_class_B == energeticClass.item_value ||
              property_filter.Energetic_class_C == energeticClass.item_value ||
              property_filter.Energetic_class_D == energeticClass.item_value ||
              property_filter.Energetic_class_E == energeticClass.item_value ||
              property_filter.Energetic_class_F == energeticClass.item_value ||
              property_filter.Energetic_class_G == energeticClass.item_value ||
              property_filter.Energetic_class_VIERGE ==
                energeticClass.item_value
                ? 'bg-light-blue text-white'
                : 'bg-ligth-gray txt-bold-gray'
            "
            [value]="energeticClass.item_value"
            (click)="onChange(energeticClass)"
            readonly
          />
        </div>
      </div>
      <!-- Property Ges -->
      <div class="mx-6 mt-6">
        <app-text
          textClasses="txt-bold-gray text-sm font-bold text-left w-4/6 uppercase mb-3"
          text="GES"
        ></app-text>
        <div class="mx-auto flex flex-wrap justify-start gap-3">
          <input
            *ngFor="let gas of gesArray"
            type="text"
            class="
              rounded-3xl
              text-center text-xs
              border-2
              p-2
              outline-none
              cursor-pointer
              w-16
            "
            [ngClass]="
              property_filter.Gas_emissions_A == gas.item_value ||
              property_filter.Gas_emissions_B == gas.item_value ||
              property_filter.Gas_emissions_C == gas.item_value ||
              property_filter.Gas_emissions_D == gas.item_value ||
              property_filter.Gas_emissions_E == gas.item_value ||
              property_filter.Gas_emissions_F == gas.item_value ||
              property_filter.Gas_emissions_G == gas.item_value ||
              property_filter.Gas_emissions_VIERGE == gas.item_value
                ? 'bg-light-blue text-white'
                : 'bg-ligth-gray txt-bold-gray'
            "
            [value]="gas.item_value"
            (click)="onChange(gas)"
            readonly
          />
        </div>
      </div>
      <!-- Investment type -->
      <div class="mx-6 mt-6">
        <app-text
          textClasses="txt-bold-gray text-sm font-bold text-left w-4/6 mb-3"
          text="TYPE D'INVESTISSEMENT"
        ></app-text>
        <div class="flex flex-wrap justify-start gap-3">
          <!-- Viagers -->
          <app-items
            type="text"
            model="Viagers"
            label_name="life_annuity"
            name="life_annuity"
            (Changed)="onChange($event)"
            value="LIFE_ANNUITY"
            [isSelected]="property_filter.Life_annuity"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
            item_id="LIFE_ANNUITY"
          ></app-items>
          <!-- Exclure les viagers -->
          <app-items
            type="text"
            model="Exclure les viagers"
            label_name="exclude_life_annuities"
            name="exclude_life_annuities"
            (Changed)="onChange($event)"
            value="EXCLUDE_LIFE_ANNUITIES"
            [isSelected]="property_filter.Exclude_life_annuities"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
            item_id="EXCLUDE_LIFE_ANNUITIES"
          ></app-items>
          <!-- Eligible loi pinel -->
          <app-items
            type="text"
            model="Eligible loi pinel"
            label_name="eligible_pinel_law"
            name="eligible_pinel_law"
            (Changed)="onChange($event)"
            value="ELIGIBLE_PINEL_LAW"
            [isSelected]="property_filter.Eligible_pinel_law"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
            item_id="ELIGIBLE_PINEL_LAW"
          ></app-items>
          <!-- Résidence sénior -->
          <app-items
            type="text"
            model="Résidence sénior"
            label_name="senior_residence"
            name="senior_residence"
            (Changed)="onChange($event)"
            value="SENIOR_RESIDENCE"
            [isSelected]="property_filter.Senior_residence"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
            item_id="SENIOR_RESIDENCE"
          ></app-items>
          <!-- Résidence étudiant -->
          <app-items
            type="text"
            model="Résidence étudiant"
            label_name="student_residence"
            name="student_residence"
            (Changed)="onChange($event)"
            value="STUDENT_RESIDENCE"
            [isSelected]="property_filter.Student_residence"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
            item_id="STUDENT_RESIDENCE"
          ></app-items>
          <!-- Exclure les résidences -->
          <app-items
            type="text"
            model="Exclure les résidences"
            label_name="exclude_residences"
            name="exclude_residences"
            (Changed)="onChange($event)"
            value="EXCLUDE_RESIDENCES"
            [isSelected]="property_filter.Exclude_residences"
            inputClasses="rounded-3xl text-center text-xs bg-ligth-gray txt-bold-gray p-2"
            inputSelectedClasses="rounded-3xl text-center text-xs bg-light-blue text-white p-2"
            readonly="readonly"
            txtClasses="w-full bg-transparent outline-none border-none text-center"
            item_id="EXCLUDE_RESIDENCES"
          ></app-items>
        </div>
      </div>
    </div>
  </div>
</div>
