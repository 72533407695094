import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-small-appointment-card',
  templateUrl: './small-appointment-card.component.html',
  styleUrls: ['./small-appointment-card.component.scss'],
})
export class SmallAppointmentCardComponent implements OnInit {
  @Input() appointment_type: string = 'Visite du bien avec un acheteur';
  @Input() appointment_reason: string = 'Motif du rendez-vous';
  @Input() appointment_date: string = 'Mercredi 28 janvier 2022';
  @Input() appointment_hour: string = '13:30';
  @Output() IsClicked = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  onClick($event) {
    this.IsClicked.emit($event);
  }
}
