<label for="{{ name }}" class="fileUpload relative" [ngClass]="inputLabelClasses">
  <input
    id="fileToUpload"
    type="file"
    class="upload"
    name="{{ name }}"
    multiple="{{ multiple }}"
    accept="{{ accept }}"
  />
  <span [ngClass]="inputFileClasses" class="text-sm txt-bold-gray inputTxt">{{
    text
  }}</span>
</label>
