import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() labelClasses = '';
  @Input() label_text = '';
  @Input() input_value = '';
  @Input() label_name = '';
  @Input() type = 'text';
  @Input() id = this.label_name;
  @Input() value = '';
  @Output() Changed = new EventEmitter<any>();
  @Output() IsClicked = new EventEmitter<any>();
  @Input() showErrors = true;
  @Input() model: any;
  public hasError = false;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() readonly = 'null';
  @Input() icon_visibility = false;
  @Input() icon = '';
  @Input() errorWidth = '';
  @Input() borderClasses = 'border-white';
  @Input() borderColor = 'input-border';
  @Input() additionnalClasses = '';
  @Input() textColor = 'txt-white_';
  @Input() maxlength = '';
  @Input() formFields: FormGroup = this.formBuilder.group({
    email: ['', Validators.required],
    new_email: ['', Validators.required],
    password: ['', Validators.required],
    password_confirm: ['', Validators.required],
    old_password: ['', Validators.required],
    new_password: ['', Validators.required],
    firstname: ['', Validators.required],
    lastname: ['', Validators.required],
    agency_name: ['', Validators.required],
    address_location: ['', Validators.required],
    postal_code: ['', Validators.required],
    city: ['', Validators.required],
    siret: ['', Validators.required],
    agency_scale_of_fees: [''], // Fichier barrème honoraire agence *Non requis*
    price: ['', Validators.required], // Estimation prix d'un bien
    superficy: ['', Validators.required], // Surface totale du bien
    amount_of_charges: ['', Validators.required], // Montant des charges en tant que location *Non requis*
    outdoor_area_1: ['', Validators.required],
    outdoor_area_2: ['', Validators.required],
    bathroom_number: ['', Validators.required],
    seating_places_number: ['', Validators.required],
    meeting_room_number: ['', Validators.required],
    individual_office_number: ['', Validators.required],
  });
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {}

  onChange($event: any): void {
    this.checkInput();
  }

  onClick($event: any, $event_2: any): void {
    const data = {
      label_name: this.label_name,
    };
    if ($event === 'email' || $event === 'password') {
      this.IsClicked.emit(data);
    } else {
      if ($event == this.label_name && $event_2 == 'readonly') {
        console.log('xxxxxxx');
        this.readonly = 'null';
        this.additionnalClasses = 'border-light-blue txt-light-blue';
      } else if ($event == this.label_name && $event_2 == 'null') {
        this.readonly = 'readonly';
        this.additionnalClasses = '';
      }
    }
  }

  checkInput(): void {
    if (this.value === 'EMAIL') {
      const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (!emailRegex.test(this.model) || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: this.model,
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'NEW_EMAIL') {
      const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (!emailRegex.test(this.model) || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: this.model,
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'FIRSTNAME') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'LASTNAME') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'PHONE') {
      const phoneRegex = /^(\+33|\+596)[1-9]([0-9]{2}){4}$/;
      if (!phoneRegex.test(this.model) || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'PASSWORD') {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
      if (!passwordRegex.test(this.model) || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'PASSWORD_CONFIRM') {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
      if (!passwordRegex.test(this.model) || this.model === '') {
        console.log('error');

        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        console.log('success');
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'OLD_PASSWORD') {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
      if (!passwordRegex.test(this.model) || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'NEW_PASSWORD') {
      console.log(
        this.formFields.controls['old_password'].value ===
          this.formFields.controls['new_password'].value
      );

      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
      if (
        !passwordRegex.test(this.model) ||
        this.model === '' ||
        this.formFields.controls['old_password'].value ===
          this.formFields.controls['new_password'].value
      ) {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'AGENCY_NAME') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'SIRET') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'AGENCY_FILE') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'ADDRESS_LOCATION') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'POSTAL_CODE') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'CITY') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'PHONE_NUMBER') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'PRICE') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'AMOUNT_OF_CHARGES') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'SUPERFICY') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'OUTDOOR_AREA_1') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'OUTDOOR_AREA_2') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'BEDROOM_NUMBER') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'BATHROOM_NUMBER') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'INDIVIDUAL_OFFICE_NUMBER') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'SEATING_PLACES_NUMBER') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MEETING_ROOM_NUMBER') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MIN_BUDGET') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MAX_BUDGET') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'PLACE_LOCATION') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MIN_AREA') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MAX_AREA') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MIN_LAND_AREA') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MAX_LAND_AREA') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MIN_PIECES_NUMBER') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MAX_PIECES_NUMBER') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MIN_BEDROOMS_NUMBER') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MAX_BEDROOMS_NUMBER') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MIN_BATHROOMS_NUMBER') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MAX_BATHROOMS_NUMBER') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MIN_INDIVIDUAL_OFFICES_NUMBER') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MAX_INDIVIDUAL_OFFICES_NUMBER') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MIN_SEATS_NUMBER') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MAX_SEATS_NUMBER') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MIN_MEETING_ROOMS_NUMBER') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MAX_MEETING_ROOMS_NUMBER') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'APPOINTMENT_REASON') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
  }
}
