import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}
  public setCurrentUser(data: any): void {
    const currentUser: string =
      typeof data === 'object' ? JSON.stringify(data) : data;
    localStorage.setItem('currentUser', currentUser);
  }

  public getCurrentUser(): any {
    return JSON.parse(localStorage.getItem('currentUser') || '{}');
  }

  public setFilterAttributes(data: any): void {
    const filterAttributes: string =
      typeof data === 'object' ? JSON.stringify(data) : data;
    localStorage.setItem('filterAttributes', filterAttributes);
  }

  public getFilterAttributes(): any {
    return JSON.parse(localStorage.getItem('filterAttributes') || '{}');
  }
  public removeFilterAttributes(): any {
    return localStorage.removeItem('filterAttributes');
  }

  public setUserType(user_type: string): void {
    localStorage.setItem('user_type', user_type);
  }

  public getUserType(): string {
    return localStorage.getItem('user_type') || '{}';
  }

  public getTokenId(email): string {
    return (
      localStorage.getItem(
        `CognitoIdentityServiceProvider.5q6rirkqv4li06p4m3vsomh6gs.${email}.idToken`
      ) || '{}'
    );
  }

  public setUserId(userId: string): void {
    localStorage.setItem('userID', userId);
  }

  public getUserId(): string {
    return localStorage.getItem('userID') || '{}';
  }

  public setUserEmail(email: string): void {
    localStorage.setItem('email', email);
  }

  public getUserEmail(): string {
    return localStorage.getItem('email') || '{}';
  }

  public setFirstname(firstname: string): void {
    localStorage.setItem('firstname', firstname);
  }

  public getFirstname(): string {
    return localStorage.getItem('firstname') || '{}';
  }

  public setLastname(lastname: string): void {
    localStorage.setItem('lastname', lastname);
  }

  public getLastname(): string {
    return localStorage.getItem('lastname') || '{}';
  }

  public setUserState(user_state: string): void {
    localStorage.setItem('user_state', user_state);
  }

  public getUserState(): string {
    return localStorage.getItem('user_state') || '{}';
  }

  public setAgencyName(agency_name: string): void {
    localStorage.setItem('agency_name', agency_name);
  }

  public getAgencyName(): string {
    return localStorage.getItem('agency_name') || '{}';
  }

  public clear(): any {
    return localStorage.clear();
  }
}
