<div [@fade] class="bg-white">
  <!-- Header -->
  <div class="border-b border-solid pb-2">
    <div class="mx-6">
      <app-secondary-header
        (IsClicked)="back()"
        headerClasses="pt-4 flex justify-between items-cente mb-2"
      ></app-secondary-header>
      <div class="mx-auto text-center">
        <p class="font-bold txt-bold-gray">{{ title }}</p>
      </div>
    </div>
  </div>
  <!-- LOADER SPINNER -->
  <div
    *ngIf="!array_property.length"
    class="mx-auto w-full my-6 flex justify-center"
    id="waiting_data"
  >
    <mat-spinner diameter="50"></mat-spinner>
  </div>
  <div *ngIf="array_property.length">
    <div *ngFor="let property of array_property">
      <!-- Buttons list -->
      <div
        *ngIf="current_step !== 'CANDIDATE_FILE'"
        class="ml-6 my-6 pr-6 flex gap-x-1 overflow-x-scroll"
        id="button_list"
      >
        <app-filter-button
          (IsClicked)="switchStep('MAIN')"
          text="Le bien"
          [textClasses]="
            current_step !== 'MAIN'
              ? 'txt-bold-gray text-xs text-center px-3 py-2'
              : 'text-white text-xs text-center px-3 py-2'
          "
          [buttonClasses]="
            current_step !== 'MAIN'
              ? 'rounded-3xl bg-ligth-gray w-16'
              : 'rounded-3xl w-16 bg-light-blue'
          "
        ></app-filter-button>
        <app-filter-button
          *ngIf="!my_property_id"
          (IsClicked)="switchStep('INTERESTED_LEVEL')"
          text="Niveau d'intérêt"
          [textClasses]="
            current_step !== 'INTERESTED_LEVEL'
              ? 'txt-bold-gray text-xs text-center px-3 py-2'
              : 'text-white text-xs text-center px-3 py-2'
          "
          [buttonClasses]="
            current_step !== 'INTERESTED_LEVEL'
              ? 'rounded-3xl bg-ligth-gray w-max'
              : 'rounded-3xl w-max bg-light-blue'
          "
        ></app-filter-button>
        <app-filter-button
          *ngIf="my_property_id"
          (IsClicked)="switchStep('CANDIDATS')"
          text="Candidats"
          [textClasses]="
            current_step !== 'CANDIDATS'
              ? 'txt-bold-gray text-xs text-center px-3 py-2'
              : 'text-white text-xs text-center px-3 py-2'
          "
          [buttonClasses]="
            current_step !== 'CANDIDATS'
              ? 'rounded-3xl bg-ligth-gray w-20'
              : 'rounded-3xl w-20 bg-light-blue'
          "
        ></app-filter-button>
        <app-filter-button
          (IsClicked)="switchStep('APPOINTMENT')"
          text="Rendez-vous"
          [textClasses]="
            current_step !== 'APPOINTMENT'
              ? 'txt-bold-gray text-xs text-center px-3 py-2'
              : 'text-white text-xs text-center px-3 py-2'
          "
          [buttonClasses]="
            current_step !== 'APPOINTMENT'
              ? 'rounded-3xl bg-ligth-gray w-24'
              : 'rounded-3xl w-24 bg-light-blue'
          "
        ></app-filter-button>
        <app-filter-button
          *ngIf="my_property_id"
          (IsClicked)="switchStep('MANDAT')"
          text="Mandat"
          [textClasses]="
            current_step !== 'MANDAT'
              ? 'txt-bold-gray text-xs text-center px-3 py-2'
              : 'text-white text-xs text-center px-3 py-2'
          "
          [buttonClasses]="
            current_step !== 'MANDAT'
              ? 'rounded-3xl bg-ligth-gray w-16'
              : 'rounded-3xl w-16 bg-light-blue'
          "
        ></app-filter-button>
        <app-filter-button
          *ngIf="user_type == 'PROFESSIONNEL'"
          (IsClicked)="switchStep('STATS')"
          text="Statistiques"
          [textClasses]="
            current_step !== 'STATS'
              ? 'txt-bold-gray text-xs text-center px-3 py-2'
              : 'text-white text-xs text-center px-3 py-2'
          "
          [buttonClasses]="
            current_step !== 'STATS'
              ? 'rounded-3xl bg-ligth-gray w-24'
              : 'rounded-3xl w-24 bg-light-blue'
          "
        ></app-filter-button>
        <app-filter-button
          *ngIf="!my_property_id && service_type !== 'rent'"
          (IsClicked)="switchStep('MAKE_OFFER')"
          text="Faire une offre"
          [textClasses]="
            current_step !== 'MAKE_OFFER'
              ? 'txt-bold-gray text-xs text-center px-3 py-2'
              : 'text-white text-xs text-center px-3 py-2'
          "
          [buttonClasses]="
            current_step !== 'MAKE_OFFER'
              ? 'rounded-3xl bg-ligth-gray w-max'
              : 'rounded-3xl w-max bg-light-blue'
          "
        ></app-filter-button>
      </div>
      <!-- MAIN STEP -->
      <div *ngIf="current_step === 'MAIN'">
        <div class="bg-transparent mx-6">
          <!-- CAROUSEL -->
          <div [@fade] class="relative">
            <!-- SWIPER -->
            <swiper
              [grabCursor]="true"
              [effect]="'creative'"
              [creativeEffect]="{
                prev: {
                  shadow: true,
                  translate: [0, 0, -400]
                },
                next: {
                  translate: ['100%', 0, 0]
                }
              }"
              [pagination]="{
                dynamicBullets: true
              }"
              class="mySwiper"
              [navigation]="true"
            >
              <!-- SLIDES -->
              <ng-template *ngFor="let imgPath of property.Photos" swiperSlide>
                <img
                  [src]="imgPath"
                  style="object-fit: cover !important"
                  class="w-full rounded-xl overflow-auto"
                />
                <div
                  *ngFor="let key of property.Photos_key"
                  (click)="removePropertyPhoto(key)"
                  class="
                    bg-white
                    rounded-full
                    p-1
                    flex
                    justify-end
                    right-4
                    cursor-pointer
                    absolute
                    top-4
                    opacity-50
                  "
                  style="z-index: 999"
                >
                  <mat-icon class="txt-bold-gray">close</mat-icon>
                </div>
              </ng-template>
            </swiper>
            <div
              class="absolute flex justify-start left-4 top-3 opacity-50 gap-3"
              style="z-index: 999"
            >
              <img
                [src]="
                  IsFavorite
                    ? 'assets/icons/svg/like.svg'
                    : 'assets/icons/svg/unlike.svg'
                "
                class="h-6 cursor-pointer"
              />
            </div>
            <!-- COUNT DATE AND EDIT BUTTON -->
            <div
              class="
                absolute
                left-4
                flex
                justify-between
                bottom-8
                opacity-50
                w-11/12
              "
              style="z-index: 999"
            >
              <div class="flex justify-start gap-3">
                <p class="p-2 rounded-xl bg-white text-xxs text-center">
                  {{ property.Photos.length }} / 10
                </p>
                <p class="p-2 rounded-xl bg-white text-xxs">
                  {{
                    property.Published_date !== "NaN Invalid Date à NaN:NaN"
                      ? property.Published_date
                      : property.CreatedAt
                  }}
                </p>
              </div>
              <div>
                <label for="file"
                  ><app-img
                    imgPath="assets/icons/svg/pencil.svg"
                    imgClasses="h-8 cursor-pointer"
                  ></app-img
                  ><input
                    class="hidden"
                    accept="image/*"
                    multiple
                    type="file"
                    name="file"
                    id="file"
                    (change)="uploadPhotos($event)"
                /></label>
              </div>
            </div>
          </div>
        </div>
        <!-- Description or characteristics buttons step and edit button -->
        <div
          class="ml-6 mt-2 mb-6 pr-6 flex justify-between overflow-x-scroll"
          id="button_list"
        >
          <div class="flex gap-x-3">
            <!-- CHARACTERISTICS STEP -->
            <app-filter-button
              (IsClicked)="switchStep('CHARACTERISTICS')"
              text="Caractéristiques du bien"
              [textClasses]="
                property_step !== 'CHARACTERISTICS'
                  ? 'txt-bold-gray text-xs text-center px-3 py-2'
                  : 'text-white text-xs text-center px-3 py-2'
              "
              [buttonClasses]="
                property_step !== 'CHARACTERISTICS'
                  ? 'rounded-3xl bg-ligth-gray w-40'
                  : 'rounded-3xl w-40 bg-light-blue'
              "
            ></app-filter-button>
            <!-- DESCRIPTION STEP -->
            <app-filter-button
              (IsClicked)="switchStep('DESCRIPTION')"
              text="Description"
              [textClasses]="
                property_step !== 'DESCRIPTION'
                  ? 'txt-bold-gray text-xs text-center px-3 py-2'
                  : 'text-white text-xs text-center px-3 py-2'
              "
              [buttonClasses]="
                property_step !== 'DESCRIPTION'
                  ? 'rounded-3xl bg-ligth-gray w-24'
                  : 'rounded-3xl w-24 bg-light-blue'
              "
            ></app-filter-button>
          </div>
          <!-- EDIT BUTTON STEP -->
          <div>
            <app-img
              (_onClick)="switchStep('EDIT_MODE')"
              imgPath="assets/icons/svg/pencil.svg"
              imgClasses="h-8 cursor-pointer"
            ></app-img>
          </div>
        </div>
        <!-- Property Characteristics step -->
        <div
          *ngIf="property_step === 'CHARACTERISTICS'"
          class="mb-6 overflow-hidden"
        >
          <!-- Characteristics blocks -->
          <div
            class="
              grid grid-cols-2
              gap-y-4
              mt-4
              mb-7
              mx-6
              border-b border-solid
              pb-6
            "
          >
            <app-input-txt
              label_name="property_type"
              label_text="Type de bien"
              labelClasses="text-xs"
              [model]="property.Property_type"
            ></app-input-txt>
            <app-input-txt
              label_name="total_area"
              [label_text]="
                property.Property_type !== 'Terrain'
                  ? 'Surface habitable'
                  : 'Surface du terrain'
              "
              labelClasses="text-xs"
              [model]="property.Total_area + ' m²'"
            ></app-input-txt>
            <app-input-txt
              label_name="property_state"
              label_text="Etat du bien"
              labelClasses="text-xs"
              [model]="property.Property_state"
            ></app-input-txt>
            <app-input-txt
              label_name="price"
              [label_text]="
                service_type === 'rent' ? 'Prix de location' : 'Prix de vente'
              "
              labelClasses="text-xs"
              [model]="property.Price + ' €'"
            ></app-input-txt>
            <app-input-txt
              label_name="address"
              label_text="Adresse"
              labelClasses="text-xs"
              [model]="property.City + ', ' + property.Postal_code"
            ></app-input-txt>
            <app-input-txt
              label_name="reference"
              label_text="Référence"
              labelClasses="text-xs"
              [model]="property.Reference"
            ></app-input-txt>
          </div>
          <!-- Pieces Block -->
          <div *ngIf="property.Property_type !== 'Terrain'" class="ml-6 mb-6">
            <app-text
              textClasses="txt-light-blue mb-3 font-bold"
              text="Les pièces du bien"
            ></app-text>
            <div class="flex flex-nowrap gap-3 overflow-y-auto no-scrollbar">
              <app-form-card
                *ngIf="property.Bedroom_number"
                imgPath="assets/icons/svg/logo-1.svg"
                imgClasses="w-8 mx-auto"
                cardClasses="default w-28"
                [model]="property.Bedroom_number + ' chambre(s)'"
                subtitleClasses="font-semibold text-center text-sm mx-auto whitespace-nowrap"
              ></app-form-card>
              <app-form-card
                *ngIf="property.Bathroom_number"
                imgPath="assets/icons/svg/logo-1.svg"
                imgClasses="w-8 mx-auto"
                cardClasses="default w-28"
                [model]="property.Bathroom_number + ' salle(s) de bain'"
                subtitleClasses="font-semibold text-center text-sm mx-auto whitespace-nowrap overflow-x-scroll no-scrollbar"
              ></app-form-card>
              <app-form-card
                *ngIf="property.Separate_toilet === 'true'"
                imgPath="assets/icons/svg/logo-1.svg"
                imgClasses="w-8 mx-auto"
                cardClasses="default w-28"
                model="WC séparé"
                subtitleClasses="font-semibold text-center text-sm mx-auto whitespace-nowrap"
              ></app-form-card>
              <app-form-card
                *ngIf="property.Meeting_room_number"
                imgPath="assets/icons/svg/logo-1.svg"
                imgClasses="w-8 mx-auto"
                cardClasses="default w-28"
                [model]="property.Meeting_room_number + ' salle(s) de réunion'"
                subtitleClasses="font-semibold text-center text-sm mx-auto whitespace-nowrap overflow-x-scroll no-scrollbar"
              ></app-form-card>
              <app-form-card
                *ngIf="property.Individual_office_number"
                imgPath="assets/icons/svg/logo-1.svg"
                imgClasses="w-8 mx-auto"
                cardClasses="default w-28"
                [model]="
                  property.Individual_office_number + ' bureau(x) individuel(s)'
                "
                subtitleClasses="font-semibold text-center text-sm mx-auto whitespace-nowrap overflow-x-scroll no-scrollbar"
              ></app-form-card>
            </div>
          </div>
          <!-- Additionnal Surface block -->
          <div
            *ngIf="
              property.Garage === 'true' ||
              property.Terrace === 'true' ||
              property.Outbuilding === 'true' ||
              property.Balcony === 'true' ||
              property.Garden === 'true' ||
              property.Private_parking === 'true'
            "
            class="ml-6 mb-6"
          >
            <app-text
              textClasses="txt-light-blue mb-3 font-bold"
              text="Surfaces annexes"
            ></app-text>
            <div class="flex flex-nowrap gap-3 overflow-x-scroll no-scrollbar">
              <app-form-card
                *ngIf="property.Garage === 'true'"
                imgPath="assets/icons/svg/logo-1.svg"
                imgClasses="w-8 mx-auto"
                cardClasses="default w-28"
                model="Garage"
                subtitleClasses="font-semibold text-center text-sm mx-auto"
              ></app-form-card>
              <app-form-card
                *ngIf="property.Terrace === 'true'"
                imgPath="assets/icons/svg/logo-1.svg"
                imgClasses="w-8 mx-auto"
                cardClasses="default w-28"
                model="Terrasse"
                subtitleClasses="font-semibold text-center text-sm mx-auto"
              ></app-form-card>
              <app-form-card
                *ngIf="property.Outbuilding === 'true'"
                imgPath="assets/icons/svg/logo-1.svg"
                imgClasses="w-8 mx-auto"
                cardClasses="default w-28"
                model="Dépendance"
                subtitleClasses="font-semibold text-center text-sm mx-auto"
              ></app-form-card>
              <app-form-card
                *ngIf="property.Balcony === 'true'"
                imgPath="assets/icons/svg/logo-1.svg"
                imgClasses="w-8 mx-auto"
                cardClasses="default w-28"
                model="Balcon"
                subtitleClasses="font-semibold text-center text-sm mx-auto"
              ></app-form-card>
              <app-form-card
                *ngIf="property.Garden === 'true'"
                imgPath="assets/icons/svg/logo-1.svg"
                imgClasses="w-8 mx-auto"
                cardClasses="default w-28"
                model="Jardin"
                subtitleClasses="font-semibold text-center text-sm mx-auto"
              ></app-form-card>
              <app-form-card
                *ngIf="property.Private_parking === 'true'"
                imgPath="assets/icons/svg/logo-1.svg"
                imgClasses="w-8 mx-auto"
                cardClasses="default w-28"
                model="Parking privé"
                subtitleClasses="font-semibold text-center text-sm mx-auto"
              ></app-form-card>
            </div>
          </div>
          <!-- Environment block -->
          <div
            *ngIf="
              property.Not_overlooked === 'true' ||
              property.Sea_view === 'true' ||
              property.Overview_sea === 'true' ||
              property.Non_adjoining === 'true' ||
              property.Panoramic_view === 'true' ||
              property.Zfu === 'true' ||
              property.Business_district === 'true' ||
              property.Industrial_estate === 'true'
            "
            class="mx-6 mb-6"
          >
            <app-text
              textClasses="txt-light-blue mb-3 font-bold"
              text="Environnement du bien"
            ></app-text>
            <div class="flex flex-col gap-3">
              <app-input-txt
                *ngIf="property.Not_overlooked === 'true'"
                label_text="Sans vis-à-vis"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Sea_view === 'true'"
                label_text="Vue mer"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Overview_sea === 'true'"
                label_text="Aperçu mer"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Non_adjoining === 'true'"
                label_text="Non-mitoyen"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Panoramic_view === 'true'"
                label_text="Vue panoramique"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Zfu === 'true'"
                label_text="Zone franche ZFU"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Business_district === 'true'"
                label_text="Quartier d'affaire"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Industrial_estate === 'true'"
                label_text="Zone industrielle"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
            </div>
          </div>
          <!-- Prestations and services block -->
          <div
            *ngIf="
              property.Air_conditioning === 'true' ||
              property.Fibre === 'true' ||
              property.Disabled_access === 'true' ||
              property.Swimming_pool === 'true' ||
              property.Elevator === 'true' ||
              property.Electric_vehicule_charging === 'true' ||
              property.Equipped_kitchen === 'true' ||
              property.Fitted_kitchen === 'true' ||
              property.Kitchen_area === 'true'
            "
            class="mx-6 mb-6"
          >
            <app-text
              textClasses="txt-light-blue mb-3 font-bold"
              text="Prestations et services"
            ></app-text>
            <div class="flex flex-col gap-3">
              <app-input-txt
                *ngIf="property.Air_conditioning === 'true'"
                label_text="Climatisation"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Fibre === 'true'"
                label_text="Fibre déployée"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Disabled_access === 'true'"
                label_text="Accès handicapé"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Swimming_pool === 'true'"
                label_text="Piscine"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Elevator === 'true'"
                label_text="Ascenseur"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Electric_vehicule_charging === 'true'"
                label_text="Recharge véhicule électrique"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Equipped_kitchen === 'true'"
                label_text="Cuisine équipée"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Fitted_kitchen === 'true'"
                label_text="Cuisine aménagée"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Kitchen_area === 'true'"
                label_text="Espace cuisine"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
            </div>
          </div>
          <!-- Security block -->
          <div
            *ngIf="
              property.Intercom === 'true' ||
              property.Electric_portal === 'true' ||
              property.Manual_portal === 'true' ||
              property.Digital_lock === 'true'
            "
            class="mx-6 mb-6 border-b border-solid"
          >
            <app-text
              textClasses="txt-light-blue mb-3 font-bold"
              text="Sécurité"
            ></app-text>
            <div class="flex flex-col gap-3">
              <app-input-txt
                *ngIf="property.Intercom === 'true'"
                label_text="Interphone"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Electric_portal === 'true'"
                label_text="Portail électrique"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Manual_portal === 'true'"
                label_text="Portail manuel"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Digital_lock === 'true'"
                label_text="Digicode"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
            </div>
          </div>
          <!-- Investment block -->
          <div
            *ngIf="
              (property.Life_annuity === 'true' ||
                property.Eligible_pinel_law === 'true' ||
                property.Senior_residence === 'true' ||
                property.Student_residence === 'true') &&
              service_type === 'sale'
            "
            class="mx-6 mb-6 border-b border-solid"
          >
            <app-text
              textClasses="txt-light-blue mb-3 font-bold"
              text="Investissement"
            ></app-text>
            <div class="flex flex-col gap-3">
              <app-input-txt
                *ngIf="property.Life_annuity === 'true'"
                label_text="Viagers"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Eligible_pinel_law === 'true'"
                label_text="éligible loi Pinel"
                labelClasses="text-sm txt-bold-gray capitalize"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Senior_residence === 'true'"
                label_text="Résidence sénior"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
              <app-input-txt
                *ngIf="property.Student_residence === 'true'"
                label_text="Résidence étudiante"
                labelClasses="text-sm txt-bold-gray"
              ></app-input-txt>
            </div>
          </div>
          <!-- Location block -->
          <div class="mx-6 mb-6 border-b border-solid">
            <app-text
              textClasses="txt-light-blue mb-3 font-bold"
              text="Où se situe le bien"
            ></app-text>
            <div class="flex flex-col gap-3">
              <!-- MAPS-->
              <!-- <div id="map" class="h-24 w-full rounded-xl"></div> -->
              <agm-map
                [zoom]="15"
                [latitude]="Lat"
                [longitude]="Lng"
                id="map"
                class="h-40 w-full rounded-xl overflow-auto"
              >
                <agm-marker [latitude]="Lat" [longitude]="Lng"></agm-marker>
              </agm-map>
              <app-input-txt
                label_name="address"
                label_text="Adresse"
                labelClasses="text-xs"
                [model]="property.City + ', ' + property.Postal_code"
              ></app-input-txt>
            </div>
          </div>
          <!-- Energetic consumption block -->
          <div class="mx-6 mb-6">
            <app-text
              textClasses="txt-light-blue mb-3 font-bold"
              text="Consommation énergétique"
            ></app-text>
            <div class="mx-auto grid grid-cols-2 gap-3">
              <app-input-txt
                label_name="energetic_class"
                label_text="Classe énergétique"
                labelClasses="text-xs"
                [model]="property.Energetic_class"
              ></app-input-txt>
              <app-input-txt
                label_name="ges"
                label_text="GES"
                labelClasses="text-xs"
                [model]="property.Gas_emissions"
              ></app-input-txt>
            </div>
          </div>
          <!-- Published or unpublished property button -->
          <div
            (click)="publishedOrUnpublishedProperty(property.State)"
            class="
              border border-solid
              w-10/12
              p-2
              mx-auto
              mt-2
              mb-3
              text-center
              cursor-pointer
              txt-bold-gray
            "
          >
            <p
              *ngIf="
                property.State == 'EN_ATTENTE' || property.State == 'DEPUBLIEE'
              "
            >
              Publier l'annonce
            </p>
            <p
              *ngIf="
                property.State == 'PUBLIEE'
              "
            >
              Dépublier l'annonce
            </p>
          </div>
          <!-- Remove property button -->
          <div
            (click)="removeProperty()"
            class="
              border border-solid
              w-10/12
              p-2
              mx-auto
              mt-2
              text-center
              cursor-pointer
              txt-bold-gray
            "
          >
            <p>Supprimer l'annonce</p>
          </div>
        </div>
        <!-- Property Description step -->
        <div *ngIf="property_step === 'DESCRIPTION'" class="mb-6">
          <!-- Description blocks -->
          <div class="mx-6 mb-6">
            <app-text
              textClasses="txt-light-blue mb-3 font-bold"
              text="Ce que vous devez savoir"
            ></app-text>
            <div
              *ngIf="!edit_mode"
              class="flex flex-nowrap gap-3 overflow-x-scroll no-scrollbar"
            >
              <app-text-inner-html
                textClasses="mb-16 text-sm"
                [content]="property.Description"
              ></app-text-inner-html>
            </div>
            <textarea
              *ngIf="edit_mode"
              name="property_description"
              id="property_description"
              [(ngModel)]="property.Description"
              class="
                w-full
                border border-solid
                rounded-xl
                resize-none
                p-3
                txt-bold-gray
              "
              cols="10"
              rows="10"
            ></textarea>
            <div
              *ngIf="edit_mode"
              class="mt-3 mx-auto flex justify-center mb-6 w-full"
            >
              <app-primary-button
                (IsClicked)="updatePropertyDescription(property.Description)"
                text="Modifier"
                buttonClasses="bg-light-blue text-white"
                [disabled]="false"
                [icon_visibility]="false"
              ></app-primary-button>
            </div>
          </div>
        </div>
      </div>
      <!-- INTERESTED_LEVEL -->
      <app-like-page
        *ngIf="current_step === 'INTERESTED_LEVEL'"
        [isOnLikePage]="false"
        [service_type]="service_type"
        [user_id]="user_id"
        [property_id]="property_id"
      ></app-like-page>
      <!-- PROPERTY CANDIDATES STEP -->
      <app-property-candidates-list
        *ngIf="current_step === 'CANDIDATS'"
        (IsClicked)="getUserInterested($event)"
      ></app-property-candidates-list>
      <!-- CANDIDATE FILE -->
      <app-user-interested
        *ngIf="current_step === 'CANDIDATE_FILE'"
        [candidate_id]="candidate_id"
        [projection_level]="projection_level"
        (onBack)="switchStep('CANDIDATS')"
      ></app-user-interested>
      <!-- APPOINTMENT STEP -->
      <app-my-appointments
        *ngIf="current_step === 'APPOINTMENT'"
        [isOnAppointmentPage]="false"
      ></app-my-appointments>
      <!-- MANDAT STEP -->
      <app-property-mandate
        *ngIf="current_step === 'MANDAT'"
      ></app-property-mandate>
      <!-- STATS STEP -->
      <app-property-stats *ngIf="current_step === 'STATS'"></app-property-stats>
      <!-- MAKE OFFER -->
      <app-make-offer *ngIf="current_step === 'MAKE_OFFER'"></app-make-offer>
    </div>
  </div>
</div>
