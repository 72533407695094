import { OfferModel } from './../../shared/models/offer-model';
import { ActivatedRoute } from '@angular/router';
import { CustomPopupComponent } from './../../shared/components/custom-popup/custom-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { LoaderPopupComponent } from './../../shared/components/loader-popup/loader-popup.component';
import { PropertyService } from 'src/app/shared/services/property.service';
import { LocalStorageService } from './../../shared/services/local-storage.service';
import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-make-offer',
  templateUrl: './make-offer.component.html',
  styleUrls: ['./make-offer.component.scss'],
})
export class MakeOfferComponent implements OnInit {
  public user_id: string;
  public offer_file: boolean = false;
  public offer: OfferModel[] = [];
  public offer_id: any = null;
  public file_key: any = null;
  public old_file_key: any = null;
  public property_id: string;
  public file_name: any;
  public media: any;
  constructor(
    private localStorageService: LocalStorageService,
    private propertyService: PropertyService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.property_id = this.route.snapshot.paramMap.get('id');
    this.user_id = this.localStorageService.getCurrentUser().id;
  }

  ngOnInit(): void {
    this.getUserOffer();
  }

  /**
   * get user Offer
   */
  public getUserOffer() {
    this.propertyService
      .getUserOffer(this.user_id, this.property_id)
      .subscribe((res: any) => {
        console.log('res', res);
        const data = res.data.getUserOffer;
        console.log('data', data.id);

        if (res && res.data.getUserOffer) {
          this.offer_file = true;
          const offer = new OfferModel().mapToModel(res.data.getUserOffer);
          console.log(offer);
          this.offer.push(offer);
          this.offer.forEach((element) => {
            console.log(element);
            this.offer_id = element.ID;
            this.file_key = element.File;
            this.old_file_key = element.File;
          });
        } else {
          return;
        }
      });
  }

  /**
   * makeOffer
   */
  public makeOffer($event) {
    console.log($event);
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Téléchargement en cours ... ',
        isLoader: true,
      },
    });
    var file = $event.target.files[0]; // FileList object

    var end_file_name = file.name.split('.')[1]; // End of string file_name

    var fieldName = $event.field_name; // Fieldname of file

    this.file_name = 'assets-upload/offer-' + uuidv4() + '.' + end_file_name; // Set a random file_name

    // If size of file bigger than 3 mega bytes throw Error
    if (file.size > 3145728) {
      this.dialog.closeAll();
      const dialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        hasBackdrop: true,
        height: '70%',
        panelClass: 'custom-modalbox',
        data: {
          status: 'error',
          title: "Une erreur s'est produite",
          message:
            'La taille du fichier ne doit pas excéder 3 méga octets (3 Mo)',
        },
      });
      this.file_name = '';
    } else {
      this.getBase64(file).then((data) => {
        this.media = data;
        this.propertyService
          .addOffer(
            this.user_id,
            this.media,
            this.file_name,
            this.property_id,
            this.old_file_key
          )
          .subscribe((res: any) => {
            console.log(res);
            if (res && res.data.addOffer.code === 'FILE_UPLOADED') {
              this.dialog.closeAll();
              window.location.reload();
            }
          });
      });
    }
  }

  /**
   * getBase64
   * Function to transform file into base64
   */
  public getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  /**
   * getFile
   */
  public getFile($event) {
    let file_url = environment.cloudfront_url + this.file_key;
    if (this.offer_file) {
      window.open(`${file_url}`, 'blank');
    }
  }

  /**
   * REMOVE FILE UPLOADED
   */
  public removeFile($event) {
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '70%',
      panelClass: 'custom-modalbox',
      data: {
        status: 'delete',
        title: 'ATTENTION',
        message: 'Etes-vous sur de vouloir supprimer ce fichier ?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dialog.closeAll();
        this.dialog.open(LoaderPopupComponent, {
          disableClose: true,
          hasBackdrop: true,
          height: '40%',
          panelClass: 'custom-modalbox',
          data: {
            text: 'Suppression en cours... ',
            isLoader: true,
          },
        });
        this.propertyService
          .removeFile(this.file_key, this.offer_id)
          .subscribe((res: any) => {
            console.log(res);
            if (res && res.data.removeOffer.code === 'FILE_REMOVED') {
              this.dialog.closeAll();
              window.location.reload();
            } else if (res.errors.message) {
              this.dialog.closeAll();
              this.dialog.open(CustomPopupComponent, {
                disableClose: true,
                hasBackdrop: true,
                height: '70%',
                panelClass: 'custom-modalbox',
                data: {
                  status: 'error',
                  title: "Une erreur s'est produite",
                  message:
                    'Nous sommes désolé, un problème est survenu. Veuillez réessayer ultérieurement.',
                },
              });
            }
          });
      } else {
        this.dialog.closeAll();
      }
    });
  }
}
