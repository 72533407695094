import { AgencyModel } from './../models/agency-model';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AgencyService {
  constructor(public http: HttpClient) {}

  public listAgencys(): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        query {
          listAgencys {
            items {
              id
              agency_name
            }
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }
}
