<div [@fade] class="w-full mx-auto bg-white overflow-auto pb-6">
  <div class="border-b border-solid pb-2">
    <div class="mx-6">
      <app-secondary-header
        (IsClicked)="back()"
        headerClasses=" pt-4 flex justify-between items-center mb-2"
      ></app-secondary-header>
      <div class="mx-auto text-center">
        <p class="font-bold text-xl txt-bold-gray">Créer un rendez-vous</p>
      </div>
    </div>
  </div>
  <!-- UserProperty list -->
  <app-my-property
    *ngIf="current_step === 'PROPERTY_LIST'"
    [isOnAppointmentPage]="true"
    headerClass="border-none mt-6"
    title="Biens en vente"
    service_type="VENTE"
    [property_IsSelected]="property_IsSelected"
    (IsClicked)="getPropertyId($event)"
  ></app-my-property>
  <div class="mx-6 h-full">
    <app-appointment-type
      *ngIf="current_step === 'APPOINTMENT_TYPE'"
      (IsClicked)="getAppointmentType($event)"
      [user_id]="userId"
    ></app-appointment-type>
    <app-appointment-reason
      *ngIf="current_step === 'APPOINTMENT_REASON'"
      (IsClicked)="getAppointmentReason($event)"
    ></app-appointment-reason>
    <app-appointment-date
      *ngIf="current_step === 'APPOINTMENT_DATE'"
      (SelectTime)="getAppointmentDate($event)"
      subtitle="A quelle date, souhaitez-vous programmer ce rendez-vous ? *"
      [appointment_type]="appointmentModel.Appointment_type"
      [pro_id]="userId"
      [available_days_array]="available_days_array"
    ></app-appointment-date>
    <app-appointment-guest
      *ngIf="current_step === 'GUEST_CHOICE'"
      (IsSelected)="getAppointmentGuest($event)"
      (ExternalSpeakerSelected)="getExternalSpeaker($event)"
      [property_id]="appointmentModel.Property_id"
    ></app-appointment-guest>
  </div>
  <app-appointment-confirmation
    *ngIf="current_step === 'APPOINTMENT_CONFIRMATION'"
    [appointment_hour]="appointment_hour"
    [appointment_date]="appointment_date"
    [appointment_reason]="appointmentModel.Appointment_reason"
    [appointment_type]="appointment_type"
    [appointment_guest]="appointment_guest"
    (IsClicked)="createAppointment()"
  ></app-appointment-confirmation>
  <!-- Progress bar -->
  <div
    style="z-index: 999"
    *ngIf="current_step !== 'APPOINTMENT_CONFIRMATION'"
    class="px-6 fixed bottom-0 md:relative items-center gap-3 mt-6 flex justify-end w-full"
  >
    <div class="flex gap-1">
      <img
        *ngFor="let step of stepsArray"
        [src]="
          step.step == stepNumber
            ? 'assets/icons/svg/bar-active.svg'
            : 'assets/icons/svg/bar.svg'
        "
        class="h-1"
      />
    </div>
    <app-next-button
      [disabled]="disabled ? true : false"
      (IsClicked)="switchStep()"
    ></app-next-button>
  </div>
</div>
