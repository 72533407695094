<div [@fade] class="mx-auto bg-white overflow-auto">
    <!-- IF FILTER PAGE NOT OPEN DISPLAY MAIN PAGE-->
    <div *ngIf="current_step === 'MAIN'">
      <!-- Header -->
      <div [ngClass]="headerClass" class="border-b border-solid pb-2">
        <div class="mx-6">
          <div *ngIf="!isOnAppointmentPage">
            <app-secondary-header
              (IsClicked)="back()"
              headerClasses=" pt-4 flex justify-between items-cente mb-2"
            ></app-secondary-header>
          </div>
          <div class="mx-auto text-center txt-bold-gray">
            <select
              [(ngModel)]="title"
              class="txt-bold-gray text-xl outline-none bg-transparent"
              (change)="listPropertyByServiceType(title)"
            >
              <option
                *ngFor="let sort of sortingTypeArray"
                [value]="sort.name"
                class="font-bold"
              >
                {{ sort.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <!-- Sort button -->
      <div class="mx-6 mt-6 flex items-center justify-between cursor-pointer">
        <div (click)="filterPage($event)" class="flex gap-3">
          <div>
            <app-img
              imgPath="assets/icons/svg/sort.svg"
              imgClasses="h-4"
            ></app-img>
          </div>
          <div>
            <app-text
              textClasses="txt-bold-gray text-sm"
              text="Filtres"
            ></app-text>
          </div>
        </div>
        <div *ngIf="filter_attributes_length">
          <app-text
            textClasses="txt-light-blue text-sm cursor-pointer"
            text="Effacer les filtres"
            (IsClicked)="removeAttributesFilter()"
          ></app-text>
        </div>
      </div>
      <!-- Property List => TODO -->
      <div class="mx-6 mt-6 mb-8 flex flex-col gap-4">
        <div
          *ngIf="!array_filtered.length && !hasError"
          class="mx-auto"
          id="waiting_data"
        >
          <mat-spinner diameter="50"></mat-spinner>
        </div>
        <!-- No Result text -->
        <p
          *ngIf="!array_filtered.length && hasError"
          class="text-center txt-light-blue"
        >
          Aucun résultats
        </p>
        <div *ngIf="array_filtered.length" class="mx-auto">
          <app-property-card
            *ngFor="let property of array_filtered"
            (IsClicked)="getProperty(property)"
            [assets_path]="property.Photos[0]"
            imgClasses="rounded-2xl overflow-auto"
            attributes_assets_path="assets/icons/svg/logo-1.svg"
            txtClasses="text-xs txt-bold-gray font-extrabold inline-block whitespace-nowrap"
            [price]="property.Price + ' €'"
            [pieces_number]="property.Number_of_pieces + ' pièces'"
            [total_area]="property.Total_area + ' m²'"
            [property_subtype]="property.Property_subtype"
            [location]="property.City"
            [assets_length]="property.Photos.length"
            [isFavorite]="property.IsFavorite"
            [published_date]="property.Published_date"
          ></app-property-card>
        </div>
      </div>
    </div>
    <!-- FILTER PAGE -->
    <div *ngIf="current_step === 'FILTERS'">
      <app-filter></app-filter>
    </div>
  </div>
  