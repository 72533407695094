import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-items',
  templateUrl: './input-items.component.html',
  styleUrls: ['./input-items.component.scss'],
})
export class ItemsComponent implements OnInit {
  @Input() label_name = '';
  @Input() type = 'text';
  @Input() id = this.label_name;
  @Input() value = '';
  @Output() Changed = new EventEmitter<any>();
  @Input() isSelected = false;
  @Input() txtClasses = '';
  @Input() inputClasses = '';
  @Input() inputSelectedClasses = '';
  @Input() model: any;
  @Input() disabled = false;
  @Input() readonly = 'null';
  @Input() icon_visibility = false;
  @Input() item_id = '';
  @Input() property_item = '';
  @Input() formFields: FormGroup = this.formBuilder.group({
    not_overlooked: ['', Validators.required],
    non_adjoining: ['', Validators.required],
    overview_sea: ['', Validators.required],
    sea_view: ['', Validators.required],
    panoramic_view: ['', Validators.required],
    separate_toilet: ['', Validators.required],
    swimming_pool: ['', Validators.required],
    chimney: ['', Validators.required],
    air_conditioning: ['', Validators.required],
    fibre: ['', Validators.required],
    manual_gate: ['', Validators.required],
    electric_gate: ['', Validators.required],
    electric_vehicle_charging: ['', Validators.required],
    disabled_access: [''],
    separate_kitchen: ['', Validators.required],
    open_kitchen: ['', Validators.required],
    semi_open_kitchen: [''],
    outdoor_kitchen: ['', Validators.required],
    digital_lock: ['', Validators.required],
    intercom: [''],
    balcony: ['', Validators.required],
    terrace: ['', Validators.required],
    garden: [''],
    private_parking: ['', Validators.required],
    garage: ['', Validators.required],
    lock_up_garage: ['', Validators.required],
    energetic_class: ['', Validators.required],
    ges_class: ['', Validators.required],
    industrial_estate: ['', Validators.required],
    business_district: ['', Validators.required],
    zfu: ['', Validators.required],
    global_management: ['', Validators.required],
    rent_quickly: ['', Validators.required],
    more_visibility_and_visits: ['', Validators.required],
    estimate_amount_of_rent: ['', Validators.required],
    estimate_area_property: ['', Validators.required],
    estimate_value_property: ['', Validators.required],
    estimate_cost_of_work: ['', Validators.required],
    sell_quickly: ['', Validators.required],
    // Sorting attributes
    house: ['', Validators.required],
    appartment: ['', Validators.required],
    office: ['', Validators.required],
    land: ['', Validators.required],
    single_story_house: ['', Validators.required],
    storey_house: ['', Validators.required],
    house_with_roof: ['', Validators.required],
    house_with_basement: ['', Validators.required],
    duplex: ['', Validators.required],
    triplex: ['', Validators.required],
    rooftop: ['', Validators.required],
    open_space_office: ['', Validators.required],
    office_appartment: ['', Validators.required],
    coworking_office: ['', Validators.required],
    building_for_private: ['', Validators.required],
    building_for_professional: ['', Validators.required],
    unserviced: ['', Validators.required],
    serviced: ['', Validators.required],
    intented_for_agriculture: ['', Validators.required],
    new_property: ['', Validators.required],
    old_property: ['', Validators.required],
    no_work_required: ['', Validators.required],
    with_work_required: ['', Validators.required],
    photos_required_for_search: ['', Validators.required],
    life_annuity: ['', Validators.required],
    exclude_life_annuities: ['', Validators.required],
    eligible_pinel_law: ['', Validators.required],
    senior_residence: ['', Validators.required],
    student_residence: ['', Validators.required],
    exclude_residences: ['', Validators.required],
    furnished: ['', Validators.required],
    elevator: ['', Validators.required],
    caritaker: ['', Validators.required],
    kitchen_area: ['', Validators.required],
    equipped_kitchen: ['', Validators.required],
    fitted_kitchen: ['', Validators.required],
    outbuilding: ['', Validators.required],
    not_furnished: ['', Validators.required],
  });
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {}

  onClick($event: any): void {
    this.checkInput();
  }

  checkInput(): void {
    if (this.value === 'NOT_OVERLOOKED') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'NON_ADJOINING') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'OVERVIEW_SEA') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'SEA_VIEW') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'PANORAMIC_VIEW') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'SEPARATE_TOILET') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'SWIMMING_POOL') {
      if (!this.model || this.model === '') {
        console.log('error');

        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        console.log('success');
        this.isSelected = !this.isSelected;

        const data = {
          value: this.value,
          model: this.model,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'CHIMNEY') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'AIR_CONDITIONING') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'FIBRE') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MANUAL_GATE') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'ELECTRIC_GATE') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'ELECTRIC_VEHICLE_CHARGING') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'DISABLED_ACCESS') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'SEPARATE_KITCHEN') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'EQUIPPED_KITCHEN') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'FITTED_KITCHEN') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'OPEN_KITCHEN') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'SEMI_OPEN_KITCHEN') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'OUTDOOR_KITCHEN') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'DIGITAL_LOCK') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'INTERCOM') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'BALCONY') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'TERRACE') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'GARDEN') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'PRIVATE_PARKING') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'OUTBUILDING') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'GARAGE') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'LOCK_UP_GARAGE') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'ENERGETIC_CLASS') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'GES_CLASS') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'INDUSTRIAL_ESTATE') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'BUSINESS_DISTRICT') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'ZFU') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'GLOBAL_MANAGEMENT') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'SELL_QUICKLY') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MORE_VISIBILITY_AND_VISITS') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'ESTIMATE_COST_OF_WORK') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'ESTIMATE_VALUE_PROPERTY') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'ESTIMATE_AREA_PROPERTY') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'RENT_QUICKLY') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'ESTIMATE_AMOUNT_OF_RENT') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);

        this.Changed.emit(data);
      }
    }
    if (this.value === 'MAISON_VILLA') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'APPARTEMENT') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'BUREAUX') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'TERRAIN') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'MAISON_PLAIN_PIEDS') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'MAISON_A_ETAGE') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'MAISON_AVEC_COMBLE') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'MAISON_AVEC_SOUS_SOL') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'DUPLEX') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'TRIPLEX') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'ROOFTOP') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'BUREAU_APPARTEMENT') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'BUREAU_EN_COWORKING') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'BUREAU_EN_OPEN_SPACE') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'BIEN_NEUF') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'BIEN_ANCIEN') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'SANS_TRAVAUX_A_PREVOIR') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'TRAVAUX_A_PREVOIR') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'TERRAIN_CONSTRUCTIBLE_PARTICULIER') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'TERRAIN_CONSTRUCTIBLE_PROFESSIONNEL') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'NON_VIABILISE') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'VIABILISE') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'TERRAIN_AGRICOLE') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'PHOTOS_REQUIRED_FOR_SEARCH') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'LIFE_ANNUITY') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'EXCLUDE_LIFE_ANNUITIES') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'ELIGIBLE_PINEL_LAW') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'SENIOR_RESIDENCE') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'STUDENT_RESIDENCE') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'EXCLUDE_RESIDENCES') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'FURNISHED') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'ELEVATOR') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'CARITAKER') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
    if (this.value === 'KITCHEN_AREA') {
      if (!this.model || this.model === '') {
        const data = {
          value: this.value,
          model: '',
          selected: '',
          id: '',
        };
        this.Changed.emit(data);
      } else {
        this.isSelected = !this.isSelected;
        const data = {
          value: this.value,
          model: this.model,
          selected: this.isSelected,
          id: this.item_id,
        };
        this.Changed.emit(data);
      }
    }
  }
}
