import { LocalStorageService } from './../../shared/services/local-storage.service';
import { DataService } from 'src/app/shared/services/data.service';
import { PropertyModel } from 'src/app/shared/models/property-model';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-filter',
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
    ]),
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0, transform: 'translateY(10px)' })),
      ]),
    ]),
  ],
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  public property_filter: any = new PropertyModel();
  public current_sorting: any = "Jusqu'à 5 km";
  public sortingTypeArray: Array<Object> = [
    { name: "Jusqu'à 5 km" },
    { name: "Jusqu'à 10 km" },
    { name: "Jusqu'à 15 km" },
    { name: "Jusqu'à 20 km" },
    { name: 'Plus de 20 km' },
  ];
  // ENERGETIC CLASS ARRAY
  public energeticClassArray = [
    {
      controlName: 'energetic_class',
      item_value: 'A',
      type: 'ENERGETIC_CLASS_A',
    },
    {
      controlName: 'energetic_class',
      item_value: 'B',
      type: 'ENERGETIC_CLASS_B',
    },
    {
      controlName: 'energetic_class',
      item_value: 'C',
      type: 'ENERGETIC_CLASS_C',
    },
    {
      controlName: 'energetic_class',
      item_value: 'D',
      type: 'ENERGETIC_CLASS_D',
    },
    {
      controlName: 'energetic_class',
      item_value: 'E',
      type: 'ENERGETIC_CLASS_E',
    },
    {
      controlName: 'energetic_class',
      item_value: 'F',
      type: 'ENERGETIC_CLASS_F',
    },
    {
      controlName: 'energetic_class',
      item_value: 'G',
      type: 'ENERGETIC_CLASS_G',
    },
    {
      controlName: 'energetic_class',
      item_value: 'VIERGE',
      type: 'ENERGETIC_CLASS_VIERGE',
    },
  ];
  // GES ARRAY
  public gesArray = [
    {
      controlName: 'gas_emissions',
      item_value: 'A',
      type: 'GAS_EMISSIONS_A',
    },
    {
      controlName: 'gas_emissions',
      item_value: 'B',
      type: 'GAS_EMISSIONS_B',
    },
    {
      controlName: 'gas_emissions',
      item_value: 'C',
      type: 'GAS_EMISSIONS_C',
    },
    {
      controlName: 'gas_emissions',
      item_value: 'D',
      type: 'GAS_EMISSIONS_D',
    },
    {
      controlName: 'gas_emissions',
      item_value: 'E',
      type: 'GAS_EMISSIONS_E',
    },
    {
      controlName: 'gas_emissions',
      item_value: 'F',
      type: 'GAS_EMISSIONS_F',
    },
    {
      controlName: 'gas_emissions',
      item_value: 'G',
      type: 'GAS_EMISSIONS_G',
    },
    {
      controlName: 'gas_emissions',
      item_value: 'VIERGE',
      type: 'GAS_EMISSIONS_VIERGE',
    },
  ];
  public service_type_search: string; // FILTER FOR RENT OR SALE
  public attributes_sort_property = {};
  public attributes_length: Number;
  public array_to_filter: any[] = [];
  public filtered_array: any[] = [];
  public second_filtered_array: any[] = [];
  public prefix_url: string = environment.cloudfront_url;
  public propertyTypeArray = [
    {
      controlName: 'house',
      path_img: 'assets/icons/svg/house.svg',
      item_value: 'Une maison / villa',
      type: 'MAISON_VILLA',
    },
    {
      controlName: 'appartment',
      path_img: 'assets/icons/svg/appartment.svg',
      item_value: 'Un appartement',
      type: 'APPARTEMENT',
    },
    {
      controlName: 'land',
      path_img: 'assets/icons/svg/land.svg',
      item_value: 'Un terrain',
      type: 'TERRAIN',
    },
    {
      controlName: 'office',
      path_img: 'assets/icons/svg/office.svg',
      item_value: 'Des bureaux',
      type: 'BUREAUX',
    },
  ];
  public current_step: string;
  public user_id: string;
  public result_count;
  @Input() header_visible: boolean = true;
  @Output() IsClicked = new EventEmitter<any>();
  @Input() array_property: any = [];
  @Input() array_filtered: any = [];
  @Input() array_To_Send: any = [];
  @Input() hasError: boolean = false;

  constructor(
    private router: Router,
    private dataService: DataService,
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService
  ) {
    this.property_filter = new PropertyModel();
    this.current_step = 'DEFAULT';
    this.user_id = this.localStorageService.getCurrentUser().id;
    this.service_type_search = this.route.snapshot.paramMap.get('service_type');
    if (this.dataService.data.PROPERTY_ARRAY) {
      this.array_property = this.dataService.data.PROPERTY_ARRAY;
      var service = this.service_type_search === 'rent' ? 'LOCATION' : 'VENTE';
      this.attributes_sort_property['service_type'] = service;
      console.log('this.filtered_array', this.array_property);
      this.result_count = this.filtered_array.length;
      console.log('attributes_sort_property', this.attributes_sort_property);
      this.checkCountFilterAttributes(this.attributes_sort_property);
    } else {
      this.getAllPublishedProperty();
    }
  }

  ngOnInit(): void {}

  /**
   * getAllPublishedProperty
   */
  public getAllPublishedProperty() {
    try {
      this.propertyService.getAllPublishedProperty().subscribe((res) => {
        console.log(res);
        if (res && res.data.getAllPublishedProperty) {
          const data = res.data.getAllPublishedProperty;
          data.forEach((element) => {
            const property = new PropertyModel().mapToModel(element);
            this.array_property.push(property);
          });
          this.sortProperty();
        } else if (res.errors.message) {
          console.log('ERROR: ', res.errors.message);
        }
      });
    } catch (error) {
      console.log('ERROR: ', error);
      throw new Error(error);
    }
  }

  /**
   * sort Property function
   * This function return sale or rent property
   */
  public sortProperty() {
    this.array_To_Send = [];
    var service = this.service_type_search === 'rent' ? 'LOCATION' : 'VENTE';
    this.attributes_sort_property['service_type'] = service;
    this.array_filtered = this.array_property.filter((element) => {
      return element.Service_type.includes(service);
    });
    this.array_To_Send = this.array_filtered;
    console.log('filtré', this.array_filtered);
    console.log('filtré', this.attributes_sort_property);
    console.log('filtré', this.attributes_length);
  }

  /**
   * back function
   */
  public back() {
    window.location.reload();
  }

  /**
   * isSelected
   * // This function add an "RADIO STYLE" for items selected / When the item is selected, add selected style on it and remove selected style on old item selected
   */
  public isSelected($propertyModel: any, $id: any) {
    var element = document.getElementById($id);
    element?.classList.add('inputSelectedClasses');
    if ($propertyModel !== $id && $propertyModel !== '') {
      var elementToRemove = document.getElementById($propertyModel);
      elementToRemove?.classList.remove('inputSelectedClasses');
    }
  }

  /**
   * checkCountFilterAttributes
   */
  public checkCountFilterAttributes(obj) {
    this.attributes_length = Object.keys(obj).length;
    console.log('LENGTH: ', this.attributes_length);
    return this.attributes_length;
  }

  /**
   * switchStep function
   * This function display the property_type => DEFAULT STEP   OR   filters items => FILTERS STEP
   */
  public switchStep() {
    switch (this.current_step) {
      case 'DEFAULT':
        this.current_step = 'FILTERS';
        console.log('attributes_sort_property', this.attributes_sort_property);
        this.checkCountFilterAttributes(this.attributes_sort_property);
        break;
      case 'FILTERS':
        this.current_step = 'DEFAULT';
        this.attributes_sort_property = [];
        this.filtered_array = [];
        break;
      default:
        //
        break;
    }
  }

  /**
   * onChange function
   * This function get value selected and push it in an array prepare sort function
   */
  public onChange($event) {
    console.log('ON CHANGE FUNCTION', $event);
    if ($event.value === 'MIN_BUDGET') {
      if ($event.model && $event.model !== null) {
        this.property_filter.Min_budget = $event.model;
        this.attributes_sort_property[$event.value] = parseInt($event.model);
      } else if (!$event.model) {
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'MAX_BUDGET') {
      if ($event.model.length > 0) {
        this.property_filter.Max_budget = $event.model;
        this.attributes_sort_property[$event.value] = parseInt($event.model);
      } else if (!$event.model) {
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'MAISON_PLAIN_PIEDS') {
      if (
        this.property_filter.Single_story_house === false ||
        !this.property_filter.Single_story_house
      ) {
        this.property_filter.Single_story_house = $event.id;
        this.attributes_sort_property[$event.value] = 'Maison plain pieds';
      } else if (this.property_filter.Single_story_house === $event.id) {
        this.property_filter.Single_story_house = false;
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'MAISON_A_ETAGE') {
      if (
        this.property_filter.Storey_house === false ||
        !this.property_filter.Storey_house
      ) {
        this.property_filter.Storey_house = $event.id;
        this.attributes_sort_property[$event.value] = 'Maison à étage';
        // this.second_filtered_array = this.filtered_array.filter((element) => {
        //   return element.property_subtype.includes('Maison à étage');
        // });
      } else if (this.property_filter.Storey_house === $event.id) {
        this.property_filter.Storey_house = false;
        delete this.attributes_sort_property[$event.value];
      }
      console.log(this.attributes_sort_property);
    }
    if ($event.value === 'MAISON_AVEC_COMBLE') {
      if (
        this.property_filter.House_with_roof === false ||
        !this.property_filter.House_with_roof
      ) {
        this.property_filter.House_with_roof = $event.id;
        this.attributes_sort_property[$event.value] = 'Maison avec comble';
      } else if (this.property_filter.House_with_roof === $event.id) {
        this.property_filter.House_with_roof = false;
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'MAISON_AVEC_SOUS_SOL') {
      if (
        this.property_filter.House_with_basement === false ||
        !this.property_filter.House_with_basement
      ) {
        this.property_filter.House_with_basement = $event.id;
        this.attributes_sort_property[$event.value] = 'Maison avec sous-sol';
      } else if (this.property_filter.House_with_basement === $event.id) {
        this.property_filter.House_with_basement = false;
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'DUPLEX') {
      if (
        this.property_filter.Duplex === false ||
        !this.property_filter.Duplex
      ) {
        this.property_filter.Duplex = $event.id;
        this.attributes_sort_property[$event.value] = 'Duplex';
      } else if (this.property_filter.Duplex === $event.id) {
        this.property_filter.Duplex = false;
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'TRIPLEX') {
      if (
        this.property_filter.Triplex === false ||
        !this.property_filter.Triplex
      ) {
        this.property_filter.Triplex = $event.id;
        this.attributes_sort_property[$event.value] = 'Triplex';
      } else if (this.property_filter.Triplex === $event.id) {
        this.property_filter.Triplex = false;
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'ROOFTOP') {
      if (
        this.property_filter.Rooftop === false ||
        !this.property_filter.Rooftop
      ) {
        this.property_filter.Rooftop = $event.id;
        this.attributes_sort_property[$event.value] = 'Rooftop';
      } else if (this.property_filter.Rooftop === $event.id) {
        this.property_filter.Rooftop = false;
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'BUREAU_EN_OPEN_SPACE') {
      if (
        this.property_filter.Open_space_office === false ||
        !this.property_filter.Open_space_office
      ) {
        this.property_filter.Open_space_office = $event.id;
        this.attributes_sort_property[$event.value] = 'Bureau en open space';
      } else if (this.property_filter.Open_space_office === $event.id) {
        this.property_filter.Open_space_office = false;
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'BUREAU_APPARTEMENT') {
      if (
        this.property_filter.Office_appartment === false ||
        !this.property_filter.Office_appartment
      ) {
        this.property_filter.Office_appartment = $event.id;
        this.attributes_sort_property[$event.value] = 'Bureau appartement';
      } else if (this.property_filter.Office_appartment === $event.id) {
        this.property_filter.Office_appartment = false;
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'BUREAU_EN_COWORKING') {
      if (
        this.property_filter.Coworking_office === false ||
        !this.property_filter.Coworking_office
      ) {
        this.property_filter.Coworking_office = $event.id;
        this.attributes_sort_property[$event.value] = 'Bureau en coworking';
      } else if (this.property_filter.Coworking_office === $event.id) {
        this.property_filter.Coworking_office = false;
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'TERRAIN_CONSTRUCTIBLE_PARTICULIER') {
      if (
        this.property_filter.Building_for_private === false ||
        !this.property_filter.Building_for_private
      ) {
        this.property_filter.Building_for_private = $event.id;
        this.attributes_sort_property[$event.value] =
          'Terrain constructible particulier';
      } else if (this.property_filter.Building_for_private === $event.id) {
        this.property_filter.Building_for_private = false;
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'TERRAIN_CONSTRUCTIBLE_PROFESSIONNEL') {
      if (
        this.property_filter.Building_for_professional === false ||
        !this.property_filter.Building_for_professional
      ) {
        this.property_filter.Building_for_professional = $event.id;
        this.attributes_sort_property[$event.value] =
          'Terrain constructible professionnel';
      } else if (this.property_filter.Building_for_professional === $event.id) {
        this.property_filter.Building_for_professional = false;
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'TERRAIN_AGRICOLE') {
      if (
        this.property_filter.Intented_for_agriculture === false ||
        !this.property_filter.Intented_for_agriculture
      ) {
        this.property_filter.Intented_for_agriculture = $event.id;
        this.attributes_sort_property[$event.value] = 'Terrain agricole';
      } else if (this.property_filter.Intented_for_agriculture === $event.id) {
        this.property_filter.Intented_for_agriculture = false;
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'BIEN_NEUF') {
      if (
        this.property_filter.New_property === false ||
        !this.property_filter.New_property
      ) {
        this.property_filter.New_property = $event.id;
        this.attributes_sort_property[$event.value] = 'Bien neuf';
      } else if (this.property_filter.New_property === $event.id) {
        this.property_filter.New_property = false;
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'BIEN_ANCIEN') {
      if (
        this.property_filter.Old_property === false ||
        !this.property_filter.Old_property
      ) {
        this.property_filter.Old_property = $event.id;
        this.attributes_sort_property[$event.value] = 'Bien ancien';
      } else if (this.property_filter.Old_property === $event.id) {
        this.property_filter.Old_property = false;
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'SANS_TRAVAUX_A_PREVOIR') {
      if (
        this.property_filter.No_work_required === false ||
        !this.property_filter.No_work_required
      ) {
        this.property_filter.No_work_required = $event.id;
        this.attributes_sort_property[$event.value] = 'Refait à neuf';
      } else if (this.property_filter.No_work_required === $event.id) {
        this.property_filter.No_work_required = false;
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'TRAVAUX_A_PREVOIR') {
      if (
        this.property_filter.With_work_required === false ||
        !this.property_filter.With_work_required
      ) {
        this.property_filter.With_work_required = $event.id;
        this.attributes_sort_property[$event.value] = 'Avec travaux';
      } else if (this.property_filter.With_work_required === $event.id) {
        this.property_filter.With_work_required = false;
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'NON_VIABILISE') {
      if (
        this.property_filter.Unserviced === false ||
        !this.property_filter.Unserviced
      ) {
        this.property_filter.Unserviced = $event.id;
        this.attributes_sort_property[$event.value] = 'Non viabilisé';
      } else if (this.property_filter.Unserviced === $event.id) {
        this.property_filter.Unserviced = false;
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'VIABILISE') {
      if (
        this.property_filter.Serviced === false ||
        !this.property_filter.Serviced
      ) {
        this.property_filter.Serviced = $event.id;
        this.attributes_sort_property[$event.value] = 'Viabilisé';
      } else if (this.property_filter.Serviced === $event.id) {
        this.property_filter.Serviced = false;
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'NOT_OVERLOOKED') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Not_overlooked === false ||
        !this.property_filter.Not_overlooked
      ) {
        this.property_filter.Not_overlooked = true;
        this.attributes_sort_property['not_overlooked'] = true;
      } else if (this.property_filter.Not_overlooked === true) {
        this.property_filter.Not_overlooked = false;
        delete this.attributes_sort_property['not_overlooked'];
        console.log(this.attributes_sort_property);
      }
    }
    if ($event.value === 'NON_ADJOINING') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Non_adjoining === false ||
        !this.property_filter.Non_adjoining
      ) {
        this.property_filter.Non_adjoining = true;
        this.attributes_sort_property['non_adjoining'] = true;
      } else if (this.property_filter.Non_adjoining === true) {
        this.property_filter.Non_adjoining = false;
        delete this.attributes_sort_property['non_adjoining'];
      }
    }
    if ($event.value === 'OVERVIEW_SEA') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Overview_sea === false ||
        !this.property_filter.Overview_sea
      ) {
        this.property_filter.Overview_sea = true;
        this.attributes_sort_property['overview_sea'] = true;
      } else if (this.property_filter.Overview_sea === true) {
        this.property_filter.Overview_sea = false;
        delete this.attributes_sort_property['overview_sea'];
      }
    }
    if ($event.value === 'SEA_VIEW') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Sea_view === false ||
        !this.property_filter.Sea_view
      ) {
        this.property_filter.Sea_view = true;
        this.attributes_sort_property['sea_view'] = true;
      } else if (this.property_filter.Sea_view === true) {
        this.property_filter.Sea_view = false;
        delete this.attributes_sort_property['sea_view'];
      }
    }
    if ($event.value === 'PANORAMIC_VIEW') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Panoramic_view === false ||
        !this.property_filter.Panoramic_view
      ) {
        this.property_filter.Panoramic_view = true;
        this.attributes_sort_property['panoramic_view'] = true;
      } else if (this.property_filter.Panoramic_view === true) {
        this.property_filter.Panoramic_view = false;
        delete this.attributes_sort_property['panoramic_view'];
      }
    }
    if ($event.value === 'FURNISHED') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Furnished === false ||
        !this.property_filter.Furnished
      ) {
        this.property_filter.Furnished = true;
        this.attributes_sort_property['furnished'] = true;
      } else if (this.property_filter.Furnished === true) {
        this.property_filter.Furnished = false;
        delete this.attributes_sort_property['furnished'];
      }
    }
    if ($event.value === 'NOT_FURNISHED') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Not_furnished === false ||
        !this.property_filter.Not_furnished
      ) {
        this.property_filter.Not_furnished = true;
        this.attributes_sort_property['not_furnished'] = true;
      } else if (this.property_filter.Not_furnished === true) {
        this.property_filter.Not_furnished = false;
        delete this.attributes_sort_property['not_furnished'];
      }
    }
    if ($event.value === 'BALCONY') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Balcony === false ||
        !this.property_filter.Balcony
      ) {
        this.property_filter.Balcony = true;
        this.attributes_sort_property['balcony'] = true;
      } else if (this.property_filter.Balcony) {
        this.property_filter.Balcony = false;
        delete this.attributes_sort_property['balcony'];
      }
    }
    if ($event.value === 'ELEVATOR') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Elevator === false ||
        !this.property_filter.Elevator
      ) {
        this.property_filter.Elevator = true;
        this.attributes_sort_property['elevator'] = true;
      } else if (this.property_filter.Elevator === true) {
        this.property_filter.Elevator = false;
        delete this.attributes_sort_property['elevator'];
      }
    }
    if ($event.value === 'CARITAKER') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Caritaker === false ||
        !this.property_filter.Caritaker
      ) {
        this.property_filter.Caritaker = true;
        this.attributes_sort_property['caritaker'] = true;
      } else if (this.property_filter.Caritaker === true) {
        this.property_filter.Caritaker = false;
        delete this.attributes_sort_property['caritaker'];
      }
    }
    if ($event.value === 'EQUIPPED_KITCHEN') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Equipped_kitchen === false ||
        !this.property_filter.Equipped_kitchen
      ) {
        this.property_filter.Equipped_kitchen = true;
        this.attributes_sort_property['equipped_kitchen'] = true;
      } else if (this.property_filter.Equipped_kitchen === true) {
        this.property_filter.Equipped_kitchen = false;
        delete this.attributes_sort_property['equipped_kitchen'];
      }
    }
    if ($event.value === 'FITTED_KITCHEN') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Fitted_kitchen === false ||
        !this.property_filter.Fitted_kitchen
      ) {
        this.property_filter.Fitted_kitchen = true;
        this.attributes_sort_property['fitted_kitchen'] = true;
      } else if (this.property_filter.Fitted_kitchen === true) {
        this.property_filter.Fitted_kitchen = false;
        delete this.attributes_sort_property['fitted_kitchen'];
      }
    }
    if ($event.value === 'OUTBUILDING') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Outbuilding === false ||
        !this.property_filter.Outbuilding
      ) {
        this.property_filter.Outbuilding = true;
        this.attributes_sort_property['outbuilding'] = true;
      } else if (this.property_filter.Outbuilding === true) {
        this.property_filter.Outbuilding = false;
        delete this.attributes_sort_property['outbuilding'];
      }
    }
    if ($event.value === 'MANUAL_GATE') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Manual_gate === false ||
        !this.property_filter.Manual_gate
      ) {
        this.property_filter.Manual_gate = true;
        this.attributes_sort_property['manual_gate'] = true;
      } else if (this.property_filter.Manual_gate === true) {
        this.property_filter.Manual_gate = false;
        delete this.attributes_sort_property['manual_gate'];
      }
    }
    if ($event.value === 'ELECTRIC_GATE') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Electric_gate === false ||
        !this.property_filter.Electric_gate
      ) {
        this.property_filter.Electric_gate = true;
        this.attributes_sort_property['electric_gate'] = true;
      } else if (this.property_filter.Electric_gate === true) {
        this.property_filter.Electric_gate = false;
        delete this.attributes_sort_property['electric_gate'];
      }
    }
    if ($event.value === 'INDUSTRIAL_ESTATE') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Industrial_estate === false ||
        !this.property_filter.Industrial_estate
      ) {
        this.property_filter.Industrial_estate = true;
        this.attributes_sort_property['industrial_estate'] = true;
      } else if (this.property_filter.Industrial_estate === true) {
        this.property_filter.Industrial_estate = false;
        delete this.attributes_sort_property['industrial_estate'];
      }
    }
    if ($event.value === 'BUSINESS_DISTRICT') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Business_district === false ||
        !this.property_filter.Business_district
      ) {
        this.property_filter.Business_district = true;
        this.attributes_sort_property['business_district'] = true;
      } else if (this.property_filter.Business_district === true) {
        this.property_filter.Business_district = false;
        delete this.attributes_sort_property['business_district'];
      }
    }
    if ($event.value === 'ZFU') {
      console.log($event, this.property_filter);
      if (this.property_filter.Zfu === false || !this.property_filter.Zfu) {
        this.property_filter.Zfu = true;
        this.attributes_sort_property['zfu'] = true;
      } else if (this.property_filter.Zfu === true) {
        this.property_filter.Zfu = false;
        delete this.attributes_sort_property['zfu'];
      }
    }
    if ($event.value === 'PLACE_LOCATION') {
      console.log($event, this.property_filter, this.property_filter.Location);
      if (
        this.property_filter.Location === false ||
        !this.property_filter.Location
      ) {
        this.property_filter.Location = true;
      } else if (this.property_filter.Location === true) {
        this.property_filter.Location = false;
      }
      // GOOGLE MAPS TO DO !!
    }
    if ($event.value === 'MIN_AREA') {
      if ($event.model.length > 0) {
        this.property_filter.Min_area = $event.model;
        this.attributes_sort_property[$event.value] = parseInt($event.model);
      } else if (!$event.model) {
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'MAX_AREA') {
      if ($event.model.length > 0) {
        this.property_filter.Max_area = $event.model;
        this.attributes_sort_property[$event.value] = parseInt($event.model);
      } else if (!$event.model) {
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'MIN_LAND_AREA') {
      if ($event.model.length > 0) {
        this.property_filter.Min_land_area = $event.model;
        this.attributes_sort_property[$event.value] = parseInt($event.model);
      } else if (!$event.model) {
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'MAX_LAND_AREA') {
      if ($event.model.length > 0) {
        this.property_filter.Max_land_area = $event.model;
        this.attributes_sort_property[$event.value] = parseInt($event.model);
      } else if (!$event.model) {
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'MIN_PIECES_NUMBER') {
      if ($event.model.length > 0) {
        this.property_filter.Min_pieces_number = $event.model;
        this.attributes_sort_property[$event.value] = parseInt($event.model);
      } else if (!$event.model) {
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'MAX_PIECES_NUMBER') {
      if ($event.model.length > 0) {
        this.property_filter.Max_pieces_number = $event.model;
        this.attributes_sort_property[$event.value] = parseInt($event.model);
      } else if (!$event.model) {
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'MIN_BEDROOMS_NUMBER') {
      if ($event.model.length > 0) {
        this.property_filter.Min_bedrooms_number = $event.model;
        this.attributes_sort_property[$event.value] = parseInt($event.model);
      } else if (!$event.model) {
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'MAX_BEDROOMS_NUMBER') {
      if ($event.model.length > 0) {
        this.property_filter.Max_bedrooms_number = $event.model;
        this.attributes_sort_property[$event.value] = parseInt($event.model);
      } else if (!$event.model) {
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'MIN_BATHROOMS_NUMBER') {
      if ($event.model.length > 0) {
        this.property_filter.Min_bathrooms_number = $event.model;
        this.attributes_sort_property[$event.value] = parseInt($event.model);
      } else if (!$event.model) {
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'MAX_BATHROOMS_NUMBER') {
      if ($event.model.length > 0) {
        this.property_filter.Max_bathrooms_number = $event.model;
        this.attributes_sort_property[$event.value] = parseInt($event.model);
      } else if (!$event.model) {
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'MIN_INDIVIDUAL_OFFICES_NUMBER') {
      if ($event.model.length > 0) {
        this.property_filter.Min_individual_offices_number = $event.model;
        this.attributes_sort_property[$event.value] = parseInt($event.model);
      } else if (!$event.model) {
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'MAX_INDIVIDUAL_OFFICES_NUMBER') {
      if ($event.model.length > 0) {
        this.property_filter.Max_individual_offices_number = $event.model;
        this.attributes_sort_property[$event.value] = parseInt($event.model);
      } else if (!$event.model) {
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'MIN_SEATS_NUMBER') {
      if ($event.model.length > 0) {
        this.property_filter.Min_seats_number = $event.model;
        this.attributes_sort_property[$event.value] = parseInt($event.model);
      } else if (!$event.model) {
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'MAX_SEATS_NUMBER') {
      if ($event.model.length > 0) {
        this.property_filter.Max_seats_number = $event.model;
        this.attributes_sort_property[$event.value] = parseInt($event.model);
      } else if (!$event.model) {
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'MIN_MEETING_ROOMS_NUMBER') {
      if ($event.model.length > 0) {
        this.property_filter.Min_meeting_rooms_number = $event.model;
        this.attributes_sort_property[$event.value] = parseInt($event.model);
      } else if (!$event.model) {
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'MAX_MEETING_ROOMS_NUMBER') {
      if ($event.model.length > 0) {
        this.property_filter.Max_meeting_rooms_number = $event.model;
        this.attributes_sort_property[$event.value] = parseInt($event.model);
      } else if (!$event.model) {
        delete this.attributes_sort_property[$event.value];
      }
    }
    if ($event.value === 'SEPARATE_TOILET') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Separate_toilet === false ||
        !this.property_filter.Separate_toilet
      ) {
        this.property_filter.Separate_toilet = true;
        this.attributes_sort_property['separate_toilet'] = true;
      } else if (this.property_filter.Separate_toilet === true) {
        this.property_filter.Separate_toilet = false;
        delete this.attributes_sort_property['separate_toilet'];
      }
    }
    if ($event.value === 'SWIMMING_POOL') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Swimming_pool === false ||
        !this.property_filter.Swimming_pool
      ) {
        this.property_filter.Swimming_pool = true;
        this.attributes_sort_property['swimming_pool'] = true;
      } else if (this.property_filter.Swimming_pool === true) {
        this.property_filter.Swimming_pool = false;
        delete this.attributes_sort_property['swimming_pool'];
      }
    }
    if ($event.value === 'AIR_CONDITIONING') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Air_conditioning === false ||
        !this.property_filter.Air_conditioning
      ) {
        this.property_filter.Air_conditioning = true;
        this.attributes_sort_property['air_conditioning'] = true;
      } else if (this.property_filter.Air_conditioning === true) {
        this.property_filter.Air_conditioning = false;
        delete this.attributes_sort_property['air_conditioning'];
      }
    }
    if ($event.value === 'FIBRE') {
      console.log($event, this.property_filter);
      if (this.property_filter.Fibre === false || !this.property_filter.Fibre) {
        this.property_filter.Fibre = true;
        this.attributes_sort_property['fibre'] = true;
      } else if (this.property_filter.Fibre === true) {
        this.property_filter.Fibre = false;
        delete this.attributes_sort_property['fibre'];
      }
    }
    if ($event.value === 'ELECTRIC_VEHICLE_CHARGING') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Electric_vehicule_charging === false ||
        !this.property_filter.Electric_vehicule_charging
      ) {
        this.property_filter.Electric_vehicule_charging = true;
        this.attributes_sort_property['electric_vehicule_charging'] = true;
      } else if (this.property_filter.Electric_vehicule_charging === true) {
        this.property_filter.Electric_vehicule_charging = false;
        delete this.attributes_sort_property['electric_vehicule_charging'];
      }
    }
    if ($event.value === 'DISABLED_ACCESS') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Disabled_access === false ||
        !this.property_filter.Disabled_access
      ) {
        this.property_filter.Disabled_access = true;
        this.attributes_sort_property['disabled_access'] = true;
      } else if (this.property_filter.Disabled_access === true) {
        this.property_filter.Disabled_access = false;
        delete this.attributes_sort_property['disabled_access'];
      }
    }
    if ($event.value === 'DIGITAL_LOCK') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Digital_lock === false ||
        !this.property_filter.Digital_lock
      ) {
        this.property_filter.Digital_lock = true;
        this.attributes_sort_property['digital_lock'] = true;
      } else if (this.property_filter.Digital_lock === true) {
        this.property_filter.Digital_lock = false;
        delete this.attributes_sort_property['digital_lock'];
      }
    }
    if ($event.value === 'INTERCOM') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Intercom === false ||
        !this.property_filter.Intercom
      ) {
        this.property_filter.Intercom = true;
        this.attributes_sort_property['intercom'] = true;
      } else if (this.property_filter.Intercom === true) {
        this.property_filter.Intercom = false;
        delete this.attributes_sort_property['intercom'];
      }
    }
    if ($event.value === 'SEPARATE_KITCHEN') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Separate_kitchen === false ||
        !this.property_filter.Separate_kitchen
      ) {
        this.property_filter.Separate_kitchen = true;
        this.attributes_sort_property['separate_kitchen'] = true;
      } else if (this.property_filter.Separate_kitchen === true) {
        this.property_filter.Separate_kitchen = false;
        delete this.attributes_sort_property['separate_kitchen'];
      }
    }
    if ($event.value === 'OPEN_KITCHEN') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Open_kitchen === false ||
        !this.property_filter.Open_kitchen
      ) {
        this.property_filter.Open_kitchen = true;
        this.attributes_sort_property['open_kitchen'] = true;
      } else if (this.property_filter.Open_kitchen === true) {
        this.property_filter.Open_kitchen = false;
        delete this.attributes_sort_property['open_kitchen'];
      }
    }
    if ($event.value === 'SEMI_OPEN_KITCHEN') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Semi_open_kitchen === false ||
        !this.property_filter.Semi_open_kitchen
      ) {
        this.property_filter.Semi_open_kitchen = true;
        this.attributes_sort_property['semi_open_kitchen'] = true;
      } else if (this.property_filter.Semi_open_kitchen === true) {
        this.property_filter.Semi_open_kitchen = false;
        delete this.attributes_sort_property['semi_open_kitchen'];
      }
    }
    if ($event.value === 'OUTDOOR_KITCHEN') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Outdoor_kitchen === false ||
        !this.property_filter.Outdoor_kitchen
      ) {
        this.property_filter.Outdoor_kitchen = true;
        this.attributes_sort_property['outdoor_kitchen'] = true;
      } else if (this.property_filter.Outdoor_kitchen === true) {
        this.property_filter.Outdoor_kitchen = false;
        delete this.attributes_sort_property['outdoor_kitchen'];
      }
    }
    if ($event.value === 'TERRACE') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Terrace === false ||
        !this.property_filter.Terrace
      ) {
        this.property_filter.Terrace = true;
        this.attributes_sort_property['terrace'] = true;
      } else if (this.property_filter.Terrace === true) {
        this.property_filter.Terrace = false;
        delete this.attributes_sort_property['terrace'];
      }
    }
    if ($event.value === 'GARDEN') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Garden === false ||
        !this.property_filter.Garden
      ) {
        this.property_filter.Garden = true;
        this.attributes_sort_property['garden'] = true;
      } else if (this.property_filter.Garden === true) {
        this.property_filter.Garden = false;
        delete this.attributes_sort_property['garden'];
      }
    }
    if ($event.value === 'PRIVATE_PARKING') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Private_parking === false ||
        !this.property_filter.Private_parking
      ) {
        this.property_filter.Private_parking = true;
        this.attributes_sort_property['private_parking'] = true;
      } else if (this.property_filter.Private_parking === true) {
        this.property_filter.Private_parking = false;
        delete this.attributes_sort_property['private_parking'];
      }
    }
    if ($event.value === 'GARAGE') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Garage === false ||
        !this.property_filter.Garage
      ) {
        this.property_filter.Garage = true;
        this.attributes_sort_property['garage'] = true;
      } else if (this.property_filter.Garage === true) {
        this.property_filter.Garage = false;
        delete this.attributes_sort_property['garage'];
      }
    }
    if ($event.value === 'LOCK_UP_GARAGE') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Lock_up_garage === false ||
        !this.property_filter.Lock_up_garage
      ) {
        this.property_filter.Lock_up_garage = true;
        this.attributes_sort_property['lock_up_garage'] = true;
      } else if (this.property_filter.Lock_up_garage === true) {
        this.property_filter.Lock_up_garage = false;
        delete this.attributes_sort_property['lock_up_garage'];
      }
    }
    if ($event.value === 'LIFE_ANNUITY') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Life_annuity === false ||
        !this.property_filter.Life_annuity
      ) {
        this.property_filter.Life_annuity = true;
        this.attributes_sort_property['life_annuity'] = true;
      } else if (this.property_filter.Life_annuity === true) {
        this.property_filter.Life_annuity = false;
        delete this.attributes_sort_property['life_annuity'];
      }
    }
    if ($event.value === 'EXCLUDE_LIFE_ANNUITIES') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Exclude_life_annuities === false ||
        !this.property_filter.Exclude_life_annuities
      ) {
        this.property_filter.Exclude_life_annuities = true;
        this.attributes_sort_property['exclude_life_annuities'] = true;
      } else if (this.property_filter.Exclude_life_annuities === true) {
        this.property_filter.Exclude_life_annuities = false;
        delete this.attributes_sort_property['exclude_life_annuities'];
      }
    }
    if ($event.value === 'ELIGIBLE_PINEL_LAW') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Eligible_pinel_law === false ||
        !this.property_filter.Eligible_pinel_law
      ) {
        this.property_filter.Eligible_pinel_law = true;
        this.attributes_sort_property['eligible_pinel_law'] = true;
      } else if (this.property_filter.Eligible_pinel_law === true) {
        this.property_filter.Eligible_pinel_law = false;
        delete this.attributes_sort_property['eligible_pinel_law'];
      }
    }
    if ($event.value === 'SENIOR_RESIDENCE') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Senior_residence === false ||
        !this.property_filter.Senior_residence
      ) {
        this.property_filter.Senior_residence = true;
        this.attributes_sort_property['senior_residence'] = true;
      } else if (this.property_filter.Senior_residence === true) {
        this.property_filter.Senior_residence = false;
        delete this.attributes_sort_property['senior_residence'];
      }
    }
    if ($event.value === 'STUDENT_RESIDENCE') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Student_residence === false ||
        !this.property_filter.Student_residence
      ) {
        this.property_filter.Student_residence = true;
        this.attributes_sort_property['student_residence'] = true;
      } else if (this.property_filter.Student_residence === true) {
        this.property_filter.Student_residence = false;
        delete this.attributes_sort_property['student_residence'];
      }
    }
    if ($event.value === 'EXCLUDE_RESIDENCES') {
      console.log($event, this.property_filter);
      if (
        this.property_filter.Exclude_residences === false ||
        !this.property_filter.Exclude_residences
      ) {
        this.property_filter.Exclude_residences = true;
        this.attributes_sort_property['exclude_residences'] = true;
      } else if (this.property_filter.Exclude_residences === true) {
        this.property_filter.Exclude_residences = false;
        delete this.attributes_sort_property['exclude_residences'];
      }
    }
    if ($event.type === 'ENERGETIC_CLASS_A') {
      if (!this.property_filter.Energetic_class_A) {
        this.property_filter.Energetic_class_A = $event.item_value;
        this.attributes_sort_property[$event.type] = 'A';
      } else if (this.property_filter.Energetic_class_A === $event.item_value) {
        this.property_filter.Energetic_class_A = false;
        delete this.attributes_sort_property[$event.type];
      }
      console.log(this.attributes_sort_property);
    }
    if ($event.type === 'ENERGETIC_CLASS_B') {
      if (!this.property_filter.Energetic_class_B) {
        this.property_filter.Energetic_class_B = $event.item_value;
        this.attributes_sort_property[$event.type] = 'B';
      } else if (this.property_filter.Energetic_class_B === $event.item_value) {
        this.property_filter.Energetic_class_B = false;
        delete this.attributes_sort_property[$event.type];
      }
      console.log(this.attributes_sort_property);
    }
    if ($event.type === 'ENERGETIC_CLASS_C') {
      if (!this.property_filter.Energetic_class_C) {
        this.property_filter.Energetic_class_C = $event.item_value;
        this.attributes_sort_property[$event.type] = 'C';
      } else if (this.property_filter.Energetic_class_C === $event.item_value) {
        this.property_filter.Energetic_class_C = false;
        delete this.attributes_sort_property[$event.type];
      }
      console.log(this.attributes_sort_property);
    }
    if ($event.type === 'ENERGETIC_CLASS_D') {
      if (!this.property_filter.Energetic_class_D) {
        this.property_filter.Energetic_class_D = $event.item_value;
        this.attributes_sort_property[$event.type] = 'D';
      } else if (this.property_filter.Energetic_class_D === $event.item_value) {
        this.property_filter.Energetic_class_D = false;
        delete this.attributes_sort_property[$event.type];
      }
      console.log(this.attributes_sort_property);
    }
    if ($event.type === 'ENERGETIC_CLASS_E') {
      if (!this.property_filter.Energetic_class_E) {
        this.property_filter.Energetic_class_E = $event.item_value;
        this.attributes_sort_property[$event.type] = 'E';
      } else if (this.property_filter.Energetic_class_E === $event.item_value) {
        this.property_filter.Energetic_class_E = false;
        delete this.attributes_sort_property[$event.type];
      }
      console.log(this.attributes_sort_property);
    }
    if ($event.type === 'ENERGETIC_CLASS_F') {
      if (!this.property_filter.Energetic_class_F) {
        this.property_filter.Energetic_class_F = $event.item_value;
        this.attributes_sort_property[$event.type] = 'F';
      } else if (this.property_filter.Energetic_class_F === $event.item_value) {
        this.property_filter.Energetic_class_F = false;
        delete this.attributes_sort_property[$event.type];
      }
      console.log(this.attributes_sort_property);
    }
    if ($event.type === 'ENERGETIC_CLASS_VIERGE') {
      if (!this.property_filter.Energetic_class_VIERGE) {
        this.property_filter.Energetic_class_VIERGE = $event.item_value;
        this.attributes_sort_property[$event.type] = 'VIERGE';
      } else if (
        this.property_filter.Energetic_class_VIERGE === $event.item_value
      ) {
        this.property_filter.Energetic_class_VIERGE = false;
        delete this.attributes_sort_property[$event.type];
      }
      console.log(this.attributes_sort_property);
    }
    if ($event.type === 'GAS_EMISSIONS_A') {
      if (!this.property_filter.Gas_emissions_A) {
        this.property_filter.Gas_emissions_A = $event.item_value;
        this.attributes_sort_property[$event.type] = 'A';
      } else if (this.property_filter.Gas_emissions_A === $event.item_value) {
        this.property_filter.Gas_emissions_A = false;
        delete this.attributes_sort_property[$event.type];
      }
      console.log(this.attributes_sort_property);
    }
    if ($event.type === 'GAS_EMISSIONS_B' && $event.item_value !== '') {
      if (!this.property_filter.Gas_emissions_B) {
        this.property_filter.Gas_emissions_B = $event.item_value;
        this.attributes_sort_property[$event.type] = 'B';
      } else if (this.property_filter.Gas_emissions_B === $event.item_value) {
        this.property_filter.Gas_emissions_B = false;
        delete this.attributes_sort_property[$event.type];
      }
      console.log(this.attributes_sort_property);
    }
    if ($event.type === 'GAS_EMISSIONS_C' && $event.item_value !== '') {
      if (!this.property_filter.Gas_emissions_C) {
        this.property_filter.Gas_emissions_C = $event.item_value;
        this.attributes_sort_property[$event.type] = 'C';
      } else if (this.property_filter.Gas_emissions_C === $event.item_value) {
        this.property_filter.Gas_emissions_C = false;
        delete this.attributes_sort_property[$event.type];
      }
      console.log(this.attributes_sort_property);
    }
    if ($event.type === 'GAS_EMISSIONS_D' && $event.item_value !== '') {
      if (!this.property_filter.Gas_emissions_D) {
        this.property_filter.Gas_emissions_D = $event.item_value;
        this.attributes_sort_property[$event.type] = 'D';
      } else if (this.property_filter.Gas_emissions_D === $event.item_value) {
        this.property_filter.Gas_emissions_D = false;
        delete this.attributes_sort_property[$event.type];
      }
      console.log(this.attributes_sort_property);
    }
    if ($event.type === 'GAS_EMISSIONS_E' && $event.item_value !== '') {
      if (!this.property_filter.Gas_emissions_E) {
        this.property_filter.Gas_emissions_E = $event.item_value;
        this.attributes_sort_property[$event.type] = 'E';
      } else if (this.property_filter.Gas_emissions_E === $event.item_value) {
        this.property_filter.Gas_emissions_E = false;
        delete this.attributes_sort_property[$event.type];
      }
      console.log(this.attributes_sort_property);
    }
    if ($event.type === 'GAS_EMISSIONS_F' && $event.item_value !== '') {
      if (!this.property_filter.Gas_emissions_F) {
        this.property_filter.Gas_emissions_F = $event.item_value;
        this.attributes_sort_property[$event.type] = 'F';
      } else if (this.property_filter.Gas_emissions_F === $event.item_value) {
        this.property_filter.Gas_emissions_F = false;
        delete this.attributes_sort_property[$event.type];
      }
      console.log(this.attributes_sort_property);
    }
    if ($event.type === 'GAS_EMISSIONS_G' && $event.item_value !== '') {
      if (!this.property_filter.Gas_emissions_G) {
        this.property_filter.Gas_emissions_G = $event.item_value;
        this.attributes_sort_property[$event.type] = 'G';
      } else if (this.property_filter.Gas_emissions_G === $event.item_value) {
        this.property_filter.Gas_emissions_G = false;
        delete this.attributes_sort_property[$event.type];
      }
      console.log(this.attributes_sort_property);
    }
    if ($event.type === 'GAS_EMISSIONS_VIERGE' && $event.item_value !== '') {
      if (!this.property_filter.Gas_emissions_VIERGE) {
        this.property_filter.Gas_emissions_VIERGE = $event.item_value;
        this.attributes_sort_property[$event.type] = 'VIERGE';
      } else if (
        this.property_filter.Gas_emissions_VIERGE === $event.item_value
      ) {
        this.property_filter.Gas_emissions_VIERGE = false;
        delete this.attributes_sort_property[$event.type];
      }
      console.log(this.attributes_sort_property);
    }
    this.checkCountFilterAttributes(this.attributes_sort_property);
    this.propertyFilter(this.attributes_sort_property);
    console.log('attributes_sort_property', this.attributes_sort_property);
  }

  /**
   * FILTER FUNCTION
   * propertyByType
   */
  public propertyByType($event) {
    console.log('EVENT_TYPE', $event);
    this.array_To_Send = [];
    if ($event.type === 'MAISON_VILLA') {
      this.property_filter.House = $event.type;
      this.attributes_sort_property['property_type'] = $event.type;
      this.switchStep();
    }
    if ($event.type === 'APPARTEMENT') {
      this.property_filter.Appartment = $event.type;
      this.attributes_sort_property['property_type'] = $event.type;
      this.switchStep();
    }
    if ($event.type === 'BUREAUX') {
      this.property_filter.Office = $event.type;
      this.attributes_sort_property['property_type'] = $event.type;
      this.switchStep();
    }
    if ($event.type === 'TERRAIN') {
      this.property_filter.Land = $event.type;
      this.attributes_sort_property['property_type'] = $event.type;
      this.switchStep();
    }
    this.result_count = 0;
    this.filtered_array = this.array_property.filter((element) => {
      return element.Property_type.includes(
        this.attributes_sort_property['property_type']
      );
    });
    this.array_To_Send = this.filtered_array;
    console.log('FILTERED_ARRAY', this.filtered_array);
  }

  /**
   * propertyFilter function
   * This function sort the property array to return only the property item who match with the filters attributes
   */
  public propertyFilter($event) {
    this.second_filtered_array = this.propertyService.filterProperty(
      this.second_filtered_array,
      this.filtered_array,
      this.attributes_sort_property
    );
    this.array_To_Send = this.second_filtered_array;
    console.log(this.second_filtered_array);
    console.log(this.filtered_array);
    if (!this.second_filtered_array.length) {
      this.hasError = true;
    }
  }

  /**
   * capitalize only the first letter of the string and lowercase for the rest.
   */
  public capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  /**
   * GET Energetic Class value and Gaz emission Level value
   */
  public getItemValue($event) {
    if ($event.type === 'ENERGETIC_CLASS' && $event.item_value !== '') {
      console.log($event, this.property_filter);
      this.property_filter.Energetic_class = $event.item_value;
    }
    if ($event.type === 'GAS_EMISSIONS' && $event.item_value !== '') {
      console.log($event, this.property_filter);
      this.property_filter.Gas_emissions = $event.item_value;
    }
  }

  /**
   * PASS FILTER DATA (property array filter and filter attributes) TO SEARCH_PROPERTY COMPONENT
   */
  public getFilteredProperty() {
    this.dataService.data['PROPERTY_ARRAY_FILTERED'] = this.array_To_Send;
    this.localStorageService.setFilterAttributes(this.attributes_sort_property);
    const data = {
      filter_attributes: this.attributes_sort_property,
      current_step: 'PROPERTY_LIST',
      array_filter: this.array_To_Send,
    };
    console.log('DATA', data);

    this.IsClicked.emit(data);
    // window.location.reload();
  }
}
