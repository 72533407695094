<div>
  <div class="flex justify-center mt-16 mb-6">
    <img src="assets/icons/svg/blue-file.svg" class="h-36" />
  </div>
  <app-text
    textClasses="txt-bold-gray font-extrabold text-center text-sm mb-3 mx-6"
    text="Faire une offre"
  ></app-text>
  <app-text
    textClasses="txt-bold-gray text-sm mb-3 text-center mx-6"
    text="Que souhaitez-vous faire ?"
  ></app-text>
  <div class="flex flex-col gap-0">
    <label for="offer" class="cursor-pointer">
      <!-- <app-primary-button
        [text]="
          offer_file ? 'Modifier mon offre écrite' : 'Déposer une offre écrite'
        "
        buttonClasses="bg-bold-blue text-white buttons items-center shadow-md w-max mx-auto mb-3"
        firstImgSrc="assets/icons/svg/arrow-right.svg"
        [disabled]="true"
      ></app-primary-button> -->
      <div
        class="
          bg-bold-blue
          flex
          gap-3
          text-white
          items-center
          shadow-md
          w-max
          mx-auto
          mb-3
          rounded-full
          p-3
        "
      >
        <p class="">
          {{
            offer_file
              ? "Modifier mon offre écrite"
              : "Déposer une offre écrite"
          }}
        </p>
        <img src="assets/icons/svg/arrow-right.svg" class="h-2" />
      </div>
      <input
        type="file"
        id="offer"
        class="hidden"
        accept=".pdf"
        (change)="makeOffer($event)"
      />
    </label>
    <app-primary-button
      text="Consulter mon offre écrite"
      [buttonClasses]="
        offer_file
          ? 'bg-white txt-light-blue border-light-blue border buttons items-center shadow-md w-max mx-auto mb-3'
          : 'hidden'
      "
      firstImgSrc="assets/icons/svg/arrow-right-blue.svg"
      (IsClicked)="getFile($event)"
      [disabled]="false"
    ></app-primary-button>
    <app-primary-button
      text="Supprimer mon offre écrite"
      [buttonClasses]="
        offer_file
          ? 'bg-white txt-pink border-pink border buttons items-center shadow-md w-max mx-auto mb-3'
          : 'hidden'
      "
      firstImgSrc="assets/icons/svg/arrow-right-red.svg"
      (IsClicked)="removeFile($event)"
      [disabled]="false"
    ></app-primary-button>
  </div>
</div>
