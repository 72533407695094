<div [@fade] class="w-full mx-auto bg-white overflow-auto pb-6">
  <div class="border-b border-solid pb-2">
    <div class="mx-6">
      <app-secondary-header
        (IsClicked)="back()"
        headerClasses=" pt-4 flex justify-between items-center mb-2"
      ></app-secondary-header>
      <div class="mx-auto text-center">
        <p class="font-bold text-xl txt-bold-gray">Mes rendez-vous</p>
      </div>
    </div>
  </div>
  <div *ngIf="current_step === 'RDV'" class="mx-6">
    <!-- Back button -->
    <app-back-button
      (IsClicked)="back()"
      buttonClasses="flex gap-3 mt-16 items-center"
      imgSrc="assets/icons/svg/back-button-blue.svg"
      imgClasses="h-6"
      text="Mes rendez-vous"
      txtClasses="txt-light-blue text-sm"
    ></app-back-button>
    <app-text
      textClasses="txt-bold-gray font-bold my-6"
      [text]="subtitle"
    ></app-text>
    <!-- Appointments list -->
    <div class="mx-6 mt-6 mb-8 flex flex-col gap-4">
      <div
        *ngIf="!appointment.length && !hasError"
        class="mx-auto"
        id="waiting_data"
      >
        <mat-spinner diameter="50"></mat-spinner>
      </div>
      <!-- No Result text -->
      <p
        *ngIf="!appointment.length && hasError"
        class="text-center txt-light-blue mt-8"
      >
        Aucun résultats
      </p>
    </div>
    <div *ngIf="appointment.length" class="mx-auto">
      <app-appointment-card
        *ngFor="let app of appointment"
        [appointment_date]="app.Appointment_date"
        [appointment_hour]="app.Appointment_hour"
        [appointment_status]="app.Appointment_status"
        [appointment_type]="app.Appointment_type"
        [appointment_reason]="app.Appointment_reason"
        [appointment_user]="app.User_id"
        [appointment_guest]="app.Appointment_guest"
      >
      </app-appointment-card>
    </div>
  </div>
  <!-- RESPONSE -->
  <div *ngIf="current_step === 'RDV' && response_required" class="mx-6">
    <app-text
      textClasses="txt-bold-gray font-bold my-6"
      text="Réponse de votre interlocuteur"
    ></app-text>
    <div class="grid grid-cols-3 gap-3">
      <app-primary-card
        imgPath="assets/icons/svg/valid-button.svg"
        imgClasses="h-16 mx-auto"
        cardClasses="bg-light-blue rounded-xl text-center mx-auto py-3 cursor-pointer"
        subtitle="Accepter"
        subtitleClasses="txt-bold-gray bg-white rounded-md p-1 font-semibold text-sm mt-8 w-4/5 mx-auto cursor-pointer"
        (IsClicked)="switchStep('ACCEPTER')"
      ></app-primary-card>
      <app-primary-card
        imgPath="assets/icons/svg/invalid-button.svg"
        imgClasses="h-16 mx-auto"
        cardClasses="bg-pink rounded-xl text-center mx-auto py-3 cursor-pointer"
        subtitle="Refuser"
        subtitleClasses="txt-bold-gray bg-white rounded-md p-1 font-semibold text-sm mt-8 w-4/5 mx-auto cursor-pointer"
        (IsClicked)="switchStep('REFUSER')"
      ></app-primary-card>
      <app-primary-card
        imgPath="assets/icons/svg/more-button.svg"
        imgClasses="h-16 mx-auto"
        cardClasses="bg-percian-gray rounded-xl text-center mx-auto py-3 cursor-pointer"
        subtitle="Contre proposition"
        subtitleClasses="txt-bold-gray bg-white rounded-md p-1 font-semibold text-sm mt-3 w-4/5 mx-auto cursor-pointer"
        (IsClicked)="switchStep('CONTRE_PROPOSITION')"
      ></app-primary-card>
    </div>
  </div>
  <div *ngIf="current_step === 'RDV' && !response_required" class="mx-6 mt-6">
    <div class="grid grid-cols-2 gap-3">
      <app-primary-card
        *ngIf="appointmentModel.Appointment_status == 'à venir'"
        imgPath="assets/icons/svg/valid-button.svg"
        imgClasses="h-16 mx-auto"
        cardClasses="bg-light-blue rounded-xl h-full text-center mx-auto py-3 cursor-pointer"
        subtitle="Rendez-vous accepté"
        subtitleClasses="txt-bold-gray bg-white rounded-md p-1 font-semibold text-sm mt-8 w-4/5 mx-auto cursor-pointer"
      ></app-primary-card>
      <app-primary-card
        *ngIf="appointmentModel.Appointment_status == 'annulé'"
        imgPath="assets/icons/svg/invalid-button.svg"
        imgClasses="h-16 mx-auto"
        cardClasses="bg-pink rounded-xl h-full text-center mx-auto py-3 cursor-pointer"
        subtitle="Rendez-vous refusé"
        subtitleClasses="txt-bold-gray bg-white rounded-md p-1 font-semibold text-sm mt-8 w-4/5 mx-auto cursor-pointer"
      ></app-primary-card>
      <app-primary-card
        *ngIf="appointmentModel.Appointment_status == 'annulé'"
        imgPath="assets/icons/svg/more-button.svg"
        imgClasses="h-16 mx-auto"
        cardClasses="bg-percian-gray h-full rounded-xl text-center mx-auto py-3 cursor-pointer"
        subtitle="Proposer nouveau rendez-vous"
        subtitleClasses="txt-bold-gray bg-white rounded-md p-1 font-semibold text-sm mt-8 w-4/5 mx-auto cursor-pointer"
        (IsClicked)="switchStep('CONTRE_PROPOSITION')"
      ></app-primary-card>
    </div>
  </div>

  <div *ngIf="current_step === 'RDV_CONFIRME'">
    <app-appointment-confirmation
      *ngFor="let app of appointment"
      title="Rendez-vous accepté !"
      subtitle="Save the date !"
      colorClasses="bg-light-blue"
      [isOnCreatedAppointmentPage]="false"
      [appointment_date]="app.Appointment_date"
      [appointment_hour]="app.Appointment_hour"
      [appointment_reason]="app.Appointment_reason"
      appointment_status="CONFIRMATION"
      [appointment_type]="app.Appointment_type"
      (IsClicked)="acceptAppointment()"
      (Clicked)="switchStep('RDV')"
    ></app-appointment-confirmation>
  </div>

  <div *ngIf="current_step === 'RDV_ANNULE'">
    <app-appointment-confirmation
      *ngFor="let app of appointment"
      title="Rendez-vous refusé !"
      subtitle="Pensez à faire une contre-proposition !"
      colorClasses="bg-pink"
      [isOnCreatedAppointmentPage]="false"
      [appointment_date]="app.Appointment_date"
      [appointment_hour]="app.Appointment_hour"
      [appointment_reason]="app.Appointment_reason"
      appointment_status="ANNULE"
      [appointment_type]="app.Appointment_type"
      (IsClicked)="cancelAppointment()"
      (Clicked)="switchStep('RDV')"
    ></app-appointment-confirmation>
  </div>

  <div *ngIf="current_step === 'CONTRE_PROPOSITION'" class="mx-3">
    <app-appointment-date
      daysClasses="mx-3"
      hoursClasses="mx-0"
      hoursTitleClasses="mx-3"
      (SelectTime)="getAppointmentDate($event)"
      [appointmentType]="appointmentModel.AppointmentType"
      [pro_id]="userId"
      [available_days_array]="available_days_array"
      [user_has_parameters]="user_has_parameters"
    ></app-appointment-date>

    <div class="mx-auto flex justify-center mb-7">
      <app-primary-button
        (IsClicked)="appointmentCounterProposal()"
        text="Enregistrer"
        buttonClasses="bg-light-blue text-white items-center w-40"
        firstImgSrc="assets/icons/svg/arrow-right.svg"
        secondImgSrc="assets/icons/svg/arrow-right.svg"
        [disabled]="disabled"
      ></app-primary-button>
    </div>
  </div>

  <div *ngIf="current_step === 'CONTRE_PROPOSITION_CONFIRMATION'">
    <app-appointment-confirmation
      *ngFor="let app of appointment"
      title="Contre-proposition envoyée !"
      subtitle="Votre interlocuteur devrait bientôt vous répondre !"
      colorClasses="bg-percian-gray"
      [isOnCreatedAppointmentPage]="false"
      [appointment_date]="app.Appointment_date"
      [appointment_hour]="app.Appointment_hour"
      [appointment_reason]="app.Appointment_reason"
      appointment_status="CONTRE_PROPOSITION_CONFIRMATION"
      [appointment_type]="app.Appointment_type"
      (IsClicked)="cancelAppointment()"
      (Clicked)="switchStep('RDV')"
    ></app-appointment-confirmation>
  </div>
</div>
