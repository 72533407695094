<div class="min-w-min-w-15">
  <div class="flex justify-between my-3 items-center">
    <app-text
      textClasses="txt-bold-gray text-sm font-extrabold mx-auto"
      [text]="appointment_date"
    ></app-text>
    <div class="border border-percian-blue p-1 rounded-md">
      <app-text
        textClasses="txt-light-blue mx-3 text-sm text-center font-light"
        [text]="appointment_hour"
      ></app-text>
    </div>
  </div>
  <div class="flex gap-3 items-center p-3 relative bg-ligth-gray rounded-xl">
    <img src="assets/icons/svg/logo-1.svg" class="h-16 mx-auto" />
    <div class="flex flex-col gap-3 justify-start">
      <app-text
        textClasses="bg-light-blue p-1 rounded-md txt-bold-blue text-xs font-extrabold mx-auto"
        [text]="appointment_type"
      ></app-text>
      <app-text
        textClasses="txt-bold-gray text-sm font-extrabold mx-auto"
        [text]="appointment_reason"
      ></app-text>
    </div>
    <img
      (click)="onClick($event)"
      src="assets/icons/svg/next-button-1.svg"
      class="h-6 absolute bottom-3 -right-4 cursor-pointer"
    />
  </div>
</div>
