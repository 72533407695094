import { ParametersService } from './../../shared/services/parameters.service';
import { LocalStorageService } from './../../shared/services/local-storage.service';
import { AppointmentModel } from './../../shared/models/appointment-model';
import { CustomPopupComponent } from './../../shared/components/custom-popup/custom-popup.component';
import { LoaderPopupComponent } from './../../shared/components/loader-popup/loader-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentService } from 'src/app/shared/services/appointment.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { Location } from '@angular/common';
import { Component, OnInit, Renderer2, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-single-appointment',
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
    ]),
  ],
  templateUrl: './single-appointment.component.html',
  styleUrls: ['./single-appointment.component.scss'],
})
export class SingleAppointmentComponent implements OnInit {
  @Input() appointment: AppointmentModel[] = [];
  @Input() appointmentModel = new AppointmentModel();
  @Input() appointment_type:string;
  @Input() userId: string;
  public hasError: boolean = false;
  public subtitle: string;
  public status: string;
  public type: string;
  public current_step: string;
  public appointment_date: any;
  public appointment_hour: any;
  public disabled: boolean = true;
  public savedData = {
    APPEL_TELEPHONIQUE: {},
    VISITE_DU_BIEN_PAR_UN_EXPERT: {},
    VISITE_DU_BIEN_AVEC_UN_ACHETEUR: {},
    RENDEZ_VOUS_EN_AGENCE: {},
  };
  @Input() available_days_array: any[] = [];
  @Input() params: any;
  @Input() user_has_parameters: boolean;
  public response_required:boolean = true;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private appointmentService: AppointmentService,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private router: Router,
    private parametersService: ParametersService
  ) {
    this.userId = this.localStorageService.getCurrentUser().id;
    this.renderer.setStyle(document.body, 'background-color', 'white');
    this.current_step = 'RDV';
  }

  ngOnInit(): void {
    this.appointmentModel.ID = this.route.snapshot.paramMap.get('id');
    this.getUserAppointment();
  }

  /**
   * checkAppointmentStatus
   */
  public checkAppointmentStatus($event) {
    switch ($event) {
      case 'à venir':
        this.response_required = false;
        break;
      case 'en cours':
        this.response_required = false;
        break;
      case 'effectué':
        this.response_required = false;
        break;
      case 'annulé':
        this.response_required = false;
        break;
      default:
        break;
    }
  }

  /**
   * back
   */
  public back() {
    this.location.back();
  }

  /**
   * getUserAppointment
   */
  public getUserAppointment() {
    this.appointmentService
      .getUserAppointment(this.appointmentModel.ID)
      .subscribe((res) => {
        console.log(res);
        if (res && res.data.getUserAppointment) {
          const appointment = new AppointmentModel().mapToModel(
            res.data.getUserAppointment
          );
          // Set subtitle switch
          switch (appointment.Appointment_status) {
            case 'en attente':
              this.subtitle = 'Rendez-vous en attente de confirmation';
              break;
            case 'à venir':
              this.subtitle = 'Rendez-vous à venir';
              break;
            case 'en cours':
              this.subtitle = 'Rendez-vous en cours';
              break;
            case 'effectué':
              this.subtitle = 'Rendez-vous effectué';
              break;
            case 'annulé':
              this.subtitle = 'Rendez-vous annulé';
              break;
            default:
              break;
          }
          this.appointmentModel.AppointmentType = appointment.AppointmentType;
          this.appointmentModel.Appointment_status = appointment.Appointment_status;
          this.checkAppointmentStatus(this.appointmentModel.Appointment_status)
          this.appointment.push(appointment);
          console.log('APPOINTMENT', this.appointment);
          console.log('APPOINTMENT', this.appointmentModel.AppointmentType);

          this.hasError = false;
          this.getParametersByUser();
        } else if (res.errors.message) {
          this.hasError = true;
          console.log(res.errors.message);
        } else {
          this.hasError = true;
          console.log('ERREUR SERVEUR');
        }
      });
  }

  /**
   * switchStep
   */
  public switchStep($event) {
    switch ($event) {
      case 'ACCEPTER':
        this.current_step = 'RDV_CONFIRME';
        break;
      case 'REFUSER':
        this.current_step = 'RDV_ANNULE';
        break;
      case 'CONTRE_PROPOSITION':
        console.log("this.APPOINTMENT", this.appointmentModel);
        this.current_step = 'CONTRE_PROPOSITION';
        break;
      case 'CONTRE_PROPOSITION_CONFIRMATION':
        this.current_step = 'CONTRE_PROPOSITION_CONFIRMATION';
        break;
      case 'RDV':
        this.current_step = 'RDV';
        break;
      default:
        break;
    }
  }

  /**
   * acceptAppointment
   */
  public acceptAppointment() {
    this.appointmentModel.Appointment_status = 'A_VENIR';
    console.log('appointment', this.appointmentModel);

    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Confirmation en cours ... ',
        isLoader: true,
      },
    });
    this.appointmentService
      .editAppointmentStatus(this.appointmentModel)
      .subscribe((res: any) => {
        console.log(res);
        if (res && res.data) {
          const successDialog = this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'success',
              title: 'Rendez-vous',
              message: 'Votre rendez-vous a bien été confirmé.',
            },
          });
          successDialog.afterClosed().subscribe(() => {
            this.dialog.closeAll();
            this.router.navigate([`/appointment/${this.appointmentModel.ID}`]);
            this.current_step = 'RDV';
          });
        } else if (res.errors.message) {
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: "Une erreur s'est produite",
              message:
                'Nous sommes désolé, un problème est survenu. Veuillez réessayer ultérieurement.',
            },
          });
        } else {
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: "Une erreur s'est produite",
              message:
                'Nous sommes désolé, un problème est survenu. Veuillez réessayer ultérieurement.',
            },
          });
        }
      });
  }

  /**
   * canceledAppointment
   */
  public cancelAppointment() {
    this.appointmentModel.Appointment_status = 'ANNULE';
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Annulation en cours ... ',
        isLoader: true,
      },
    });
    this.appointmentService
      .editAppointmentStatus(this.appointmentModel)
      .subscribe((res: any) => {
        console.log(res);
        if (res && res.data) {
          const successDialog = this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'success',
              title: 'Rendez-vous',
              message: 'Votre rendez-vous a bien été annulé.',
            },
          });
          successDialog.afterClosed().subscribe(() => {
            this.dialog.closeAll();
            this.router.navigate([`/appointment/${this.appointmentModel.ID}`]);
            this.current_step = 'RDV';
          });
        } else if (res.errors.message) {
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: "Une erreur s'est produite",
              message:
                'Nous sommes désolé, un problème est survenu. Veuillez réessayer ultérieurement.',
            },
          });
        } else {
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: "Une erreur s'est produite",
              message:
                'Nous sommes désolé, un problème est survenu. Veuillez réessayer ultérieurement.',
            },
          });
        }
      });
  }

  /**
   * appointmentCounterProposal
   */
  public appointmentCounterProposal() {
    if (!this.disabled) {
      this.current_step = 'CONTRE_PROPOSITION_CONFIRMATION';
      
    }
  }

  /**
   * getParametersByUser
   */
  public async getParametersByUser() {
    await this.parametersService
      .getParametersByProfessionnel(this.userId)
      .subscribe((res) => {
        console.log(res);

        if (res.data && res.data.ParameterByUser) {
          console.log(res);
          console.log(
            'APPOINTMENT_TYPE',
            this.appointmentModel.Appointment_type
          );
          this.params = res.data.ParameterByUser.items;

          Array.from(this.params).forEach((param: any) => {
            if (
              param.appointment_type === this.appointmentModel.AppointmentType
            ) {
              this.savedData.APPEL_TELEPHONIQUE = param;
              this.available_days_array = param.days.split('-');
            } else if (
              param.appointment_type === this.appointmentModel.AppointmentType
            ) {
              this.savedData.VISITE_DU_BIEN_PAR_UN_EXPERT = param;
              this.available_days_array = param.days.split('-');
            } else if (
              param.appointment_type === this.appointmentModel.AppointmentType
            ) {
              this.savedData.VISITE_DU_BIEN_AVEC_UN_ACHETEUR = param;
              this.available_days_array = param.days.split('-');
            } else if (
              param.appointment_type === this.appointmentModel.AppointmentType
            ) {
              this.savedData.RENDEZ_VOUS_EN_AGENCE = param;
              this.available_days_array = param.days.split('-');
            }
          });
          console.log('this.savedData', this.savedData);
          console.log('this.available_days_array', this.available_days_array);
          this.user_has_parameters = true;
        } else {
          this.user_has_parameters = false;
        }
      });
  }

  /**
   * getAppointmentDate
   */
  public getAppointmentDate($event) {
    console.log('EVENT', $event);
    this.appointment_date = $event.date;
    this.appointment_hour = $event.hour;
    this.appointmentModel.Appointment_date = new Date(
      $event.datetime
    ).getTime();
    if ($event.date && $event.hour) {
      this.disabled = false;
    }
  }
}
