<div class="bg-light-blue py-6 h-full">
  <div [@fade] class="mx-6 relative">
    <img src="assets/icons/svg/weBuy.svg" class="h-12 mb-12" />
    <app-title
      titleClasses="text-white text-left w-4/6"
      text="Bienvenue sur Wiby"
    ></app-title>
    <app-text
      textClasses="txt-bold-blue text-left w-4/6 mb-16"
      text="Sélectionnez votre profil"
    ></app-text>
    <img src="assets/icons/svg/avatar-1.svg" class="h-52 mx-auto mb-16" />
    <div class="mx-auto mb-7 flex flex-col gap-6 justify-center">
      <app-primary-button
        (IsClicked)="onClick('PROFESSIONNEL')"
        text="Je suis un agent immobilier"
        buttonClasses="bg-super-light-blue border-white border-2 text-white text-center items-center w-full py-3"
        paragraphClasses="w-full"
        [icon_visibility]="false"
        [disabled]="false"
      ></app-primary-button>
      <app-primary-button
        (IsClicked)="onClick('PARTICULIER')"
        text="Je suis un client"
        buttonClasses="bg-super-light-blue border-white border-2 text-white text-center items-center w-full py-3"
        paragraphClasses="w-full"
        [icon_visibility]="false"
        [disabled]="false"
      ></app-primary-button>
    </div>
  </div>
</div>
