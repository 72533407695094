<div [@fade] class="mx-6">
  <div class="mt-32">
    <app-img
      imgPath="assets/icons/svg/search.svg"
      imgClasses="h-32 my-auto mx-auto bg-extra-light-blue rounded-xl p-3"
    ></app-img>
  </div>

  <app-text-inner-html
    textClasses="text-center my-6 txt-bold-gray"
    content="Vous souhaitez trouver votre perle rare ? <br /> C'est par ici !!"
  ></app-text-inner-html>
  <div class="mt-3 mx-auto flex justify-center mb-6 w-full">
    <app-primary-button
      (IsClicked)="switchStep('SEARCH_FILTER')"
      text="Commencer"
      buttonClasses="bg-bold-blue text-white"
      [disabled]="false"
      [icon_visibility]="true"
      firstImgSrc="assets/icons/svg/arrow-right.svg"
    ></app-primary-button>
  </div>
</div>
