import { LocalStorageService } from './../../shared/services/local-storage.service';
import { CustomPopupComponent } from 'src/app/shared/components/custom-popup/custom-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { LoaderPopupComponent } from 'src/app/shared/components/loader-popup/loader-popup.component';
import { AppointmentModel } from './../../shared/models/appointment-model';
import { Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { AppointmentService } from 'src/app/shared/services/appointment.service';

@Component({
  selector: 'app-create-appointment',
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
    ]),
  ],
  templateUrl: './create-appointment.component.html',
  styleUrls: ['./create-appointment.component.scss'],
})
export class CreateAppointmentComponent implements OnInit {
  public step: string;
  public current_step: string;
  @Input() appointmentModel = new AppointmentModel();
  public property_id: string;
  public disabled = true;
  public stepNumber: Number;
  public stepsArray: any[] = [];
  public appointment_type: any;
  public appointment_hour: any;
  public appointment_date: any;
  public appointment_guest: any;
  public external_speaker_email: any;
  @Input() userId: string;
  @Input() property_IsSelected: boolean = false;
  @Input() available_days_array: any[] = [];

  constructor(
    private router: Router,
    private appointmentService: AppointmentService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService
  ) {
    this.current_step = 'PROPERTY_LIST';
    this.stepNumber = 1;
    this.stepsArray = [
      {
        step: 1,
      },
      {
        step: 2,
      },
      {
        step: 3,
      },
      {
        step: 4,
      },
      {
        step: 5,
      },
      {
        step: 6,
      },
    ];
    this.appointmentModel.User_id =
      this.localStorageService.getCurrentUser().id;
    console.log('TYPE', this.appointment_type);
  }

  ngOnInit(): void {}

  /**
   * BACK FUNCTION
   */
  public back() {
    this.router.navigate(['/appointment']);
  }

  /**
   * switchStep
   */
  public switchStep() {
    switch (this.step) {
      case 'APPOINTMENT_TYPE':
        this.current_step = 'APPOINTMENT_TYPE';
        this.stepNumber = 2;
        break;
      case 'APPOINTMENT_REASON':
        this.current_step = 'APPOINTMENT_REASON';
        this.stepNumber = 3;
        break;
      case 'APPOINTMENT_DATE':
        this.current_step = 'APPOINTMENT_DATE';
        this.stepNumber = 4;
        break;
      case 'GUEST_CHOICE':
        this.current_step = 'GUEST_CHOICE';
        this.stepNumber = 5;
        break;
      case 'APPOINTMENT_CONFIRMATION':
        this.current_step = 'APPOINTMENT_CONFIRMATION';
        this.stepNumber = 6;
        break;
      default:
        break;
    }
  }

  /**
   * getPropertyId
   */
  public getPropertyId($event) {
    console.log('EVENT', $event);
    this.appointmentModel.Property_id = $event.property_id;
    this.userId = $event.user_id;
    this.property_IsSelected = $event.IsSelected;
    if (this.appointmentModel.Property_id && this.userId) {
      this.disabled = false;
      this.step = 'APPOINTMENT_TYPE';
    }
  }

  /**
   * getAppointmentType
   */
  public getAppointmentType($event) {
    console.log('EVENT', $event);
    this.appointmentModel.Appointment_type = $event.appointment_type.id;
    this.appointment_type = $event.appointment_type.model;
    this.available_days_array = $event.available_days_array;
    if (this.appointmentModel.Appointment_type) {
      this.disabled = false;
      this.step = 'APPOINTMENT_REASON';
    }
  }

  /**
   * getAppointmentReason
   */
  public getAppointmentReason($event) {
    console.log('EVENT', $event);
    this.appointmentModel.Appointment_reason = $event.model;
    if (this.appointmentModel.Appointment_reason) {
      this.disabled = false;
      this.step = 'APPOINTMENT_DATE';
    }
  }

  /**
   * getAppointmentDate
   */
  public getAppointmentDate($event) {
    console.log('EVENT', $event);
    this.appointment_date = $event.date;
    this.appointment_hour = $event.hour;
    this.appointmentModel.Appointment_date = new Date(
      $event.datetime
    ).getTime();
    if (this.appointment_date && this.appointment_hour) {
      this.disabled = false;
      this.step = 'GUEST_CHOICE';
    }
  }

  /**
   * getAppointmentGuest
   */
  public getAppointmentGuest($event) {
    console.log('EVENT', $event);
    this.appointment_guest = $event.user;
    this.appointmentModel.Appointment_guest = $event.user_id;
    if (this.appointmentModel.Appointment_guest) {
      this.disabled = false;
      this.step = 'APPOINTMENT_CONFIRMATION';
    }
  }

  /**
   * getAppointmentGuest
   */
  public getExternalSpeaker($event) {
    console.log('EVENT', $event);
    this.external_speaker_email = $event.external_speaker_email;
  }

  /**
   * createAppointment function
   */
  public createAppointment() {
    console.log('createAppointment', this.appointmentModel);

    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Création en cours ... ',
        isLoader: true,
      },
    });
    this.appointmentService
      .addAppointment(this.appointmentModel)
      .subscribe((res: any) => {
        console.log(res);
        if (res && res.data) {
          const successDialog = this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'success',
              title: 'Rendez-vous',
              message: 'Nous avons bien enregistré votre demande.',
            },
          });
          successDialog.afterClosed().subscribe(() => {
            this.dialog.closeAll();
            this.router.navigate(['/appointment']);
          });
        } else if (res.errors.message) {
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: "Une erreur s'est produite",
              message:
                'Nous sommes désolé, un problème est survenu. Veuillez réessayer ultérieurement.',
            },
          });
        } else {
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: "Une erreur s'est produite",
              message:
                'Nous sommes désolé, un problème est survenu. Veuillez réessayer ultérieurement.',
            },
          });
        }
      });
  }
}
