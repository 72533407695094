import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-txt',
  templateUrl: './input-txt.component.html',
  styleUrls: ['./input-txt.component.scss'],
})
export class InputTxtComponent implements OnInit {
  @Input() label_name = '';
  @Input() label_text = '';
  @Input() icon_visibility = true;
  @Input() labelClasses = '';
  @Input() inputClasses = '';
  @Input() blockClasses = '';
  @Input() model: any;
  @Input() imgPathClasses = '';
  @Input() img_visibility = false;
  @Input() imgPath = '';
  @Output() IsClicked = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  onClick($event: any) {
    this.IsClicked.emit($event);
  }
}
