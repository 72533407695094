<div [@fade] class="w-full mx-auto bg-white overflow-auto pb-6">
  <div class="border-b border-solid pb-2">
    <div class="mx-6">
      <app-secondary-header
        (IsClicked)="back()"
        headerClasses=" pt-4 flex justify-between items-center mb-2"
      ></app-secondary-header>
      <div class="mx-auto text-center">
        <p class="font-bold text-xl txt-bold-gray">Rechercher un bien</p>
      </div>
    </div>
  </div>
  <app-search-welcome
    *ngIf="current_step === 'MAIN_SEARCH'"
    (IsClicked)="getCurrentStep($event)"
  ></app-search-welcome>
  <app-filter
    *ngIf="current_step === 'SEARCH_FILTER'"
    [header_visible]="false"
    (IsClicked)="getFilterAttributes($event)"
  ></app-filter>
</div>
