import { AppointmentModel } from './../../shared/models/appointment-model';
import { PropertyModel } from './../../shared/models/property-model';
import { LocalStorageService } from './../../shared/services/local-storage.service';
import { DataService } from 'src/app/shared/services/data.service';
import { environment } from 'src/environments/environment';
import { PropertyService } from 'src/app/shared/services/property.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  Renderer2,
} from '@angular/core';

@Component({
  selector: 'app-property-list',
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
    ]),
  ],
  templateUrl: './property-list.component.html',
  styleUrls: ['./property-list.component.scss'],
})
export class PropertyListComponent implements OnInit {
  public property_list_type: any = 'Biens en vente';
  public sortingTypeArray: Array<Object> = [
    { name: 'Biens en vente' },
    { name: 'Biens en location' },
  ];
  public service_type_sort: string;
  public service_type: string = 'VENTE';
  public array_property: PropertyModel[] = [];
  public prefix_url: string = environment.cloudfront_url;
  public index = 0;
  @Input() array_filtered = [];
  public hasError: boolean = false;
  public current_step: string = 'MAIN';
  @Output() IsClicked = new EventEmitter<any>();
  @Input() filter_attributes: any;
  @Input() filter_attributes_length: Number;
  public favorites_property_array: any[] = [];
  public user_id: string;

  constructor(
    private router: Router,
    private propertyService: PropertyService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private renderer: Renderer2
  ) {
    this.user_id = this.localStorageService.getCurrentUser().id;
    this.renderer.setStyle(document.body, 'background-color', '#ffff');
    this.filter_attributes = this.localStorageService.getFilterAttributes();
    this.service_type_sort = this.route.snapshot.paramMap.get('service_type');
  }

  ngOnInit(): void {
    console.log(
      'PROPERTY_ARRAY_FILTERED',
      this.dataService.data.PROPERTY_ARRAY_FILTERED
    );
    console.log('filter_attributes', this.filter_attributes);
    this.listAllPublishedProperty();
    this.checkCountFilterAttributes(this.filter_attributes);
    this.listFavoritesPropertys();
  }

  /**
   * back function => return to Home
   */
  public back() {
    switch (this.service_type) {
      case 'VENTE':
        this.service_type = 'sale';
        break;
      case 'LOCATION':
        this.service_type = 'rent';
        break;
      default:
        break;
    }
    // this.router.navigate([`/search-property/${this.service_type}`]);
    this.router.navigate([`/home`]);
  }

  /**
   * checkCountFilterAttributes
   */
  public checkCountFilterAttributes(obj) {
    this.filter_attributes_length = Object.keys(obj).length;
    console.log('LENGTH: ', this.filter_attributes_length);
    return this.filter_attributes_length;
  }

  /**
   * listProperty by service type (rent or sale)
   */
  public async listPropertyByServiceType($service_type) {
    switch ($service_type) {
      case 'Biens en vente':
        this.service_type = 'VENTE';
        this.service_type_sort = 'sale';
        this.listAllPublishedProperty();
        break;
      case 'Biens en location':
        this.service_type = 'LOCATION';
        this.service_type_sort = 'rent';
        this.listAllPublishedProperty();
        break;
      default:
        break;
    }
  }

  /**
   * switchStep Function
   */
  public switchStep() {
    switch (this.current_step) {
      case 'MAIN':
        this.current_step = 'MAIN';
        break;
      case 'FILTERS':
        this.current_step = 'FILTERS';
        break;
      default:
        break;
    }
  }

  /**
   * filter page function
   */
  public filterPage() {
    this.current_step = 'FILTERS';
    this.dataService.data['PROPERTY_ARRAY'] = this.array_filtered;
  }

  /**
   * sort Property function
   * This function return sale or rent property
   */
  public sortProperty() {
    this.array_filtered = this.array_property.filter((element) => {
      return element.Service_type.includes(this.service_type);
    });
  }

  /**
   * Return all published property
   */
  public async listAllPublishedProperty() {
    // Always empty video array before run the function
    this.array_property = [];

    // IF array filtered data exist and array filtered data LENGTH ===> GET IT and return
    if (this.dataService.data.PROPERTY_ARRAY_FILTERED) {
      this.dataService.data.PROPERTY_ARRAY_FILTERED;
      this.dataService.data.PROPERTY_ARRAY_FILTERED.forEach((element) => {
        this.array_filtered.push(element);
      });
    } else {
      // Else return all published property by service type IF FILTER ATTRIBUTES (store in localstorage) ARRAY IS EMPTY
      try {
        this.propertyService.getAllPublishedProperty().subscribe((res) => {
          console.log(res);
          if (res && res.data.getAllPublishedProperty) {
            console.log(res);

            const data = res.data.getAllPublishedProperty;
            data.forEach((element) => {
              const propertyModel = new PropertyModel().mapToModel(element);
              console.log('propertyModel', propertyModel);
              for (let i = 0; i < this.favorites_property_array.length; i++) {
                console.log(this.favorites_property_array[i].property_id , propertyModel.ID);
                if (
                  this.favorites_property_array[i].property_id ===
                  propertyModel.ID
                ) {
                  console.log('FAVORITE IS TRUE');

                  propertyModel.IsFavorite = true;
                }
              }

              this.array_property.push(propertyModel);
            });
            // If its the last tour of the loop run the sortProperty function
            if (this.filter_attributes_length) {
              this.propertyFilter(this.filter_attributes);
            } else {
              this.sortProperty();
            }
          } else if (res.errors.message) {
            console.log('ERROR: ', res.errors.message);
            this.hasError = true;
          }
        });
      } catch (error) {
        console.log('ERROR: ', error);
        throw new Error(error);
      }
    }
  }

  /**
   * propertyFilter function
   * This function sort the property array to return only the property item who match with the filters attributes
   */
  public propertyFilter($event) {
    this.array_filtered = this.array_property.filter((element) => {
      // SUBTYPE CHECK
      var checkPropertySubType = 1;
      if (
        $event.MAISON_PLAIN_PIEDS ||
        $event.MAISON_A_ETAGE ||
        $event.MAISON_AVEC_COMBLE ||
        $event.MAISON_AVEC_SOUS_SOL ||
        $event.DUPLEX ||
        $event.TRIPLEX ||
        $event.ROOFTOP ||
        $event.BUREAU_EN_OPEN_SPACE ||
        $event.BUREAU_APPARTEMENT ||
        $event.BUREAU_EN_COWORKING ||
        $event.TERRAIN_CONSTRUCTIBLE_PARTICULIER ||
        $event.TERRAIN_CONSTRUCTIBLE_PROFESSIONNEL ||
        $event.TERRAIN_AGRICOLE
      ) {
        checkPropertySubType =
          element.Property_subtype.includes($event.MAISON_PLAIN_PIEDS) ||
          element.Property_subtype.includes($event.MAISON_A_ETAGE) ||
          element.Property_subtype.includes($event.MAISON_AVEC_COMBLE) ||
          element.Property_subtype.includes($event.MAISON_AVEC_SOUS_SOL) ||
          element.Property_subtype.includes($event.DUPLEX) ||
          element.Property_subtype.includes($event.TRIPLEX) ||
          element.Property_subtype.includes($event.ROOFTOP) ||
          element.Property_subtype.includes($event.BUREAU_EN_OPEN_SPACE) ||
          element.Property_subtype.includes($event.BUREAU_APPARTEMENT) ||
          element.Property_subtype.includes($event.BUREAU_EN_COWORKING) ||
          element.Property_subtype.includes(
            $event.TERRAIN_CONSTRUCTIBLE_PARTICULIER
          ) ||
          element.Property_subtype.includes(
            $event.TERRAIN_CONSTRUCTIBLE_PROFESSIONNEL
          ) ||
          element.Property_subtype.includes($event.TERRAIN_AGRICOLE);
      }

      // STATE CHECK
      var checkPropertyState = 1;
      if (
        $event.BIEN_ANCIEN ||
        $event.BIEN_NEUF ||
        $event.SANS_TRAVAUX_A_PREVOIR ||
        $event.TRAVAUX_A_PREVOIR ||
        $event.NON_VIABILISE ||
        $event.VIABILISE
      ) {
        checkPropertyState =
          element.Property_state.includes($event.BIEN_ANCIEN) ||
          element.Property_state.includes($event.BIEN_NEUF) ||
          element.Property_state.includes($event.SANS_TRAVAUX_A_PREVOIR) ||
          element.Property_state.includes($event.TRAVAUX_A_PREVOIR) ||
          element.Property_state.includes($event.NON_VIABILISE) ||
          element.Property_state.includes($event.VIABILISE);
      }

      var not_overlooked = 1;
      if ($event.not_overlooked) {
        not_overlooked = element.Not_overlooked.includes($event.not_overlooked);
      }
      var non_adjoining = 1;
      if ($event.non_adjoining) {
        non_adjoining = element.Non_adjoining.includes($event.non_adjoining);
      }
      var overview_sea = 1;
      if ($event.overview_sea) {
        overview_sea = element.Overview_sea.includes($event.overview_sea);
      }
      var sea_view = 1;
      if ($event.sea_view) {
        sea_view = element.Sea_view.includes($event.sea_view);
      }
      var panoramic_view = 1;
      if ($event.panoramic_view) {
        panoramic_view = element.Panoramic_view.includes($event.panoramic_view);
      }
      var furnished = 1;
      if ($event.furnished) {
        furnished = element.Furnished.includes($event.furnished);
      }
      var balcony = 1;
      if ($event.balcony) {
        balcony = element.Balcony.includes($event.balcony);
      }
      var elevator = 1;
      if ($event.elevator) {
        elevator = element.Elevator.includes($event.elevator);
      }
      var caritaker = 1;
      if ($event.caritaker) {
        caritaker = element.Caritaker.includes($event.caritaker);
      }
      var equipped_kitchen = 1;
      if ($event.equipped_kitchen) {
        equipped_kitchen = element.Equipped_kitchen.includes(
          $event.equipped_kitchen
        );
      }
      var fitted_kitchen = 1;
      if ($event.fitted_kitchen) {
        fitted_kitchen = element.Fitted_kitchen.includes($event.fitted_kitchen);
      }
      var outbuilding = 1;
      if ($event.outbuilding) {
        outbuilding = element.Outbuilding.includes($event.outbuilding);
      }
      var manual_gate = 1;
      if ($event.manual_gate) {
        manual_gate = element.Manual_gate.includes($event.manual_gate);
      }
      var electric_gate = 1;
      if ($event.electric_gate) {
        electric_gate = element.Electric_gate.includes($event.electric_gate);
      }
      var industrial_estate = 1;
      if ($event.industrial_estate) {
        industrial_estate = element.Industrial_estate.includes(
          $event.industrial_estate
        );
      }
      var business_district = 1;
      if ($event.business_district) {
        business_district = element.Business_district.includes(
          $event.business_district
        );
      }
      var zfu = 1;
      if ($event.zfu) {
        zfu = element.Zfu.includes($event.zfu);
      }
      var separate_toilet = 1;
      if ($event.separate_toilet) {
        separate_toilet = element.Separate_toilet.includes(
          $event.separate_toilet
        );
      }
      var swimming_pool = 1;
      if ($event.swimming_pool) {
        swimming_pool = element.Swimming_pool.includes($event.swimming_pool);
      }
      var air_conditioning = 1;
      if ($event.air_conditioning) {
        air_conditioning = element.Air_conditioning.includes(
          $event.air_conditioning
        );
      }
      var fibre = 1;
      if ($event.fibre) {
        fibre = element.Fibre.includes($event.fibre);
      }
      var electric_vehicule_charging = 1;
      if ($event.electric_vehicule_charging) {
        electric_vehicule_charging =
          element.Electric_vehicule_charging.includes(
            $event.electric_vehicule_charging
          );
      }
      var digital_lock = 1;
      if ($event.digital_lock) {
        digital_lock = element.Digital_lock.includes($event.digital_lock);
      }
      var disabled_access = 1;
      if ($event.disabled_access) {
        disabled_access = element.Disabled_access.includes(
          $event.disabled_access
        );
      }
      var intercom = 1;
      if ($event.intercom) {
        intercom = element.Intercom.includes($event.intercom);
      }
      var separate_kitchen = 1;
      if ($event.separate_kitchen) {
        separate_kitchen = element.Separate_kitchen.includes(
          $event.separate_kitchen
        );
      }
      var open_kitchen = 1;
      if ($event.open_kitchen) {
        open_kitchen = element.Open_kitchen.includes($event.open_kitchen);
      }
      var semi_open_kitchen = 1;
      if ($event.semi_open_kitchen) {
        semi_open_kitchen = element.Semi_open_kitchen.includes(
          $event.semi_open_kitchen
        );
      }
      var outdoor_kitchen = 1;
      if ($event.outdoor_kitchen) {
        outdoor_kitchen = element.Outdoor_kitchen.includes(
          $event.outdoor_kitchen
        );
      }
      var terrace = 1;
      if ($event.terrace) {
        terrace = element.Terrace.includes($event.terrace);
      }
      var garden = 1;
      if ($event.garden) {
        garden = element.Garden.includes($event.garden);
      }
      var private_parking = 1;
      if ($event.private_parking) {
        private_parking = element.Private_parking.includes(
          $event.private_parking
        );
      }
      var garage = 1;
      if ($event.garage) {
        garage = element.Garage.includes($event.garage);
      }
      var lock_up_garage = 1;
      if ($event.lock_up_garage) {
        lock_up_garage = element.Lock_up_garage.includes($event.lock_up_garage);
      }
      var life_annuity = 1;
      if ($event.life_annuity) {
        life_annuity = element.Life_annuity.includes($event.life_annuity);
      }
      var eligible_pinel_law = 1;
      if ($event.eligible_pinel_law) {
        eligible_pinel_law = element.Eligible_pinel_law.includes(
          $event.eligible_pinel_law
        );
      }
      var senior_residence = 1;
      if ($event.senior_residence) {
        senior_residence = element.Senior_residence.includes(
          $event.senior_residence
        );
      }
      var student_residence = 1;
      if ($event.student_residence) {
        student_residence = element.Student_residence.includes(
          $event.student_residence
        );
      }
      var min_budget = true;
      if ($event.MIN_BUDGET) {
        min_budget = element.Price >= $event.MIN_BUDGET;
      }
      var max_budget = true;
      if ($event.MAX_BUDGET) {
        max_budget = element.Price <= $event.MAX_BUDGET;
      }
      var min_area = true;
      if ($event.MIN_AREA) {
        min_area = element.Total_area >= $event.MIN_AREA;
      }
      var max_area = true;
      if ($event.MAX_AREA) {
        max_area = element.Total_area <= $event.MAX_AREA;
      }
      var min_land_area = true;
      if ($event.MIN_LAND_AREA) {
        min_land_area = element.Total_area >= $event.MIN_LAND_AREA;
      }
      var max_land_area = true;
      if ($event.MAX_LAND_AREA) {
        max_land_area = element.Total_area <= $event.MAX_LAND_AREA;
      }
      var min_pieces_number = true;
      if ($event.MIN_PIECES_NUMBER) {
        min_pieces_number =
          element.Number_of_pieces >= $event.MIN_PIECES_NUMBER;
      }
      var max_pieces_number = true;
      if ($event.MAX_PIECES_NUMBER) {
        max_pieces_number =
          element.Number_of_pieces <= $event.MAX_PIECES_NUMBER;
      }
      var min_bedrooms_number = true;
      if ($event.MIN_BEDROOMS_NUMBER) {
        min_bedrooms_number =
          element.Bedroom_number >= $event.MIN_BEDROOMS_NUMBER;
      }
      var max_bedrooms_number = true;
      if ($event.MAX_BEDROOMS_NUMBER) {
        max_bedrooms_number =
          element.Bedroom_number <= $event.MAX_BEDROOMS_NUMBER;
      }
      var min_bathrooms_number = true;
      if ($event.MIN_BATHROOMS_NUMBER) {
        min_bathrooms_number =
          element.Bathroom_number >= $event.MIN_BATHROOMS_NUMBER;
      }
      var max_bathrooms_number = true;
      if ($event.MAX_BATHROOMS_NUMBER) {
        max_bathrooms_number =
          element.Bathroom_number <= $event.MAX_BATHROOMS_NUMBER;
      }
      var min_individual_offices_number = true;
      if ($event.MIN_INDIVIDUAL_OFFICES_NUMBER) {
        min_individual_offices_number =
          element.Individual_office_number >=
          $event.MIN_INDIVIDUAL_OFFICES_NUMBER;
      }
      var max_individual_offices_number = true;
      if ($event.MAX_INDIVIDUAL_OFFICES_NUMBER) {
        max_individual_offices_number =
          element.Individual_office_number <=
          $event.MAX_INDIVIDUAL_OFFICES_NUMBER;
      }
      var min_seats_number = true;
      if ($event.MIN_SEATS_NUMBER) {
        min_seats_number =
          element.Seating_places_number >= $event.MIN_SEATS_NUMBER;
      }
      var max_seats_number = true;
      if ($event.MAX_SEATS_NUMBER) {
        max_seats_number =
          element.Seating_places_number <= $event.MAX_SEATS_NUMBER;
      }
      var min_meeting_rooms_number = true;
      if ($event.MIN_MEETING_ROOMS_NUMBER) {
        min_meeting_rooms_number =
          element.Meeting_room_number >= $event.MIN_MEETING_ROOMS_NUMBER;
      }
      var max_meeting_rooms_number = true;
      if ($event.MAX_MEETING_ROOMS_NUMBER) {
        max_meeting_rooms_number =
          element.Meeting_room_number <= $event.MAX_MEETING_ROOMS_NUMBER;
      }
      return (
        element.Service_type.includes($event.service_type) &&
        element.Property_type.includes($event.property_type) &&
        checkPropertySubType &&
        checkPropertyState &&
        not_overlooked &&
        non_adjoining &&
        overview_sea &&
        sea_view &&
        panoramic_view &&
        furnished &&
        balcony &&
        elevator &&
        caritaker &&
        equipped_kitchen &&
        fitted_kitchen &&
        outbuilding &&
        manual_gate &&
        electric_gate &&
        industrial_estate &&
        business_district &&
        zfu &&
        separate_toilet &&
        swimming_pool &&
        air_conditioning &&
        fibre &&
        electric_vehicule_charging &&
        disabled_access &&
        digital_lock &&
        intercom &&
        separate_kitchen &&
        open_kitchen &&
        semi_open_kitchen &&
        outdoor_kitchen &&
        terrace &&
        garden &&
        private_parking &&
        garage &&
        lock_up_garage &&
        life_annuity &&
        eligible_pinel_law &&
        senior_residence &&
        student_residence &&
        min_budget &&
        max_budget &&
        min_area &&
        max_area &&
        min_land_area &&
        max_land_area &&
        min_pieces_number &&
        max_pieces_number &&
        min_bedrooms_number &&
        max_bedrooms_number &&
        min_bathrooms_number &&
        max_bathrooms_number &&
        min_individual_offices_number &&
        max_individual_offices_number &&
        min_seats_number &&
        max_seats_number &&
        min_meeting_rooms_number &&
        max_meeting_rooms_number
      );
    });
    console.log(this.array_filtered);
    console.log(this.array_property);
  }

  /**
   * removeAttributesFilter
   */
  public removeAttributesFilter() {
    this.localStorageService.removeFilterAttributes();
    window.location.reload();
  }

  /**
   * GET Property
   * This function get property id selected and current service type to create the url
   */
  public getProperty($property_id) {
    this.router.navigate([
      `property/${this.service_type_sort}/${$property_id}`,
    ]);
  }

  /**
   * addOrRemoveFavoriteProperty
   */
  public addOrRemoveFavoriteProperty($property) {
    console.log('favorite-property', $property);
    this.dataService.data['FAVORITE_PROPERTY'] = $property;
    if (this.user_id !== $property.User_id) {
      this.router.navigate([
        `/favorite-property/${this.service_type_sort}/${$property.User_id}/${$property.ID}`,
      ]);
    } else {
      // Apparition popup pour informer user qu'il ne peut pas liker son propre bien afin de ne pas créer de pollution de base de données
    }
  }

  /**
   * listFavoritesPropertys
   */
  public listFavoritesPropertys() {
    this.propertyService.getAllUserFavoritesProperty(this.user_id).subscribe((res) => {
      console.log('res', res);
      if (res && res.data.listFavoritesPropertys.items) {
        this.favorites_property_array = res.data.listFavoritesPropertys.items;
      }
    });
  }

   /**
   * getFilterAttributes
   */
    public getFilterAttributes($event) {
      console.log($event);
      this.current_step = 'MAIN';
      this.array_filtered = $event.array_filter;
      this.filter_attributes = $event.filter_attributes;
      this.checkCountFilterAttributes(this.filter_attributes)
      console.log('FILTERED_ARRAY', this.array_filtered);
    }
}
