import { environment } from './../../../environments/environment';
export class PropertyModel {
  ID: any;
  User_id: any;
  Reference: any;
  Address: any;
  Postal_code: any;
  City: any;
  Property_type: any;
  Property_subtype: any;
  Property_state: any;
  Service_type: any;
  Description: any;
  Total_area: any;
  Number_of_pieces: any;
  Energetic_class: any;
  Energetic_class_A: any;
  Energetic_class_B: any;
  Energetic_class_C: any;
  Energetic_class_D: any;
  Energetic_class_E: any;
  Energetic_class_F: any;
  Energetic_class_G: any;
  Energetic_class_VIERGE: any;
  Gas_emissions: any;
  Gas_emissions_A: any;
  Gas_emissions_B: any;
  Gas_emissions_C: any;
  Gas_emissions_D: any;
  Gas_emissions_E: any;
  Gas_emissions_F: any;
  Gas_emissions_G: any;
  Gas_emissions_VIERGE: any;
  Bedroom_number: any;
  Digital_lock: any; //Digicode
  Intercom: any; //Interphone
  Separate_toilet: any;
  Fibre: any;
  Electric_vehicule_charging: any;
  Swimming_pool: any;
  Disabled_access: any; //Accès Handicapé
  Separate_kitchen: any;
  Air_conditioning: any;
  Sea_view: any; //Vue mer
  Overview_sea: any; //Aperçu mer
  Not_overlooked: any; //Sans vis-à-vis
  Balcony: any;
  Terrace: any;
  Garden: any;
  Private_parking: any;
  Garage: any;
  Lock_up_garage: any;
  Photos: string[];
  Price: any;
  State: any;
  Published_date: any;
  CreatedAt: any;
  UpdatedAt: any;
  Amount_of_charges: any;
  Non_adjoining: any;
  Panoramic_view: any;
  Industrial_estate: any;
  Business_district: any;
  Zfu: any;
  Outdoor_area_1: any;
  Outdoor_area_2: any;
  Bathroom_number: any;
  Individual_office_number: any;
  Seating_places_number: any;
  Meeting_room_number: any;
  Open_kitchen: any;
  Semi_open_kitchen: any;
  Outdoor_kitchen: any;
  Global_management: any;
  Rent_quickly: any;
  More_visibility_and_visits: any;
  Estimate_amount_of_rent: any;
  Estimate_area_property: any;
  Estimate_value_property: any;
  Estimate_cost_of_work: any;
  Sell_quickly: any;
  Furnished: any; // Meublé
  Elevator: any;
  Caritaker: any; // Concierge Gardien
  Kitchen_area: any; // Espace cuisine
  Electric_gate: any;
  Manual_gate: any;
  Equipped_kitchen: any; // Cuisine équipée
  Fitted_kitchen: any; // Cuisine aménagée
  Outbuilding: any; // Dépendance
  // SEARCH ATTRIBUTES
  Min_budget: any;
  Max_budget: any;
  House: any;
  Appartment: any;
  Office: any;
  Land: any;
  Place_location: any; // Distance à partir d'un lieu donné
  Single_story_house: any; // Maison plain pieds
  Storey_house: any; // Maison à étage
  House_with_roof: any; // Maison avec comble
  House_with_basement: any; // Maison avec sous-sol
  Duplex: any;
  Triplex: any;
  Rooftop: any;
  Open_space_office: any; // Bureau en open space
  Office_appartment: any; // Bureau appartement
  Coworking_office: any; // Bureau en coworking
  Building_for_private: any; // Terrain constructible pour particulier
  Building_for_professional: any; // Terrain constructible pour pro
  Unserviced: any; // Non visiabilisé
  Serviced: any; // Viabilisé
  Intented_for_agriculture: any; // Destiné à l'agriculture
  New_property: any; // Etat du bien : neuf
  Old_property: any; // Etat du bien : ancien
  No_work_required: any; // Sans travaux à prévoir
  With_work_required: any; // Avec travaux à prévoir
  Min_area: any; // Surface habitable minimum
  Max_area: any; // Surface habitable maximum
  Min_land_area: any; // Surface du terrain minimum
  Max_land_area: any; // Surface du terrain maximum
  Min_pieces_number: any; // Nbre de pièces minimum
  Max_pieces_number: any; // Nbre de pièces maximum
  Min_bedrooms_number: any; // Nbre de chambres minimum
  Max_bedrooms_number: any; // Nbre de chambres maximum
  Min_bathrooms_number: any; // Nbre de salles de bains minimum
  Max_bathrooms_number: any; // Nbre de salles de bains maximum
  Min_individual_offices_number: any; // Nbre de bureaux individuel minimum
  Max_individual_offices_number: any; // Nbre de bureaux individuel maximum
  Min_seats_number: any; // Nbre de places assises minimum
  Max_seats_number: any; // Nbre de places assises maximum
  Min_meeting_rooms_number: any; // Nbre de salles de réunion minimum
  Max_meeting_rooms_number: any; // Nbre de salles de réunion maximum
  Photos_required_for_search: any; // Photos obligatoires pour les résultats de la recherche
  Life_annuity: any; // Viagers
  Exclude_life_annuities: any; // Exclure les viagers
  Eligible_pinel_law: any; // Eligible loi pinel
  Senior_residence: any;
  Student_residence: any;
  Exclude_residences: any; // Exclure les résidences
  Not_furnished: any; // Non meublé
  IsFavorite: any;
  Location: any;
  Photos_key: any;

  public checkNewPropertyInput() {
    if (!this.Sell_quickly || this.Sell_quickly == '') {
      this.Sell_quickly = 'false';
    }
    if (!this.Estimate_cost_of_work || this.Estimate_cost_of_work == '') {
      this.Estimate_cost_of_work = 'false';
    }
    if (!this.Estimate_value_property || this.Estimate_value_property == '') {
      this.Estimate_value_property = 'false';
    }
    if (!this.Estimate_area_property || this.Estimate_area_property == '') {
      this.Estimate_area_property = 'false';
    }
    if (!this.Estimate_amount_of_rent || this.Estimate_amount_of_rent == '') {
      this.Estimate_amount_of_rent = 'false';
    }
    if (
      !this.More_visibility_and_visits ||
      this.More_visibility_and_visits == ''
    ) {
      this.More_visibility_and_visits = 'false';
    }
    if (!this.Rent_quickly || this.Rent_quickly == '') {
      this.Rent_quickly = 'false';
    }
    if (!this.Global_management || this.Global_management == '') {
      this.Global_management = 'false';
    }
    if (!this.Electric_gate || this.Electric_gate == '') {
      this.Electric_gate = 'false';
    }
    if (!this.Manual_gate || this.Manual_gate == '') {
      this.Manual_gate = 'false';
    }
    if (!this.Outdoor_kitchen || this.Outdoor_kitchen == '') {
      this.Outdoor_kitchen = 'false';
    }
    if (!this.Semi_open_kitchen || this.Semi_open_kitchen == '') {
      this.Semi_open_kitchen = 'false';
    }
    if (!this.Open_kitchen || this.Open_kitchen == '') {
      this.Open_kitchen = 'false';
    }
    if (!this.Meeting_room_number || this.Meeting_room_number == '') {
      this.Meeting_room_number = 0;
    }
    if (!this.Seating_places_number || this.Seating_places_number == '') {
      this.Seating_places_number = 0;
    }
    if (!this.Individual_office_number || this.Individual_office_number == '') {
      this.Individual_office_number = 0;
    }
    if (!this.Bathroom_number || this.Bathroom_number == '') {
      this.Bathroom_number = 0;
    }
    if (!this.Outdoor_area_2 || this.Outdoor_area_2 == '') {
      this.Outdoor_area_2 = 0;
    }
    if (!this.Outdoor_area_1 || this.Outdoor_area_1 == '') {
      this.Outdoor_area_1 = 0;
    }
    if (!this.Zfu || this.Zfu == '') {
      this.Zfu = 'false';
    }
    if (!this.Business_district || this.Business_district == '') {
      this.Business_district = 'false';
    }
    if (!this.Industrial_estate || this.Industrial_estate == '') {
      this.Industrial_estate = 'false';
    }
    if (!this.Panoramic_view || this.Panoramic_view == '') {
      this.Panoramic_view = 'false';
    }
    if (!this.Non_adjoining || this.Non_adjoining == '') {
      this.Non_adjoining = 'false';
    }
    if (!this.Lock_up_garage || this.Lock_up_garage == '') {
      this.Lock_up_garage = 'false';
    }
    if (!this.Garage || this.Garage == '') {
      this.Garage = 'false';
    }
    if (!this.Private_parking || this.Private_parking == '') {
      this.Private_parking = 'false';
    }
    if (!this.Garden || this.Garden == '') {
      this.Garden = 'false';
    }
    if (!this.Terrace || this.Terrace == '') {
      this.Terrace = 'false';
    }
    if (!this.Equipped_kitchen || this.Equipped_kitchen == '') {
      this.Equipped_kitchen = 'false';
    }
    if (!this.Fitted_kitchen || this.Fitted_kitchen == '') {
      this.Fitted_kitchen = 'false';
    }
    if (!this.Outbuilding || this.Outbuilding == '') {
      this.Outbuilding = 'false';
    }
    if (!this.Balcony || this.Balcony == '') {
      this.Balcony = 'false';
    }
    if (!this.Not_overlooked || this.Not_overlooked == '') {
      this.Not_overlooked = 'false';
    }
    if (!this.Overview_sea || this.Overview_sea == '') {
      this.Overview_sea = 'false';
    }
    if (!this.Sea_view || this.Sea_view == '') {
      this.Sea_view = 'false';
    }
    if (!this.Air_conditioning || this.Air_conditioning == '') {
      this.Air_conditioning = 'false';
    }
    if (!this.Separate_kitchen || this.Separate_kitchen == '') {
      this.Separate_kitchen = 'false';
    }
    if (!this.Disabled_access || this.Disabled_access == '') {
      this.Disabled_access = 'false';
    }
    if (!this.Swimming_pool || this.Swimming_pool == '') {
      this.Swimming_pool = 'false';
    }
    if (!this.Life_annuity || this.Life_annuity == '') {
      this.Life_annuity = 'false';
    }
    if (!this.Eligible_pinel_law || this.Eligible_pinel_law == '') {
      this.Eligible_pinel_law = 'false';
    }
    if (!this.Senior_residence || this.Senior_residence == '') {
      this.Senior_residence = 'false';
    }
    if (!this.Student_residence || this.Student_residence == '') {
      this.Student_residence = 'false';
    }
    if (!this.Furnished || this.Furnished == '') {
      this.Furnished = 'false';
    }
    if (!this.Elevator || this.Elevator == '') {
      this.Elevator = 'false';
    }
    if (!this.Caritaker || this.Caritaker == '') {
      this.Caritaker = 'false';
    }
    if (!this.Kitchen_area || this.Kitchen_area == '') {
      this.Kitchen_area = 'false';
    }
    if (
      !this.Electric_vehicule_charging ||
      this.Electric_vehicule_charging == ''
    ) {
      this.Electric_vehicule_charging = 'false';
    }
    if (!this.Fibre || this.Fibre == '') {
      this.Fibre = 'false';
    }
    if (!this.Separate_toilet || this.Separate_toilet == '') {
      this.Separate_toilet = 'false';
    }
    if (!this.Intercom || this.Intercom == '') {
      this.Intercom = 'false';
    }
    if (!this.Digital_lock || this.Digital_lock == '') {
      this.Digital_lock = 'false';
    }
    if (!this.Bedroom_number || this.Bedroom_number == '') {
      this.Bedroom_number = 0;
    }
    if (!this.Amount_of_charges || this.Amount_of_charges == '') {
      this.Amount_of_charges = 0;
    }
    if (
      !this.Number_of_pieces ||
      this.Number_of_pieces == '' ||
      this.Number_of_pieces == '5+'
    ) {
      this.Number_of_pieces = 5;
    }
    if (this.Total_area === 'Non renseigné') {
      this.Total_area = 0;
    }
    if (this.Price === 'Non renseigné') {
      this.Price = 0;
    }
  }

  public transformPropertyNeeds(data: any): PropertyModel {
    const property = new PropertyModel();
    property.Global_management = this.getBoolean(data.global_management)
      ? 'Gestion globale'
      : null;
    property.Rent_quickly = this.getBoolean(data.rent_quickly)
      ? 'Louer le plus vite possible'
      : null;
    property.More_visibility_and_visits = this.getBoolean(
      data.more_visibility_and_visits
    )
      ? 'Avoir plus de visibilité et de visites'
      : null;
    property.Estimate_amount_of_rent = this.getBoolean(
      data.estimate_amount_of_rent
    )
      ? 'Conseil pour le montant du loyer'
      : null;
    property.Estimate_area_property = this.getBoolean(
      data.estimate_area_property
    )
      ? 'Estimer la superficie du bien'
      : null;
    property.Estimate_value_property = this.getBoolean(
      data.estimate_value_property
    )
      ? 'Estimer la valeur du bien'
      : null;
    property.Estimate_cost_of_work = this.getBoolean(data.estimate_cost_of_work)
      ? 'Estimer le coût des travaux'
      : null;
    property.Sell_quickly = this.getBoolean(data.sell_quickly)
      ? 'Vendre le plus vite possible'
      : null;
    return property;
  }

  public mapToModel(data: any): PropertyModel {
    const propertyModel = new PropertyModel();
    // Date constructor
    var today = new Date(parseInt(data.published_date));
    var day = today.getDate();
    var month = today.toLocaleString('fr-FR', { month: 'long' });
    var time = today.getHours() + ':' + today.getMinutes();
    propertyModel.Published_date = day + ' ' + month + ' à ' + time;

    // Date constructor
    var today = new Date(data.createdAt);
    var day = today.getDate();
    var month = today.toLocaleString('fr-FR', { month: 'long' });
    var time = today.getHours() + ':' + today.getMinutes();
    propertyModel.CreatedAt = day + ' ' + month + ' à ' + time;

    // Replace description function for keep the line break when we display it in front
    propertyModel.Description = data.description.replaceAll('</br>', '\n');

    // Transform data from backend
    switch (data.property_subtype) {
      case 'MAISON_PLAIN_PIEDS':
        propertyModel.Property_subtype = 'Maison plain pieds';
        break;
      case 'MAISON_A_ETAGE':
        propertyModel.Property_subtype = 'Maison à étage';
        break;
      case 'MAISON_AVEC_COMBLE':
        propertyModel.Property_subtype = 'Maison avec comble';
        break;
      case 'MAISON_AVEC_SOUS_SOL':
        propertyModel.Property_subtype = 'Maison avec sous-sol';
        break;
      case 'TERRAIN_AGRICOLE':
        propertyModel.Property_subtype = 'Terrain agricole';
        break;
      case 'DUPLEX':
        propertyModel.Property_subtype = 'Duplex';
        break;
      case 'TRIPLEX':
        propertyModel.Property_subtype = 'Triplex';
        break;
      case 'ROOFTOP':
        propertyModel.Property_subtype = 'Rooftop';
        break;
      case 'BUREAU_EN_OPEN_SPACE':
        propertyModel.Property_subtype = 'Bureau en open space';
        break;
      case 'BUREAU_APPARTEMENT':
        propertyModel.Property_subtype = 'Bureau appartement';
        break;
      case 'BUREAU_EN_COWORKING':
        propertyModel.Property_subtype = 'Bureau en coworking';
        break;
      case 'TERRAIN_CONSTRUCTIBLE_PARTICULIER':
        propertyModel.Property_subtype = 'Terrain constructible particulier';
        break;
      case 'TERRAIN_CONSTRUCTIBLE_PROFESSIONNELLE':
        propertyModel.Property_subtype = 'Terrain constructible professionnel';
        break;
      default:
        //
        break;
    }

    // // Transform price number
    // By adding the toLocaleString method, each three number will be added a space
    propertyModel.Price = data.price.toLocaleString();

    propertyModel.Photos_key = [];
    // Add the prefix cloudfront url to img key for get it in front
    propertyModel.Photos = [];
    for (let i = 0; i < data.photos.length; i++) {
      data.photos[i] = environment.cloudfront_url + data.photos[i];
      propertyModel.Photos.push(data.photos[i]);
    }
    propertyModel.ID = data.id;
    propertyModel.User_id = data.user_id;
    propertyModel.Reference = data.reference;
    propertyModel.Address = data.address;
    propertyModel.Postal_code = data.postal_code;
    propertyModel.City = data.city;
    propertyModel.Property_type = data.property_type;
    propertyModel.Property_state = data.property_state;
    propertyModel.Service_type = data.service_type;
    propertyModel.Total_area = data.total_area;
    propertyModel.Number_of_pieces = data.number_of_pieces;
    propertyModel.Energetic_class = data.energetic_class;
    propertyModel.Gas_emissions = data.gas_emissions;
    propertyModel.Bedroom_number = data.bedroom_number;
    propertyModel.Digital_lock = data.digital_lock;
    propertyModel.Intercom = data.intercom;
    propertyModel.Separate_toilet = data.separate_toilet;
    propertyModel.Fibre = data.fibre;
    propertyModel.Electric_vehicule_charging = data.electric_vehicule_charging;
    propertyModel.Swimming_pool = data.swimming_pool;
    propertyModel.Disabled_access = data.disabled_access;
    propertyModel.Separate_kitchen = data.separate_kitchen;
    propertyModel.Air_conditioning = data.air_conditioning;
    propertyModel.Sea_view = data.sea_view;
    propertyModel.Overview_sea = data.overview_sea;
    propertyModel.Not_overlooked = data.not_overlooked;
    propertyModel.Balcony = data.balcony;
    propertyModel.Terrace = data.terrace;
    propertyModel.Garden = data.garden;
    propertyModel.Private_parking = data.private_parking;
    propertyModel.Garage = data.garage;
    propertyModel.Lock_up_garage = data.lock_up_garage;
    propertyModel.State = data.state;
    propertyModel.UpdatedAt = data.updatedAt;
    propertyModel.Amount_of_charges = data.amount_of_charges;
    propertyModel.Non_adjoining = data.non_adjoining;
    propertyModel.Panoramic_view = data.panoramic_view;
    propertyModel.Industrial_estate = data.industrial_estate;
    propertyModel.Business_district = data.business_district;
    propertyModel.Zfu = data.zfu;
    propertyModel.Outdoor_area_1 = data.outdoor_area_1;
    propertyModel.Outdoor_area_2 = data.outdoor_area_2;
    propertyModel.Bathroom_number = data.bathroom_number;
    propertyModel.Individual_office_number = data.individual_office_number;
    propertyModel.Seating_places_number = data.seating_places_number;
    propertyModel.Meeting_room_number = data.meeting_room_number;
    propertyModel.Open_kitchen = data.open_kitchen;
    propertyModel.Semi_open_kitchen = data.semi_open_kitchen;
    propertyModel.Outdoor_kitchen = data.outdoor_kitchen;
    propertyModel.Global_management = data.global_management;
    propertyModel.Rent_quickly = data.rent_quickly;
    propertyModel.More_visibility_and_visits = data.more_visibility_and_visits;
    propertyModel.Estimate_amount_of_rent = data.estimate_amount_of_rent;
    propertyModel.Estimate_area_property = data.estimate_area_property;
    propertyModel.Estimate_value_property = data.estimate_value_property;
    propertyModel.Estimate_cost_of_work = data.estimate_cost_of_work;
    propertyModel.Sell_quickly = data.sell_quickly;
    propertyModel.Furnished = data.furnished;
    propertyModel.Life_annuity = data.life_annuity;
    propertyModel.Senior_residence = data.senior_residence;
    propertyModel.Student_residence = data.student_residence;
    propertyModel.Eligible_pinel_law = data.eligible_pinel_law;
    propertyModel.Outbuilding = data.outbuilding;
    propertyModel.Elevator = data.elevaator;
    propertyModel.Caritaker = data.caritaker; // Concierge Gardien
    propertyModel.Kitchen_area = data.kitchen_area; // Espace cuisine
    propertyModel.Electric_gate = data.electric_gate;
    propertyModel.Manual_gate = data.manual_gate;
    propertyModel.Equipped_kitchen = data.equipped_kitchen; // Cuisine équipée
    propertyModel.Fitted_kitchen = data.fitted_kitchen; // Cuisine aménagée
    propertyModel.IsFavorite = null;
    propertyModel.Location = null;
    return propertyModel;
  }

  public mapToModelV2(data: any): PropertyModel {
    const propertyModel = new PropertyModel();
    propertyModel.Published_date = data.published_date;
    propertyModel.Description = data.description;
    propertyModel.Property_subtype = data.property_subtype;
    propertyModel.Price = data.price;
    propertyModel.Photos = data.photos;
    propertyModel.ID = data.id;
    propertyModel.User_id = data.user_id;
    propertyModel.Reference = data.reference;
    propertyModel.Address = data.address;
    propertyModel.Postal_code = data.postal_code;
    propertyModel.City = data.city;
    propertyModel.Property_type = data.property_type;
    propertyModel.Property_state = data.property_state;
    propertyModel.Service_type = data.service_type;
    propertyModel.Total_area = data.total_area;
    propertyModel.Number_of_pieces = data.number_of_pieces;
    propertyModel.Energetic_class = data.energetic_class;
    propertyModel.Gas_emissions = data.gas_emissions;
    propertyModel.Bedroom_number = data.bedroom_number;
    propertyModel.Digital_lock = data.digital_lock;
    propertyModel.Intercom = data.intercom;
    propertyModel.Separate_toilet = data.separate_toilet;
    propertyModel.Fibre = data.fibre;
    propertyModel.Electric_vehicule_charging = data.electric_vehicule_charging;
    propertyModel.Swimming_pool = data.swimming_pool;
    propertyModel.Disabled_access = data.disabled_access;
    propertyModel.Separate_kitchen = data.separate_kitchen;
    propertyModel.Air_conditioning = data.air_conditioning;
    propertyModel.Sea_view = data.sea_view;
    propertyModel.Overview_sea = data.overview_sea;
    propertyModel.Not_overlooked = data.not_overlooked;
    propertyModel.Balcony = data.balcony;
    propertyModel.Terrace = data.terrace;
    propertyModel.Garden = data.garden;
    propertyModel.Private_parking = data.private_parking;
    propertyModel.Garage = data.garage;
    propertyModel.Lock_up_garage = data.lock_up_garage;
    propertyModel.State = data.state;
    propertyModel.CreatedAt = data.createdAt;
    propertyModel.UpdatedAt = data.updatedAt;
    propertyModel.Amount_of_charges = data.amount_of_charges;
    propertyModel.Non_adjoining = data.non_adjoining;
    propertyModel.Panoramic_view = data.panoramic_view;
    propertyModel.Industrial_estate = data.industrial_estate;
    propertyModel.Business_district = data.business_district;
    propertyModel.Zfu = data.zfu;
    propertyModel.Outdoor_area_1 = data.outdoor_area_1;
    propertyModel.Outdoor_area_2 = data.outdoor_area_2;
    propertyModel.Bathroom_number = data.bathroom_number;
    propertyModel.Individual_office_number = data.individual_office_number;
    propertyModel.Seating_places_number = data.seating_places_number;
    propertyModel.Meeting_room_number = data.meeting_room_number;
    propertyModel.Open_kitchen = data.open_kitchen;
    propertyModel.Semi_open_kitchen = data.semi_open_kitchen;
    propertyModel.Outdoor_kitchen = data.outdoor_kitchen;
    propertyModel.Global_management = data.global_management;
    propertyModel.Rent_quickly = data.rent_quickly;
    propertyModel.More_visibility_and_visits = data.more_visibility_and_visits;
    propertyModel.Estimate_amount_of_rent = data.estimate_amount_of_rent;
    propertyModel.Estimate_area_property = data.estimate_area_property;
    propertyModel.Estimate_value_property = data.estimate_value_property;
    propertyModel.Estimate_cost_of_work = data.estimate_cost_of_work;
    propertyModel.Sell_quickly = data.sell_quickly;
    propertyModel.Furnished = data.furnished;
    propertyModel.Life_annuity = data.life_annuity;
    propertyModel.Senior_residence = data.senior_residence;
    propertyModel.Student_residence = data.student_residence;
    propertyModel.Eligible_pinel_law = data.eligible_pinel_law;
    propertyModel.Outbuilding = data.outbuilding;
    propertyModel.Elevator = data.elevaator;
    propertyModel.Caritaker = data.caritaker; // Concierge Gardien
    propertyModel.Kitchen_area = data.kitchen_area; // Espace cuisine
    propertyModel.Electric_gate = data.electric_gate;
    propertyModel.Manual_gate = data.manual_gate;
    propertyModel.Equipped_kitchen = data.equipped_kitchen; // Cuisine équipée
    propertyModel.Fitted_kitchen = data.fitted_kitchen; // Cuisine aménagée
    propertyModel.IsFavorite = null;
    return propertyModel;
  }

  public mapData(data: any) {
    this.Address = data.address;
    this.Postal_code = data.postal_code;
    this.City = data.city;
    this.Property_type = data.property_type;
    this.Property_subtype = data.property_subtype;
    this.Property_state = data.property_state;
    this.Total_area = data.total_area;
    this.Number_of_pieces = data.number_of_pieces;
    this.Energetic_class = data.energetic_class;
    this.Gas_emissions = data.gas_emissions;
    this.Bedroom_number = data.bedroom_number;
    this.Digital_lock = this.getBoolean(data.digital_lock);
    this.Intercom = this.getBoolean(data.intercom);
    this.Separate_toilet = this.getBoolean(data.separate_toilet);
    this.Fibre = this.getBoolean(data.fibre);
    this.Electric_vehicule_charging = this.getBoolean(
      data.electric_vehicule_charging
    );
    this.Swimming_pool = this.getBoolean(data.swimming_pool);
    this.Disabled_access = this.getBoolean(data.disabled_access);
    this.Separate_kitchen = this.getBoolean(data.separate_kitchen);
    this.Air_conditioning = this.getBoolean(data.air_conditioning);
    this.Sea_view = this.getBoolean(data.sea_view);
    this.Overview_sea = this.getBoolean(data.overview_sea);
    this.Not_overlooked = this.getBoolean(data.not_overlooked);
    this.Balcony = this.getBoolean(data.balcony);
    this.Terrace = this.getBoolean(data.terrace);
    this.Garden = this.getBoolean(data.garden);
    this.Private_parking = this.getBoolean(data.private_parking);
    this.Garage = this.getBoolean(data.garage);
    this.Lock_up_garage = this.getBoolean(data.lock_up_garage);
    this.Price = data.price;
    this.Amount_of_charges = data.amount_of_charges;
    this.Non_adjoining = this.getBoolean(data.non_adjoining);
    this.Panoramic_view = this.getBoolean(data.panoramic_view);
    this.Industrial_estate = this.getBoolean(data.industrial_estate);
    this.Business_district = this.getBoolean(data.business_district);
    this.Zfu = this.getBoolean(data.zfu);
    this.Outdoor_area_1 = data.outdoor_area_1;
    this.Outdoor_area_2 = data.outdoor_area_2;
    this.Bathroom_number = data.bathroom_number;
    this.Individual_office_number = data.individual_office_number;
    this.Seating_places_number = data.seating_places_number;
    this.Meeting_room_number = data.meeting_room_number;
    this.Open_kitchen = this.getBoolean(data.open_kitchen);
    this.Semi_open_kitchen = this.getBoolean(data.semi_open_kitchen);
    this.Outdoor_kitchen = this.getBoolean(data.outdoor_kitchen);
    this.Furnished = this.getBoolean(data.furnished);
    this.Life_annuity = this.getBoolean(data.life_annuity);
    this.Senior_residence = this.getBoolean(data.senior_residence);
    this.Student_residence = this.getBoolean(data.student_residence);
    this.Eligible_pinel_law = this.getBoolean(data.eligible_pinel_law);
    this.Outbuilding = this.getBoolean(data.outbuilding);
    this.Elevator = this.getBoolean(data.elevator);
    this.Caritaker = this.getBoolean(data.caritaker); // Concierge Gardien
    this.Kitchen_area = this.getBoolean(data.kitchen_area); // Espace cuisine
    this.Electric_gate = this.getBoolean(data.electric_gate);
    this.Manual_gate = this.getBoolean(data.manual_gate);
    this.Equipped_kitchen = this.getBoolean(data.equipped_kitchen); // Cuisine équipée
    this.Fitted_kitchen = this.getBoolean(data.fitted_kitchen); // Cuisine aménagée
  }

  public getBoolean(value) {
    switch (value) {
      case true:
      case 'true':
        return true;
      default:
        return false;
    }
  }
}
