import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-primary-card',
  templateUrl: './primary-card.component.html',
  styleUrls: ['./primary-card.component.scss'],
})
export class PrimaryCardComponent implements OnInit {
  @Input() text = '';
  @Input() textClasses = '';
  @Input() cardClasses = '';
  @Input() imgPath = '';
  @Input() imgClasses = '';
  @Input() subtitleClasses = '';
  @Input() subtitle = '';
  
  @Output() IsClicked = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  onClick($event: any): void {
    this.IsClicked.emit($event);
  }
}
