import { AppointmentModel } from './../models/appointment-model';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  constructor(public http: HttpClient) {}

  public addOrRemoveFavoriteProperty(
    user_id: string,
    property_id: string
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          addOrRemoveFavoriteProperty(
            user_id: "${user_id}", 
            property_id: "${property_id}"
            ) {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public addAppointment(appointmentModel: AppointmentModel): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          addAppointment(
            input: {
              appointment_date: "${appointmentModel.Appointment_date}", 
              appointment_guest: "${appointmentModel.Appointment_guest}", 
              appointment_reason: "${appointmentModel.Appointment_reason}", 
              property_id: "${appointmentModel.Property_id}", 
              status: EN_ATTENTE, 
              type: ${appointmentModel.Appointment_type}, 
              user_id: "${appointmentModel.User_id}"
            }) {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public editAppointmentStatus(
    appointmentModel: AppointmentModel
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          editAppointmentStatus(
            appointment_id: "${appointmentModel.ID}", 
            status: ${appointmentModel.Appointment_status},
            ) {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public appointmentCounterProposal(
    appointmentModel: AppointmentModel
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          appointmentCounterProposal
          (
            appointment_date: "${appointmentModel.Appointment_date}", 
            appointment_id: "${appointmentModel.ID}"
            ) {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public getUserAppointment(appointment_id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        query {
          getUserAppointment(appointment_id: "${appointment_id}") {
            user_id
            type
            status
            id
            property_id
            appointment_reason
            appointment_guest
            appointment_date
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public getAllUserAppointments(user_id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        query {
          getAllUserAppointments(user_id: "${user_id}") {
            id
            appointment_date
            appointment_guest
            appointment_reason
            property_id
            status
            type
          }
        }
        
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public getAllAppointmentsByProperty(property_id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        query {
          getAllAppointmentsByProperty(property_id: "${property_id}") {
            appointment_date
            appointment_guest
            appointment_reason
            id
            property_id
            status
            type
            user_id
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }
}
