import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Renderer2,
} from '@angular/core';

@Component({
  selector: 'app-search-welcome',
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
    ]),
  ],
  templateUrl: './search-welcome.component.html',
  styleUrls: ['./search-welcome.component.scss'],
})
export class SearchWelcomeComponent implements OnInit {
  @Output() IsClicked = new EventEmitter<any>();
  constructor(private renderer: Renderer2) {
    this.renderer.setStyle(document.body, 'background-color', '#ffff');
  }

  ngOnInit(): void {}

  /**
   * switchStep
   */
  public switchStep($current_step) {
    this.IsClicked.emit($current_step);
  }
}
