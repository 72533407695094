<form [formGroup]="myForm">
  <div
    (click)="onClick($event)"
    [ngClass]="[item_id !== property_item ? cardClasses : 'active']"
    [id]="item_id"
    class="cursor-pointer"
  >
    <p
      *ngIf="textClasses !== '' ? 'block' : 'hidden'"
      [ngClass]="textClasses"
      class="pt-6"
    >
      {{ text }}
    </p>
    <img [ngClass]="imgClasses" [src]="imgPath" />
    <p
      *ngIf="subtitleClasses !== '' ? 'block' : 'hidden'"
      [ngClass]="subtitleClasses"
      class="py-6"
    >
      {{ model }}
    </p>
  </div>
</form>
