export class UserModel {
  ID: any;
  Agency_id: any;
  Address_id: any;
  Status: any;
  Firstname: any;
  Lastname: any;
  Phone_number: any;
  Email: any;
  New_email: any;
  Email_verified: any;
  Password: any;
  Password_confirm: any;
  Old_password: any;
  New_password: any;
  Property_list_id: any;
  Otp_code: any;
  User_type: any;
  State: any;
  Address_location: any;
  Postal_code: any;
  City: any;
  Address_type: any;
  Siret: any;
  Agency_name: any;
  Agency_scale_of_fees: any;

  public mapToModel(data: any): UserModel {
    const userModel = new UserModel();
    userModel.ID = data.id;
    userModel.Agency_id = data.agency_id;
    userModel.Address_id = data.address_id;
    switch (data.status) {
      case 'AGENT':
        userModel.Status = 'Agent';
        break;
      case 'ADMINISTRATEUR':
        userModel.Status = 'Administrateur';
        break;
      default:
        break;
    }
    userModel.Firstname = data.firstname;
    userModel.Lastname = data.lastname;
    userModel.Phone_number = data.phone_number;
    userModel.Email = data.email;
    userModel.Email_verified = data.email_verified;
    userModel.Property_list_id = [];
    if (data.property_list_id) {
      data.property_list_id.forEach((element: any) => {
        userModel.Property_list_id.push(element.id);
      });
    }
    userModel.State = data.state;
    userModel.User_type = data.user_type;
    userModel.Address_location = data.address_location;
    userModel.Postal_code = data.postal_code;
    userModel.City = data.city;
    userModel.Address_type = data.address_type;
    userModel.Siret = data.siret;
    userModel.Agency_name = data.agency_name;
    userModel.Agency_scale_of_fees = data.agency_scale_of_fees;
    return userModel;
  }

  transformStatus() {
    if (this.Status == 'Administrateur') {
      this.Status = 'ADMINISTRATEUR';
    } else if (this.Status == 'Agent') {
      this.Status = 'AGENT';
    }
  }

  firstnameValid(): boolean {
    if (this.Firstname !== '' && this.Firstname != null) {
      return true;
    } else {
      return false;
    }
  }
  lastnameValid(): boolean {
    if (this.Lastname !== '' && this.Lastname != null) {
      return true;
    } else {
      return false;
    }
  }
  phoneValid(): boolean {
    if (
      ((this.Phone_number == '' || this.Phone_number == 'Non renseigné') &&
        this.Phone_number != null) ||
      /^(0|\+33|\+596)[1-9]([-. ]?[0-9]{2}){4}$/.test(this.Phone_number)
    ) {
      if (this.Phone_number == '') {
        this.Phone_number = 'Non renseigné';
      }
      return true;
    } else {
      return false;
    }
  }
  emailValid(): boolean {
    if (this.Email !== '' && this.Email != null) {
      return true;
    } else {
      return false;
    }
  }
  passwordValid(): boolean {
    if (this.Password !== '' && this.Password != null) {
      return true;
    } else {
      return false;
    }
  }

  passwordConfirmMatch(): boolean {
    if (
      this.Password_confirm !== '' &&
      this.Password_confirm != null &&
      this.Password_confirm === this.Password
    ) {
      return true;
    } else {
      return false;
    }
  }
}
