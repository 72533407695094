import { PropertyModel } from 'src/app/shared/models/property-model';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  constructor(public http: HttpClient) {}

  /**
   * Add a property
   */
  public addProperty(propertyModel: PropertyModel): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          addProperty(
            input: {
              air_conditioning: "${propertyModel.Air_conditioning}", 
              amount_of_charges: ${propertyModel.Amount_of_charges}, 
              balcony: "${propertyModel.Balcony}", 
              outdoor_area_1: ${propertyModel.Outdoor_area_1}, 
              bathroom_number: ${propertyModel.Bathroom_number}, 
              bedroom_number: ${propertyModel.Bedroom_number}, 
              business_district: "${propertyModel.Business_district}", 
              description: "${propertyModel.Description}", 
              digital_lock: "${propertyModel.Digital_lock}", 
              disabled_access: "${propertyModel.Disabled_access}", 
              electric_vehicule_charging: "${propertyModel.Electric_vehicule_charging}", 
              energetic_class: "${propertyModel.Energetic_class}", 
              estimate_amount_of_rent: "${propertyModel.Estimate_amount_of_rent}", 
              estimate_area_property: "${propertyModel.Estimate_area_property}", 
              estimate_cost_of_work: "${propertyModel.Estimate_cost_of_work}", 
              estimate_value_property: "${propertyModel.Estimate_value_property}", 
              fibre: "${propertyModel.Fibre}", 
              garage: "${propertyModel.Garage}", 
              garden: "${propertyModel.Garden}", 
              gas_emissions: "${propertyModel.Gas_emissions}", 
              global_management: "${propertyModel.Global_management}",  
              id: "${propertyModel.User_id}", 
              individual_office_number: ${propertyModel.Individual_office_number}, 
              industrial_estate: "${propertyModel.Industrial_estate}", 
              intercom: "${propertyModel.Intercom}", 
              lock_up_garage: "${propertyModel.Lock_up_garage}", 
              meeting_room_number: ${propertyModel.Meeting_room_number}, 
              more_visibility_and_visits: "${propertyModel.More_visibility_and_visits}", 
              non_adjoining: "${propertyModel.Non_adjoining}", 
              not_overlooked: "${propertyModel.Not_overlooked}", 
              number_of_pieces: ${propertyModel.Number_of_pieces}, 
              open_kitchen: "${propertyModel.Open_kitchen}", 
              outdoor_kitchen: "${propertyModel.Outdoor_kitchen}", 
              overview_sea: "${propertyModel.Overview_sea}", 
              panoramic_view: "${propertyModel.Panoramic_view}", 
              photos: "${propertyModel.Photos}", 
              price: ${propertyModel.Price}, 
              private_parking: "${propertyModel.Private_parking}", 
              property_state: ${propertyModel.Property_state}, 
              property_subtype: "${propertyModel.Property_subtype}", 
              property_type: "${propertyModel.Property_type}", 
              rent_quickly: "${propertyModel.Rent_quickly}", 
              sea_view: "${propertyModel.Sea_view}", 
              seating_places_number: ${propertyModel.Seating_places_number}, 
              sell_quickly: "${propertyModel.Sell_quickly}", 
              semi_open_kitchen: "${propertyModel.Semi_open_kitchen}", 
              separate_kitchen: "${propertyModel.Separate_kitchen}", 
              separate_toilet: "${propertyModel.Separate_toilet}", 
              service_type: ${propertyModel.Service_type}, 
              state: EN_ATTENTE, 
              swimming_pool: "${propertyModel.Swimming_pool}", 
              terrace: "${propertyModel.Terrace}", 
              outdoor_area_2: ${propertyModel.Outdoor_area_2}, 
              total_area: ${propertyModel.Total_area}, 
              zfu: "${propertyModel.Zfu}"
            }, address: {
              city: "${propertyModel.City}", 
              postal_code: "${propertyModel.Postal_code}"
            }) {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * Return all published property
   */
  public getAllPublishedProperty(): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        query {
          getAllPublishedProperty {
            air_conditioning
            amount_of_charges
            balcony
            bathroom_number
            bedroom_number
            business_district
            city
            createdAt
            description
            digital_lock
            disabled_access
            electric_vehicule_charging
            energetic_class
            estimate_amount_of_rent
            estimate_area_property
            estimate_cost_of_work
            estimate_value_property
            fibre
            garage
            garden
            gas_emissions
            global_management
            id
            individual_office_number
            industrial_estate
            intercom
            lock_up_garage
            meeting_room_number
            more_visibility_and_visits
            non_adjoining
            not_overlooked
            number_of_pieces
            open_kitchen
            outdoor_area_1
            outdoor_area_2
            outdoor_kitchen
            overview_sea
            owner_firstname
            owner_lastname
            panoramic_view
            photos
            postal_code
            price
            private_parking
            property_type
            published_date
            reference
            rent_quickly
            sea_view
            seating_places_number
            sell_quickly
            semi_open_kitchen
            separate_kitchen
            separate_toilet
            service_type
            state
            swimming_pool
            terrace
            total_area
            updatedAt
            zfu
            student_residence
            senior_residence
            property_subtype
            property_state
            outbuilding
            manual_gate
            life_annuity
            kitchen_area
            furnished
            fitted_kitchen
            equipped_kitchen
            eligible_pinel_law
            elevator
            electric_gate
            caritaker
            address
            user_id
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * Return property by id
   */
  public getOneProperty(id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        query {
          getOneProperty(id: "${id}") {
            address
            air_conditioning
            amount_of_charges
            balcony
            bathroom_number
            bedroom_number
            business_district
            caritaker
            city
            createdAt
            description
            digital_lock
            disabled_access
            electric_gate
            electric_vehicule_charging
            elevator
            eligible_pinel_law
            energetic_class
            equipped_kitchen
            estimate_amount_of_rent
            estimate_area_property
            estimate_cost_of_work
            estimate_value_property
            fibre
            fitted_kitchen
            furnished
            garage
            garden
            gas_emissions
            global_management
            id
            individual_office_number
            industrial_estate
            intercom
            kitchen_area
            life_annuity
            lock_up_garage
            manual_gate
            meeting_room_number
            more_visibility_and_visits
            non_adjoining
            not_overlooked
            number_of_pieces
            open_kitchen
            outbuilding
            outdoor_area_1
            outdoor_area_2
            outdoor_kitchen
            overview_sea
            owner_firstname
            owner_lastname
            panoramic_view
            photos
            postal_code
            price
            private_parking
            property_state
            property_subtype
            property_type
            published_date
            reference
            rent_quickly
            sea_view
            seating_places_number
            sell_quickly
            semi_open_kitchen
            senior_residence
            separate_kitchen
            separate_toilet
            service_type
            state
            student_residence
            swimming_pool
            terrace
            total_area
            updatedAt
            user_id
            zfu
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * Return All user property
   */
  public getAllUserProperty(id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        query {
          getAllUserProperty(id: "${id}") {
            address
            air_conditioning
            amount_of_charges
            balcony
            bathroom_number
            bedroom_number
            business_district
            caritaker
            city
            createdAt
            description
            digital_lock
            disabled_access
            electric_gate
            electric_vehicule_charging
            elevator
            eligible_pinel_law
            energetic_class
            equipped_kitchen
            estimate_amount_of_rent
            estimate_area_property
            estimate_cost_of_work
            estimate_value_property
            fibre
            fitted_kitchen
            furnished
            garage
            garden
            gas_emissions
            global_management
            id
            individual_office_number
            industrial_estate
            intercom
            kitchen_area
            life_annuity
            lock_up_garage
            manual_gate
            meeting_room_number
            more_visibility_and_visits
            non_adjoining
            not_overlooked
            number_of_pieces
            open_kitchen
            outbuilding
            outdoor_area_1
            outdoor_area_2
            outdoor_kitchen
            overview_sea
            owner_firstname
            owner_lastname
            panoramic_view
            photos
            postal_code
            price
            private_parking
            property_state
            property_subtype
            property_type
            published_date
            reference
            rent_quickly
            sea_view
            seating_places_number
            sell_quickly
            semi_open_kitchen
            senior_residence
            separate_kitchen
            separate_toilet
            service_type
            state
            student_residence
            swimming_pool
            terrace
            total_area
            updatedAt
            user_id
            zfu
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * Remove property
   */
  public removeProperty(property_id: string, user_id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          removeProperty(
            property_id: "${property_id}", 
            user_id: "${user_id}"
            ) {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * Remove property assets key
   */
  public removePropertyAssetsKey(
    assets_key: string,
    property_id: string
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation  {
          removePropertyAssetsKey(
            assets_key: "${assets_key}", 
            property_id: "${property_id}"
            ) {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * Edit property assets photo
   */
  public editPropertyPhoto(
    property_id: string,
    assets_key: string
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          editPropertyPhoto(
            property_id: "${property_id}", 
            assets_key: "${assets_key}"
            ) {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * Edit property description
   */
  public updatePropertyDescription(
    property_id: string,
    description: string
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          updatePropertyDescription(
            property_id: "${property_id}", 
            description: "${description}"
            ) {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * Edit property
   */
  public editProperty(propertyModel: PropertyModel): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          editProperty(
            address: {
              postal_code: "${propertyModel.Postal_code}", 
              city: "${propertyModel.City}"
            },
            input: {
              id: "${propertyModel.ID}",
              property_type: "${propertyModel.Property_type}",
              property_subtype: "${propertyModel.Property_subtype}",
              property_state: ${propertyModel.Property_state},
              total_area: ${propertyModel.Total_area},
              number_of_pieces: ${propertyModel.Number_of_pieces},
              energetic_class: "${propertyModel.Energetic_class}",
              gas_emissions: "${propertyModel.Gas_emissions}",
              bedroom_number: ${propertyModel.Bedroom_number},
              digital_lock: "${propertyModel.Digital_lock}",
              intercom: "${propertyModel.Intercom}",
              separate_toilet: "${propertyModel.Separate_toilet}",
              fibre: "${propertyModel.Fibre}",
              electric_vehicule_charging: "${propertyModel.Electric_vehicule_charging}",
              swimming_pool: "${propertyModel.Swimming_pool}",
              disabled_access: "${propertyModel.Disabled_access}",
              separate_kitchen: "${propertyModel.Separate_kitchen}",
              air_conditioning: "${propertyModel.Air_conditioning}",
              sea_view: "${propertyModel.Sea_view}",
              overview_sea: "${propertyModel.Overview_sea}",
              not_overlooked: "${propertyModel.Not_overlooked}",
              balcony: "${propertyModel.Balcony}",
              terrace: "${propertyModel.Terrace}",
              garden: "${propertyModel.Garden}",
              private_parking: "${propertyModel.Private_parking}",
              garage: "${propertyModel.Garage}",
              lock_up_garage: "${propertyModel.Lock_up_garage}",
              price: ${propertyModel.Price},
              amount_of_charges: ${propertyModel.Amount_of_charges},
              non_adjoining: "${propertyModel.Non_adjoining}",
              panoramic_view: "${propertyModel.Panoramic_view}",
              industrial_estate: "${propertyModel.Industrial_estate}",
              business_district: "${propertyModel.Business_district}",
              zfu: "${propertyModel.Zfu}",
              outdoor_area_1: ${propertyModel.Outdoor_area_1},
              outdoor_area_2: ${propertyModel.Outdoor_area_2},
              bathroom_number: ${propertyModel.Bathroom_number},
              individual_office_number: ${propertyModel.Individual_office_number},
              seating_places_number: ${propertyModel.Seating_places_number},
              meeting_room_number: ${propertyModel.Meeting_room_number},
              open_kitchen: "${propertyModel.Open_kitchen}",
              semi_open_kitchen: "${propertyModel.Semi_open_kitchen}",
              outdoor_kitchen: "${propertyModel.Outdoor_kitchen}",
              furnished: "${propertyModel.Furnished}",
              life_annuity: "${propertyModel.Life_annuity}",
              senior_residence: "${propertyModel.Senior_residence}",
              student_residence: "${propertyModel.Student_residence}",
              eligible_pinel_law: "${propertyModel.Eligible_pinel_law}",
              outbuilding: "${propertyModel.Outbuilding}",
              elevator: "${propertyModel.Elevator}",
              caritaker: "${propertyModel.Caritaker}",
              kitchen_area: "${propertyModel.Kitchen_area}",
              electric_gate: "${propertyModel.Electric_gate}",
              manual_gate: "${propertyModel.Manual_gate}",
              equipped_kitchen: "${propertyModel.Equipped_kitchen}",
              fitted_kitchen: "${propertyModel.Fitted_kitchen}",
            }
            ) {
            code
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * Return All interested users by one specific property
   */
  public getInterestedUsers(property_id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
          query {
            getInterestedUsers(
              property_id: "${property_id}"
              ) {
              email
              id
              like_status
              user_interested_firstname
              user_interested_lastname
            }
          }
        `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * Add or Remove a like on a property
   */
  public addOrRemoveFavoriteProperty(
    user_id: string,
    like_status: string,
    property_id: string
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          addOrRemoveFavoriteProperty(
            property_id: "${property_id}", 
            user_id: "${user_id}", 
            like_status: ${like_status}) {
            code
          }
        }
        `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * Return the candidate file with all data
   */
  public getCandidateFile(candidate_id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
          query {
            getCandidateFileData(
              candidate_id: "${candidate_id}"
              ) {
              id
              candidate_firstname
              candidate_lastname
              candidate_email
              candidate_phone_number
              conditions_precedent_if_loan
              electricity_bill
              employment_contract
              employment_contract_guarantor
              family_record_book
              kbis_or_extract
              marriage_or_pacs_certificate
              proof_of_identity
              proof_of_identity_guarantor
              proof_of_the_funds_making_up_the_contribution
              rent_receipt_1
              rent_receipt_2
              rent_receipt_3
              rental_investment
              tax_notice
              tax_notice_guarantor
            }
          }
          `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * getAllUserFavoritesProperty
   */
  public getAllUserFavoritesProperty(user_id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        query {
          listFavoritesPropertys(filter: {
            user_id: {eq: "${user_id}"}}) {
            items {
              id
              like_status
              property_id
              user_id
            }
          }
        }
        `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * getFavoriteProperty
   */
  public getFavoriteProperty(
    user_id: string,
    property_id: string
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
      query MyQuery {
        listFavoritesPropertys(filter: {user_id: {eq: "${user_id}"}, property_id: {eq: "${property_id}"}}) {
          items {
            id
            like_status
            property_id
            user_id
          }
        }
      }
      
        `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * Add or Edit a offer
   */
  public addOffer(
    user_id: string,
    file: string,
    filename: string,
    property_id: string,
    old_file_key?: string
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          addOffer(
            property_id: "${property_id}", 
            user_id: "${user_id}", 
            file: "${file}",
            filename: "${filename}",
            old_file_key: "${old_file_key}"
            ) {
            code
          }
        }
        `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * removeFile a offer
   */
  public removeFile(file_key: string, id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          removeOffer(
            id: "${id}", 
            file_key: "${file_key}"
            ) {
            code
          }
        }      
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * getUserOffer
   */
  public getUserOffer(user_id: string, property_id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        query  {
          getUserOffer(
            user_id: "${user_id}", 
            property_id: "${property_id}"
            ) {
            id
            property_id
            user_id
            file
          }
        }
        
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * Add or Edit a Mandate
   */
  public addMandate(
    user_id: string,
    file: string,
    filename: string,
    property_id: string,
    old_file_key?: string
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          addMandate(
            property_id: "${property_id}", 
            user_id: "${user_id}", 
            file: "${file}",
            filename: "${filename}",
            old_file_key: "${old_file_key}"
            ) {
            code
          }
        }
        `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * removeFile Mandate
   */
  public removeMandate(file_key: string, id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          removeMandate(id: "${id}", file_key: "${file_key}") {
            code
          }
        }      
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * getPropertyMandate
   */
  public getPropertyMandate(property_id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        query  {
          getPropertyMandate(property_id: "${property_id}") {
            id
            property_id
            user_id
            file
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * getNeedsForSaleOrRent
   */
  public getNeedsForSaleOrRent(property_id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        query {
          getProperty(id: "${property_id}") {
            global_management
            estimate_area_property
            estimate_cost_of_work
            estimate_value_property
            more_visibility_and_visits
            sell_quickly
            estimate_amount_of_rent
            rent_quickly
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  /**
   * filterProperty
   */
  public filterProperty(
    array_filtered: any,
    array_property: any,
    filter_attributes: any
  ): any {
    return (array_filtered = array_property.filter((data: any) => {
      // SUBTYPE CHECK
      var checkPropertySubType = 1;
      if (
        filter_attributes['MAISON_PLAIN_PIEDS'] ||
        filter_attributes['MAISON_A_ETAGE'] ||
        filter_attributes['MAISON_AVEC_COMBLE'] ||
        filter_attributes['MAISON_AVEC_SOUS_SOL'] ||
        filter_attributes['DUPLEX'] ||
        filter_attributes['TRIPLEX'] ||
        filter_attributes['ROOFTOP'] ||
        filter_attributes['BUREAU_EN_OPEN_SPACE'] ||
        filter_attributes['BUREAU_APPARTEMENT'] ||
        filter_attributes['BUREAU_EN_COWORKING'] ||
        filter_attributes['TERRAIN_CONSTRUCTIBLE_PARTICULIER'] ||
        filter_attributes['TERRAIN_CONSTRUCTIBLE_PROFESSIONNEL'] ||
        filter_attributes['TERRAIN_AGRICOLE']
      ) {
        checkPropertySubType =
          data.Property_subtype.includes(
            filter_attributes['MAISON_PLAIN_PIEDS']
          ) ||
          data.Property_subtype.includes(filter_attributes['MAISON_A_ETAGE']) ||
          data.Property_subtype.includes(
            filter_attributes['MAISON_AVEC_COMBLE']
          ) ||
          data.Property_subtype.includes(
            filter_attributes['MAISON_AVEC_SOUS_SOL']
          ) ||
          data.Property_subtype.includes(filter_attributes['DUPLEX']) ||
          data.Property_subtype.includes(filter_attributes['TRIPLEX']) ||
          data.Property_subtype.includes(filter_attributes['ROOFTOP']) ||
          data.Property_subtype.includes(
            filter_attributes['BUREAU_EN_OPEN_SPACE']
          ) ||
          data.Property_subtype.includes(
            filter_attributes['BUREAU_APPARTEMENT']
          ) ||
          data.Property_subtype.includes(
            filter_attributes['BUREAU_EN_COWORKING']
          ) ||
          data.Property_subtype.includes(
            filter_attributes['TERRAIN_CONSTRUCTIBLE_PARTICULIER']
          ) ||
          data.Property_subtype.includes(
            filter_attributes['TERRAIN_CONSTRUCTIBLE_PROFESSIONNEL']
          ) ||
          data.Property_subtype.includes(filter_attributes['TERRAIN_AGRICOLE']);
      }

      // STATE CHECK
      var checkPropertyState = 1;
      if (
        filter_attributes['BIEN_ANCIEN'] ||
        filter_attributes['BIEN_NEUF'] ||
        filter_attributes['SANS_TRAVAUX_A_PREVOIR'] ||
        filter_attributes['TRAVAUX_A_PREVOIR'] ||
        filter_attributes['NON_VIABILISE'] ||
        filter_attributes['VIABILISE']
      ) {
        checkPropertyState =
          data.Property_state.includes(filter_attributes['BIEN_ANCIEN']) ||
          data.Property_state.includes(filter_attributes['BIEN_NEUF']) ||
          data.Property_state.includes(
            filter_attributes['SANS_TRAVAUX_A_PREVOIR']
          ) ||
          data.Property_state.includes(
            filter_attributes['TRAVAUX_A_PREVOIR']
          ) ||
          data.Property_state.includes(filter_attributes['NON_VIABILISE']) ||
          data.Property_state.includes(filter_attributes['VIABILISE']);
      }

      var not_overlooked = 1;
      if (filter_attributes['not_overlooked']) {
        not_overlooked = data.Not_overlooked.includes(
          filter_attributes['not_overlooked']
        );
      }
      var non_adjoining = 1;
      if (filter_attributes['non_adjoining']) {
        non_adjoining = data.Non_adjoining.includes(
          filter_attributes['non_adjoining']
        );
      }
      var overview_sea = 1;
      if (filter_attributes['overview_sea']) {
        overview_sea = data.Overview_sea.includes(
          filter_attributes['overview_sea']
        );
      }
      var sea_view = 1;
      if (filter_attributes['sea_view']) {
        sea_view = data.Sea_view.includes(filter_attributes['sea_view']);
      }
      var panoramic_view = 1;
      if (filter_attributes['panoramic_view']) {
        panoramic_view = data.Panoramic_view.includes(
          filter_attributes['panoramic_view']
        );
      }
      var furnished = 1;
      if (filter_attributes['furnished']) {
        furnished = data.Furnished.includes(filter_attributes['furnished']);
      }
      var balcony = 1;
      if (filter_attributes['balcony']) {
        balcony = data.Balcony.includes(filter_attributes['balcony']);
      }
      var elevator = 1;
      if (filter_attributes['elevator']) {
        elevator = data.Elevator.includes(filter_attributes['elevator']);
      }
      var caritaker = 1;
      if (filter_attributes['caritaker']) {
        caritaker = data.Caritaker.includes(filter_attributes['caritaker']);
      }
      var equipped_kitchen = 1;
      if (filter_attributes['equipped_kitchen']) {
        equipped_kitchen = data.Equipped_kitchen.includes(
          filter_attributes['equipped_kitchen']
        );
      }
      var fitted_kitchen = 1;
      if (filter_attributes['fitted_kitchen']) {
        fitted_kitchen = data.Fitted_kitchen.includes(
          filter_attributes['fitted_kitchen']
        );
      }
      var outbuilding = 1;
      if (filter_attributes['outbuilding']) {
        outbuilding = data.Outbuilding.includes(
          filter_attributes['outbuilding']
        );
      }
      var manual_gate = 1;
      if (filter_attributes['manual_gate']) {
        manual_gate = data.Manual_gate.includes(
          filter_attributes['manual_gate']
        );
      }
      var electric_gate = 1;
      if (filter_attributes['electric_gate']) {
        electric_gate = data.Electric_gate.includes(
          filter_attributes['electric_gate']
        );
      }
      var industrial_estate = 1;
      if (filter_attributes['industrial_estate']) {
        industrial_estate = data.Industrial_estate.includes(
          filter_attributes['industrial_estate']
        );
      }
      var business_district = 1;
      if (filter_attributes['business_district']) {
        business_district = data.Business_district.includes(
          filter_attributes['business_district']
        );
      }
      var zfu = 1;
      if (filter_attributes['zfu']) {
        zfu = data.zfu.includes(filter_attributes['zfu']);
      }
      var separate_toilet = 1;
      if (filter_attributes['separate_toilet']) {
        separate_toilet = data.Separate_toilet.includes(
          filter_attributes['separate_toilet']
        );
      }
      var swimming_pool = 1;
      if (filter_attributes['swimming_pool']) {
        swimming_pool = data.Swimming_pool.includes(
          filter_attributes['swimming_pool']
        );
      }
      var air_conditioning = 1;
      if (filter_attributes['air_conditioning']) {
        air_conditioning = data.Air_conditioning.includes(
          filter_attributes['air_conditioning']
        );
      }
      var fibre = 1;
      if (filter_attributes['fibre']) {
        fibre = data.Fibre.includes(filter_attributes['fibre']);
      }
      var electric_vehicule_charging = 1;
      if (filter_attributes['electric_vehicule_charging']) {
        electric_vehicule_charging = data.Electric_vehicule_charging.includes(
          filter_attributes['electric_vehicule_charging']
        );
      }
      var digital_lock = 1;
      if (filter_attributes['digital_lock']) {
        digital_lock = data.Digital_lock.includes(
          filter_attributes['digital_lock']
        );
      }
      var disabled_access = 1;
      if (filter_attributes['disabled_access']) {
        disabled_access = data.Disabled_access.includes(
          filter_attributes['disabled_access']
        );
      }
      var intercom = 1;
      if (filter_attributes['intercom']) {
        intercom = data.Intercom.includes(filter_attributes['intercom']);
      }
      var separate_kitchen = 1;
      if (filter_attributes['separate_kitchen']) {
        separate_kitchen = data.Separate_kitchen.includes(
          filter_attributes['separate_kitchen']
        );
      }
      var open_kitchen = 1;
      if (filter_attributes['open_kitchen']) {
        open_kitchen = data.Open_kitchen.includes(
          filter_attributes['open_kitchen']
        );
      }
      var semi_open_kitchen = 1;
      if (filter_attributes['semi_open_kitchen']) {
        semi_open_kitchen = data.Semi_open_kitchen.includes(
          filter_attributes['semi_open_kitchen']
        );
      }
      var outdoor_kitchen = 1;
      if (filter_attributes['outdoor_kitchen']) {
        outdoor_kitchen = data.Outdoor_kitchen.includes(
          filter_attributes['outdoor_kitchen']
        );
      }
      var terrace = 1;
      if (filter_attributes['terrace']) {
        terrace = data.Terrace.includes(filter_attributes['terrace']);
      }
      var garden = 1;
      if (filter_attributes['garden']) {
        garden = data.Garden.includes(filter_attributes['garden']);
      }
      var private_parking = 1;
      if (filter_attributes['private_parking']) {
        private_parking = data.Private_parking.includes(
          filter_attributes['private_parking']
        );
      }
      var garage = 1;
      if (filter_attributes['garage']) {
        garage = data.Garage.includes(filter_attributes['garage']);
      }
      var lock_up_garage = 1;
      if (filter_attributes['lock_up_garage']) {
        lock_up_garage = data.Lock_up_garage.includes(
          filter_attributes['lock_up_garage']
        );
      }
      var life_annuity = 1;
      if (filter_attributes['life_annuity']) {
        life_annuity = data.Life_annuity.includes(
          filter_attributes['life_annuity']
        );
      }
      var eligible_pinel_law = 1;
      if (filter_attributes['eligible_pinel_law']) {
        eligible_pinel_law = data.Eligible_pinel_law.includes(
          filter_attributes['eligible_pinel_law']
        );
      }
      var senior_residence = 1;
      if (filter_attributes['senior_residence']) {
        senior_residence = data.Senior_residence.includes(
          filter_attributes['senior_residence']
        );
      }
      var student_residence = 1;
      if (filter_attributes['student_residence']) {
        student_residence = data.Student_residence.includes(
          filter_attributes['student_residence']
        );
      }
      var min_budget = true;
      if (filter_attributes['MIN_BUDGET']) {
        min_budget = data.Price >= filter_attributes['MIN_BUDGET'];
      }
      var max_budget = true;
      if (filter_attributes['MAX_BUDGET']) {
        max_budget = data.Price <= filter_attributes['MAX_BUDGET'];
      }
      var min_area = true;
      if (filter_attributes['MIN_AREA']) {
        min_area = data.Total_area >= filter_attributes['MIN_AREA'];
      }
      var max_area = true;
      if (filter_attributes['MAX_AREA']) {
        max_area = data.Total_area <= filter_attributes['MAX_AREA'];
      }
      var min_land_area = true;
      if (filter_attributes['MIN_LAND_AREA']) {
        min_land_area = data.Total_area >= filter_attributes['MIN_LAND_AREA'];
      }
      var max_land_area = true;
      if (filter_attributes['MAX_LAND_AREA']) {
        max_land_area = data.Total_area <= filter_attributes['MAX_LAND_AREA'];
      }
      var min_pieces_number = true;
      if (filter_attributes['MIN_PIECES_NUMBER']) {
        min_pieces_number =
          data.Number_of_pieces >= filter_attributes['MIN_PIECES_NUMBER'];
      }
      var max_pieces_number = true;
      if (filter_attributes['MAX_PIECES_NUMBER']) {
        max_pieces_number =
          data.Number_of_pieces <= filter_attributes['MAX_PIECES_NUMBER'];
      }
      var min_bedrooms_number = true;
      if (filter_attributes['MIN_BEDROOMS_NUMBER']) {
        min_bedrooms_number =
          data.Bedroom_number >= filter_attributes['MIN_BEDROOMS_NUMBER'];
      }
      var max_bedrooms_number = true;
      if (filter_attributes['MAX_BEDROOMS_NUMBER']) {
        max_bedrooms_number =
          data.Bedroom_number <= filter_attributes['MAX_BEDROOMS_NUMBER'];
      }
      var min_bathrooms_number = true;
      if (filter_attributes['MIN_BATHROOMS_NUMBER']) {
        min_bathrooms_number =
          data.Bathroom_number >= filter_attributes['MIN_BATHROOMS_NUMBER'];
      }
      var max_bathrooms_number = true;
      if (filter_attributes['MAX_BATHROOMS_NUMBER']) {
        max_bathrooms_number =
          data.Bathroom_number <= filter_attributes['MAX_BATHROOMS_NUMBER'];
      }
      var min_individual_offices_number = true;
      if (filter_attributes['MIN_INDIVIDUAL_OFFICES_NUMBER']) {
        min_individual_offices_number =
          data.Individual_office_number >=
          filter_attributes['MIN_INDIVIDUAL_OFFICES_NUMBER'];
      }
      var max_individual_offices_number = true;
      if (filter_attributes['MAX_INDIVIDUAL_OFFICES_NUMBER']) {
        max_individual_offices_number =
          data.Individual_office_number <=
          filter_attributes['MAX_INDIVIDUAL_OFFICES_NUMBER'];
      }
      var min_seats_number = true;
      if (filter_attributes['MIN_SEATS_NUMBER']) {
        min_seats_number =
          data.Seating_places_number >= filter_attributes['MIN_SEATS_NUMBER'];
      }
      var max_seats_number = true;
      if (filter_attributes['MAX_SEATS_NUMBER']) {
        max_seats_number =
          data.Seating_places_number <= filter_attributes['MAX_SEATS_NUMBER'];
      }
      var min_meeting_rooms_number = true;
      if (filter_attributes['MIN_MEETING_ROOMS_NUMBER']) {
        min_meeting_rooms_number =
          data.Meeting_room_number >=
          filter_attributes['MIN_MEETING_ROOMS_NUMBER'];
      }
      var max_meeting_rooms_number = true;
      if (filter_attributes['MAX_MEETING_ROOMS_NUMBER']) {
        max_meeting_rooms_number =
          data.Meeting_room_number <=
          filter_attributes['MAX_MEETING_ROOMS_NUMBER'];
      }
      var state = 1;
      if (filter_attributes['state']) {
        eligible_pinel_law = data.State.includes(filter_attributes['state']);
      }
      var service_type = 1;
      if (filter_attributes['service_type']) {
        eligible_pinel_law = data.Service_type.includes(
          filter_attributes['service_type']
        );
      }
      // ENERGETIC CLASS CHECK
      var checkEnergeticClass = 1;
      if (
        filter_attributes['ENERGETIC_CLASS_A'] ||
        filter_attributes['ENERGETIC_CLASS_B'] ||
        filter_attributes['ENERGETIC_CLASS_C'] ||
        filter_attributes['ENERGETIC_CLASS_D'] ||
        filter_attributes['ENERGETIC_CLASS_E'] ||
        filter_attributes['ENERGETIC_CLASS_F'] ||
        filter_attributes['ENERGETIC_CLASS_G'] ||
        filter_attributes['ENERGETIC_CLASS_VIERGE']
      ) {
        checkEnergeticClass =
          data.Energetic_class.includes(
            filter_attributes['ENERGETIC_CLASS_A']
          ) ||
          data.Energetic_class.includes(
            filter_attributes['ENERGETIC_CLASS_B']
          ) ||
          data.Energetic_class.includes(
            filter_attributes['ENERGETIC_CLASS_C']
          ) ||
          data.Energetic_class.includes(
            filter_attributes['ENERGETIC_CLASS_D']
          ) ||
          data.Energetic_class.includes(
            filter_attributes['ENERGETIC_CLASS_E']
          ) ||
          data.Energetic_class.includes(
            filter_attributes['ENERGETIC_CLASS_G']
          ) ||
          data.Energetic_class.includes(
            filter_attributes['ENERGETIC_CLASS_VIERGE']
          ) ||
          data.Energetic_class.includes(filter_attributes['ENERGETIC_CLASS_F']);
      }

      // CHECK GAS EMISSIONS
      var checkGasEmissions = 1;
      if (
        filter_attributes['GAS_EMISSIONS_A'] ||
        filter_attributes['GAS_EMISSIONS_B'] ||
        filter_attributes['GAS_EMISSIONS_C'] ||
        filter_attributes['GAS_EMISSIONS_D'] ||
        filter_attributes['GAS_EMISSIONS_E'] ||
        filter_attributes['GAS_EMISSIONS_F'] ||
        filter_attributes['GAS_EMISSIONS_G'] ||
        filter_attributes['GAS_EMISSIONS_VIERGE']
      ) {
        checkGasEmissions =
          data.Gas_emissions.includes(filter_attributes['GAS_EMISSIONS_A']) ||
          data.Gas_emissions.includes(filter_attributes['GAS_EMISSIONS_B']) ||
          data.Gas_emissions.includes(filter_attributes['GAS_EMISSIONS_C']) ||
          data.Gas_emissions.includes(filter_attributes['GAS_EMISSIONS_D']) ||
          data.Gas_emissions.includes(filter_attributes['GAS_EMISSIONS_E']) ||
          data.Gas_emissions.includes(filter_attributes['GAS_EMISSIONS_G']) ||
          data.Gas_emissions.includes(
            filter_attributes['GAS_EMISSIONS_VIERGE']
          ) ||
          data.Gas_emissions.includes(filter_attributes['GAS_EMISSIONS_F']);
      }
      return (
        service_type &&
        state &&
        checkPropertySubType &&
        checkPropertyState &&
        not_overlooked &&
        non_adjoining &&
        overview_sea &&
        sea_view &&
        panoramic_view &&
        furnished &&
        balcony &&
        elevator &&
        caritaker &&
        equipped_kitchen &&
        fitted_kitchen &&
        outbuilding &&
        manual_gate &&
        electric_gate &&
        industrial_estate &&
        business_district &&
        zfu &&
        separate_toilet &&
        swimming_pool &&
        air_conditioning &&
        fibre &&
        electric_vehicule_charging &&
        disabled_access &&
        digital_lock &&
        intercom &&
        separate_kitchen &&
        open_kitchen &&
        semi_open_kitchen &&
        outdoor_kitchen &&
        terrace &&
        garden &&
        private_parking &&
        garage &&
        lock_up_garage &&
        life_annuity &&
        eligible_pinel_law &&
        senior_residence &&
        student_residence &&
        min_budget &&
        max_budget &&
        min_area &&
        max_area &&
        min_land_area &&
        max_land_area &&
        min_pieces_number &&
        max_pieces_number &&
        min_bedrooms_number &&
        max_bedrooms_number &&
        min_bathrooms_number &&
        max_bathrooms_number &&
        min_individual_offices_number &&
        max_individual_offices_number &&
        min_seats_number &&
        max_seats_number &&
        min_meeting_rooms_number &&
        max_meeting_rooms_number &&
        checkEnergeticClass &&
        checkGasEmissions
      );
    }));
  }

  /**
   * Pubished or unpublished property
   */
  public editStateProperty(
    property_id: string,
    state: string
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          editStateProperty(id: "${property_id}", state: ${state}) {
            code
          }
        }
        `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }
}
