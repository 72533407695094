<button mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon>more_horiz</mat-icon>
</button>
<mat-menu
  #menu="matMenu"
  yPosition="above"
  xPosition="after"
  [hasBackdrop]="false"
  [overlapTrigger]="false"
  class="customizeMenu"
>
  <div>
    <img class="h-12 mx-auto mb-8" src="assets/icons/svg/logo.svg" />
  </div>
  <button mat-menu-item>
    <span
      [ngClass]="
        active_link === '/home'
          ? 'font-bold text-white '
          : 'font-light txt-light-blue'
      "
      ><a href="/home">Accueil</a></span
    >
  </button>
  <button mat-menu-item>
    <span
      [ngClass]="
        active_link == '/my-account'
          ? 'font-bold text-white '
          : 'font-light txt-light-blue'
      "
      ><a (click)="checkIfUserIsLogged()">Mon compte</a></span
    >
  </button>
  <button mat-menu-item>
    <span
      [ngClass]="
        active_link === '/about'
          ? 'font-bold text-white '
          : 'font-light txt-light-blue'
      "
      ><a href="/about">A propos</a>
    </span>
  </button>
  <button mat-menu-item>
    <span
      [ngClass]="
        active_link === '/faq'
          ? 'font-bold text-white '
          : 'font-light txt-light-blue'
      "
      ><a href="/faq">FAQ</a>
    </span>
  </button>
  <button mat-menu-item class="my-12">
    <span
      class="border border-solid p-2 rounded-3xl bg-light-blue"
      [ngClass]="
        active_link === '/account/*'
          ? 'font-bold text-white '
          : 'font-light text-white'
      "
      ><a href="/account/authentication">Inscription / Connexion</a>
    </span>
  </button>
  <button mat-menu-item>
    <span
      [ngClass]="
        active_link === '/contact'
          ? 'font-bold text-white '
          : 'font-light txt-light-blue'
      "
      ><a href="/contact">Contactez-nous !</a>
    </span>
  </button>
  <div>
    <img class="h-8 mx-auto mb-8" src="assets/icons/svg/close.svg" />
  </div>
</mat-menu>
