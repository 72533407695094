export class AgencyModel {
  ID: any;
  Address_id: any;
  Agency_name: any;
  Siret: any;
  Agency_scale_of_fees: any;

  public mapToModel(data: any): AgencyModel {
    const agencyModel = new AgencyModel();
    agencyModel.ID = data.id;
    agencyModel.Address_id = data.address_id;
    agencyModel.Agency_name = data.agency_name;
    agencyModel.Siret = data.siret;
    agencyModel.Agency_scale_of_fees = data.agency_scale_of_fees;

    return agencyModel;
  }

  public searchMapping(data: any): AgencyModel {
    const agencyModel = new AgencyModel();
    agencyModel.ID = data.id;
    agencyModel.Agency_name = data.agency_name;
    return agencyModel;
  }

  agencyNameValid(): boolean {
    if (this.Agency_name !== '' && this.Agency_name != null) {
      return true;
    } else {
      return false;
    }
  }
  siretValid(): boolean {
    if (this.Siret !== '' && this.Siret != null) {
      return true;
    } else {
      return false;
    }
  }

  addressIdValid(): boolean {
    if (this.Address_id !== '' && this.Address_id != null) {
      return true;
    } else {
      return false;
    }
  }
  agencyScaleOfFeesValid(): boolean {
    if (this.Agency_scale_of_fees !== '' && this.Agency_scale_of_fees != null) {
      return true;
    } else {
      return false;
    }
  }
}
