import { UserService } from 'src/app/shared/services/user.service';
import { CustomPopupComponent } from './../../shared/components/custom-popup/custom-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { LoaderPopupComponent } from './../../shared/components/loader-popup/loader-popup.component';
import { LocalStorageService } from './../../shared/services/local-storage.service';
import { environment } from './../../../environments/environment';
import { Component, Input, OnInit } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-file-page',
  templateUrl: './file-page.component.html',
  styleUrls: ['./file-page.component.scss'],
})
export class FilePageComponent implements OnInit {
  public current_step: string;
  @Input() step: string;
  public user_id: string;
  public candidate_files: any[] = [];
  public file_name: string;
  public media: any;
  public candidate_files_exists: boolean = false;
  public candidate_file_id: string;
  public proof_of_identity: string; //Justificatif d'identité
  public proof_of_identity_guarantor: string;
  public family_record_book: string; //Livret de famille
  public marriage_or_pacs_certificate: string; //Certificat de mariage, pacs
  public tax_notice: string; //Avis d'imposition
  public tax_notice_guarantor: string;
  public electricity_bill: string; //Facture d'électricité
  public kbis_or_extract: string;
  public rental_investment: string; //Investissement locatif
  public conditions_precedent_if_loan: string; //Conditions suspensives si emprunt
  public proof_of_the_funds_making_up_the_contribution: string; //Justificatif des fonds composant l'apport
  public employment_contract: string; //Contrat de travail
  public employment_contract_guarantor: string;
  public rent_receipt_1: string; //Quittance de loyer 1
  public rent_receipt_2: string; //Quittance de loyer 1
  public rent_receipt_3: string; //Quittance de loyer 1

  constructor(
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private userService: UserService
  ) {
    this.user_id = this.localStorageService.getCurrentUser().id;
    this.current_step = 'ACHAT';
  }

  ngOnInit(): void {
    this.getCandidateFile();
  }

  /**
   * switchStep
   */
  public switchStep($event) {
    switch ($event) {
      case 'LOCATION':
        this.current_step = 'LOCATION';
        break;
      case 'ACHAT':
        this.current_step = 'ACHAT';
        break;
      default:
        break;
    }
  }

  /**
   * getFile
   */
  public getFile($file_key) {
    console.log($file_key, 'KEYYYYY');
    let file_url = environment.cloudfront_url + $file_key;
    if (this.candidate_files_exists) {
      window.open(`${file_url}`, 'blank');
    }
  }

  /**
   * getCandidateFile
   */
  public getCandidateFile() {
    this.userService.getCandidateFileData(this.user_id).subscribe((res) => {
      console.log('data', res);
      if (res && !res.errors) {
        this.candidate_files_exists = true;
        this.proof_of_identity =
          res.data.getCandidateFileData.proof_of_identity !== 'Non renseigné'
            ? res.data.getCandidateFileData.proof_of_identity
            : null;
        this.proof_of_identity_guarantor =
          res.data.getCandidateFileData.proof_of_identity_guarantor !==
          'Non renseigné'
            ? res.data.getCandidateFileData.proof_of_identity_guarantor
            : null;
        this.family_record_book =
          res.data.getCandidateFileData.family_record_book !== 'Non renseigné'
            ? res.data.getCandidateFileData.family_record_book
            : null;
        this.marriage_or_pacs_certificate =
          res.data.getCandidateFileData.marriage_or_pacs_certificate !==
          'Non renseigné'
            ? res.data.getCandidateFileData.marriage_or_pacs_certificate
            : null;
        this.tax_notice =
          res.data.getCandidateFileData.tax_notice !== 'Non renseigné'
            ? res.data.getCandidateFileData.tax_notice
            : null;
        this.tax_notice_guarantor =
          res.data.getCandidateFileData.tax_notice_guarantor !== 'Non renseigné'
            ? res.data.getCandidateFileData.tax_notice_guarantor
            : null;
        this.electricity_bill =
          res.data.getCandidateFileData.electricity_bill !== 'Non renseigné'
            ? res.data.getCandidateFileData.electricity_bill
            : null;
        this.kbis_or_extract =
          res.data.getCandidateFileData.kbis_or_extract !== 'Non renseigné'
            ? res.data.getCandidateFileData.kbis_or_extract
            : null;
        this.rental_investment =
          res.data.getCandidateFileData.rental_investment !== 'Non renseigné'
            ? res.data.getCandidateFileData.rental_investment
            : null;
        this.conditions_precedent_if_loan =
          res.data.getCandidateFileData.conditions_precedent_if_loan !==
          'Non renseigné'
            ? res.data.getCandidateFileData.conditions_precedent_if_loan
            : null;
        this.proof_of_the_funds_making_up_the_contribution =
          res.data.getCandidateFileData
            .proof_of_the_funds_making_up_the_contribution !== 'Non renseigné'
            ? res.data.getCandidateFileData
                .proof_of_the_funds_making_up_the_contribution
            : null;
        this.employment_contract =
          res.data.getCandidateFileData.employment_contract !== 'Non renseigné'
            ? res.data.getCandidateFileData.employment_contract
            : null;
        this.employment_contract_guarantor =
          res.data.getCandidateFileData.employment_contract_guarantor !==
          'Non renseigné'
            ? res.data.getCandidateFileData.employment_contract_guarantor
            : null;
        this.rent_receipt_1 =
          res.data.getCandidateFileData.rent_receipt_1 !== 'Non renseigné'
            ? res.data.getCandidateFileData.rent_receipt_1
            : null;
        this.rent_receipt_2 =
          res.data.getCandidateFileData.rent_receipt_2 !== 'Non renseigné'
            ? res.data.getCandidateFileData.rent_receipt_2
            : null;
        this.rent_receipt_3 =
          res.data.getCandidateFileData.rent_receipt_3 !== 'Non renseigné'
            ? res.data.getCandidateFileData.rent_receipt_3
            : null;
      }
    });
  }

  /**
   * addFile
   */
  public addFile($event) {
    console.log($event);
    if ($event.key) {
      this.removeFile($event);
    } else {
      this.dialog.open(LoaderPopupComponent, {
        disableClose: true,
        hasBackdrop: true,
        height: '40%',
        panelClass: 'custom-modalbox',
        data: {
          text: 'Téléchargement en cours ... ',
          isLoader: true,
        },
      });
      var file = $event.file.target.files[0]; // FileList object

      var end_file_name = file.name.split('.')[1]; // End of string file_name

      var fieldName = $event.field_name; // Fieldname of file

      this.file_name = 'assets-upload/file-' + uuidv4() + '.' + end_file_name; // Set a random file_name

      // If size of file bigger than 3 mega bytes throw Error
      if (file.size > 3145728) {
        this.dialog.closeAll();
        const dialogRef = this.dialog.open(CustomPopupComponent, {
          disableClose: true,
          hasBackdrop: true,
          height: '70%',
          panelClass: 'custom-modalbox',
          data: {
            status: 'error',
            title: "Une erreur s'est produite",
            message:
              'La taille du fichier ne doit pas excéder 3 méga octets (3 Mo)',
          },
        });
        this.file_name = '';
      } else {
        console.log($event);
        this.getBase64(file).then((data) => {
          this.media = data;
          console.log('xxxxxx', this.media);
          this.userService
            .addFile(this.user_id, fieldName, this.media, this.file_name)
            .subscribe((res: any) => {
              console.log(res);
              if (res && res.data.addFile.code === 'FILE_UPLOADED') {
                this.dialog.closeAll();
                window.location.reload();
                this.step = 'CANDIDATE_FILE';
              }
            });
        });
      }
    }
  }

  /**
   * getBase64
   * Function to transform file into base64
   */
  public getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  /**
   * REMOVE FILE UPLOADED
   */
  public removeFile($event: any) {
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '70%',
      panelClass: 'custom-modalbox',
      data: {
        status: 'delete',
        title: 'ATTENTION',
        message: 'Etes-vous sur de vouloir supprimer ce fichier ?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dialog.closeAll();
        this.dialog.open(LoaderPopupComponent, {
          disableClose: true,
          hasBackdrop: true,
          height: '40%',
          panelClass: 'custom-modalbox',
          data: {
            text: 'Suppression en cours... ',
            isLoader: true,
          },
        });
        this.userService
          .removeFile($event.key, $event.field_name, this.candidate_file_id)
          .subscribe((res: any) => {
            console.log(res);
            if (res && res.data.removeFile.code === 'FILE_REMOVED') {
              this.dialog.closeAll();
              window.location.reload();
            } else if (res.errors.message) {
              this.dialog.closeAll();
              this.dialog.open(CustomPopupComponent, {
                disableClose: true,
                hasBackdrop: true,
                height: '70%',
                panelClass: 'custom-modalbox',
                data: {
                  status: 'error',
                  title: "Une erreur s'est produite",
                  message:
                    'Nous sommes désolé, un problème est survenu. Veuillez réessayer ultérieurement.',
                },
              });
            }
          });
      } else {
        this.dialog.closeAll();
      }
    });
  }
}
