<div (click)="onClick($event)" class="fex flex-col relative" [ngClass]="blockClasses">
  <img *ngIf="img_visibility" [ngClass]="imgPathClasses" class="absolute right-0 -top-2 h-4" [src]="imgPath" />
  <div class="flex gap-2 items-center">
    <img
      src="assets/icons/svg/logo-1.svg"
      [ngClass]="icon_visibility ? 'h-4 cursor-pointer' : 'hidden'"
    />
    <label
      [ngClass]="labelClasses"
      class="txt-percian-gray"
      for="{{ label_name }}"
      >{{ label_text }}</label
    >
  </div>
  <div *ngIf="label_name !== ''">
    <input
      [ngClass]="inputClasses"
      class="
        outline-none
        appearance-none
        border-none
        bg-transparent
        txt-bold-gray
        ml-6
        text-sm
      "
      type="text"
      name="{{ label_name }}"
      [(ngModel)]="model"
      readonly
      disabled
    />
  </div>
</div>
