import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-tertiary-card',
  templateUrl: './tertiary-card.component.html',
  styleUrls: ['./tertiary-card.component.scss'],
})
export class TertiaryCardComponent implements OnInit {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() cardClasses = '';
  @Input() titleClasses = '';
  @Input() subtitleClasses = '';
  @Input() imgPathClasses = '';
  @Input() icon_visibility = false;
  @Input() img_visibility = false;
  @Input() imgSrc = '';
  @Input() imgPath = '';
  @Input() imgClasses = '';
  @Output() IsClicked = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onClick($event) {
    this.IsClicked.emit($event);
  }
}
