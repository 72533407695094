<div
  [formGroup]="agencyID"
  [ngClass]="searchClasses"
  class="
    flex
    items-center
    my-8
    mx-8
    rounded-full
    bg-gray-300
    p-2
  "
>
  <input
    formControlName="agency_id"
    type="text"
    [placeholder]="placeholder"
    class="text-sm m-2 text-white bg-transparent w-full outline-none"
    [(ngModel)]="model"
    (ngModelChange)="onChange($event)"
  />
  <mat-icon class="w-4 mx-4 text-white">search</mat-icon>
</div>
