import { LocalStorageService } from './../../shared/services/local-storage.service';
import { AppointmentService } from 'src/app/shared/services/appointment.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Renderer2,
} from '@angular/core';
import { AppointmentModel } from 'src/app/shared/models/appointment-model';

@Component({
  selector: 'app-my-appointments',
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
    ]),
  ],
  templateUrl: './my-appointments.component.html',
  styleUrls: ['./my-appointments.component.scss'],
})
export class MyAppointmentsComponent implements OnInit {
  public user_id: string;
  public today: any;
  public see_all_appointment: boolean = false;
  public days: any[];
  @Input() date: any;
  @Input() current_selected_year;
  @Input() current_selected_month;
  public month_array = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'aôut',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ];
  public appointments_list: AppointmentModel[] = [];
  public appointments_list_filtered: any[] = [];
  public hasError: boolean = false;
  public selectedDate: any;
  @Input() isOnAppointmentPage: boolean = true;
  public property_id: string;
  public username: string;
  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private appointmentService: AppointmentService,
    private localStorageService: LocalStorageService,
    private renderer: Renderer2,
    private route: ActivatedRoute
  ) {
    this.username =
      this.localStorageService.getCurrentUser().firstname +
      ' ' +
      this.localStorageService.getCurrentUser().lastname;
    this.renderer.setStyle(document.body, 'background-color', 'white');
    this.today = new Date();
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    this.today = this.today.toLocaleDateString('fr-FR', options);
    this.current_selected_month = new Date().getMonth();
    this.current_selected_year = new Date().getFullYear();
    this.user_id = this.localStorageService.getCurrentUser().id;
    if (!this.isOnAppointmentPage) {
      this.property_id = this.route.snapshot.paramMap.get('id');
    }
  }

  ngOnInit(): void {
    this.getAllUserAppointments();
  }

  /**
   * BACK FUNCTION
   */
  public back() {
    this.router.navigate(['/appointment']);
  }

  /**
   * seeAllAppointment
   */
  public seeAllAppointment() {
    this.see_all_appointment = true;
    var days = this.getDaysInMonth(
      this.current_selected_year,
      this.current_selected_month
    );
    this.getFrDaysInMonth(days);
    // This line is here for remove error Expression has changed after it was checked (this.days array)
    this.cd.detectChanges();
  }

  /**
   * getDaysInMonth
   */
  public getFrDaysInMonth($days) {
    this.days = [];
    for (let index = 0; index < $days.length; index++) {
      this.date = new Date($days[index]);
      console.log('NEW DATE', this.date);
      console.log('MONTH', $days[index]);
      var day = this.date.toLocaleString('fr-FR', { weekday: 'short' });
      let month = this.date.toLocaleString('fr-FR', { month: 'short' });
      // Je le traduit en français pour l'affichage et en anglais pour générer mes dates car en francais => Invalide date!
      var day_en = this.date.toLocaleString('en-EN', { weekday: 'short' });
      let month_en = this.date.toLocaleString('en-EN', { month: 'short' });
      var day_number =
        this.date.getDate() < 10
          ? '0' + this.date.getDate()
          : this.date.getDate();
      var date_string =
        day + ' ' + day_number + ' ' + month + ' ' + this.current_selected_year;
      var date_string_en =
      day_en + ' ' + day_number + ' ' + month_en + ' ' + this.current_selected_year;
      this.days.push({
        number: day_number,
        name: day.substring(0, 3) + '.',
        date: date_string,
        date_en: date_string_en,
      });
    }
  }

  /**
   * selectMonthAndYear
   */
  public selectMonthAndYear($event) {
    if ($event.value === 'DATE_SORT') {
      console.log($event);
      var month_number: any = this.month_array.indexOf(
        $event.current_month.toLowerCase()
      );

      console.log('DATE_SORT', $event.current_year, month_number);

      var days_list = this.getDaysInMonth($event.current_year, month_number);
      this.current_selected_year = $event.current_year;
      this.getFrDaysInMonth(days_list);
    }
  }
  /**
   * selectDate
   */
  public selectDate($date) {
    console.log($date);
    this.selectedDate = $date.date_en;
    console.log('selectedDate', this.selectedDate);
    this.date = new Date($date.date_en);
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    this.date = this.date.toLocaleDateString('fr-FR', options);
    console.log(this.date);
    this.appointmentFilterByDate(this.date);
  }

  /**
   * @param {int} The month number, 0 based
   * @param {int} The year, not zero based, required to account for leap years
   * @return {Date[]} List with date objects for each day of the month
   */
  public getDaysInMonth(year: number, month: number) {
    var date = new Date(year, month);
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    console.log('days', days);

    return days;
  }

  /**
   * getAllUserAppointments
   */
  public getAllUserAppointments() {
    if (this.isOnAppointmentPage) {
      this.appointmentService
        .getAllUserAppointments(this.user_id)
        .subscribe((res) => {
          console.log(res);
          if (res && res.data.getAllUserAppointments) {
            const data = res.data.getAllUserAppointments;
            // Transform date for filter function
            data.forEach((element) => {
              const appointmentsList = new AppointmentModel().mapToModel(
                element
              );
              console.log('test', appointmentsList.Appointment_type);

              this.appointments_list.push(appointmentsList);
            });

            this.appointmentFilterByDate(this.today);
          } else if (res.errors.message) {
            this.hasError = true;
            console.log(res.errors.message);
          } else {
            this.hasError = true;
            console.log('ERREUR SERVEUR');
          }
        });
    } else {
      this.appointmentService
        .getAllAppointmentsByProperty(this.user_id)
        .subscribe((res) => {
          console.log(res);
          if (res && res.data.getAllAppointmentsByProperty) {
            const data = res.data.getAllAppointmentsByProperty;
            // Transform date for filter function
            data.forEach((element) => {
              const appointmentsList = new AppointmentModel().mapToModel(
                element
              );
              console.log('test', appointmentsList.Appointment_type);

              this.appointments_list.push(appointmentsList);
              console.log('LIST', this.appointments_list);
            });

            this.appointmentFilterByDate(this.today);
          } else if (res.errors.message) {
            this.hasError = true;
            console.log(res.errors.message);
          } else {
            this.hasError = true;
            console.log('ERREUR SERVEUR');
          }
        });
    }
  }

  /**
   * appointmentFilterByDate
   */
  public appointmentFilterByDate($date) {
    console.log('DATE', $date);

    this.appointments_list_filtered = this.appointments_list.filter(
      (element) => {
        return element.Appointment_date.includes($date);
      }
    );
    console.log('FILTRE', this.appointments_list_filtered);
    if (!this.appointments_list_filtered.length) {
      this.hasError = true;
    }
  }

  /**
   * getUserAppointment
   */
  public getUserAppointment($appointment_id) {
    this.router.navigate([`appointment/${$appointment_id}`]);
  }
}
