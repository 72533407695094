import { LocalStorageService } from './../../services/local-storage.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public active_link: any;
  public userIsLogged: boolean = false;
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    // this.checkIfUserIsLogged();
    this.toggleClassMenu();
  }

  // Check the local storage to know if user is logged or not for the redirection link
  checkIfUserIsLogged() {
    const user_id = this.localStorageService.getCurrentUser().id;
    if (user_id) {
      this.userIsLogged = true;

      this.router.navigate(['/my-account']);
      this.active_link = '/my-account';
    } else {
      this.router.navigate(['/account/authentication']);
      this.active_link = '/account/authentication';
    }
  }

  toggleClassMenu() {
    if (this.router.url === '/home') {
      console.log('on home page');
      this.active_link = '/home';
    }
  }
}
