<div [@fade] class="h-screen w-full mx-auto bg-white overflow-auto">
  <div class="border-b border-solid pb-2">
    <div class="mx-6">
      <app-secondary-header
        (IsClicked)="back()"
        headerClasses=" pt-4 flex justify-between items-cente mb-2"
      ></app-secondary-header>
      <div class="mx-auto text-center">
        <p class="font-bold text-xl txt-bold-gray">Mes rendez-vous</p>
      </div>
    </div>
  </div>
  <div class="mx-6">
    <app-img
      imgPath="assets/icons/svg/calendar.svg"
      imgClasses="h-36 mt-16 mb-6 mx-auto bg-light-blue p-6 rounded-xl"
    ></app-img>
    <app-text
      textClasses="txt-bold-gray text-center font-bold mb-6"
      text="Organisez vos rendez-vous"
    ></app-text>
    <app-text
      textClasses="txt-percian-gray text-sm mx-auto text-center font-bold"
      text="Que souhaitez-vous faire ?"
    ></app-text>
    <div class="flex flex-col gap-3 my-16">
      <app-primary-button
        (IsClicked)="appointmentAction('/create-appointment')"
        text="Créer un rendez-vous"
        buttonClasses="bg-white border-secondary-blue txt-secondary-blue border items-center w-full py-3 hover-style-light-blue"
        secondImgSrc="assets/icons/svg/arrow-right.svg"
        [disabled]="false"
      ></app-primary-button>
      <app-primary-button
        (IsClicked)="appointmentAction('/my-appointments')"
        text="Consulter mes rendez-vous"
        buttonClasses="bg-white border-secondary-blue txt-secondary-blue border items-center w-full py-3 hover-style-light-blue"
        [disabled]="false"
        secondImgSrc="assets/icons/svg/arrow-right.svg"
      ></app-primary-button>
      <app-primary-button
        (IsClicked)="appointmentAction('/appointment-management')"
        text="Paramétrer mes rendez-vous"
        buttonClasses="bg-white border-secondary-blue txt-secondary-blue border items-center w-full py-3 hover-style-light-blue"
        [disabled]="false"
        secondImgSrc="assets/icons/svg/arrow-right.svg"
      ></app-primary-button>
    </div>
  </div>
</div>
