<button
  type="submit"
  [ngClass]="buttonClasses"
  (click)="onClick($event)"
  [attr.disabled]="disabled ? true : null"
>
  <img
    [ngClass]="nextButtonClasses"
    class="h-8"
    [src]="
      disabled
        ? 'assets/icons/svg/next-button-disabled.svg'
        : 'assets/icons/svg/next-button.svg'
    "
  />
</button>
