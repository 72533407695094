<div
  [formGroup]="formFields"
  class="flex items-center gap-4 cursor-pointer"
  [ngClass]="!isSelected ? inputClasses : inputSelectedClasses"
  id="{{ item_id }}"
>
  <img
    src="assets/icons/svg/logo-1.svg"
    [ngClass]="icon_visibility ? 'h-6' : 'hidden'"
  />
  <p
    [id]="label_name"
    [ngClass]="txtClasses"
    (click)="onClick($event)"
    class="border-2 outline-none cursor-pointer appearance-none"
  >
    {{ model }}
  </p>
  <!-- <input
    formControlName="{{ label_name }}"
    class="border-2 outline-none cursor-pointer appearance-none"
    type="{{ type }}"
    name="{{ label_name }}"
    id="{{ label_name }}"
    [(ngModel)]="model"
    [ngClass]="txtClasses"
    (click)="onClick($event)"
    [attr.disabled]="disabled ? true : null"
    [attr.readonly]="readonly === 'readonly' ? 'readonly' : null"
  /> -->
</div>
