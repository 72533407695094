import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
  @Input() checked = false;
  @Output() Changed = new EventEmitter<any>();
  @Input() value = '';
  public model: any;
  public hasError = false;
  @Input() text_content = ``;
  @Input() contentClasses = '';

  constructor() {}

  ngOnInit(): void {}

  onChange($event: any): void {
    this.checkInput();
  }

  checkInput(): void {
    if (this.value === 'PRIVACY_POLICY') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);
        this.Changed.emit(data);
      }
    }
    if (this.value === 'WITHOUT_PRICE') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);
        this.Changed.emit(data);
      }
    }
    if (this.value === 'WITHOUT_SUPERFICY') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);
        this.Changed.emit(data);
      }
    }
    if (this.value === 'PLACE_LOCATION') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        console.log(data);
        this.Changed.emit(data);
      }
    }
  }
}
