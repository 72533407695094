<div (click)="onClick($event)" class="bg-white rounded-xl shadow-lg w-full cursor-pointer">
  <div class="flex justify-between items-center my-3 w-full border-b border-solid p-3">
    <app-text
      textClasses="txt-bold-gray font-bold text-sm"
      [text]="appointment_date"
    ></app-text>
    <!-- Statut -->
    <div class="bg-percian-gray rounded-md p-1">
      <app-text
        textClasses="txt-bold-gray mx-3 text-xs text-center font-bold capitalize"
        [text]="appointment_status"
      ></app-text>
    </div>
  </div>
  <!-- Appointment type + Appointment Hour -->
  <div class="flex mx-3 justify-between mt-3 mb-6 items-center">
    <div class="bg-light-blue rounded-md p-1">
      <app-text
        textClasses="txt-bold-blue mx-3 text-xs text-center font-bold"
        [text]="appointment_type"
      ></app-text>
    </div>
    <div class="border border-percian-green p-1 rounded-md">
      <app-text
        textClasses="txt-percian-green mx-3 text-sm text-center font-light"
        [text]="appointment_hour"
      ></app-text>
    </div>
  </div>
  <!-- Appointment Reason -->
  <app-text
    textClasses="txt-bold-gray mb-6 mx-3 text-sm font-bold"
    [text]="appointment_reason"
  ></app-text>
  <!-- Appointment Participants -->
  <app-text
    textClasses="txt-percian-gray mb-3 mx-3 text-xs font-extrabold"
    text="PARTICIPANTS"
  ></app-text>
  <div class="flex gap-3 items-center mx-3 pb-3">
    <div class="bg-bold-gray p-2 rounded-md">
      <app-text
        textClasses="text-white mx-3 text-xs text-center font-light"
        [text]="appointment_user"
      ></app-text>
    </div>
    <div class="bg-bold-gray p-2 rounded-md">
      <app-text
        textClasses="text-white mx-3 text-xs text-center font-light"
        [text]="appointment_guest"
      ></app-text>
    </div>
  </div>
</div>
