<div [@fade]>
  <app-header
    headerClasses="mx-6 mt-8 flex justify-between items-center text-white mb-20"
  ></app-header>
  <div>
    <div>
      <div class="w-10/12 mx-auto">
        <app-title
          titleClasses="text-white text-center"
          text="Bienvenue sur Wiby"
        ></app-title>
      </div>
    </div>
    <div style="max-width: 500px;" class="bottom-0 flex flex-col bg-white rounded-t-xl absolute w-full">
      <!-- ACHAT OU LOCATION -->
      <div
        class="
          flex
          rounded-3xl
          bg-extra-ligth-gray
          w-10/12
          justify-between
          mx-auto
          my-3
        "
      >
        <app-primary-button
          (IsClicked)="selectServiceType('sale')"
          text="Achat"
          [buttonClasses]="
            service_type === 'sale'
              ? 'bg-light-blue text-white items-center justify-center w-36 py-1'
              : 'bg-extra-ligth-gray txt-bold-gray items-center justify-center w-36 py-1'
          "
          [disabled]="false"
          [icon_visibility]="false"
        ></app-primary-button>
        <app-primary-button
          (IsClicked)="selectServiceType('rent')"
          text="Location"
          [buttonClasses]="
            service_type === 'rent'
              ? 'bg-light-blue text-white items-center w-36 justify-center py-1'
              : 'bg-light-gray txt-bold-gray items-center w-36 justify-center py-1'
          "
          [disabled]="false"
          [icon_visibility]="false"
        ></app-primary-button>
      </div>
      <!-- GESTION ET DEPOT -->
      <div [ngClass]="isLoggedIn ? '': 'pb-3'" class="grid grid-cols-2 gap-3 mx-6">
        <app-secondary-card
          imgPath="assets/icons/svg/search.svg"
          imgClasses="h-16 mx-auto"
          imgBlockClasses="bg-extra-light-blue rounded-xl mx-auto p-3"
          cardClasses="bg-extra-light-blue rounded-xl text-center mx-auto py-3"
          textClasses="txt-bold-gray font-semibold text-sm mb-2 w-full mx-auto"
          text_button="Rechercher un bien"
          buttonClasses="rounded-full txt-bold-gray bg-white tracking-tighter p-1 text-sm text-center mx-auto w-4/5 mt-2"
          (IsClicked)="onClick('RECHERCHE')"
        ></app-secondary-card>
        <app-secondary-card
          imgPath="assets/icons/svg/bubble.svg"
          imgClasses="h-16 mx-auto"
          imgBlockClasses="bg-extra-light-blue rounded-xl mx-auto p-3"
          cardClasses="bg-extra-light-blue rounded-xl text-center mx-auto py-3"
          textClasses="txt-bold-gray font-semibold text-sm mb-2 w-full mx-auto"
          text_button="Créer une annonce"
          buttonClasses="rounded-full txt-bold-gray bg-white p-1 text-sm tracking-tighter text-center mx-auto w-4/5 mt-2"
          (IsClicked)="onClick('DEPOT')"
        ></app-secondary-card>
      </div>
      <!-- RENDEZ_VOUS -->
      <div
        [@fade]
        (click)="seePreviewAppointment()"
        *ngIf="flag_appointment_to_come && isLoggedIn"
        class="bg-ligth-gray p-3 flex justify-between mx-6 rounded-lg my-6"
      >
        <app-text
          textClasses="text-white bg-light-blue w-8 text-center p-1 rounded-full"
          [text]="appointment_to_come_number"
        ></app-text>
        <div class="flex gap-3 items-center">
          <app-text
            textClasses="txt-light-blue"
            text="Mes prochains rendez-vous"
          ></app-text>
          <img
            src="assets/icons/svg/arrow-down.svg"
            class="h-1 cursor-pointer mx-auto"
          />
        </div>
      </div>
      <div [@flyInOut] *ngIf="preview_appointment_to_come && isLoggedIn">
        <img
          (click)="seePreviewAppointment()"
          src="assets/icons/svg/arrow-down.svg"
          class="h-2 cursor-pointer mx-auto"
        />
        <app-text
          textClasses="txt-light-blue mx-6"
          text="Prochain rendez-vous"
        ></app-text>
        <div class="flex gap-8 overflow-auto mb-6 no-scrollbar ml-6 pr-6">
          <app-small-appointment-card
            *ngFor="let appointment of appointments_list_filtered"
            [appointment_date]="appointment.Appointment_date"
            [appointment_hour]="appointment.Appointment_hour"
            [appointment_type]="appointment.Appointment_type"
            [appointment_reason]="appointment.Appointment_reason"
            (IsClicked)="getUserAppointment(appointment.ID)"
          ></app-small-appointment-card>
        </div>
      </div>
    </div>
  </div>
</div>
