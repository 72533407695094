import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Input, Inject } from '@angular/core';

@Component({
  selector: 'app-loader-popup',
  templateUrl: './loader-popup.component.html',
  styleUrls: ['./loader-popup.component.scss'],
})
export class LoaderPopupComponent implements OnInit {
  @Input() text = 'text';
  @Input() isLoader = false;
  @Input() isError = false;

  constructor(
    public dialogRef: MatDialogRef<LoaderPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public options: {
      text: string;
      isLoader: boolean;
      isError: boolean;
    }
  ) {
    this.text = options.text;
    this.isError = options.isError;
    this.isLoader = options.isLoader;
  }

  ngOnInit(): void {}

  close(): void {
    this.dialogRef.close();
  }

  confirm($event: any): void {
    this.dialogRef.close($event);
  }
}
