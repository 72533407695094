import { LoaderPopupComponent } from 'src/app/shared/components/loader-popup/loader-popup.component';
import { environment } from 'src/environments/environment';
import { UploadPhotosS3Service } from './../../shared/services/upload-photos-s3.service';
import { CustomPopupComponent } from 'src/app/shared/components/custom-popup/custom-popup.component';
import { LocalStorageService } from './../../shared/services/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { PropertyModel } from 'src/app/shared/models/property-model';
import { v4 as uuidv4 } from 'uuid';
import { PropertyService } from 'src/app/shared/services/property.service';
// import Swiper core and required modules
import SwiperCore, { EffectCreative, Pagination, Navigation } from 'swiper';

// install Swiper modules
SwiperCore.use([EffectCreative, Pagination, Navigation]);

@Component({
  selector: 'app-put-a-post',
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
    ]),
  ],
  templateUrl: './put-a-post.component.html',
  styleUrls: ['./put-a-post.component.scss'],
})
export class PutAPostComponent implements OnInit {
  @Input() content: any;
  @Input() title: string = 'Déposer une annonce';
  @Input() cardClasses: string = '';
  @Input() default: string = 'bg-gray-200 rounded-xl text-center mx-auto py-6';
  @Input() current_step: string ;
  @Input() propertyModel = new PropertyModel();
  @Input() user_type: string;
  @Input() propertyType: string;
  @Input() propertyState: string;
  @Input() serviceType: string;
  @Input() datetime: string;
  @Input() stepNumber: Number;
  @Input() stepsArray: any[] = [];
  public array_property_assets: any = [];
  public cloudfront_url = environment.cloudfront_url;
  public file_name: string;
  public media: any;
  public hasError: boolean = false;
  public edition_mode: boolean = false;

  // SALE OR RENT STEP ARRAY
  @Input() propertyTypeArray = [
    {
      controlName: 'property_type',
      path_img: 'assets/icons/svg/house.svg',
      item_value: 'Une maison/villa',
      item_value_db: 'MAISON_VILLA',
      type: 'PROPERTY_TYPE',
    },
    {
      controlName: 'property_type',
      path_img: 'assets/icons/svg/appartment.svg',
      item_value: 'Un appartement',
      item_value_db: 'APPARTEMENT',
      type: 'PROPERTY_TYPE',
    },
    {
      controlName: 'property_type',
      path_img: 'assets/icons/svg/land.svg',
      item_value: 'Un terrain',
      item_value_db: 'TERRAIN',
      type: 'PROPERTY_TYPE',
    },
    {
      controlName: 'property_type',
      path_img: 'assets/icons/svg/office.svg',
      item_value: 'Des bureaux',
      item_value_db: 'BUREAUX',
      type: 'PROPERTY_TYPE',
    },
  ];
  // HOUSE STEP ARRAY
  @Input() houseStepArray = [
    {
      controlName: 'property_subtype',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Une maison plain pieds',
      item_value_db: 'MAISON_PLAIN_PIEDS',
      type: 'PROPERTY_SUBTYPE',
    },
    {
      controlName: 'property_subtype',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Une maison à étage',
      item_value_db: 'MAISON_A_ETAGE',
      type: 'PROPERTY_SUBTYPE',
    },
    {
      controlName: 'property_subtype',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Une maison avec sous-sol',
      item_value_db: 'MAISON_AVEC_SOUS_SOL',
      type: 'PROPERTY_SUBTYPE',
    },
    {
      controlName: 'property_subtype',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Une maison avec comble',
      item_value_db: 'MAISON_AVEC_COMBLE',
      type: 'PROPERTY_SUBTYPE',
    },
  ];
  // APPARTMENT STEP ARRAY
  @Input() appartmentStepArray = [
    {
      controlName: 'property_subtype',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Un duplex',
      item_value_db: 'DUPLEX',
      type: 'PROPERTY_SUBTYPE',
    },
    {
      controlName: 'property_subtype',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Un triplex',
      item_value_db: 'TRIPLEX',
      type: 'PROPERTY_SUBTYPE',
    },
    {
      controlName: 'property_subtype',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Un rooftop',
      item_value_db: 'ROOFTOP',
      type: 'PROPERTY_SUBTYPE',
    },
    {
      controlName: 'property_subtype',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Je ne sais pas',
      item_value_db: 'NON_RENSEIGNE',
      type: 'PROPERTY_SUBTYPE',
    },
  ];
  // OFFICE STEP ARRAY
  @Input() officeStepArray = [
    {
      controlName: 'property_subtype',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Un bureau en open space',
      item_value_db: 'BUREAU_EN_OPEN_SPACE',
      type: 'PROPERTY_SUBTYPE',
    },
    {
      controlName: 'property_subtype',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Un bureau appartement',
      item_value_db: 'BUREAU_APPARTEMENT',
      type: 'PROPERTY_SUBTYPE',
    },
    {
      controlName: 'property_subtype',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Un bureau en coworking',
      item_value_db: 'BUREAU_EN_COWORKING',
      type: 'PROPERTY_SUBTYPE',
    },
    {
      controlName: 'property_subtype',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Je ne sais pas',
      item_value_db: 'NON_RENSEIGNE',
      type: 'PROPERTY_SUBTYPE',
    },
  ];
  // LAND STEP ARRAY
  @Input() landStepArray = [
    {
      controlName: 'property_subtype',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Un terrain constructible particulier',
      item_value_db: 'TERRAIN_CONSTRUCTIBLE_PARTICULIER',
      type: 'PROPERTY_SUBTYPE',
    },
    {
      controlName: 'property_subtype',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Un terrain constructible professionnelle',
      item_value_db: 'TERRAIN_CONSTRUCTIBLE_PROFESSIONNELLE',
      type: 'PROPERTY_SUBTYPE',
    },
    {
      controlName: 'property_subtype',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Un terrain agricole',
      item_value_db: 'TERRAIN_AGRICOLE',
      type: 'PROPERTY_SUBTYPE',
    },
    {
      controlName: 'property_subtype',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Je ne sais pas',
      item_value_db: 'NON_RENSEIGNE',
      type: 'PROPERTY_SUBTYPE',
    },
  ];
  // PROPERTY STEP ARRAY
  @Input() propertyStepArray = [
    {
      controlName: 'property_state',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Un bien ancien refait à neuf',
      item_value_db: 'BIEN_ANCIEN_REFAIT_A_NEUF',
      type: 'PROPERTY_STATE',
    },
    {
      controlName: 'property_state',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Un bien ancien avec travaux',
      item_value_db: 'BIEN_ANCIEN_AVEC_TRAVAUX',
      type: 'PROPERTY_STATE',
    },
    {
      controlName: 'property_state',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Un bien neuf',
      item_value_db: 'BIEN_NEUF',
      type: 'PROPERTY_STATE',
    },
    {
      controlName: 'property_state',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Je ne sais pas',
      item_value_db: 'NON_RENSEIGNE',
      type: 'PROPERTY_STATE',
    },
  ];
  // LAND_STEP ARRAY
  @Input() land_stepArray = [
    {
      controlName: 'property_state',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Un terrain viabilisé',
      item_value_db: 'TERRAIN_VIABILISE',
      type: 'PROPERTY_STATE',
    },
    {
      controlName: 'property_state',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Un terrain non viabilisé',
      item_value_db: 'TERRAIN_NON_VIABILISE',
      type: 'PROPERTY_STATE',
    },
    {
      controlName: 'property_state',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Je ne sais pas',
      item_value_db: 'NON_RENSEIGNE',
      type: 'PROPERTY_STATE',
    },
  ];
  // PIECES NUMBER STEP ARRAY
  @Input() piecesNumberStepArray = [
    {
      controlName: 'piecesNumberFields',
      item_value: '1',
      type: 'PIECES_NUMBER',
    },
    {
      controlName: 'piecesNumberFields',
      item_value: '2',
      type: 'PIECES_NUMBER',
    },
    {
      controlName: 'piecesNumberFields',
      item_value: '3',
      type: 'PIECES_NUMBER',
    },
    {
      controlName: 'piecesNumberFields',
      item_value: '4',
      type: 'PIECES_NUMBER',
    },
    {
      controlName: 'piecesNumberFields',
      item_value: '5+',
      type: 'PIECES_NUMBER',
    },
  ];
  // ENERGETIC CLASS ARRAY
  @Input() energeticClassArray = [
    {
      controlName: 'energetic_class',
      item_value: 'A',
      type: 'ENERGETIC_CLASS',
    },
    {
      controlName: 'energetic_class',
      item_value: 'B',
      type: 'ENERGETIC_CLASS',
    },
    {
      controlName: 'energetic_class',
      item_value: 'C',
      type: 'ENERGETIC_CLASS',
    },
    {
      controlName: 'energetic_class',
      item_value: 'D',
      type: 'ENERGETIC_CLASS',
    },
    {
      controlName: 'energetic_class',
      item_value: 'E',
      type: 'ENERGETIC_CLASS',
    },
    {
      controlName: 'energetic_class',
      item_value: 'F',
      type: 'ENERGETIC_CLASS',
    },
    {
      controlName: 'energetic_class',
      item_value: 'G',
      type: 'ENERGETIC_CLASS',
    },
    {
      controlName: 'energetic_class',
      item_value: 'VIERGE',
      type: 'ENERGETIC_CLASS',
    },
  ];
  // GES ARRAY
  @Input() gesArray = [
    {
      controlName: 'gas_emissions',
      item_value: 'A',
      type: 'GAS_EMISSIONS',
    },
    {
      controlName: 'gas_emissions',
      item_value: 'B',
      type: 'GAS_EMISSIONS',
    },
    {
      controlName: 'gas_emissions',
      item_value: 'C',
      type: 'GAS_EMISSIONS',
    },
    {
      controlName: 'gas_emissions',
      item_value: 'D',
      type: 'GAS_EMISSIONS',
    },
    {
      controlName: 'gas_emissions',
      item_value: 'E',
      type: 'GAS_EMISSIONS',
    },
    {
      controlName: 'gas_emissions',
      item_value: 'F',
      type: 'GAS_EMISSIONS',
    },
    {
      controlName: 'gas_emissions',
      item_value: 'G',
      type: 'GAS_EMISSIONS',
    },
    {
      controlName: 'gas_emissions',
      item_value: 'VIERGE',
      type: 'GAS_EMISSIONS',
    },
  ];
  public service_type: string;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private uploadPhotosS3Service: UploadPhotosS3Service,
    private propertyService: PropertyService,
    private route: ActivatedRoute
  ) {
    this.service_type = this.route.snapshot.paramMap.get('service_type');
    this.user_type = this.localStorageService.getUserType();
    this.checkStepNumber();
    this.getServiceType();
  }

  onSwiper(swiper) {
    console.log(swiper);
  }
  onSlideChange() {
    console.log('slide change');
  }

  ngOnInit(): void {
    this.propertyModel.User_id = this.localStorageService.getCurrentUser().id;
    if (
      this.route.snapshot.paramMap.get('id') &&
      this.route.snapshot.paramMap.get('service_type')
    ) {
      console.log('xxx');

      this.propertyModel.ID = this.route.snapshot.paramMap.get('id');
      this.propertyModel.Service_type = this.route.snapshot.paramMap
        .get('service_type')
        .toLocaleUpperCase();
      this.current_step = this.propertyModel.Service_type;
      this.switchStep();
      this.getOneProperty();
      this.edition_mode = true;
    }
    this.stepNumber = 1;
  }

  // This function get the current date to display it on the create property workflow final step
  getDate() {
    var today = new Date();
    var day = today.getDate();
    let month = today.toLocaleString('fr-FR', { month: 'long' });
    var time = today.getHours() + ':' + today.getMinutes();
    this.datetime = day + ' ' + month + ' à ' + time;
  }

  // BACK FUNCTION //
  back() {
    switch (this.current_step) {
      case 'SALE':
        this.router.navigate(['/home']);
        break;
      case 'RENT':
        this.router.navigate(['/home']);
        break;
      case 'HOUSE':
        if (this.propertyModel.Service_type === 'VENTE') {
          this.title = 'Mise en vente';
          this.current_step = 'SALE';
          this.propertyModel = new PropertyModel();
        } else {
          this.title = 'Mise en location';
          this.current_step = 'RENT';
          this.propertyModel = new PropertyModel();
        }
        this.stepNumber = 1;
        break;
      case 'APPARTMENT':
        if (this.propertyModel.Service_type === 'VENTE') {
          this.title = 'Mise en vente';
          this.current_step = 'SALE';
        } else {
          this.title = 'Mise en location';
          this.current_step = 'RENT';
        }
        this.stepNumber = 1;
        break;
      case 'OFFICE':
        if (this.propertyModel.Service_type === 'VENTE') {
          this.title = 'Mise en vente';
          this.current_step = 'SALE';
        } else {
          this.title = 'Mise en location';
          this.current_step = 'RENT';
        }
        this.stepNumber = 1;
        break;
      case 'LAND':
        if (this.propertyModel.Service_type === 'VENTE') {
          this.title = 'Mise en vente';
          this.current_step = 'SALE';
        } else {
          this.title = 'Mise en location';
          this.current_step = 'RENT';
        }
        this.stepNumber = 1;
        break;
      case 'PROPERTY_STATE':
        if (this.propertyModel.Service_type === 'VENTE') {
          this.title = 'Mise en vente';
        } else {
          this.title = 'Mise en location';
        }
        if (
          this.propertyModel.Property_subtype === 'MAISON_PLAIN_PIEDS' ||
          this.propertyModel.Property_subtype === 'MAISON_A_ETAGE' ||
          this.propertyModel.Property_subtype === 'MAISON_AVEC_COMBLE' ||
          this.propertyModel.Property_subtype === 'MAISON_AVEC_SOUS_SOL'
        ) {
          this.current_step = 'HOUSE';
        } else if (
          this.propertyModel.Property_subtype === 'DUPLEX' ||
          this.propertyModel.Property_subtype === 'TRIPLEX' ||
          this.propertyModel.Property_subtype === 'ROOFTOP' ||
          this.propertyModel.Property_subtype === 'NON_RENSEIGNE'
        ) {
          this.current_step = 'APPARTMENT';
        } else if (
          this.propertyModel.Property_subtype === 'BUREAU_EN_OPEN_SPACE' ||
          this.propertyModel.Property_subtype === 'BUREAU_APPARTEMENT' ||
          this.propertyModel.Property_subtype === 'BUREAU_EN_COWORKING' ||
          this.propertyModel.Property_subtype === 'NON_RENSEIGNE'
        ) {
          this.current_step = 'OFFICE';
        }
        this.stepNumber = 2;
        break;
      case 'LAND_STATE':
        if (this.propertyModel.Service_type === 'VENTE') {
          this.title = 'Mise en vente';
        } else {
          this.title = 'Mise en location';
        }
        if (
          this.propertyModel.Property_subtype ===
            'TERRAIN_CONSTRUCTIBLE_PARTICULIER' ||
          this.propertyModel.Property_subtype ===
            'TERRAIN_CONSTRUCTIBLE_PROFESSIONNELLE' ||
          this.propertyModel.Property_subtype === 'TERRAIN_AGRICOLE' ||
          this.propertyModel.Property_subtype === 'NON_RENSEIGNE'
        ) {
          this.current_step = 'LAND';
        }
        this.stepNumber = 2;
        break;
      case 'SALE_PRICE':
        this.title = 'Mise en vente';
        if (this.propertyModel.Property_type === 'TERRAIN') {
          this.current_step = 'LAND_STATE';
        } else {
          this.current_step = 'PROPERTY_STATE';
        }
        this.stepNumber = 3;
        break;
      case 'RENT_PRICE':
        this.title = 'Mise en location';
        if (this.propertyModel.Property_type === 'TERRAIN') {
          this.current_step = 'LAND_STATE';
        } else {
          this.current_step = 'PROPERTY_STATE';
        }
        this.stepNumber = 3;
        break;
      case 'POSTAL_CODE_AND_CITY':
        if (this.propertyModel.Service_type === 'VENTE') {
          this.title = 'Mise en vente';
        } else {
          this.title = 'Mise en location';
        }
        if (this.propertyModel.Service_type === 'LOCATION') {
          this.current_step = 'RENT_PRICE';
        } else {
          this.current_step = 'SALE_PRICE';
        }
        this.stepNumber = 4;
        break;
      case 'PIECES_NUMBER':
        if (this.propertyModel.Service_type === 'VENTE') {
          this.title = 'Mise en vente';
        } else {
          this.title = 'Mise en location';
        }
        this.current_step = 'POSTAL_CODE_AND_CITY';
        this.stepNumber = 5;
        break;
      case 'SUPERFICY':
        if (this.propertyModel.Service_type === 'VENTE') {
          this.title = 'Mise en vente';
        } else {
          this.title = 'Mise en location';
        }
        if (
          this.propertyModel.Property_type === 'MAISON_VILLA' ||
          this.propertyModel.Property_type === 'APPARTEMENT' ||
          this.propertyModel.Property_type === 'BUREAUX'
        ) {
          this.current_step = 'PIECES_NUMBER';
          this.stepNumber = 6;
        } else {
          this.current_step = 'POSTAL_CODE_AND_CITY';
          this.stepNumber = 5;
        }
        break;
      case 'CHARACTERISTICS_APPARTMENT':
        if (this.propertyModel.Service_type === 'VENTE') {
          this.title = 'Mise en vente';
        } else {
          this.title = 'Mise en location';
        }
        this.current_step = 'SUPERFICY';
        this.stepNumber = 7;
        break;
      case 'CHARACTERISTICS_OFFICE':
        if (this.propertyModel.Service_type === 'VENTE') {
          this.title = 'Mise en vente';
        } else {
          this.title = 'Mise en location';
        }
        this.current_step = 'SUPERFICY';
        this.stepNumber = 7;
        break;
      case 'CHARACTERISTICS_LAND':
        if (this.propertyModel.Service_type === 'VENTE') {
          this.title = 'Mise en vente';
        } else {
          this.title = 'Mise en location';
        }
        this.current_step = 'SUPERFICY';
        this.stepNumber = 7;
        break;
      case 'PHOTOS':
        if (this.propertyModel.Service_type === 'VENTE') {
          this.title = 'Mise en vente';
        } else {
          this.title = 'Mise en location';
        }
        if (
          this.propertyModel.Property_type === 'MAISON_VILLA' ||
          this.propertyModel.Property_type === 'APPARTEMENT'
        ) {
          this.current_step = 'CHARACTERISTICS_APPARTMENT';
        } else if (this.propertyModel.Property_type === 'BUREAUX') {
          this.current_step = 'CHARACTERISTICS_OFFICE';
        } else if (this.propertyModel.Property_type === 'TERRAIN') {
          this.current_step = 'CHARACTERISTICS_LAND';
        }
        this.stepNumber = 8;
        break;
      case 'DESCRIPTION':
        if (this.propertyModel.Service_type === 'VENTE') {
          this.title = 'Mise en vente';
        } else {
          this.title = 'Mise en location';
        }
        this.current_step = 'PHOTOS';
        this.stepNumber = 9;
        break;
      case 'CLIENT_REQUEST':
        if (this.propertyModel.Service_type === 'VENTE') {
          this.title = 'Mise en vente';
        } else {
          this.title = 'Mise en location';
        }
        this.current_step = 'DESCRIPTION';
        this.stepNumber = 10;
        break;
      case 'CREATE_PROPERTY_FINAL':
        if (this.propertyModel.Service_type === 'VENTE') {
          this.title = 'Mise en vente';
        } else {
          this.title = 'Mise en location';
        }
        this.current_step = 'CLIENT_REQUEST';
        break;
      default:
        //
        break;
    }
  }

  // This function add an "RADIO STYLE" for items selected / When the item is selected, add selected style on it and remove selected style on old item selected
  isSelected($propertyModel: any, $id: any) {
    var element = document.getElementById($id);
    element?.classList.add('active');
    if ($propertyModel !== $id && $propertyModel !== '') {
      var elementToRemove = document.getElementById($propertyModel);
      elementToRemove?.classList.remove('active');
    }
  }

  // This function transform specific value to display it in front
  transformValue() {
    if (this.propertyModel.Property_subtype === 'MAISON_PLAIN_PIEDS') {
      this.propertyType = 'Maison plain pieds';
    }
    if (this.propertyModel.Property_subtype === 'MAISON_A_ETAGE') {
      this.propertyType = 'Maison à étage';
    }
    if (this.propertyModel.Property_subtype === 'MAISON_AVEC_SOUS_SOL') {
      this.propertyType = 'Maison avec sous-sol';
    }
    if (this.propertyModel.Property_subtype === 'MAISON_AVEC_COMBLE') {
      this.propertyType = 'Maison avec comble';
    }
    if (this.propertyModel.Property_subtype === 'DUPLEX') {
      this.propertyType = 'Duplex';
    }
    if (this.propertyModel.Property_subtype === 'TRIPLEX') {
      this.propertyType = 'Triplex';
    }
    if (this.propertyModel.Property_subtype === 'ROOFTOP') {
      this.propertyType = 'Rooftop';
    }
    if (this.propertyModel.Property_subtype === 'BUREAU_EN_OPEN_SPACE') {
      this.propertyType = 'Bureau en open space';
    }
    if (this.propertyModel.Property_subtype === 'BUREAU_APPARTEMENT') {
      this.propertyType = 'Bureau appartement';
    }
    if (this.propertyModel.Property_subtype === 'BUREAU_EN_COWORKING') {
      this.propertyType = 'Bureau en coworking';
    }
    if (
      this.propertyModel.Property_subtype ===
      'TERRAIN_CONSTRUCTIBLE_PARTICULIER'
    ) {
      this.propertyType = 'Terrain constructible particulier';
    }
    if (
      this.propertyModel.Property_subtype ===
      'TERRAIN_CONSTRUCTIBLE_PROFESSIONNELLE'
    ) {
      this.propertyType = 'Terrain constructible professionnelle';
    }
    if (this.propertyModel.Property_subtype === 'TERRAIN_AGRICOLE') {
      this.propertyType = 'Terrain agricole';
    }
    if (this.propertyModel.Property_subtype === 'NON_RENSEIGNE') {
      this.propertyType = 'Non renseigné';
    }
    if (this.propertyModel.Service_type === 'LOCATION') {
      this.serviceType = 'Location';
    }
    if (this.propertyModel.Service_type === 'VENTE') {
      this.serviceType = 'Vente';
    }

    if (this.propertyModel.Property_state === 'BIEN_ANCIEN_REFAIT_A_NEUF') {
      this.propertyState = 'Bien ancien refait à neuf';
    }
    if (this.propertyModel.Property_state === 'BIEN_ANCIEN_AVEC_TRAVAUX') {
      this.propertyState = 'Bien ancien avec travaux';
    }
    if (this.propertyModel.Property_state === 'BIEN_NEUF') {
      this.propertyState = 'Bien neuf';
    }
    if (this.propertyModel.Property_state === 'NON_RENSEIGNE') {
      this.propertyState = 'Non renseigné';
    }
    if (this.propertyModel.Property_state === 'TERRAIN_VIABILISE') {
      this.propertyState = 'Terrain viabilisé';
    }
    if (this.propertyModel.Property_state === 'TERRAIN_NON_VIABILISE') {
      this.propertyState = 'Terrain non viabilisé';
    }
    if (
      this.propertyModel.Total_area === 'NON_RENSEIGNE' ||
      this.propertyModel.Total_area === 0
    ) {
      this.propertyModel.Total_area = 'Non renseigné';
    }
    if (
      this.propertyModel.Price === 'NON_RENSEIGNE' ||
      this.propertyModel.Price === 0
    ) {
      this.propertyModel.Price = 'Non renseigné';
    }
  }

  // SWITCH STEP FUNCTION // TO DO => PROGRESS BAR FUNCTION
  switchStep() {
    switch (this.current_step) {
      case 'SALE':
        if (this.propertyModel.Property_type === 'APPARTEMENT') {
          this.current_step = 'APPARTMENT';
        } else if (this.propertyModel.Property_type === 'MAISON_VILLA') {
          this.current_step = 'HOUSE';
        } else if (this.propertyModel.Property_type === 'BUREAUX') {
          this.current_step = 'OFFICE';
        } else if (this.propertyModel.Property_type === 'TERRAIN') {
          this.current_step = 'LAND';
        }
        this.title = 'Mise en vente';
        this.stepNumber = 2;

        break;
      case 'RENT':
        if (this.propertyModel.Property_type === 'APPARTEMENT') {
          this.current_step = 'APPARTMENT';
        } else if (this.propertyModel.Property_type === 'MAISON_VILLA') {
          this.current_step = 'HOUSE';
        } else if (this.propertyModel.Property_type === 'BUREAUX') {
          this.current_step = 'OFFICE';
        } else if (this.propertyModel.Property_type === 'TERRAIN') {
          this.current_step = 'LAND';
        }
        this.title = 'Mise en location';
        this.stepNumber = 2;
        break;
      case 'HOUSE':
        if (
          this.propertyModel.Property_subtype === 'MAISON_PLAIN_PIEDS' ||
          this.propertyModel.Property_subtype === 'MAISON_A_ETAGE' ||
          this.propertyModel.Property_subtype === 'MAISON_AVEC_SOUS_SOL' ||
          this.propertyModel.Property_subtype === 'MAISON_AVEC_COMBLE'
        ) {
          this.current_step = 'PROPERTY_STATE';
          this.stepNumber = 3;
        }
        break;
      case 'APPARTMENT':
        if (
          this.propertyModel.Property_subtype === 'DUPLEX' ||
          this.propertyModel.Property_subtype === 'TRIPLEX' ||
          this.propertyModel.Property_subtype === 'ROOFTOP' ||
          this.propertyModel.Property_subtype === 'NON_RENSEIGNE'
        ) {
          this.current_step = 'PROPERTY_STATE';
          this.stepNumber = 3;
        }
        break;
      case 'OFFICE':
        if (
          this.propertyModel.Property_subtype === 'BUREAU_EN_OPEN_SPACE' ||
          this.propertyModel.Property_subtype === 'BUREAU_APPARTEMENT' ||
          this.propertyModel.Property_subtype === 'BUREAU_EN_COWORKING' ||
          this.propertyModel.Property_subtype === 'NON_RENSEIGNE'
        ) {
          this.current_step = 'PROPERTY_STATE';
          this.stepNumber = 3;
        }
        break;
      case 'LAND':
        if (
          this.propertyModel.Property_subtype ===
            'TERRAIN_CONSTRUCTIBLE_PARTICULIER' ||
          this.propertyModel.Property_subtype ===
            'TERRAIN_CONSTRUCTIBLE_PROFESSIONNELLE' ||
          this.propertyModel.Property_subtype === 'TERRAIN_AGRICOLE' ||
          this.propertyModel.Property_subtype === 'NON_RENSEIGNE'
        ) {
          this.current_step = 'LAND_STATE';
          this.stepNumber = 3;
        }
        break;
      case 'PROPERTY_STATE':
        if (
          this.propertyModel.Property_state &&
          this.propertyModel.Property_state !== ''
        ) {
          if (this.propertyModel.Service_type === 'LOCATION') {
            this.current_step = 'RENT_PRICE';
          } else {
            this.current_step = 'SALE_PRICE';
          }
          this.stepNumber = 4;
        }
        break;
      case 'LAND_STATE':
        if (
          this.propertyModel.Property_state &&
          this.propertyModel.Property_state !== ''
        ) {
          if (this.propertyModel.Service_type === 'LOCATION') {
            this.current_step = 'RENT_PRICE';
          } else {
            this.current_step = 'SALE_PRICE';
          }
          this.stepNumber = 4;
        }
        break;
      case 'SALE_PRICE':
        if (this.propertyModel.Price && this.propertyModel.Price !== '') {
          this.current_step = 'POSTAL_CODE_AND_CITY';
          this.stepNumber = 5;
        }
        break;
      case 'RENT_PRICE':
        if (this.propertyModel.Price && this.propertyModel.Price !== '') {
          this.current_step = 'POSTAL_CODE_AND_CITY';
          this.stepNumber = 5;
        }
        break;
      case 'POSTAL_CODE_AND_CITY':
        if (
          this.propertyModel.Postal_code &&
          this.propertyModel.City &&
          this.propertyModel.Postal_code !== '' &&
          this.propertyModel.City !== ''
        ) {
          if (
            this.propertyModel.Property_type === 'MAISON_VILLA' ||
            this.propertyModel.Property_type === 'APPARTEMENT' ||
            this.propertyModel.Property_type === 'BUREAUX'
          ) {
            this.current_step = 'PIECES_NUMBER';
          } else {
            this.current_step = 'SUPERFICY';
          }
          this.stepNumber = 6;
        }
        break;
      case 'PIECES_NUMBER':
        if (
          this.propertyModel.Number_of_pieces &&
          this.propertyModel.Number_of_pieces !== ''
        ) {
          this.current_step = 'SUPERFICY';
          this.stepNumber = 7;
        }
        break;
      case 'SUPERFICY':
        if (
          this.propertyModel.Property_type === 'MAISON_VILLA' ||
          this.propertyModel.Property_type === 'APPARTEMENT'
        ) {
          this.current_step = 'CHARACTERISTICS_APPARTMENT';
        } else if (this.propertyModel.Property_type === 'BUREAUX') {
          this.current_step = 'CHARACTERISTICS_OFFICE';
        } else if (this.propertyModel.Property_type === 'TERRAIN') {
          this.current_step = 'CHARACTERISTICS_LAND';
        }
        this.stepNumber = 8;
        break;
      case 'CHARACTERISTICS_APPARTMENT':
        this.current_step = 'PHOTOS';
        this.stepNumber = 9;
        break;
      case 'CHARACTERISTICS_LAND':
        this.current_step = 'PHOTOS';
        this.stepNumber = 9;
        break;
      case 'CHARACTERISTICS_OFFICE':
        this.current_step = 'PHOTOS';
        this.stepNumber = 9;
        break;
      case 'PHOTOS':
        this.current_step = 'DESCRIPTION';
        this.stepNumber = 10;
        break;
      case 'DESCRIPTION':
        this.current_step = 'CLIENT_REQUEST';
        break;
      case 'CLIENT_REQUEST':
        this.current_step = 'CREATE_PROPERTY_FINAL';
        this.transformValue();
        this.getDate();
        break;
      default:
        //
        break;
    }
  }

  // This function get the pieces number / energetic class / ges
  getItemValue($event: any) {
    if ($event.type === 'PIECES_NUMBER' && $event.item_value !== '') {
      console.log($event, this.propertyModel);

      this.propertyModel.Number_of_pieces = $event.item_value;
    }
    if ($event.type === 'ENERGETIC_CLASS' && $event.item_value !== '') {
      console.log($event, this.propertyModel);

      this.propertyModel.Energetic_class = $event.item_value;
    }
    if ($event.type === 'GAS_EMISSIONS' && $event.item_value !== '') {
      console.log($event, this.propertyModel);

      this.propertyModel.Gas_emissions = $event.item_value;
    }
  }

  /**
   * getServiceType
   */
  public getServiceType() {
    switch (this.service_type) {
      case 'rent':
        this.propertyModel.Service_type = 'LOCATION';
        this.current_step = 'RENT';
        this.title = 'Mise en location';
        break;
      case 'sale':
        this.propertyModel.Service_type = 'VENTE';
        this.current_step = 'SALE';
        this.title = 'Mise en vente';
        break;

      default:
        break;
    }
  }

  // Get set or selected value
  onChange($event: any): void {
    console.log('EVENT', $event);
    if ($event.value === 'PROPERTY_TYPE' && $event.id === 'MAISON_VILLA') {
      console.log('this.propertyModel', this.propertyModel);

      this.isSelected(this.propertyModel.Property_type, $event.id);
      this.propertyModel.Property_type = $event.id;
    }
    if ($event.value === 'PROPERTY_TYPE' && $event.id === 'APPARTEMENT') {
      this.isSelected(this.propertyModel.Property_type, $event.id);
      this.propertyModel.Property_type = $event.id;
    }
    if ($event.value === 'PROPERTY_TYPE' && $event.id === 'BUREAUX') {
      this.isSelected(this.propertyModel.Property_type, $event.id);
      this.propertyModel.Property_type = $event.id;
    }
    if ($event.value === 'PROPERTY_TYPE' && $event.id === 'TERRAIN') {
      this.isSelected(this.propertyModel.Property_type, $event.id);
      this.propertyModel.Property_type = $event.id;
    }
    if (
      $event.value === 'PROPERTY_SUBTYPE' &&
      $event.id === 'MAISON_PLAIN_PIEDS'
    ) {
      this.isSelected(this.propertyModel.Property_subtype, $event.id);
      this.propertyModel.Property_subtype = $event.id;
    }
    if ($event.value === 'PROPERTY_SUBTYPE' && $event.id === 'MAISON_A_ETAGE') {
      this.isSelected(this.propertyModel.Property_subtype, $event.id);
      this.propertyModel.Property_subtype = $event.id;
    }
    if (
      $event.value === 'PROPERTY_SUBTYPE' &&
      $event.id === 'MAISON_AVEC_SOUS_SOL'
    ) {
      this.isSelected(this.propertyModel.Property_subtype, $event.id);
      this.propertyModel.Property_subtype = $event.id;
    }
    if (
      $event.value === 'PROPERTY_SUBTYPE' &&
      $event.id === 'MAISON_AVEC_COMBLE'
    ) {
      this.isSelected(this.propertyModel.Property_subtype, $event.id);
      this.propertyModel.Property_subtype = $event.id;
    }
    if ($event.value === 'PROPERTY_SUBTYPE' && $event.id === 'DUPLEX') {
      this.isSelected(this.propertyModel.Property_subtype, $event.id);
      this.propertyModel.Property_subtype = $event.id;
    }
    if ($event.value === 'PROPERTY_SUBTYPE' && $event.id === 'TRIPLEX') {
      this.isSelected(this.propertyModel.Property_subtype, $event.id);
      this.propertyModel.Property_subtype = $event.id;
    }
    if ($event.value === 'PROPERTY_SUBTYPE' && $event.id === 'ROOFTOP') {
      this.isSelected(this.propertyModel.Property_subtype, $event.id);
      this.propertyModel.Property_subtype = $event.id;
    }
    if (
      $event.value === 'PROPERTY_SUBTYPE' &&
      $event.id === 'BUREAU_EN_OPEN_SPACE'
    ) {
      this.isSelected(this.propertyModel.Property_subtype, $event.id);
      this.propertyModel.Property_subtype = $event.id;
    }
    if (
      $event.value === 'PROPERTY_SUBTYPE' &&
      $event.id === 'BUREAU_APPARTEMENT'
    ) {
      this.isSelected(this.propertyModel.Property_subtype, $event.id);
      this.propertyModel.Property_subtype = $event.id;
    }
    if (
      $event.value === 'PROPERTY_SUBTYPE' &&
      $event.id === 'BUREAU_EN_COWORKING'
    ) {
      this.isSelected(this.propertyModel.Property_subtype, $event.id);
      this.propertyModel.Property_subtype = $event.id;
    }
    if (
      $event.value === 'PROPERTY_SUBTYPE' &&
      $event.id === 'TERRAIN_CONSTRUCTIBLE_PARTICULIER'
    ) {
      this.isSelected(this.propertyModel.Property_subtype, $event.id);
      this.propertyModel.Property_subtype = $event.id;
    }
    if (
      $event.value === 'PROPERTY_SUBTYPE' &&
      $event.id === 'TERRAIN_CONSTRUCTIBLE_PROFESSIONNELLE'
    ) {
      this.isSelected(this.propertyModel.Property_subtype, $event.id);
      this.propertyModel.Property_subtype = $event.id;
    }
    if (
      $event.value === 'PROPERTY_SUBTYPE' &&
      $event.id === 'TERRAIN_AGRICOLE'
    ) {
      this.isSelected(this.propertyModel.Property_subtype, $event.id);
      this.propertyModel.Property_subtype = $event.id;
    }
    if ($event.value === 'PROPERTY_SUBTYPE' && $event.id === 'NON_RENSEIGNE') {
      this.isSelected(this.propertyModel.Property_subtype, $event.id);
      this.propertyModel.Property_subtype = $event.id;
    }
    if (
      $event.value === 'PROPERTY_STATE' &&
      $event.id === 'BIEN_ANCIEN_REFAIT_A_NEUF'
    ) {
      this.isSelected(this.propertyModel.Property_state, $event.id);
      this.propertyModel.Property_state = $event.id;
    }
    if (
      $event.value === 'PROPERTY_STATE' &&
      $event.id === 'BIEN_ANCIEN_AVEC_TRAVAUX'
    ) {
      this.isSelected(this.propertyModel.Property_state, $event.id);
      this.propertyModel.Property_state = $event.id;
    }
    if ($event.value === 'PROPERTY_STATE' && $event.id === 'BIEN_NEUF') {
      this.isSelected(this.propertyModel.Property_state, $event.id);
      this.propertyModel.Property_state = $event.id;
    }
    if ($event.value === 'PROPERTY_STATE' && $event.id === 'NON_RENSEIGNE') {
      this.isSelected(this.propertyModel.Property_state, $event.id);
      this.propertyModel.Property_state = $event.id;
    }
    if (
      $event.value === 'PROPERTY_STATE' &&
      $event.id === 'TERRAIN_VIABILISE'
    ) {
      this.isSelected(this.propertyModel.Property_state, $event.id);
      this.propertyModel.Property_state = $event.id;
    }
    if (
      $event.value === 'PROPERTY_STATE' &&
      $event.id === 'TERRAIN_NON_VIABILISE'
    ) {
      this.isSelected(this.propertyModel.Property_state, $event.id);
      this.propertyModel.Property_state = $event.id;
    }
    if ($event.value === 'PRICE' && $event.model !== '') {
      this.propertyModel.Price = $event.model;
    }
    if ($event.value === 'AMOUNT_OF_CHARGES' && $event.model !== '') {
      this.propertyModel.Amount_of_charges = $event.model;
    }
    if ($event.value === 'WITHOUT_PRICE' && $event.model !== '') {
      console.log($event, this.propertyModel);

      this.propertyModel.Price = 'NON_RENSEIGNE';
    }
    if ($event.value === 'POSTAL_CODE' && $event.model !== '') {
      console.log($event, this.propertyModel);

      this.propertyModel.Postal_code = $event.model;
    }
    if ($event.value === 'CITY' && $event.model !== '') {
      console.log($event, this.propertyModel);

      this.propertyModel.City = $event.model;
    }
    if ($event.value === 'SUPERFICY' && $event.model !== '') {
      console.log($event, this.propertyModel);

      this.propertyModel.Total_area = $event.model;
    }
    if ($event.value === 'WITHOUT_SUPERFICY' && $event.model !== '') {
      console.log($event, this.propertyModel);

      this.propertyModel.Total_area = 'NON_RENSEIGNE';
    }
    if ($event.value === 'NOT_OVERLOOKED') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Not_overlooked === false ||
        !this.propertyModel.Not_overlooked
      ) {
        this.propertyModel.Not_overlooked = true;
      } else if (this.propertyModel.Not_overlooked === true) {
        this.propertyModel.Not_overlooked = false;
      }
    }
    if ($event.value === 'NON_ADJOINING') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Non_adjoining === false ||
        !this.propertyModel.Non_adjoining
      ) {
        this.propertyModel.Non_adjoining = true;
      } else if (this.propertyModel.Non_adjoining === true) {
        this.propertyModel.Non_adjoining = false;
      }
    }
    if ($event.value === 'OVERVIEW_SEA') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Overview_sea === false ||
        !this.propertyModel.Overview_sea
      ) {
        this.propertyModel.Overview_sea = true;
      } else if (this.propertyModel.Overview_sea === true) {
        this.propertyModel.Overview_sea = false;
      }
    }
    if ($event.value === 'SEA_VIEW') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Sea_view === false ||
        !this.propertyModel.Sea_view
      ) {
        this.propertyModel.Sea_view = true;
      } else if (this.propertyModel.Sea_view === true) {
        this.propertyModel.Sea_view = false;
      }
    }
    if ($event.value === 'PANORAMIC_VIEW') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Panoramic_view === false ||
        !this.propertyModel.Panoramic_view
      ) {
        this.propertyModel.Panoramic_view = true;
      } else if (this.propertyModel.Panoramic_view === true) {
        this.propertyModel.Panoramic_view = false;
      }
    }
    if ($event.value === 'INDUSTRIAL_ESTATE') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Industrial_estate === false ||
        !this.propertyModel.Industrial_estate
      ) {
        this.propertyModel.Industrial_estate = true;
      } else if (this.propertyModel.Industrial_estate === true) {
        this.propertyModel.Industrial_estate = false;
      }
    }
    if ($event.value === 'BUSINESS_DISTRICT') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Business_district === false ||
        !this.propertyModel.Business_district
      ) {
        this.propertyModel.Business_district = true;
      } else if (this.propertyModel.Business_district === true) {
        this.propertyModel.Business_district = false;
      }
    }
    if ($event.value === 'ZFU') {
      console.log($event, this.propertyModel);
      if (this.propertyModel.Zfu === false || !this.propertyModel.Zfu) {
        this.propertyModel.Zfu = true;
      } else if (this.propertyModel.Zfu === true) {
        this.propertyModel.Zfu = false;
      }
    }
    if ($event.value === 'OUTDOOR_AREA_1' && $event.model !== '') {
      console.log($event, this.propertyModel);

      this.propertyModel.Outdoor_area_1 = $event.model;
    }
    if ($event.value === 'OUTDOOR_AREA_2' && $event.model !== '') {
      console.log($event, this.propertyModel);

      this.propertyModel.Outdoor_area_2 = $event.model;
    }
    if ($event.value === 'BEDROOM_NUMBER' && $event.model !== '') {
      console.log($event, this.propertyModel);

      this.propertyModel.Bedroom_number = $event.model;
    }
    if ($event.value === 'BATHROOM_NUMBER' && $event.model !== '') {
      console.log($event, this.propertyModel);

      this.propertyModel.Bathroom_number = $event.model;
    }
    if ($event.value === 'INDIVIDUAL_OFFICE_NUMBER' && $event.model !== '') {
      console.log($event, this.propertyModel);
      this.propertyModel.Individual_office_number = $event.model;
    }
    if ($event.value === 'SEATING_PLACES_NUMBER' && $event.model !== '') {
      console.log($event, this.propertyModel);
      this.propertyModel.Seating_places_number = $event.model;
    }
    if ($event.value === 'MEETING_ROOM_NUMBER' && $event.model !== '') {
      console.log($event, this.propertyModel);
      this.propertyModel.Meeting_room_number = $event.model;
    }
    if ($event.value === 'SEPARATE_TOILET') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Separate_toilet === false ||
        !this.propertyModel.Separate_toilet
      ) {
        this.propertyModel.Separate_toilet = true;
      } else if (this.propertyModel.Separate_toilet === true) {
        this.propertyModel.Separate_toilet = false;
      }
    }
    if ($event.value === 'SWIMMING_POOL') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Swimming_pool === false ||
        !this.propertyModel.Swimming_pool
      ) {
        this.propertyModel.Swimming_pool = true;
      } else if (this.propertyModel.Swimming_pool === true) {
        this.propertyModel.Swimming_pool = false;
      }
    }
    if ($event.value === 'BALCONY') {
      console.log($event, this.propertyModel);
      if (this.propertyModel.Balcony === false || !this.propertyModel.Balcony) {
        this.propertyModel.Balcony = true;
      } else if (this.propertyModel.Balcony === true) {
        this.propertyModel.Balcony = false;
      }
    }
    if ($event.value === 'AIR_CONDITIONING') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Air_conditioning === false ||
        !this.propertyModel.Air_conditioning
      ) {
        this.propertyModel.Air_conditioning = true;
      } else if (this.propertyModel.Air_conditioning === true) {
        this.propertyModel.Air_conditioning = false;
      }
    }
    if ($event.value === 'OUTBUILDING') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Outbuilding === false ||
        !this.propertyModel.Outbuilding
      ) {
        this.propertyModel.Outbuilding = true;
      } else if (this.propertyModel.Outbuilding === true) {
        this.propertyModel.Outbuilding = false;
      }
    }
    if ($event.value === 'KITCHEN_AREA') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Kitchen_area === false ||
        !this.propertyModel.Kitchen_area
      ) {
        this.propertyModel.Kitchen_area = true;
      } else if (this.propertyModel.Kitchen_area === true) {
        this.propertyModel.Kitchen_area = false;
      }
    }
    if ($event.value === 'EQUIPPED_KITCHEN') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Equipped_kitchen === false ||
        !this.propertyModel.Equipped_kitchen
      ) {
        this.propertyModel.Equipped_kitchen = true;
      } else if (this.propertyModel.Equipped_kitchen === true) {
        this.propertyModel.Equipped_kitchen = false;
      }
    }
    if ($event.value === 'FITTED_KITCHEN') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Fitted_kitchen === false ||
        !this.propertyModel.Fitted_kitchen
      ) {
        this.propertyModel.Fitted_kitchen = true;
      } else if (this.propertyModel.Fitted_kitchen === true) {
        this.propertyModel.Fitted_kitchen = false;
      }
    }
    if ($event.value === 'FIBRE') {
      console.log($event, this.propertyModel);
      if (this.propertyModel.Fibre === false || !this.propertyModel.Fibre) {
        this.propertyModel.Fibre = true;
      } else if (this.propertyModel.Fibre === true) {
        this.propertyModel.Fibre = false;
      }
    }
    if ($event.value === 'ELECTRIC_VEHICLE_CHARGING') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Electric_vehicule_charging === false ||
        !this.propertyModel.Electric_vehicule_charging
      ) {
        this.propertyModel.Electric_vehicule_charging = true;
      } else if (this.propertyModel.Electric_vehicule_charging === true) {
        this.propertyModel.Electric_vehicule_charging = false;
      }
    }
    if ($event.value === 'MANUAL_GATE') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Manual_gate === false ||
        !this.propertyModel.Manual_gate
      ) {
        this.propertyModel.Manual_gate = true;
      } else if (this.propertyModel.Manual_gate === true) {
        this.propertyModel.Manual_gate = false;
      }
    }
    if ($event.value === 'ELECTRIC_GATE') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Electric_gate === false ||
        !this.propertyModel.Electric_gate
      ) {
        this.propertyModel.Electric_gate = true;
      } else if (this.propertyModel.Electric_gate === true) {
        this.propertyModel.Electric_gate = false;
      }
    }
    if ($event.value === 'DISABLED_ACCESS') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Disabled_access === false ||
        !this.propertyModel.Disabled_access
      ) {
        this.propertyModel.Disabled_access = true;
      } else if (this.propertyModel.Disabled_access === true) {
        this.propertyModel.Disabled_access = false;
      }
    }
    if ($event.value === 'DIGITAL_LOCK') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Digital_lock === false ||
        !this.propertyModel.Digital_lock
      ) {
        this.propertyModel.Digital_lock = true;
      } else if (this.propertyModel.Digital_lock === true) {
        this.propertyModel.Digital_lock = false;
      }
    }
    if ($event.value === 'INTERCOM') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Intercom === false ||
        !this.propertyModel.Intercom
      ) {
        this.propertyModel.Intercom = true;
      } else if (this.propertyModel.Intercom === true) {
        this.propertyModel.Intercom = false;
      }
    }
    if ($event.value === 'ELEVATOR') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Elevator === false ||
        !this.propertyModel.Elevator
      ) {
        this.propertyModel.Elevator = true;
      } else if (this.propertyModel.Elevator === true) {
        this.propertyModel.Elevator = false;
      }
    }
    if ($event.value === 'FURNISHED') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Furnished === false ||
        !this.propertyModel.Furnished
      ) {
        this.propertyModel.Furnished = true;
      } else if (this.propertyModel.Furnished === true) {
        this.propertyModel.Furnished = false;
      }
    }
    if ($event.value === 'CARITAKER') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Caritaker === false ||
        !this.propertyModel.Caritaker
      ) {
        this.propertyModel.Caritaker = true;
      } else if (this.propertyModel.Caritaker === true) {
        this.propertyModel.Caritaker = false;
      }
    }
    if ($event.value === 'SEPARATE_KITCHEN') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Separate_kitchen === false ||
        !this.propertyModel.Separate_kitchen
      ) {
        this.propertyModel.Separate_kitchen = true;
      } else if (this.propertyModel.Separate_kitchen === true) {
        this.propertyModel.Separate_kitchen = false;
      }
    }
    if ($event.value === 'OPEN_KITCHEN') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Open_kitchen === false ||
        !this.propertyModel.Open_kitchen
      ) {
        this.propertyModel.Open_kitchen = true;
      } else if (this.propertyModel.Open_kitchen === true) {
        this.propertyModel.Open_kitchen = false;
      }
    }
    if ($event.value === 'SEMI_OPEN_KITCHEN') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Semi_open_kitchen === false ||
        !this.propertyModel.Semi_open_kitchen
      ) {
        this.propertyModel.Semi_open_kitchen = true;
      } else if (this.propertyModel.Semi_open_kitchen === true) {
        this.propertyModel.Semi_open_kitchen = false;
      }
    }
    if ($event.value === 'OUTDOOR_KITCHEN') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Outdoor_kitchen === false ||
        !this.propertyModel.Outdoor_kitchen
      ) {
        this.propertyModel.Outdoor_kitchen = true;
      } else if (this.propertyModel.Outdoor_kitchen === true) {
        this.propertyModel.Outdoor_kitchen = false;
      }
    }
    if ($event.value === 'TERRACE') {
      console.log($event, this.propertyModel);
      if (this.propertyModel.Terrace === false || !this.propertyModel.Terrace) {
        this.propertyModel.Terrace = true;
      } else if (this.propertyModel.Terrace === true) {
        this.propertyModel.Terrace = false;
      }
    }
    if ($event.value === 'GARDEN') {
      console.log($event, this.propertyModel);
      if (this.propertyModel.Garden === false || !this.propertyModel.Garden) {
        this.propertyModel.Garden = true;
      } else if (this.propertyModel.Garden === true) {
        this.propertyModel.Garden = false;
      }
    }
    if ($event.value === 'PRIVATE_PARKING') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Private_parking === false ||
        !this.propertyModel.Private_parking
      ) {
        this.propertyModel.Private_parking = true;
      } else if (this.propertyModel.Private_parking === true) {
        this.propertyModel.Private_parking = false;
      }
    }
    if ($event.value === 'GARAGE') {
      console.log($event, this.propertyModel);
      if (this.propertyModel.Garage === false || !this.propertyModel.Garage) {
        this.propertyModel.Garage = true;
      } else if (this.propertyModel.Garage === true) {
        this.propertyModel.Garage = false;
      }
    }
    if ($event.value === 'LOCK_UP_GARAGE') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Lock_up_garage === false ||
        !this.propertyModel.Lock_up_garage
      ) {
        this.propertyModel.Lock_up_garage = true;
      } else if (this.propertyModel.Lock_up_garage === true) {
        this.propertyModel.Lock_up_garage = false;
      }
    }
    if ($event.value === 'DESCRIPTION' && $event.model !== '') {
      console.log($event, this.propertyModel);
      $event.model = $event.model.replaceAll('\n', '</br>');
      this.propertyModel.Description = $event.model;
    }
    if ($event.value === 'GLOBAL_MANAGEMENT') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Global_management === false ||
        !this.propertyModel.Global_management
      ) {
        this.propertyModel.Global_management = true;
      } else if (this.propertyModel.Global_management === true) {
        this.propertyModel.Global_management = false;
      }
    }
    if ($event.value === 'SELL_QUICKLY') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Sell_quickly === false ||
        !this.propertyModel.Sell_quickly
      ) {
        this.propertyModel.Sell_quickly = true;
      } else if (this.propertyModel.Sell_quickly === true) {
        this.propertyModel.Sell_quickly = false;
      }
    }
    if ($event.value === 'MORE_VISIBILITY_AND_VISITS') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.More_visibility_and_visits === false ||
        !this.propertyModel.More_visibility_and_visits
      ) {
        this.propertyModel.More_visibility_and_visits = true;
      } else if (this.propertyModel.More_visibility_and_visits === true) {
        this.propertyModel.More_visibility_and_visits = false;
      }
    }
    if ($event.value === 'ESTIMATE_COST_OF_WORK') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Estimate_cost_of_work === false ||
        !this.propertyModel.Estimate_cost_of_work
      ) {
        this.propertyModel.Estimate_cost_of_work = true;
      } else if (this.propertyModel.Estimate_cost_of_work === true) {
        this.propertyModel.Estimate_cost_of_work = false;
      }
    }
    if ($event.value === 'ESTIMATE_VALUE_PROPERTY') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Estimate_value_property === false ||
        !this.propertyModel.Estimate_value_property
      ) {
        this.propertyModel.Estimate_value_property = true;
      } else if (this.propertyModel.Estimate_value_property === true) {
        this.propertyModel.Estimate_value_property = false;
      }
    }
    if ($event.value === 'ESTIMATE_AREA_PROPERTY') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Estimate_area_property === false ||
        !this.propertyModel.Estimate_area_property
      ) {
        this.propertyModel.Estimate_area_property = true;
      } else if (this.propertyModel.Estimate_area_property === true) {
        this.propertyModel.Estimate_area_property = false;
      }
    }
    if ($event.value === 'RENT_QUICKLY') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Rent_quickly === false ||
        !this.propertyModel.Rent_quickly
      ) {
        this.propertyModel.Rent_quickly = true;
      } else if (this.propertyModel.Rent_quickly === true) {
        this.propertyModel.Rent_quickly = false;
      }
    }
    if ($event.value === 'ESTIMATE_AMOUNT_OF_RENT') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Estimate_amount_of_rent === false ||
        !this.propertyModel.Estimate_amount_of_rent
      ) {
        this.propertyModel.Estimate_amount_of_rent = true;
      } else if (this.propertyModel.Estimate_amount_of_rent === true) {
        this.propertyModel.Estimate_amount_of_rent = false;
      }
    }
    if ($event.value === 'LIFE_ANNUITY') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Life_annuity === false ||
        !this.propertyModel.Life_annuity
      ) {
        this.propertyModel.Life_annuity = true;
      } else if (this.propertyModel.Life_annuity === true) {
        this.propertyModel.Life_annuity = false;
      }
    }
    if ($event.value === 'ELIGIBLE_PINEL_LAW') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Eligible_pinel_law === false ||
        !this.propertyModel.Eligible_pinel_law
      ) {
        this.propertyModel.Eligible_pinel_law = true;
      } else if (this.propertyModel.Eligible_pinel_law === true) {
        this.propertyModel.Eligible_pinel_law = false;
      }
    }
    if ($event.value === 'SENIOR_RESIDENCE') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Senior_residence === false ||
        !this.propertyModel.Senior_residence
      ) {
        this.propertyModel.Senior_residence = true;
      } else if (this.propertyModel.Senior_residence === true) {
        this.propertyModel.Senior_residence = false;
      }
    }
    if ($event.value === 'STUDENT_RESIDENCE') {
      console.log($event, this.propertyModel);
      if (
        this.propertyModel.Student_residence === false ||
        !this.propertyModel.Student_residence
      ) {
        this.propertyModel.Student_residence = true;
      } else if (this.propertyModel.Student_residence === true) {
        this.propertyModel.Student_residence = false;
      }
    }
  }

  // Upload file function
  uploadPhotos($event: any) {
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Téléchargement en cours ... ',
        isLoader: true,
      },
    });
    var file = $event.target.files[0]; // FileList object

    var end_file_name = file.name.split('.')[1]; // End of string file_name

    var extension = file.type; // Extension to upload in S3 Bucket

    this.file_name = 'assets-upload/photo-' + uuidv4() + '.' + end_file_name; // Set a random file_name

    // If size of file bigger than 3 mega bytes throw Error
    if (file.size > 3145728) {
      this.dialog.closeAll();
      const dialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        hasBackdrop: true,
        height: '70%',
        panelClass: 'custom-modalbox',
        data: {
          status: 'error',
          title: "Une erreur s'est produite",
          message:
            'La taille du fichier ne doit pas excéder 3 méga octets (3 Mo)',
        },
      });
      this.file_name = '';
    } else if (this.array_property_assets.length > 10) {
      this.dialog.closeAll();
      const dialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        hasBackdrop: true,
        height: '70%',
        panelClass: 'custom-modalbox',
        data: {
          status: 'error',
          title: "Une erreur s'est produite",
          message: 'Vous ne pouvez ajouter que 10 photos maximum.',
        },
      });
      this.file_name = '';
    } else {
      this.getBase64(file).then((data) => {
        this.media = data;
        console.log('xxxxxx', this.media);
        this.uploadPhotosS3Service
          .uploadPhoto(this.media, extension, this.file_name)
          .subscribe((res: any) => {
            console.log(res);
            if (
              res &&
              res.data.addPropertyPhoto.code ===
                'PROPERTY_ASSETS_SUCCESSFULLY_UPLOADED'
            ) {
              this.dialog.closeAll();
              var filename_url = environment.cloudfront_url + this.file_name;
              this.array_property_assets.push({
                value: filename_url,
                key: this.file_name,
              });
              this.propertyModel.Photos = [];
              this.propertyModel.Photos.push(this.file_name);
            }
          });
      });
    }
  }

  // Function to transform file into base64
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  // REMOVE FILE UPLOADED
  removePropertyPhoto($event: any) {
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Suppression en cours ... ',
        isLoader: true,
      },
    });
    this.uploadPhotosS3Service
      .removePropertyPhoto($event)
      .subscribe((res: any) => {
        console.log(res);
        if (
          res &&
          res.data.removePropertyPhoto.code ===
            'PROPERTY_ASSETS_SUCCESSFULLY_REMOVED'
        ) {
          this.dialog.closeAll();
          // This loop check the index number into property_assets_array
          // => to use splice method array to remove the assets property selected
          for (
            let index = 0;
            index < this.array_property_assets.length;
            index++
          ) {
            if (this.array_property_assets[index].key === $event) {
              this.array_property_assets.splice(index, 1);
            }
          }
        } else {
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: "Une erreur s'est produite",
              message:
                'Nous sommes désolé, un problème est survenu. Veuillez réessayer ultérieurement.',
            },
          });
        }
      });
  }

  // Create Property function
  createProperty() {
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Création en cours ... ',
        isLoader: true,
      },
    });
    this.propertyModel.checkNewPropertyInput();
    this.propertyService
      .addProperty(this.propertyModel)
      .subscribe((res: any) => {
        console.log(res);

        if (res && res.data) {
          this.dialog.closeAll();
          this.router.navigate(['/home']);
        } else {
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: "Une erreur s'est produite",
              message:
                'Nous sommes désolé, un problème est survenu. Veuillez réessayer ultérieurement.',
            },
          });
        }
      });
  }

  // Progress bar function
  checkStepNumber() {
    this.stepsArray = [
      {
        step: 1,
      },
      {
        step: 2,
      },
      {
        step: 3,
      },
      {
        step: 4,
      },
      {
        step: 5,
      },
      {
        step: 6,
      },
      {
        step: 7,
      },
      {
        step: 8,
      },
      {
        step: 9,
      },
      {
        step: 10,
      },
    ];
  }

  /**
   * Return property by ID
   */
  public async getOneProperty() {
    await this.propertyService
      .getOneProperty(this.propertyModel.ID)
      .subscribe((res) => {
        if (res && res.data.getOneProperty) {
          const data = res.data.getOneProperty;
          console.log(data);
          data.forEach((element) => {
            this.propertyModel.mapData(element);
            console.log(this.propertyModel);
          });
        } else if (res.errors.message) {
          console.log('ERROR: ', res.errors.message);
          this.hasError = true;
        }
      });
  }

  /**
   * updateProperty
   */
  public updateProperty() {
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Modification en cours ... ',
        isLoader: true,
      },
    });
    this.propertyService
      .editProperty(this.propertyModel)
      .subscribe((res: any) => {
        console.log(res);
        if (
          res &&
          res.data.editProperty.code === 'PROPERTY_SUCCESSFULLY_UPDATED'
        ) {
          this.dialog.closeAll();
          this.router.navigate([
            `/property/${this.propertyModel.Service_type.toLowerCase()}/${
              this.propertyModel.ID
            }`,
          ]);
        } else if (res.errors.message) {
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: "Une erreur s'est produite",
              message:
                'Nous sommes désolé, un problème est survenu. Veuillez réessayer ultérieurement.',
            },
          });
        }
      });
  }
}
