import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  @Input() value = '';
  @Output() Changed = new EventEmitter<any>();
  @Input() showErrors = true;
  @Input() model: any;
  @Input() hasError = false;
  @Input() selectClasses = '';
  @Input() labelClasses = '';
  @Input() form_control_name = '';
  @Input() label_text = 'Select Label';
  @Input() id = this.form_control_name;
  userStatus: Array<Object> = [{ name: 'Administrateur' }, { name: 'Agent' }];
  @Input() selectFormFields: FormGroup = this.formBuilder.group({
    user_status: ['', Validators.required],
  });
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {}

  checkInput(): void {
    if (this.value === 'STATUS') {
      if (!this.model || this.model === '') {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.model,
        };
        this.Changed.emit(data);
      }
    }
  }
}
