<app-text
  textClasses="txt-bold-gray text-sm mb-3 mx-6 font-bold"
  text="Niveau de projection des candidats"
></app-text>
<!-- Buttons list -->
<div class="ml-6 mb-6 pr-6 flex gap-x-1 overflow-x-scroll" id="button_list">
  <app-filter-button
    (IsClicked)="switchStep('INTERESSE')"
    text="Intéressé(e)"
    [textClasses]="
      current_step !== 'INTERESSE'
        ? 'txt-bold-gray text-xs px-3 w-max py-2'
        : 'text-white text-xs px-3 w-max py-2'
    "
    [buttonClasses]="
      current_step !== 'INTERESSE'
        ? 'rounded-3xl bg-ligth-gray '
        : 'rounded-3xl  bg-light-blue'
    "
  ></app-filter-button>
  <app-filter-button
    (IsClicked)="switchStep('SOUHAITE_UNE_VISITE')"
    text="Souhaite une visite"
    [textClasses]="
      current_step !== 'SOUHAITE_UNE_VISITE'
        ? 'txt-bold-gray text-xs px-3 w-max py-2'
        : 'text-white text-xs px-3 w-max py-2'
    "
    [buttonClasses]="
      current_step !== 'SOUHAITE_UNE_VISITE'
        ? 'rounded-3xl bg-ligth-gray '
        : 'rounded-3xl bg-light-blue'
    "
  ></app-filter-button>
  <app-filter-button
    (IsClicked)="switchStep('SOUHAITE_PLUS_DE_PHOTOS')"
    text="Souhaite plus de photos"
    [textClasses]="
      current_step !== 'SOUHAITE_PLUS_DE_PHOTOS'
        ? 'txt-bold-gray text-xs w-max px-3 py-2'
        : 'text-white text-xs w-max px-3 py-2'
    "
    [buttonClasses]="
      current_step !== 'SOUHAITE_PLUS_DE_PHOTOS'
        ? 'rounded-3xl bg-ligth-gray '
        : 'rounded-3xl bg-light-blue'
    "
  ></app-filter-button>
  <app-filter-button
    (IsClicked)="switchStep('PRET_A_FAIRE_UNE_OFFRE')"
    text="Prêt à faire une offre"
    [textClasses]="
      current_step !== 'PRET_A_FAIRE_UNE_OFFRE'
        ? 'txt-bold-gray text-xs w-max px-3 py-2'
        : 'text-white text-xs w-max px-3 py-2'
    "
    [buttonClasses]="
      current_step !== 'PRET_A_FAIRE_UNE_OFFRE'
        ? 'rounded-3xl bg-ligth-gray '
        : 'rounded-3xl bg-light-blue'
    "
  ></app-filter-button>
</div>
<div>
  <div class="mx-6 mt-6 mb-8 flex flex-col gap-4">
    <div
      *ngIf="!users_array_filtered.length && !isEmpty"
      class="mx-auto"
      id="waiting_data"
    >
      <mat-spinner diameter="50"></mat-spinner>
    </div>
    <!-- No Result text -->
    <p
      *ngIf="!users_array_filtered.length && isEmpty"
      class="text-center txt-light-blue mt-8"
    >
      Aucun résultats
    </p>
  </div>
  <div class="grid grid-cols-2 gap-3 w-10/12 mx-auto">
    <app-tertiary-card
      *ngFor="let user of users_array_filtered"
      [icon_visibility]="true"
      [title]="user.user_interested_firstname"
      [subtitle]="user.user_interested_lastname"
      cardClasses="bg-white shadow-lg"
      titleClasses="text-center txt-bold-gray font-extrabold mt-3"
      subtitleClasses="text-center txt-bold-gray font-extralight"
      imgSrc="assets/icons/svg/logo-1.svg"
      imgClasses="h-16 mx-auto"
      (IsClicked)="onClick(user.id)"
    ></app-tertiary-card>
  </div>
</div>
