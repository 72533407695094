import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
  @Output() IsClicked = new EventEmitter<string>();
  @Input() value = '';
  @Input() imgSrc = "assets/icons/svg/back-button.svg"
  @Input() imgClasses = "h-8"
  @Input() txtClasses = ""
  @Input() buttonClasses = ""
  @Input() text = ""
  constructor() {}

  ngOnInit(): void {}

  onClick($event: any): void {
    this.IsClicked.emit($event);
  }
}
