import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.scss'],
})
export class SelectDateComponent implements OnInit {
  @Input() value = '';
  @Output() Changed = new EventEmitter<any>();
  @Input() showErrors = true;
  @Input() model: any;
  @Input() hasError = false;
  @Input() selectClasses = '';
  @Input() yearClasses = '';
  @Input() form_control_name_month = '';
  @Input() form_control_name_year = '';
  @Input() current_month: any;
  @Input() current_year: any;
  @Input() monthsArray: Array<Object> = [
    { name: 'janvier', value: 'JAN' },
    { name: 'février', value: 'FEV' },
    { name: 'mars', value: 'MAR' },
    { name: 'avril', value: 'AVR' },
    { name: 'mai', value: 'MAI' },
    { name: 'juin', value: 'JUN' },
    { name: 'juillet', value: 'JUI' },
    { name: 'août', value: 'AOU' },
    { name: 'septembre', value: 'SEP' },
    { name: 'octobre', value: 'OCT' },
    { name: 'novembre', value: 'NOV' },
    { name: 'décembre', value: 'DEC' },
  ];
  @Input() yearsArray: Array<Object> = [];
  @Input() selectDateFormFields: FormGroup = this.formBuilder.group({
    month: ['', Validators.required],
    year: ['', Validators.required],
  });
  constructor(private formBuilder: FormBuilder) {
    const today = new Date();
    this.current_month = today.toLocaleString('fr-FR', { month: 'long' });
  }

  ngOnInit(): void {
    let startYear = 2020;
    var currentYear = new Date().getFullYear();
    this.current_year = currentYear;
    while (startYear <= currentYear) {
      this.yearsArray.push({ name: startYear++ });
    }
  }

  checkInput(): void {
    if (this.value === 'DATE_SORT') {
      if (
        !this.current_month ||
        this.current_month === '' ||
        !this.current_year ||
        this.current_year === ''
      ) {
        this.hasError = true;
        const data = {
          value: this.value,
          current_year: '',
          current_month: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          current_year: this.current_year,
          current_month: this.current_month,
        };
        this.Changed.emit(data);
      }
    }
  }
}
