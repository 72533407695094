import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss'],
})
export class ImgComponent implements OnInit {
  @Input() imgPath = 'img';
  @Input() imgClasses = '';
  @Output() _onClick = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  /**
   * OnClick
   */
  public OnClick($event: any) {
    this._onClick.emit($event);
  }
}
