import { PropertyModel } from 'src/app/shared/models/property-model';
import { DataService } from './../../shared/services/data.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { LocalStorageService } from './../../shared/services/local-storage.service';
import { PropertyService } from 'src/app/shared/services/property.service';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import {
  Component,
  OnInit,
  Renderer2,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-favorites-property-list',
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0, transform: 'translateY(10px)' })),
      ]),
    ]),
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0, transform: 'translateY(10px)' })),
      ]),
    ]),
  ],
  templateUrl: './favorites-property-list.component.html',
  styleUrls: ['./favorites-property-list.component.scss']
})
export class FavoritesPropertyListComponent implements OnInit {
  @Input() service_type_sort: string = 'sale';
  @Input() sortingTypeArray: Array<Object> = [
    { name: 'Biens en vente' },
    { name: 'Biens en location' },
  ];
  @Input() title: string;
  @Input() prefix_url: string = environment.cloudfront_url;
  @Input() array_property: any[] = [];
  @Input() service_type: string;
  @Input() array_filtered: any[] = [];
  @Input() user_id: string;
  @Input() current_step: string = 'MAIN';
  @Input() property_step: string = 'CHARACTERISTICS';
  @Input() published_date: string;
  @Input() hasError: boolean = false;
  @Input() filter_attributes = {};
  @Input() filter_attributes_length: Number;
  @Input() isOnAppointmentPage: boolean = false;
  @Input() headerClass = '';
  @Output() IsClicked = new EventEmitter<string>();
  public favorites_property_array: any[] = [];
  public user_type: string;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private location: Location,
    private dataService: DataService,
    private renderer: Renderer2
  ) {
    this.user_type = this.localStorageService.getCurrentUser().user_type;
    this.renderer.setStyle(document.body, 'background-color', '#ffff');
  }

  ngOnInit(): void {
    this.user_id = this.localStorageService.getCurrentUser().id;
    const service = this.route.snapshot.paramMap.get('service_type');
    this.filter_attributes = this.localStorageService.getFilterAttributes();
    this.setHeaderTitle(service);
    this.listFavoritesPropertys();
    this.listAllPublishedProperty();
    this.checkCountFilterAttributes(this.filter_attributes);
  }

  // /**
  //  * addOrRemoveFavoriteProperty
  //  */
  // public addOrRemoveFavoriteProperty($property) {
  //   console.log('favorite-property', $property);
  //   this.dataService.data['FAVORITE_PROPERTY'] = $property;
  //   if (this.user_id !== $property.User_id) {
  //     this.router.navigate([
  //       `/favorite-property/${this.service_type_sort}/${$property.User_id}/${$property.ID}`,
  //     ]);
  //   }
  // }

  /**
   * onClick
   * This function share property id into another component on click event
   */
  public onClick($event) {
    this.IsClicked.emit($event);
  }

  /**
   * checkCountFilterAttributes
   */
  public checkCountFilterAttributes(obj) {
    this.filter_attributes_length = Object.keys(obj).length;
    console.log('LENGTH: ', this.filter_attributes_length);
    return this.filter_attributes_length;
  }

  /**
   * back
   * Return to property list page
   */
  public back() {
    this.router.navigate([`/my-account`]);
  }

  /**
   * SET Header title from url (rent or sale)
   */
  public async setHeaderTitle($service_type) {
    switch ($service_type) {
      case 'sale':
        this.title = 'Biens en vente';
        this.service_type = 'VENTE';
        break;
      case 'rent':
        this.title = 'Biens en location';
        this.service_type = 'LOCATION';
        break;
      default:
        break;
    }
    console.log('FILTER', this.filter_attributes);
  }

  /**
   * removeAttributesFilter
   */
  public removeAttributesFilter() {
    this.localStorageService.removeFilterAttributes();
    window.location.reload();
  }

  /**
   * filter page function
   */
  public filterPage() {
    this.current_step = 'FILTERS';
    this.dataService.data['PROPERTY_ARRAY'] = this.array_filtered;
  }

  /**
   * listProperty by service type (rent or sale)
   */
  public async listPropertyByServiceType($service_type) {
    switch ($service_type) {
      case 'Biens en vente':
        this.service_type = 'VENTE';
        this.service_type_sort = 'sale';
        if (!this.isOnAppointmentPage) {
          this.location.replaceState('/my-property/sale');
        }
        this.listAllPublishedProperty();
        break;
      case 'Biens en location':
        this.service_type = 'LOCATION';
        this.service_type_sort = 'rent';
        if (!this.isOnAppointmentPage) {
          this.location.replaceState('/my-property/rent');
        }
        this.listAllPublishedProperty();
        break;
      default:
        break;
    }
  }


  /**
   * FILTER User Property function
   * This function return sale or rent user property
   */
  public sortProperty() {
    this.array_filtered = this.array_property.filter((element) => {
      // SUBTYPE CHECK
      var checkPropertySubType = 1;
      if (
        this.filter_attributes['MAISON_PLAIN_PIEDS'] ||
        this.filter_attributes['MAISON_A_ETAGE'] ||
        this.filter_attributes['MAISON_AVEC_COMBLE'] ||
        this.filter_attributes['MAISON_AVEC_SOUS_SOL'] ||
        this.filter_attributes['DUPLEX'] ||
        this.filter_attributes['TRIPLEX'] ||
        this.filter_attributes['ROOFTOP'] ||
        this.filter_attributes['BUREAU_EN_OPEN_SPACE'] ||
        this.filter_attributes['BUREAU_APPARTEMENT'] ||
        this.filter_attributes['BUREAU_EN_COWORKING'] ||
        this.filter_attributes['TERRAIN_CONSTRUCTIBLE_PARTICULIER'] ||
        this.filter_attributes['TERRAIN_CONSTRUCTIBLE_PROFESSIONNEL'] ||
        this.filter_attributes['TERRAIN_AGRICOLE']
      ) {
        checkPropertySubType =
          element.property_subtype.includes(
            this.filter_attributes['MAISON_PLAIN_PIEDS']
          ) ||
          element.property_subtype.includes(
            this.filter_attributes['MAISON_A_ETAGE']
          ) ||
          element.property_subtype.includes(
            this.filter_attributes['MAISON_AVEC_COMBLE']
          ) ||
          element.property_subtype.includes(
            this.filter_attributes['MAISON_AVEC_SOUS_SOL']
          ) ||
          element.property_subtype.includes(this.filter_attributes['DUPLEX']) ||
          element.property_subtype.includes(
            this.filter_attributes['TRIPLEX']
          ) ||
          element.property_subtype.includes(
            this.filter_attributes['ROOFTOP']
          ) ||
          element.property_subtype.includes(
            this.filter_attributes['BUREAU_EN_OPEN_SPACE']
          ) ||
          element.property_subtype.includes(
            this.filter_attributes['BUREAU_APPARTEMENT']
          ) ||
          element.property_subtype.includes(
            this.filter_attributes['BUREAU_EN_COWORKING']
          ) ||
          element.property_subtype.includes(
            this.filter_attributes['TERRAIN_CONSTRUCTIBLE_PARTICULIER']
          ) ||
          element.property_subtype.includes(
            this.filter_attributes['TERRAIN_CONSTRUCTIBLE_PROFESSIONNEL']
          ) ||
          element.property_subtype.includes(
            this.filter_attributes['TERRAIN_AGRICOLE']
          );
      }

      // STATE CHECK
      var checkPropertyState = 1;
      if (
        this.filter_attributes['BIEN_ANCIEN'] ||
        this.filter_attributes['BIEN_NEUF'] ||
        this.filter_attributes['SANS_TRAVAUX_A_PREVOIR'] ||
        this.filter_attributes['TRAVAUX_A_PREVOIR'] ||
        this.filter_attributes['NON_VIABILISE'] ||
        this.filter_attributes['VIABILISE']
      ) {
        checkPropertyState =
          element.property_state.includes(
            this.filter_attributes['BIEN_ANCIEN']
          ) ||
          element.property_state.includes(
            this.filter_attributes['BIEN_NEUF']
          ) ||
          element.property_state.includes(
            this.filter_attributes['SANS_TRAVAUX_A_PREVOIR']
          ) ||
          element.property_state.includes(
            this.filter_attributes['TRAVAUX_A_PREVOIR']
          ) ||
          element.property_state.includes(
            this.filter_attributes['NON_VIABILISE']
          ) ||
          element.property_state.includes(this.filter_attributes['VIABILISE']);
      }

      var not_overlooked = 1;
      if (this.filter_attributes['not_overlooked']) {
        not_overlooked = element.not_overlooked.includes(
          this.filter_attributes['not_overlooked']
        );
      }
      var non_adjoining = 1;
      if (this.filter_attributes['non_adjoining']) {
        non_adjoining = element.non_adjoining.includes(
          this.filter_attributes['non_adjoining']
        );
      }
      var overview_sea = 1;
      if (this.filter_attributes['overview_sea']) {
        overview_sea = element.overview_sea.includes(
          this.filter_attributes['overview_sea']
        );
      }
      var sea_view = 1;
      if (this.filter_attributes['sea_view']) {
        sea_view = element.sea_view.includes(
          this.filter_attributes['sea_view']
        );
      }
      var panoramic_view = 1;
      if (this.filter_attributes['panoramic_view']) {
        panoramic_view = element.panoramic_view.includes(
          this.filter_attributes['panoramic_view']
        );
      }
      var furnished = 1;
      if (this.filter_attributes['furnished']) {
        furnished = element.furnished.includes(
          this.filter_attributes['furnished']
        );
      }
      var balcony = 1;
      if (this.filter_attributes['balcony']) {
        balcony = element.balcony.includes(this.filter_attributes['balcony']);
      }
      var elevator = 1;
      if (this.filter_attributes['elevator']) {
        elevator = element.elevator.includes(
          this.filter_attributes['elevator']
        );
      }
      var caritaker = 1;
      if (this.filter_attributes['caritaker']) {
        caritaker = element.caritaker.includes(
          this.filter_attributes['caritaker']
        );
      }
      var equipped_kitchen = 1;
      if (this.filter_attributes['equipped_kitchen']) {
        equipped_kitchen = element.equipped_kitchen.includes(
          this.filter_attributes['equipped_kitchen']
        );
      }
      var fitted_kitchen = 1;
      if (this.filter_attributes['fitted_kitchen']) {
        fitted_kitchen = element.fitted_kitchen.includes(
          this.filter_attributes['fitted_kitchen']
        );
      }
      var outbuilding = 1;
      if (this.filter_attributes['outbuilding']) {
        outbuilding = element.outbuilding.includes(
          this.filter_attributes['outbuilding']
        );
      }
      var manual_gate = 1;
      if (this.filter_attributes['manual_gate']) {
        manual_gate = element.manual_gate.includes(
          this.filter_attributes['manual_gate']
        );
      }
      var electric_gate = 1;
      if (this.filter_attributes['electric_gate']) {
        electric_gate = element.electric_gate.includes(
          this.filter_attributes['electric_gate']
        );
      }
      var industrial_estate = 1;
      if (this.filter_attributes['industrial_estate']) {
        industrial_estate = element.industrial_estate.includes(
          this.filter_attributes['industrial_estate']
        );
      }
      var business_district = 1;
      if (this.filter_attributes['business_district']) {
        business_district = element.business_district.includes(
          this.filter_attributes['business_district']
        );
      }
      var zfu = 1;
      if (this.filter_attributes['zfu']) {
        zfu = element.zfu.includes(this.filter_attributes['zfu']);
      }
      var separate_toilet = 1;
      if (this.filter_attributes['separate_toilet']) {
        separate_toilet = element.separate_toilet.includes(
          this.filter_attributes['separate_toilet']
        );
      }
      var swimming_pool = 1;
      if (this.filter_attributes['swimming_pool']) {
        swimming_pool = element.swimming_pool.includes(
          this.filter_attributes['swimming_pool']
        );
      }
      var air_conditioning = 1;
      if (this.filter_attributes['air_conditioning']) {
        air_conditioning = element.air_conditioning.includes(
          this.filter_attributes['air_conditioning']
        );
      }
      var fibre = 1;
      if (this.filter_attributes['fibre']) {
        fibre = element.fibre.includes(this.filter_attributes['fibre']);
      }
      var electric_vehicule_charging = 1;
      if (this.filter_attributes['electric_vehicule_charging']) {
        electric_vehicule_charging =
          element.electric_vehicule_charging.includes(
            this.filter_attributes['electric_vehicule_charging']
          );
      }
      var digital_lock = 1;
      if (this.filter_attributes['digital_lock']) {
        digital_lock = element.digital_lock.includes(
          this.filter_attributes['digital_lock']
        );
      }
      var disabled_access = 1;
      if (this.filter_attributes['disabled_access']) {
        disabled_access = element.disabled_access.includes(
          this.filter_attributes['disabled_access']
        );
      }
      var intercom = 1;
      if (this.filter_attributes['intercom']) {
        intercom = element.intercom.includes(
          this.filter_attributes['intercom']
        );
      }
      var separate_kitchen = 1;
      if (this.filter_attributes['separate_kitchen']) {
        separate_kitchen = element.separate_kitchen.includes(
          this.filter_attributes['separate_kitchen']
        );
      }
      var open_kitchen = 1;
      if (this.filter_attributes['open_kitchen']) {
        open_kitchen = element.open_kitchen.includes(
          this.filter_attributes['open_kitchen']
        );
      }
      var semi_open_kitchen = 1;
      if (this.filter_attributes['semi_open_kitchen']) {
        semi_open_kitchen = element.semi_open_kitchen.includes(
          this.filter_attributes['semi_open_kitchen']
        );
      }
      var outdoor_kitchen = 1;
      if (this.filter_attributes['outdoor_kitchen']) {
        outdoor_kitchen = element.outdoor_kitchen.includes(
          this.filter_attributes['outdoor_kitchen']
        );
      }
      var terrace = 1;
      if (this.filter_attributes['terrace']) {
        terrace = element.terrace.includes(this.filter_attributes['terrace']);
      }
      var garden = 1;
      if (this.filter_attributes['garden']) {
        garden = element.garden.includes(this.filter_attributes['garden']);
      }
      var private_parking = 1;
      if (this.filter_attributes['private_parking']) {
        private_parking = element.private_parking.includes(
          this.filter_attributes['private_parking']
        );
      }
      var garage = 1;
      if (this.filter_attributes['garage']) {
        garage = element.garage.includes(this.filter_attributes['garage']);
      }
      var lock_up_garage = 1;
      if (this.filter_attributes['lock_up_garage']) {
        lock_up_garage = element.lock_up_garage.includes(
          this.filter_attributes['lock_up_garage']
        );
      }
      var life_annuity = 1;
      if (this.filter_attributes['life_annuity']) {
        life_annuity = element.life_annuity.includes(
          this.filter_attributes['life_annuity']
        );
      }
      var eligible_pinel_law = 1;
      if (this.filter_attributes['eligible_pinel_law']) {
        eligible_pinel_law = element.eligible_pinel_law.includes(
          this.filter_attributes['eligible_pinel_law']
        );
      }
      var senior_residence = 1;
      if (this.filter_attributes['senior_residence']) {
        senior_residence = element.senior_residence.includes(
          this.filter_attributes['senior_residence']
        );
      }
      var student_residence = 1;
      if (this.filter_attributes['student_residence']) {
        student_residence = element.student_residence.includes(
          this.filter_attributes['student_residence']
        );
      }
      var min_budget = true;
      if (this.filter_attributes['MIN_BUDGET']) {
        min_budget = element.price >= this.filter_attributes['MIN_BUDGET'];
      }
      var max_budget = true;
      if (this.filter_attributes['MAX_BUDGET']) {
        max_budget = element.price <= this.filter_attributes['MAX_BUDGET'];
      }
      var min_area = true;
      if (this.filter_attributes['MIN_AREA']) {
        min_area = element.total_area >= this.filter_attributes['MIN_AREA'];
      }
      var max_area = true;
      if (this.filter_attributes['MAX_AREA']) {
        max_area = element.total_area <= this.filter_attributes['MAX_AREA'];
      }
      var min_land_area = true;
      if (this.filter_attributes['MIN_LAND_AREA']) {
        min_land_area =
          element.total_area >= this.filter_attributes['MIN_LAND_AREA'];
      }
      var max_land_area = true;
      if (this.filter_attributes['MAX_LAND_AREA']) {
        max_land_area =
          element.total_area <= this.filter_attributes['MAX_LAND_AREA'];
      }
      var min_pieces_number = true;
      if (this.filter_attributes['MIN_PIECES_NUMBER']) {
        min_pieces_number =
          element.number_of_pieces >=
          this.filter_attributes['MIN_PIECES_NUMBER'];
      }
      var max_pieces_number = true;
      if (this.filter_attributes['MAX_PIECES_NUMBER']) {
        max_pieces_number =
          element.number_of_pieces <=
          this.filter_attributes['MAX_PIECES_NUMBER'];
      }
      var min_bedrooms_number = true;
      if (this.filter_attributes['MIN_BEDROOMS_NUMBER']) {
        min_bedrooms_number =
          element.bedroom_number >=
          this.filter_attributes['MIN_BEDROOMS_NUMBER'];
      }
      var max_bedrooms_number = true;
      if (this.filter_attributes['MAX_BEDROOMS_NUMBER']) {
        max_bedrooms_number =
          element.bedroom_number <=
          this.filter_attributes['MAX_BEDROOMS_NUMBER'];
      }
      var min_bathrooms_number = true;
      if (this.filter_attributes['MIN_BATHROOMS_NUMBER']) {
        min_bathrooms_number =
          element.bathroom_number >=
          this.filter_attributes['MIN_BATHROOMS_NUMBER'];
      }
      var max_bathrooms_number = true;
      if (this.filter_attributes['MAX_BATHROOMS_NUMBER']) {
        max_bathrooms_number =
          element.bathroom_number <=
          this.filter_attributes['MAX_BATHROOMS_NUMBER'];
      }
      var min_individual_offices_number = true;
      if (this.filter_attributes['MIN_INDIVIDUAL_OFFICES_NUMBER']) {
        min_individual_offices_number =
          element.individual_office_number >=
          this.filter_attributes['MIN_INDIVIDUAL_OFFICES_NUMBER'];
      }
      var max_individual_offices_number = true;
      if (this.filter_attributes['MAX_INDIVIDUAL_OFFICES_NUMBER']) {
        max_individual_offices_number =
          element.individual_office_number <=
          this.filter_attributes['MAX_INDIVIDUAL_OFFICES_NUMBER'];
      }
      var min_seats_number = true;
      if (this.filter_attributes['MIN_SEATS_NUMBER']) {
        min_seats_number =
          element.seating_places_number >=
          this.filter_attributes['MIN_SEATS_NUMBER'];
      }
      var max_seats_number = true;
      if (this.filter_attributes['MAX_SEATS_NUMBER']) {
        max_seats_number =
          element.seating_places_number <=
          this.filter_attributes['MAX_SEATS_NUMBER'];
      }
      var min_meeting_rooms_number = true;
      if (this.filter_attributes['MIN_MEETING_ROOMS_NUMBER']) {
        min_meeting_rooms_number =
          element.meeting_room_number >=
          this.filter_attributes['MIN_MEETING_ROOMS_NUMBER'];
      }
      var max_meeting_rooms_number = true;
      if (this.filter_attributes['MAX_MEETING_ROOMS_NUMBER']) {
        max_meeting_rooms_number =
          element.meeting_room_number <=
          this.filter_attributes['MAX_MEETING_ROOMS_NUMBER'];
      }
      return (
        element.service_type.includes(this.service_type) &&
        checkPropertySubType &&
        checkPropertyState &&
        not_overlooked &&
        non_adjoining &&
        overview_sea &&
        sea_view &&
        panoramic_view &&
        furnished &&
        balcony &&
        elevator &&
        caritaker &&
        equipped_kitchen &&
        fitted_kitchen &&
        outbuilding &&
        manual_gate &&
        electric_gate &&
        industrial_estate &&
        business_district &&
        zfu &&
        separate_toilet &&
        swimming_pool &&
        air_conditioning &&
        fibre &&
        electric_vehicule_charging &&
        disabled_access &&
        digital_lock &&
        intercom &&
        separate_kitchen &&
        open_kitchen &&
        semi_open_kitchen &&
        outdoor_kitchen &&
        terrace &&
        garden &&
        private_parking &&
        garage &&
        lock_up_garage &&
        life_annuity &&
        eligible_pinel_law &&
        senior_residence &&
        student_residence &&
        min_budget &&
        max_budget &&
        min_area &&
        max_area &&
        min_land_area &&
        max_land_area &&
        min_pieces_number &&
        max_pieces_number &&
        min_bedrooms_number &&
        max_bedrooms_number &&
        min_bathrooms_number &&
        max_bathrooms_number &&
        min_individual_offices_number &&
        max_individual_offices_number &&
        min_seats_number &&
        max_seats_number &&
        min_meeting_rooms_number &&
        max_meeting_rooms_number
      );
    });
    console.log(this.array_filtered);
    if (!this.array_filtered.length) {
      this.hasError = true;
    }
  }

  /**
   * GET Property
   * This function get property id selected and current service type to create the url
   */
  public getProperty($property) {
    if (!this.isOnAppointmentPage) {
      this.router.navigate([
        `property/${this.service_type_sort}/${$property.ID}`,
      ]);
    } else {
      const data = {
        property_id: $property.ID,
        user_id: $property.User_id,
      };
      this.onClick(data);
    }
  }

  /**
   * listFavoritesPropertys
   */
  public listFavoritesPropertys() {
    this.propertyService.getAllUserFavoritesProperty(this.user_id).subscribe((res) => {
      console.log('res', res);
      if (res && res.data.listFavoritesPropertys.items) {
        this.favorites_property_array = res.data.listFavoritesPropertys.items;
      }
    });
  }

  /**
   * Return all published property
   */
  public async listAllPublishedProperty() {
    // Always empty video array before run the function
    this.array_filtered = [];

    try {
      this.propertyService.getAllPublishedProperty().subscribe((res) => {
        console.log(res);
        if (res && res.data.getAllPublishedProperty) {
          console.log(res);

          const data = res.data.getAllPublishedProperty;
          data.forEach((element) => {
            const propertyModel = new PropertyModel().mapToModel(element);
            console.log('propertyModel', propertyModel);
            for (let i = 0; i < this.favorites_property_array.length; i++) {
              console.log(
                this.favorites_property_array[i].property_id,
                propertyModel.ID
              );
              if (
                this.favorites_property_array[i].property_id ===
                propertyModel.ID
              ) {
                console.log('FAVORITE IS TRUE');
                propertyModel.IsFavorite = true;
                // Push only property currently user liked
                this.array_filtered.push(propertyModel);
              }
            }
          });
          // If its the last tour of the loop run the sortProperty function
          // if (this.filter_attributes_length) {
          //   this.propertyFilter(this.filter_attributes);
          // } else {
          //   this.sortProperty();
          // }
        } else if (res.errors.message) {
          console.log('ERROR: ', res.errors.message);
          this.hasError = true;
        }
      });
    } catch (error) {
      console.log('ERROR: ', error);
      throw new Error(error);
    }
  }

}
