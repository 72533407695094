<div>
  <div class="flex flex-col gap-3 border border-t border-b py-3">
    <app-text
      textClasses="txt-bold-gray text-sm font-bold mx-6"
      text="Besoin pour cette vente"
    ></app-text>
    <div class="flex gap-3 overflow-x-scroll ml-6 no-scrollbar">
      <app-text
        *ngIf="propertyNeeds.Estimate_amount_of_rent"
        textClasses="text-white bg-percian-gray text-xs rounded-md p-1 w-max"
        [text]="propertyNeeds.Estimate_amount_of_rent"
      ></app-text>
      <app-text
        *ngIf="propertyNeeds.Estimate_area_property"
        textClasses="text-white bg-percian-gray text-xs rounded-md p-1 w-max"
        [text]="propertyNeeds.Estimate_area_property"
      ></app-text>
      <app-text
        *ngIf="propertyNeeds.Estimate_cost_of_work"
        textClasses="text-white bg-percian-gray text-xs rounded-md p-1 w-max"
        [text]="propertyNeeds.Estimate_cost_of_work"
      ></app-text>
      <app-text
        *ngIf="propertyNeeds.Estimate_value_property"
        textClasses="text-white bg-percian-gray text-xs rounded-md p-1 w-max"
        [text]="propertyNeeds.Estimate_value_property"
      ></app-text>
      <app-text
        *ngIf="propertyNeeds.Global_management"
        textClasses="text-white bg-percian-gray text-xs rounded-md p-1 w-max"
        [text]="propertyNeeds.Global_management"
      ></app-text>
      <app-text
        *ngIf="propertyNeeds.More_visibility_and_visits"
        textClasses="text-white bg-percian-gray text-xs rounded-md p-1 w-max"
        [text]="propertyNeeds.More_visibility_and_visits"
      ></app-text>
      <app-text
        *ngIf="propertyNeeds.Rent_quickly"
        textClasses="text-white bg-percian-gray text-xs rounded-md p-1 w-max"
        [text]="propertyNeeds.Rent_quickly"
      ></app-text>
      <app-text
        *ngIf="propertyNeeds.Sell_quickly"
        textClasses="text-white bg-percian-gray text-xs rounded-md p-1 w-max"
        [text]="propertyNeeds.Sell_quickly"
      ></app-text>
    </div>
  </div>
  <div class="flex justify-center mt-8 mb-6">
    <img src="assets/icons/svg/blue-file.svg" class="h-36" />
  </div>
  <label for="offer" class="cursor-pointer">
    <div
      class="
        bg-bold-blue
        flex
        gap-3
        text-white
        items-center
        shadow-md
        w-max
        mx-auto
        mb-3
        rounded-full
        p-3
      "
    >
      <p class="">
        {{ mandate_file ? "Modifier mon mandat" : "Télécharger mon mandat" }}
      </p>
      <img src="assets/icons/svg/arrow-right.svg" class="h-2" />
    </div>
    <input
      type="file"
      id="offer"
      class="hidden"
      accept=".pdf"
      (change)="makeOffer($event)"
    />
  </label>
  <app-primary-button
    text="Consulter mon mandat"
    [buttonClasses]="
      mandate_file
        ? 'bg-white txt-light-blue border-light-blue border buttons items-center shadow-md w-max mx-auto mb-3'
        : 'hidden'
    "
    firstImgSrc="assets/icons/svg/arrow-right-blue.svg"
    (IsClicked)="getFile($event)"
    [disabled]="false"
  ></app-primary-button>
  <app-primary-button
    text="Supprimer mon mandat"
    [buttonClasses]="
      mandate_file
        ? 'bg-white txt-pink border-pink border buttons items-center shadow-md w-max mx-auto mb-3'
        : 'hidden'
    "
    firstImgSrc="assets/icons/svg/arrow-right-red.svg"
    (IsClicked)="removeFile($event)"
    [disabled]="false"
  ></app-primary-button>
</div>
