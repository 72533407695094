<div [formGroup]="selectFormFields">
  <div class="flex justify-between items-center">
    <label
      [ngClass]="labelClasses"
      class="txt-bold-gray mb-2"
      for="{{ form_control_name }}"
      >{{ label_text }}</label
    >
  </div>
  <select
    id="{{ form_control_name }}"
    formControlName="{{ form_control_name }}"
    [ngClass]="selectClasses"
    [(ngModel)]="model"
    (ngModelChange)="checkInput()"
    class="outline-none"
  >
    <option *ngFor="let status of userStatus" [ngValue]="status.name" class="text-black">
      {{ status.name }}
    </option>
  </select>
</div>
