import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-next-button',
  templateUrl: './next-button.component.html',
  styleUrls: ['./next-button.component.scss'],
})
export class NextButtonComponent implements OnInit {
  @Output() IsClicked = new EventEmitter<string>();
  @Input() nextButtonClasses = '';
  @Input() buttonClasses = '';
  @Input() disabled = true;
  constructor() {}

  ngOnInit(): void {}

  onClick($event: any): void {
    this.IsClicked.emit($event);
  }
}
