import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() headerClasses = '';
  constructor(private router: Router) {}

  ngOnInit(): void {}

  getHome() {
    this.router.navigate(['/home']);
  }
}
