import { PropertyService } from './../../shared/services/property.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-property-candidates-list',
  templateUrl: './property-candidates-list.component.html',
  styleUrls: ['./property-candidates-list.component.scss'],
})
export class PropertyCandidatesListComponent implements OnInit {
  public current_step: string;
  public property_id: string;
  public isEmpty: boolean = false;
  public users_array: any[] = [];
  public users_array_filtered: any[] = [];
  @Output() IsClicked = new EventEmitter<any>();
  public my_property_id: string;
  constructor(
    private propertyService: PropertyService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.my_property_id = this.route.snapshot.paramMap.get('property_id');
    this.property_id = this.route.snapshot.paramMap.get('id');
    this.current_step = 'INTERESSE';
  }

  ngOnInit(): void {
    this.getUsersInterested();
  }

  /**
   * switchStep
   */
  public switchStep($step) {
    switch ($step) {
      case 'INTERESSE':
        this.current_step = 'INTERESSE';
        this.filteredInterestedUsers(this.current_step);
        break;
      case 'PRET_A_FAIRE_UNE_OFFRE':
        this.current_step = 'PRET_A_FAIRE_UNE_OFFRE';
        this.filteredInterestedUsers(this.current_step);
        break;
      case 'SOUHAITE_UNE_VISITE':
        this.current_step = 'SOUHAITE_UNE_VISITE';
        this.filteredInterestedUsers(this.current_step);
        break;
      case 'SOUHAITE_PLUS_DE_PHOTOS':
        this.current_step = 'SOUHAITE_PLUS_DE_PHOTOS';
        this.filteredInterestedUsers(this.current_step);
        break;
      case 'SINGLE_USER_INTERESTED':
        this.current_step = 'SINGLE_USER_INTERESTED';
        this.filteredInterestedUsers(this.current_step);
        break;
      default:
        break;
    }
  }

  /**
   * getUsersInterested
   */
  public getUsersInterested() {
    if (!this.my_property_id) {
      this.propertyService
        .getInterestedUsers(this.property_id)
        .subscribe((res) => {
          console.log(res);
          if (res && res.data.getInterestedUsers) {
            this.users_array = res.data.getInterestedUsers;
            if (this.users_array.length) {
              this.filteredInterestedUsers(this.current_step);
            } else {
              this.isEmpty = true;
            }
          } else if (res.errors.message) {
            this.isEmpty = true;
            console.log(res.errors.message);
          } else {
            this.isEmpty = true;
            console.log('ERREUR SERVEUR');
          }
        });
    } else {
      this.propertyService
        .getInterestedUsers(this.my_property_id)
        .subscribe((res) => {
          console.log(res);
          if (res && res.data.getInterestedUsers) {
            this.users_array = res.data.getInterestedUsers;
            if (this.users_array.length) {
              this.filteredInterestedUsers(this.current_step);
            } else {
              this.isEmpty = true;
            }
          } else if (res.errors.message) {
            this.isEmpty = true;
            console.log(res.errors.message);
          } else {
            this.isEmpty = true;
            console.log('ERREUR SERVEUR');
          }
        });
    }
  }

  /**
   * filteredInterestedUsers
   */
  public filteredInterestedUsers($event) {
    this.users_array_filtered = this.users_array.filter((element) => {
      return element.like_status.includes($event);
    });
    console.log('FILTRE', this.users_array_filtered);
    if (!this.users_array_filtered.length) {
      this.isEmpty = true;
    }
  }

  /**
   * getUser
   */
  public onClick($user_id) {
    const data = {
      candidate_id: $user_id,
      projection_level: this.current_step
    }
    this.IsClicked.emit(data);
  }
}
