<div [formGroup]="selectDateFormFields" class="flex">
  <select
    id="{{ form_control_name_month }}"
    formControlName="{{ form_control_name_month }}"
    [ngClass]="selectClasses"
    [(ngModel)]="current_month"
    (change)="checkInput()"
    class="outline-none"
  >
    <option [value]=""></option>
    <option
      *ngFor="let month of monthsArray"
      [value]="month.name"
      class="txt-bold-gray font-bold"
    >
      {{ month.value }}
    </option>
  </select>
  <select
    id="{{ form_control_name_year }}"
    formControlName="{{ form_control_name_year }}"
    [ngClass]="yearClasses"
    [(ngModel)]="current_year"
    (change)="checkInput()"
    class="outline-none"
  >
    <option
      *ngFor="let year of yearsArray"
      [value]="year.name"
      class="txt-bold-gray font-bold"
    >
      {{ year.name }}
    </option>
  </select>
</div>
