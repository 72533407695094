<div class="fex flex-col">
  <div class="flex justify-between items-center">
    <label
      [ngClass]="labelClasses"
      class="txt-bold-gray"
      for="{{ label_name }}"
      >{{ label_text }}</label
    >
    <img
      (click)="onClick(label_name, readonly)"
      src="assets/icons/svg/pencil.svg"
      [ngClass]="icon_visibility ? 'h-8 cursor-pointer' : 'hidden'"
    />
  </div>
  <div [formGroup]="formFields">
    <textarea
      formControlName="{{ label_name }}"
      class="border-b-2 w-full outline-none resize-none txt-bold-gray"
      name="{{ label_name }}"
      id="{{ label_name }}"
      [ngClass]="
        hasError && showErrors
          ? [additionnalClasses, 'txt-pink', 'border-pink']
          : [additionnalClasses, textColor]
      "
      cols="30"
      rows="10"
      [placeholder]="placeholder"
      [(ngModel)]="model"
      (keyup)="onChange($event)"
      [attr.disabled]="disabled ? true : null"
      [attr.readonly]="readonly === 'readonly' ? 'readonly' : null"
      maxlength="{{ maxlength }}"
      >{{ model }}</textarea
    >
    <div class="pt-2">
      <div class="bg-pink rounded-md" [ngClass]="errorWidth">
        <span
          *ngIf="
            hasError &&
            showErrors &&
            (value === 'EMAIL' || value === 'NEW_EMAIL')
          "
          class="text-white text-xxs mr-2"
          >Adresse email non valide</span
        >
        <span
          *ngIf="
            (hasError && showErrors && value === 'PASSWORD') ||
            (hasError && showErrors && value === 'NEW_PASSWORD')
          "
          class="text-white text-xxs mr-2"
          >Doit avoir au minimum 8 caractères, une majuscule, une minuscule, un
          chiffre et un caractère spécial</span
        >
      </div>
    </div>
  </div>
</div>
