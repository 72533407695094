<div (click)="onClick($event)" [ngClass]="cardClasses">
  <p *ngIf="textClasses !== '' ? 'block' : 'hidden'" [ngClass]="textClasses">
    {{ text }}
  </p>
  <img [ngClass]="imgClasses" [src]="imgPath" />
  <p
    *ngIf="subtitleClasses !== '' ? 'block' : 'hidden'"
    [ngClass]="subtitleClasses"
  >
    {{ subtitle }}
  </p>
</div>
