import { PropertyModel } from 'src/app/shared/models/property-model';
import { PropertyService } from 'src/app/shared/services/property.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { LocalStorageService } from './../../shared/services/local-storage.service';
import { environment } from 'src/environments/environment';
import { CustomPopupComponent } from 'src/app/shared/components/custom-popup/custom-popup.component';
import { LoaderPopupComponent } from './../../shared/components/loader-popup/loader-popup.component';
import { Router } from '@angular/router';
import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
/* Configure Amplify resources */
Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-3',

    // REQUIRED - Amazon Cognito User Pool ID
    userPoolId: environment.userPoolId,

    // REQUIRED - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: environment.userPoolWebClientId,
  },
});

@Component({
  selector: 'app-my-account',
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
    ]),
  ],
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {
  @Input() user: any = {};
  public userIsLoggedIn: boolean = false;
  public user_type: string;
  public array_property: PropertyModel[] = [];
  public favorites_property_array: any[] = [];
  public user_id: string;
  public hasError: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private propertyService: PropertyService,
    private renderer: Renderer2
  ) {
    this.user.firstname = this.localStorageService.getCurrentUser().firstname
    this.user_id = this.localStorageService.getCurrentUser().id;
    this.user_type = this.localStorageService.getCurrentUser().user_type;
    this.userIsLoggedIn = false;
    this.renderer.setStyle(document.body, 'background-color', '#b9dbf1');
  }

  ngOnInit(): void {
    console.log('USER', this.user_id);
    this.checkIfUserIsLogged();
  }

  /**
   * Check the local storage to know if user is logged or not for the redirection link
   */
  public checkIfUserIsLogged() {
    if (this.user_id) {
      this.userIsLoggedIn = true;
    } else {
      this.router.navigate(['/account/authentication']);
    }
  }

  /**
   * Go to personal informations page
   */
  public infoPerso() {
    this.router.navigate(['/personal-informations']);
  }

  /**
   * Go to personal informations page
   */
  public appointment() {
    this.router.navigate(['/appointment']);
  }

  /**
   * Logout function
   */
  public logout() {
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Déconnexion en cours ... ',
        isLoader: true,
      },
    });
    Auth.signOut()
      .then((response) => {
        console.log(response);
        this.dialog.closeAll();
        this.router.navigate(['/welcome']);
        this.localStorageService.clear();
        return response;
      })
      .catch((error) => {
        console.log('error sign out', error);
        this.dialog.closeAll();
        this.dialog.open(CustomPopupComponent, {
          disableClose: true,
          hasBackdrop: true,
          height: '70%',
          panelClass: 'custom-modalbox',
          data: {
            status: 'error',
            title: "Une erreur s'est produite",
            message: 'Nous sommes désolé. Veuillez réessayer ultérieurement.',
          },
        });
      });
  }

  /**
   * getMyProperty function
   * Return user sale or rent property
   */
  public getMyProperty($service) {
    this.router.navigate([`/my-property/${$service}`]);
  }

  /**
   * getFavoritesListProperty
   */
  public getFavoritesListProperty($service) {
    this.router.navigate([`favorite-property-list/${$service}`]);
  }
}
