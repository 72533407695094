<div [@fade] class="w-full mx-auto bg-white overflow-auto">
  <!-- IF FILTER PAGE NOT OPEN DISPLAY MAIN PAGE-->
  <div *ngIf="current_step === 'MAIN'">
    <!-- Header -->
    <div class="border-b border-solid pb-2">
      <div class="mx-6">
        <app-secondary-header
          (IsClicked)="back()"
          headerClasses=" pt-4 flex justify-between items-center mb-2"
        ></app-secondary-header>
        <div class="mx-auto text-center">
          <p class="font-bold text-xl txt-bold-gray">Rechercher un bien</p>
        </div>
      </div>
    </div>
    <!-- Sort button -->
    <div class="flex justify-between mx-6 items-center mt-3">
      <div
      (click)="filterPage($event)"
      class="flex gap-3 items-center justify-start cursor-pointer"
    >
      <div>
        <app-img imgPath="assets/icons/svg/sort.svg" imgClasses="h-4"></app-img>
      </div>
      <div>
        <app-text textClasses="txt-bold-gray text-sm" text="Filtres"></app-text>
      </div>
    </div>
    <!-- Remove all filter attributes -->
    <div *ngIf="filter_attributes_length">
      <app-text
        textClasses="txt-light-blue text-sm cursor-pointer"
        text="Effacer les filtres"
        (IsClicked)="removeAttributesFilter()"
      ></app-text>
    </div>
    </div>
    <!-- Property List => TODO -->
    <div class="mx-6 mt-6 mb-8 flex flex-col gap-4">
      <div
        *ngIf="!array_filtered.length && !hasError"
        class="mx-auto"
        id="waiting_data"
      >
        <mat-spinner diameter="50"></mat-spinner>
      </div>
      <!-- No Result text -->
      <p
        *ngIf="!array_filtered.length && hasError"
        class="text-center txt-light-blue"
      >
        Aucun résultats
      </p>
      <div *ngIf="array_filtered.length" class="mx-auto">
        <app-property-card
          *ngFor="let property of array_filtered"
          (IsClicked)="getProperty(property.ID)"
          [assets_path]="property.Photos[0]"
          [assets_length]="property.Photos.length"
          imgClasses="rounded-2xl overflow-auto"
          attributes_assets_path="assets/icons/svg/logo-1.svg"
          txtClasses="text-xs txt-bold-gray font-extrabold"
          [price]="property.Price + ' €'"
          [pieces_number]="property.Number_of_pieces + ' pièces'"
          [total_area]="property.Total_area + ' m²'"
          [property_subtype]="property.Property_subtype"
          [location]="property.City"
          [published_date]="property.Published_date"
          [isFavorite]="property.IsFavorite"
          (IsLikeOrUnlike)="addOrRemoveFavoriteProperty(property)"
        ></app-property-card>
      </div>
    </div>
  </div>
  <!-- FILTER PAGE -->
  <div>
    <app-filter *ngIf="current_step === 'FILTERS'" (IsClicked)="getFilterAttributes($event)"></app-filter>
  </div>
</div>
