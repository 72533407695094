import { Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appointment',
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
    ]),
  ],
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss'],
})
export class AppointmentComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  /**
   * BACK FUNCTION
   */
  public back() {
    this.router.navigate(['/home']);
  }

  /**
   * appointmentAction
   * This function is a redirect function => create or consult appointment
   */
  public appointmentAction(action: string) {
    this.router.navigate([`${action}`]);
  }
}
