import { PropertyService } from 'src/app/shared/services/property.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-appointment-guest',
  templateUrl: './appointment-guest.component.html',
  styleUrls: ['./appointment-guest.component.scss'],
})
export class AppointmentGuestComponent implements OnInit {
  public current_step: string;
  public user_name: string;
  public user_email: string;
  public external_speaker_email: string;
  public external_speaker_email_valid: boolean = false;
  public firstname: any;
  public lastname: any;
  public guest_confirmed: boolean = false;
  public external_speaker_confirmed: boolean = false;
  @Input() property_id: string;
  // TEST
  public array_users: any[] = [
    {
      id: '98765',
      firstname: 'Nicolas',
      lastname: 'Burlac',
      email: 'nburlac@outlook.fr',
    },
    {
      id: '45678',
      firstname: 'Eric',
      lastname: 'Alphonse',
      email: 'ealphonse@outlook.fr',
    },
    {
      id: '12345',
      firstname: 'Jonathan',
      lastname: 'Turisy',
      email: 'jturisy@outlook.fr',
    },
    {
      id: '09877',
      firstname: 'Alain',
      lastname: 'Poux',
      email: 'apoux@outlook.fr',
    },
  ];
  public array_users_filtered: any[] = [];
  @Output() IsSelected = new EventEmitter<any>();
  @Output() ExternalSpeakerSelected = new EventEmitter<any>();
  public users_interested: boolean;
  constructor(private propertyService: PropertyService) {
    this.current_step = 'NAME';
  }

  ngOnInit(): void {
    this.getInterestedUsers();
  }

  /**
   * switchStep
   */
  public switchStep($step: string) {
    switch ($step) {
      case 'NAME':
        this.current_step = $step;
        break;
      case 'EMAIL':
        this.current_step = $step;
        break;
      default:
        break;
    }
  }

  /**
   * Add an External Speaker
   */
  public addAnExternalSpeaker($email: any) {
    console.log($email);
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (emailRegex.test($email.model) && $email.model) {
      this.external_speaker_email_valid = true;
      this.external_speaker_email = $email.model;
    }
  }

  /**
   * getUsersInterested
   */
  public getInterestedUsers() {
    console.log(this.property_id);

    this.propertyService
      .getInterestedUsers(this.property_id)
      .subscribe((res) => {
        console.log(res);
        if (res && res.data.getInterestedUsers) {
          console.log('ça passe');
          this.array_users = res.data.getInterestedUsers;
          this.users_interested = true;
        } else {
          this.users_interested = false;
        }
      });
  }

  /**
   * onSearchTextChange => NAME STEP
   */
  public onSearchTextChange($name: any) {
    console.log($name);
    if ($name && $name.model && this.users_interested) {
      this.array_users_filtered = this.array_users.filter((object: any) => {
        console.log('ici', object);
        this.user_name =
          object.user_interested_firstname.toLowerCase() +
          ' ' +
          object.user_interested_lastname.toLowerCase();
        console.log('userName', this.user_name);
        return this.user_name.includes($name.model.toLowerCase());
      });
    }
  }

  /**
   * userSelected
   */
  public userSelected($user: any) {
    console.log($user);
    this.firstname = $user.user_interested_firstname;
    this.lastname = $user.user_interested_lastname;
    let data = {};
    if (this.current_step === 'NAME') {
      data = {
        user:
          $user.user_interested_firstname +
          ' ' +
          $user.user_interested_lastname,
        user_id: $user.id,
      };
    }

    this.IsSelected.emit(data);
  }

  /**
   * getExternalSpeakerEmail
   */
  public getExternalSpeakerEmail($email: any) {
    console.log($email);
    this.external_speaker_email = $email;
    let data = {};
    data = {
      external_speaker_email: $email,
    };
    this.ExternalSpeakerSelected.emit(data);
  }

  /**
   * confirmAppointmentGuest
   */
  public confirmAppointmentGuest() {
    this.guest_confirmed = true;
  }

  /**
   * changeGuest
   */
  public changeGuest() {
    this.guest_confirmed = false;
    this.firstname = null;
    this.lastname = null;
    this.user_email = null;
  }

  /**
   * changeExternalSpeaker
   */
  public changeExternalSpeaker() {
    this.external_speaker_confirmed = false;
    this.external_speaker_email = null;
  }

  /**
   * confirmExternalSpeaker
   */
  public confirmExternalSpeaker() {
    this.external_speaker_confirmed = true;
  }
}
