<div [@fade] class="gap-3">
  <app-text
    textClasses="txt-bold-gray mx-auto text-center font-bold mt-16 mb-6"
    text="Indiquez le motif de ce rendez-vous. *"
  ></app-text>
  <div class="mx-auto flex justify-center">
    <app-img imgPath="assets/icons/svg/interrogation.svg" imgClasses="h-40"></app-img>
  </div>
  <div class="mx-auto mt-16">
    <div class="mx-auto">
      <app-input
        [model]="appointmentModel.Appointment_reason"
        type="text"
        (Changed)="onChange($event)"
        value="APPOINTMENT_REASON"
        placeholder="Tapez ici ..."
        maxlength="50"
      ></app-input>
      <app-text
        textClasses="txt-percian-gray text-xxs mx-auto text-left mt-6"
        text="Champs requis*"
      ></app-text>
    </div>
  </div>
</div>
