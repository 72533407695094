import { ParametersService } from './../../shared/services/parameters.service';
import { DataService } from 'src/app/shared/services/data.service';
import { AppointmentModel } from './../../shared/models/appointment-model';
import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-appointment-type',
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
    ]),
  ],
  templateUrl: './appointment-type.component.html',
  styleUrls: ['./appointment-type.component.scss'],
})
export class AppointmentTypeComponent implements OnInit {
  @Input() appointmentModel = new AppointmentModel();
  @Input() appointmentTypeArray = [
    {
      controlName: 'appointment_type',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Visite du bien avec un acheteur',
      item_value_db: 'VISITE_DU_BIEN_AVEC_UN_ACHETEUR',
      type: 'APPOINTMENT_TYPE',
    },
    {
      controlName: 'appointment_type',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Visite du bien par un expert',
      item_value_db: 'VISITE_DU_BIEN_PAR_UN_EXPERT',
      type: 'APPOINTMENT_TYPE',
    },
    {
      controlName: 'appointment_type',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Appel téléphonique',
      item_value_db: 'APPEL_TELEPHONIQUE',
      type: 'APPOINTMENT_TYPE',
    },
    {
      controlName: 'appointment_type',
      path_img: 'assets/icons/svg/logo-1.svg',
      item_value: 'Rendez-vous en agence',
      item_value_db: 'RENDEZ_VOUS_EN_AGENCE',
      type: 'APPOINTMENT_TYPE',
    },
  ];
  @Output() IsClicked = new EventEmitter<any>();
  public savedData = {
    APPEL_TELEPHONIQUE: {},
    VISITE_DU_BIEN_PAR_UN_EXPERT: {},
    VISITE_DU_BIEN_AVEC_UN_ACHETEUR: {},
    RENDEZ_VOUS_EN_AGENCE: {},
  };
  @Input() available_days_array: any[] = [];
  @Input() user_has_parameters: boolean;
  @Input() user_id: string;
  public params: any;

  constructor(
    private dataService: DataService,
    private parametersService: ParametersService
  ) {}

  ngOnInit(): void {
    this.getParametersByUser();
  }

  /**
   * onClick Function
   * This function add an "RADIO STYLE" for items selected
   * When the item is selected, add selected style on it and remove selected style on old item selected
   */
  public isSelected($appointment_type: any, $id: any) {
    var element = document.getElementById($id);
    element?.classList.add('active');
    if ($appointment_type !== $id && $appointment_type !== '') {
      var elementToRemove = document.getElementById($appointment_type);
      elementToRemove?.classList.remove('active');
    }
  }

  /**
   * onClick
   */
  public onClick($appointment_type) {
    if ($appointment_type.value === 'APPOINTMENT_TYPE') {
      Array.from(this.params).forEach((param: any) => {
        if (param.appointment_type === $appointment_type.id) {
          this.savedData.APPEL_TELEPHONIQUE = param;
          this.available_days_array = param.days.split('-');
        } else if (param.appointment_type === $appointment_type.id) {
          this.savedData.VISITE_DU_BIEN_PAR_UN_EXPERT = param;
          this.available_days_array = param.days.split('-');
        } else if (param.appointment_type === $appointment_type.id) {
          this.savedData.VISITE_DU_BIEN_AVEC_UN_ACHETEUR = param;
          this.available_days_array = param.days.split('-');
        } else if (param.appointment_type === $appointment_type.id) {
          this.savedData.RENDEZ_VOUS_EN_AGENCE = param;
          this.available_days_array = param.days.split('-');
        }
      });
      console.log('this.savedData', this.savedData);
      console.log('this.available_days_array', this.available_days_array);
      this.isSelected(
        this.appointmentModel.Appointment_type,
        $appointment_type.id
      );
      this.appointmentModel.Appointment_type = $appointment_type.id;
    }
    const data = {
      appointment_type: $appointment_type,
      available_days_array: this.available_days_array,
    };
    this.IsClicked.emit(data);
  }

  /**
   * getParametersByUser
   */
  public async getParametersByUser() {
    await this.parametersService
      .getParametersByProfessionnel(this.user_id)
      .subscribe((res) => {
        console.log(res);

        if (res.data && res.data.ParameterByUser) {
          console.log(res);
          console.log(
            'APPOINTMENT_TYPE',
            this.appointmentModel.Appointment_type
          );
          this.params = res.data.ParameterByUser.items;
          this.user_has_parameters = true;
        } else {
          this.user_has_parameters = false;
        }
      });
  }
}
