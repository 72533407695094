import { LocalStorageService } from './../../shared/services/local-storage.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-account-profile',
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
    ]),
  ],
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.scss'],
})
export class AccountProfileComponent implements OnInit {
  public user_status: String = '';
  constructor(
    private router: Router,
    private dataService: DataService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {}

  onClick($event: string) {
    switch ($event) {
      case 'PROFESSIONNEL':
        this.dataService.data['USER_TYPE'] = $event;
        this.localStorageService.setUserType($event)
        break;
      case 'PARTICULIER':
        this.dataService.data['USER_TYPE'] = $event;
        this.localStorageService.setUserType($event)
        break;
    }
    this.router.navigate(['/home']);
  }
}
