import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from './../../services/data.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LocalStorageService } from '../../services/local-storage.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-input-otp',
  templateUrl: './input-otp.component.html',
  styleUrls: ['./input-otp.component.scss'],
})
export class InputOtpComponent implements OnInit {
  public disableContinue = true;
  public conditionsChecked = false;
  public confirmationCode = ['', '', '', '', '', ''];
  @Input() value = '';
  @Output() Changed = new EventEmitter<any>();
  @Input() showErrors = true;
  public model: any;
  public hasError = false;
  @Input() disabled = false;
  @Input() errorWidth = '';
  @Input() borderClasses = 'border-white';
  @Input() borderColor = 'input-border';
  @Input() additionnalClasses = '';
  @Input() textColor = 'txt-white';
  @Input() input_code: String = '';
  @Input() otpFormFields: FormGroup = this.formBuilder.group({
    otp_code_1: ['', Validators.required],
    otp_code_2: ['', Validators.required],
    otp_code_3: ['', Validators.required],
    otp_code_4: ['', Validators.required],
    otp_code_5: ['', Validators.required],
    otp_code_6: ['', Validators.required],
  });
  constructor(
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private dataService: DataService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {}

  // Cette fonction est pour le passage automatique d'un champs à l'autre pour le code OTP
  onInputEntry(event: any, id: string, nextInputIndex: any): any {
    if (event.target.value !== '') {
      const input = event.target;
      const nexInput = +nextInputIndex + 1;
      const newID = id + nexInput;
      if (document.getElementById(newID)) {
        document.getElementById(newID)!.focus(); // Le point d'exclamation est présent pour dire qu'on assure à Typescript que l'objet ne sera pas "null"
      }
    }
  }
  onChange($event: any): void {
    this.isValidCode()
      ? (this.disableContinue = false)
      : (this.disableContinue = true);
    this.checkInput();
  }

  isValidCode(): boolean {
    if (
      this.otpFormFields.controls['otp_code_1'].value !== '' &&
      this.otpFormFields.controls['otp_code_2'].value !== '' &&
      this.otpFormFields.controls['otp_code_3'].value !== '' &&
      this.otpFormFields.controls['otp_code_4'].value !== '' &&
      this.otpFormFields.controls['otp_code_5'].value !== '' &&
      this.otpFormFields.controls['otp_code_6'].value !== ''
    ) {
      return true;
    }
    return false;
  }
  checkInput(): void {
    if (this.value === 'OTP_CODE') {
      if (!this.isValidCode()) {
        this.hasError = true;
        const data = {
          value: this.value,
          model: '',
        };
        this.Changed.emit(data);
      } else {
        this.hasError = false;
        const data = {
          value: this.value,
          model: this.confirmationCode,
        };
        this.Changed.emit(data);
      }
    }
  }
}
