import { Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-secondary-header',
  templateUrl: './secondary-header.component.html',
  styleUrls: ['./secondary-header.component.scss'],
})
export class SecondaryHeaderComponent implements OnInit {
  @Input() headerClasses = '';
  @Output() IsClicked = new EventEmitter<string>();
  constructor(private router: Router) {}

  ngOnInit(): void {}
  
  back($event: any) {
    this.IsClicked.emit($event);
    // this.router.navigate(['/my-account']);
  }
}
