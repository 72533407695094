<div
  style="z-index: 999999 !important"
  class="
    flex
    w-11/12
    mx-auto
    justify-center
    items-center
    rounded-3xl
    bg-white
    shadow-2xl
    popupPlace
  "
>
  <!-- STATUS => DELETE -->
  <div
    style="z-index: 999999 !important"
    class="w-full"
    *ngIf="status === 'delete'"
  >
    <img
      draggable="false"
      src="assets/icons/svg/logo-1.svg"
      class="mx-auto h-20 mb-6 mt-16"
    />
    <div
      class="
        txt-bold-gray
        text-xl
        font-semibold
        text-md text-center
        mx-auto
        w-auto
        px-4
        mb-6
      "
    >
      {{ title }}
    </div>
    <div class="txt-bold-gray text-md text-center mx-auto w-auto px-4 mb-6">
      {{ message }}
    </div>
    <div class="text-center mx-auto mb-16 flex gap-3 justify-center">
      <app-primary-button
        textPosition="right"
        text="Annuler"
        buttonClasses="bg-white border-light-purple txt-light-purple border mx-auto gap-3 items-center py-3"
        firstImgSrc="assets/icons/svg/purple-cross.svg"
        secondImgSrc="assets/icons/svg/purple-cross.svg"
        (IsClicked)="close()"
        [disabled]="false"
      ></app-primary-button>
      <app-primary-button
        text="Continuer"
        buttonClasses="border-light-purple bg-purple border text-white mx-auto gap-3 items-center py-3"
        firstImgSrc="assets/icons/svg/arrow-right.svg"
        secondImgSrc="assets/icons/svg/arrow-right.svg"
        (IsClicked)="confirm()"
        [disabled]="false"
      ></app-primary-button>
    </div>
  </div>
  <!-- STATUS => SUCCESS -->
  <div
    style="z-index: 999999 !important"
    class="w-full"
    *ngIf="status === 'success'"
  >
    <div class="mx-auto text-center mt-16 mb-6 txt-light-blue font-bold">
      <p>Tout est ok !</p>
    </div>
    <img
      draggable="false"
      src="assets/icons/svg/logo-1.svg"
      class="mx-auto h-20 mb-6"
    />
    <div
      class="
        txt-bold-gray
        text-xl
        font-semibold
        text-md text-center
        mx-auto
        w-auto
        px-4
        mb-6
      "
    >
      {{ title }}
    </div>
    <div class="txt-bold-gray text-md text-center mx-auto w-auto px-4 mb-6">
      {{ message }}
    </div>
    <div class="mx-auto text-center mb-16">
      <app-primary-button
        text="Continuer"
        buttonClasses="bg-light-blue text-white border-secondary-blue mx-auto gap-3 items-center"
        firstImgSrc="assets/icons/svg/arrow-right.svg"
        secondImgSrc="assets/icons/svg/arrow-right.svg"
        (IsClicked)="close()"
        [disabled]="false"
      ></app-primary-button>
    </div>
  </div>
  <!-- STATUS => ERROR -->
  <div
    style="z-index: 999999 !important"
    class="w-full"
    *ngIf="status === 'error'"
  >
    <div class="mx-auto mt-16 mb-6 text-center txt-pink font-bold">
      <p>Oups !</p>
    </div>
    <img
      draggable="false"
      src="assets/icons/svg/logo-1.svg"
      class="mx-auto h-20 mb-6"
    />
    <div
      class="
        txt-bold-gray
        text-xl
        font-semibold
        text-md text-center
        mx-auto
        w-auto
        px-4
        mb-6
      "
    >
      {{ title }}
    </div>
    <div class="txt-bold-gray text-md text-center mx-auto w-auto px-4 mb-6">
      {{ message }}
    </div>
    <div class="mx-auto text-center mb-16">
      <app-primary-button
        text="Réessayer"
        buttonClasses="bg-pink text-white items-center mx-auto gap-3"
        firstImgSrc="assets/icons/svg/arrow-right.svg"
        secondImgSrc="assets/icons/svg/arrow-right.svg"
        (IsClicked)="close()"
        [disabled]="false"
      ></app-primary-button>
    </div>
  </div>
</div>
