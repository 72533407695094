import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import Amplify from 'aws-amplify';
import * as AWS from 'aws-sdk';
const config: any = {
  aws_cognito_identity_pool_id: environment.AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: environment.AWS_COGNITO_REGION,
  aws_user_pools_id: environment.userPoolId,
  aws_user_pools_web_client_id: environment.userPoolWebClientId,
};
Amplify.configure(config);

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  constructor() {}

  /* DISABLED USER */
  public disabledUser(event: any) {
    try {
      var cognitoUser = new AWS.CognitoIdentityServiceProvider(config);
      new Promise((resolve, reject) => {
        const params: any = {
          UserPoolId: environment.userPoolId,
          Username: event.arguments.id, // I want to disabled this user
        };

        cognitoUser.adminDisableUser(params, (err: any, data: any) => {
          if (err) {
            console.log('ERROR DISABLED USER: ', err, err.stack);
            reject(err);
            console.log(err);
          } else {
            console.log('SUCCESS DISABLED USER: ', data);
            resolve(data);
          }
        });
      });
      return;
    } catch (error) {
      throw error;
    }
  }
  /* ENABLED USER */
  public enabledUser(event: any) {
    try {
      var cognitoUser = new AWS.CognitoIdentityServiceProvider(config);
      new Promise((resolve, reject) => {
        const params: any = {
          UserPoolId: process.env.AWS_USER_POOLS_ID,
          Username: event.arguments.id, // I want to enabled this user
        };

        cognitoUser.adminEnableUser(params, (err: any, data: any) => {
          if (err) {
            console.log('ERROR DISABLED USER: ', err, err.stack);
            reject(err);
            console.log(err);
          } else {
            console.log('SUCCESS DISABLED USER: ', data);
            resolve(data);
          }
        });
      });
      return;
    } catch (error) {
      throw error;
    }
  }

}
