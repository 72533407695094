import { trigger, transition, style, animate } from '@angular/animations';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './../../shared/services/local-storage.service';
import { UserService } from './../../shared/services/user.service';
import { AgencyModel } from './../../shared/models/agency-model';
import { Router } from '@angular/router';
import { AddressModel } from './../../shared/models/address-model';
import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserModel } from 'src/app/shared/models/user-model';
import { MatDialog } from '@angular/material/dialog';
import { CustomPopupComponent } from 'src/app/shared/components/custom-popup/custom-popup.component';
import { LoaderPopupComponent } from 'src/app/shared/components/loader-popup/loader-popup.component';
import { AgencyService } from 'src/app/shared/services/agency.service';
import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { GoogleService } from 'src/app/shared/services/google.service';
/* Configure Amplify resources */
Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-3',

    // REQUIRED - Amazon Cognito User Pool ID
    userPoolId: environment.userPoolId,

    // REQUIRED - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: environment.userPoolWebClientId,
  },
});

@Component({
  selector: 'app-account-authentication',
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
    ]),
  ],
  templateUrl: './account-authentication.component.html',
  styleUrls: ['./account-authentication.component.scss'],
})
export class AccountAuthenticationComponent implements OnInit {
  public user_type: String = '';
  @Input() authentication_choice: String = '';
  public current_step: String = 'EMAIL';
  public email: String = '';
  @Input() input_value = [];
  @Input() privacy_policy_accepted = false;
  @Input() agencyName: string = '';
  public agencyListArray: any[] = [];
  public agencyListArrayFiltered: any[] = [];
  public user_firstname: string = '';
  @Input() stepNumber: Number;
  @Input() stepsArray = [];
  public myForm: FormGroup = this.formBuilder.group({
    formFields: this.formBuilder.group({
      // create nested formgroup to pass to child
      email: ['', Validators.required],
      password: ['', Validators.required],
      password_confirm: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      agency_name: ['', Validators.required],
      address_location: ['', Validators.required],
      postal_code: ['', Validators.required],
      city: ['', Validators.required],
      siret: ['', Validators.required],
    }),
    otpFormFields: this.formBuilder.group({
      // create nested formgroup to pass to child
      otp_code_1: ['', Validators.required],
      otp_code_2: ['', Validators.required],
      otp_code_3: ['', Validators.required],
      otp_code_4: ['', Validators.required],
      otp_code_5: ['', Validators.required],
      otp_code_6: ['', Validators.required],
    }),
    agencyFile: this.formBuilder.group({
      // create nested formgroup to pass to child
      agency_scale_of_fees: [''], // Fichier barrème honoraire agence *Non requis*
    }),
    agencyID: this.formBuilder.group({
      // create nested formgroup to pass to child
      agency_id: [''],
    }),
  });
  public userModel = new UserModel();
  public addressModel = new AddressModel();
  public agencyModel = new AgencyModel();
  constructor(
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private agencyService: AgencyService,
    private googleService: GoogleService
  ) {}

  ngOnInit() {
    this.stepNumber = 1;
    this.agencysList();
    this.checkUserType();
    this.authentication_choice = 'CONNEXION';
    const userModel = new UserModel();
    this.cdRef.detectChanges();
    this.getAddressAgency();
  }

  // Progress bar function
  checkStepNumber() {
    if (
      this.user_type === 'PARTICULIER' &&
      this.authentication_choice === 'INSCRIPTION'
    ) {
      this.stepsArray = [
        {
          step: 1,
        },
        {
          step: 2,
        },
        {
          step: 3,
        },
        {
          step: 4,
        },
      ];
    } else if (
      this.user_type === 'PROFESSIONNEL' &&
      this.authentication_choice === 'INSCRIPTION'
    ) {
      this.stepsArray = [
        {
          step: 1,
        },
        {
          step: 2,
        },
        {
          step: 3,
        },
        {
          step: 4,
        },
        {
          step: 5,
        },
        {
          step: 6,
        },
      ];
    }
  }

  // BACK FUNCTION
  back($event: any): void {
    switch (this.current_step) {
      case 'EMAIL':
        this.router.navigate(['/home']);
        break;
      case 'PASSWORD':
        this.current_step = 'EMAIL';
        this.stepNumber = 1;
        this.userModel = new UserModel();
        break;
      case 'MISSING_PASSWORD':
        this.current_step = 'EMAIL';
        break;
      case 'CHANGE_MISSING_PASSWORD':
        this.current_step = 'MISSING_PASSWORD';
        break;
      case 'OTP_CONFIRM':
        this.stepNumber = 2;
        break;
      case 'USERNAME':
        this.current_step = 'EMAIL';
        this.stepNumber = 1;
        break;
      case 'CREATE_ACCOUNT':
        break;
      case 'AGENCY':
        this.current_step = 'USERNAME';
        this.stepNumber = 4;
        break;
      case 'CREATE_AGENCY':
        this.current_step = 'AGENCY';
        this.stepNumber = 5;
        break;
      case 'CREATE_AGENCY_ADDRESS':
        this.current_step = 'CREATE_AGENCY';
        this.stepNumber = 5;
        break;
      case 'GO_HOME':
        this.current_step = 'EMAIL';
        break;
      default:
        //
        break;
    }
  }
  // CHECK USER TYPE
  checkUserType() {
    this.user_type = this.localStorageService.getUserType();
    this.userModel.User_type = this.user_type;
    console.log(this.userModel.User_type);
  }
  // INSCRIPTION OR CONNEXION
  authenticationChoice($event: String) {
    switch ($event) {
      case 'INSCRIPTION':
        this.authentication_choice = 'INSCRIPTION';
        this.checkStepNumber();
        break;
      case 'CONNEXION':
        this.authentication_choice = 'CONNEXION';
        break;
      default:
        this.authentication_choice = 'INSCRIPTION';
        break;
    }
  }

  onChange($event: any): void {
    if ($event.value === 'FIRSTNAME') {
      this.userModel.Firstname = $event.model;
    }
    if ($event.value === 'LASTNAME') {
      this.userModel.Lastname = $event.model;
    }
    if ($event.value === 'PHONE_NUMBER') {
      this.userModel.Phone_number = $event.model;
    }
    if ($event.value === 'EMAIL') {
      this.userModel.Email = $event.model;
    }
    if ($event.value === 'PASSWORD') {
      if ($event.model !== '') {
        this.userModel.Password = $event.model;
      }
    }
    if ($event.value === 'PASSWORD_CONFIRM') {
      if ($event.model !== '') {
        this.userModel.Password_confirm = $event.model;
      }
    }
    if ($event.value === 'AGENCY_NAME') {
      this.agencyModel.Agency_name = $event.model;
    }
    if ($event.value === 'SIRET') {
      this.agencyModel.Siret = $event.model;
      this.agencyModel.Siret = this.agencyModel.Siret.replaceAll(' ', '');
    }
    if ($event.value === 'AGENCY_FILE') {
      this.agencyModel.Agency_scale_of_fees = $event.model;
    }
    if ($event.value === 'ADDRESS_LOCATION') {
      this.addressModel.Address_location = $event.model;
    }
    if ($event.value === 'POSTAL_CODE') {
      this.addressModel.Postal_code = $event.model;
      // if (this.addressModel.Postal_code.length > 4) {
      //   this.getAddressAgency();
      // }
    }
    if ($event.value === 'CITY') {
      this.addressModel.City = $event.model;
    }
    if ($event.value === 'OTP_CODE') {
      this.userModel.Otp_code = $event.model;
    }
    if ($event.value === 'PRIVACY_POLICY') {
      this.privacy_policy_accepted = $event.model;
    }
    if ($event.value === 'AGENCY_ID') {
      this.agencyModel.ID = $event.model;
    }
  }

  // This function is implemented for the different step of authentication => Inscription
  switchCurrentStep($event: String) {
    switch ($event) {
      case 'EMAIL':
        this.current_step = 'EMAIL';
        this.stepNumber = 1;
        break;
      case 'PASSWORD':
        this.current_step = 'PASSWORD';
        this.stepNumber = 2;
        break;
      case 'MISSING_PASSWORD':
        this.current_step = 'MISSING_PASSWORD';
        break;
      case 'CHANGE_MISSING_PASSWORD':
        this.missingPassword();
        break;
      case 'OTP_CONFIRM':
        this.stepNumber = 3;
        this.signUp();
        break;
      case 'USERNAME':
        this.stepNumber = 4;
        this.signUpConfirm();
        break;
      case 'CREATE_ACCOUNT':
        this.userModel.Email = this.localStorageService.getUserEmail();
        this.userModel.ID = this.localStorageService.getUserId();
        if (
          this.user_type === 'PARTICULIER' &&
          this.authentication_choice === 'INSCRIPTION'
        ) {
          this.createIndividualAccount();
        } else if (
          this.user_type === 'PROFESSIONNEL' &&
          this.authentication_choice === 'INSCRIPTION' &&
          this.agencyModel.ID
        ) {
          this.createProfessionalAccountWithAgencyId();
        } else if (
          this.user_type === 'PROFESSIONNEL' &&
          this.authentication_choice === 'INSCRIPTION' &&
          this.agencyModel.Agency_name
        ) {
          this.createProfessionalAccountWithAgencyFields();
        }
        break;
      case 'AGENCY':
        this.stepNumber = 5;
        this.current_step = 'AGENCY';
        console.log('AGENCY', this.myForm.controls);
        break;
      case 'CREATE_AGENCY':
        this.stepNumber = 5;
        this.current_step = 'CREATE_AGENCY';
        console.log('CREATE_AGENCY', this.myForm.controls);
        break;
      case 'CREATE_AGENCY_ADDRESS':
        this.stepNumber = 6;
        this.current_step = 'CREATE_AGENCY_ADDRESS';
        console.log('CREATE_AGENCY_ADDRESS', this.myForm.controls);
        break;
      case 'GO_HOME':
        this.current_step = 'GO_HOME';
        if (this.privacy_policy_accepted) {
          this.router.navigate(['/home']);
        }
        break;
      default:
        break;
    }
  }

  // Login function
  async login() {
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Connexion en cours ... ',
        isLoader: true,
      },
    });
    this.getUser();
    await Auth.signIn(this.userModel.Email, this.userModel.Password)
      .then((user) => {
        console.log(user);
        this.dialog.closeAll();
        this.router.navigate(['/home']);
        return user;
      })
      .catch((error) => {
        console.log('error sign in', error);
        if (error.code == 'NotAuthorizedException') {
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: "Une erreur s'est produite",
              message: 'Adresse email et/ou mot de passe incorrect.',
            },
          });
        } else if (error.code == 'UserNotFoundException') {
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: "Une erreur s'est produite",
              message:
                'Utilisateur non trouvé. Veuillez vous inscrire ou vérifier les informations saisies.',
            },
          });
        } else {
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: 'Erreur serveur',
              message: 'Nous sommes désolé. Veuillez réessayer ultérieurement.',
            },
          });
        }
      });
  }

  // Get user
  async getUser() {
    try {
      await this.userService.getUser(this.userModel.Email).subscribe((res: any) => {
        if (res && res.data) {
          console.log('RES', res);
          this.localStorageService.setCurrentUser(res.data.getUser);
        }
      });
    } catch (err) {
      throw new Error(err);
    }
  }

  // Sign up Request Function
  signUp() {
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Veuillez patienter ... ',
        isLoader: true,
      },
    });
    let id;
    let username;
    Auth.signUp({
      username: this.userModel.Email,
      password: this.userModel.Password,
      attributes: {
        email: this.userModel.Email,
        phone_number: this.userModel.Phone_number,
      },
    })
      .then((data) => {
        console.log('SUCCESS SIGN UP', data);
        id = data.userSub;
        username = data.user.getUsername();
        this.localStorageService.setUserId(id);
        this.localStorageService.setUserEmail(username);
        const successDialog = this.dialog.open(CustomPopupComponent, {
          disableClose: true,
          hasBackdrop: true,
          height: '70%',
          panelClass: 'custom-modalbox',
          data: {
            status: 'success',
            title: 'Code OTP',
            message: 'Un code de sécurité vous a été envoyé par email.',
          },
        });

        successDialog.afterClosed().subscribe(() => {
          this.current_step = 'OTP_CONFIRM';
          this.dialog.closeAll();
        });
      })
      .catch((error) => {
        if (error == 'UsernameExistsException: User already exists') {
          console.log('ERROR ALREADY EXISTS' + error);
          this.dialog.closeAll();
          const errorDialog = this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: "Une erreur s'est produite",
              message: 'Cet email existe déjà.',
            },
          });
        } else {
          console.log('ERROR ' + error);
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: "Une erreur s'est produite",
              message: 'Nous sommes désolé. Veuillez réessayer ultérieurement.',
            },
          });
        }
      });
  }

  // Sign up Confirm Function
  signUpConfirm() {
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Veuillez patienter ... ',
        isLoader: true,
      },
    });
    console.log('CREDITS', this.userModel.Email, this.userModel.Otp_code);

    Auth.confirmSignUp(
      this.userModel.Email,
      this.userModel.Otp_code.join(', ').replaceAll(', ', '')
    )
      .then((user) => {
        console.log('cognito', user);
        // this.localStorageService.setCurrentUser(result.data);
        const successDialog = this.dialog.open(CustomPopupComponent, {
          disableClose: true,
          hasBackdrop: true,
          height: '70%',
          panelClass: 'custom-modalbox',
          data: {
            status: 'success',
            title: 'Votre compte a bien été créé',
            message:
              'Veuillez poursuivre en saisissant vos informations personnelles.',
          },
        });
        successDialog.afterClosed().subscribe(() => {
          this.dialog.closeAll();
          this.current_step = 'USERNAME';
        });
      })
      .catch((error) => {
        console.log('error confirming sign up', error);
        this.dialog.closeAll();
        const errorDialog = this.dialog.open(CustomPopupComponent, {
          disableClose: true,
          hasBackdrop: true,
          height: '70%',
          panelClass: 'custom-modalbox',
          data: {
            status: 'error',
            title: "Une erreur s'est produite",
            message:
              'Veuillez vérifier si le code que vous avez saisi est correct.',
          },
        });
      });
  }

  // Sign up Request Function
  resendSignUp() {
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Veuillez patienter ... ',
        isLoader: true,
      },
    });
    Auth.resendSignUp(this.userModel.Email)
      .then((user) => {
        console.log(user);
        const successDialog = this.dialog.open(CustomPopupComponent, {
          disableClose: true,
          hasBackdrop: true,
          height: '70%',
          panelClass: 'custom-modalbox',
          data: {
            status: 'success',
            title: 'Code OTP',
            message: 'Un code de sécurité vous a été renvoyé par email.',
          },
        });
      })
      .catch((error) => {
        console.log('ERROR ' + error);
        this.dialog.closeAll();
        this.dialog.open(CustomPopupComponent, {
          disableClose: true,
          hasBackdrop: true,
          height: '70%',
          panelClass: 'custom-modalbox',
          data: {
            status: 'error',
            title: "Une erreur s'est produite",
            message: 'Nous sommes désolé. Veuillez réessayer ultérieurement.',
          },
        });
      });
  }

  // Create Account without address and agency
  createIndividualAccount() {
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Veuillez patienter ... ',
        isLoader: true,
      },
    });
    this.userService.createIndividualAccount(this.userModel).subscribe(
      (result) => {
        if (result.data && result.data.createAccount) {
          console.log('RESULT', result);
          this.dialog.closeAll();
          this.current_step = 'CREATE_ACCOUNT';
        } else {
          if (result.errors) {
            console.log(result.errors);
            this.dialog.closeAll();
            const errorDialog = this.dialog.open(CustomPopupComponent, {
              disableClose: true,
              hasBackdrop: true,
              height: '70%',
              panelClass: 'custom-modalbox',
              data: {
                status: 'error',
                title: "Une erreur s'est produite",
                message:
                  'Nous sommes désolé. Veuillez réessayer ultérieurement.',
              },
            });
          }
        }
      },
      (err) => {
        this.dialog.closeAll();
        this.dialog.open(CustomPopupComponent, {
          disableClose: true,
          hasBackdrop: true,
          height: '70%',
          panelClass: 'custom-modalbox',
          data: {
            status: 'error',
            title: 'Erreur serveur',
            message: 'Nous sommes désolé. Veuillez réessayer ultérieurement.',
          },
        });
      }
    );
  }

  // Create Professional Account with agency id
  createProfessionalAccountWithAgencyId() {
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Veuillez patienter ... ',
        isLoader: true,
      },
    });
    this.userService
      .createProfessionalAccountWithAgencyId(this.userModel, this.agencyModel)
      .subscribe(
        (result) => {
          if (result.data && result.data.createAccount) {
            console.log('RESULT', result);
            this.dialog.closeAll();
            this.current_step = 'CREATE_ACCOUNT';
          } else {
            if (result.errors) {
              console.log(result.errors);
              this.dialog.closeAll();
              const errorDialog = this.dialog.open(CustomPopupComponent, {
                disableClose: true,
                hasBackdrop: true,
                height: '70%',
                panelClass: 'custom-modalbox',
                data: {
                  status: 'error',
                  title: "Une erreur s'est produite",
                  message:
                    'Nous sommes désolé. Veuillez réessayer ultérieurement.',
                },
              });
            }
          }
        },
        (err) => {
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: 'Erreur serveur',
              message: 'Nous sommes désolé. Veuillez réessayer ultérieurement.',
            },
          });
        }
      );
  }

  // Create Professional Account with address and agency fields
  createProfessionalAccountWithAgencyFields() {
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Veuillez patienter ... ',
        isLoader: true,
      },
    });
    console.log(this.agencyModel.Siret.replaceAll(' ', ''));

    this.userService
      .createProfessionalAccountWithAgencyFields(
        this.userModel,
        this.addressModel,
        this.agencyModel
      )
      .subscribe(
        (result: any) => {
          if (result.data && result.data.createAccount) {
            console.log('RESULT', result);
            this.dialog.closeAll();
            this.current_step = 'CREATE_ACCOUNT';
          } else {
            if (result.errors) {
              console.log(result.errors);
              this.dialog.closeAll();
              const errorDialog = this.dialog.open(CustomPopupComponent, {
                disableClose: true,
                hasBackdrop: true,
                height: '70%',
                panelClass: 'custom-modalbox',
                data: {
                  status: 'error',
                  title: "Une erreur s'est produite",
                  message:
                    'Nous sommes désolé. Veuillez réessayer ultérieurement.',
                },
              });
            }
          }
        },
        (err: any) => {
          this.dialog.closeAll();
          this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: 'Erreur serveur',
              message: 'Nous sommes désolé. Veuillez réessayer ultérieurement.',
            },
          });
        }
      );
  }

  // FORGOT PASSWORD FUNCTION ==========>> TO DO
  forgotPassword() {
    console.log('FORGOT PASSWORD');
    // TODO => Implement Forgot password function
  }

  // GET ALL AGENCY FOR SEARCH FUNCTION => INSCRIPTION PRO
  agencysList() {
    this.agencyService.listAgencys().subscribe((res) => {
      if (res.data && res.data.listAgencys) {
        for (
          let index = 0;
          index < res.data.listAgencys.items.length;
          index++
        ) {
          console.log(res.data.listAgencys);

          const agency = new AgencyModel().searchMapping(
            res.data.listAgencys.items[index]
          );
          this.agencyListArray.push(agency);
          console.log('this.agencyListArray', this.agencyListArray);
          this.agencyListArrayFiltered = this.agencyListArray;
          console.log(
            'this.agencyListArrayFiltered',
            this.agencyListArrayFiltered
          );
        }
      }
    });
  }

  // SELECT AGENCY FUNCTION
  onSelectAgency(id: string, name: string) {
    console.log('id', id);
    console.log('name', name);
    this.agencyModel.ID = id;
    this.agencyName = name;
  }

  // SEARCH AGENCY NAME FUNCTION
  onSearchTextChange = ($event: any) => {
    console.log($event);
    this.agencyListArrayFiltered = this.agencyListArray;
    this.agencyListArrayFiltered = this.agencyListArrayFiltered.filter(
      (object: any) => {
        console.log('ici', object);
        const agencyName = object.Agency_name.toLowerCase();
        console.log('agencyName', agencyName);

        return agencyName.includes($event.model.toLowerCase());
      }
    );
  };

  // REMOVE AGENCY SELECTED
  removeAgencyId() {
    this.agencyModel.ID = '';
    this.agencyName = '';
  }

  /* MISSING PASSWORD */
  public async missingPassword() {
    try {
      this.dialog.open(LoaderPopupComponent, {
        disableClose: true,
        hasBackdrop: true,
        height: '40%',
        panelClass: 'custom-modalbox',
        data: {
          text: 'Veuillez patienter ... ',
          isLoader: true,
        },
      });
      // Send confirmation code to user's email
      await Auth.forgotPassword(this.userModel.Email)
        .then((data) => {
          this.dialog.closeAll();
          this.current_step = 'CHANGE_MISSING_PASSWORD';
          console.log(data);
        })
        .catch((err) => {
          if (err.code === 'UserNotFoundException') {
            console.log('error forgotPassword: ', err);
            console.log(err);
            this.dialog.closeAll();
            const errorDialog = this.dialog.open(CustomPopupComponent, {
              disableClose: true,
              hasBackdrop: true,
              height: '70%',
              panelClass: 'custom-modalbox',
              data: {
                status: 'error',
                title: "Une erreur s'est produite",
                message: "Cet utilisateur n'existe pas",
              },
            });
          } else {
            console.log('error forgotPassword: ', err);
            console.log(err);
            this.dialog.closeAll();
            const errorDialog = this.dialog.open(CustomPopupComponent, {
              disableClose: true,
              hasBackdrop: true,
              height: '70%',
              panelClass: 'custom-modalbox',
              data: {
                status: 'error',
                title: "Une erreur s'est produite",
                message:
                  'Nous sommes désolé, veuillez réessayer ultérieurement.',
              },
            });
          }
        });
      return;
    } catch (error) {
      this.dialog.closeAll();
      const errorDialog = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        hasBackdrop: true,
        height: '70%',
        panelClass: 'custom-modalbox',
        data: {
          status: 'error',
          title: "Une erreur s'est produite",
          message: 'Nous sommes désolé, veuillez réessayer ultérieurement.',
        },
      });
    }
  }

  /* CHANGE MISSING PASSWORD */
  public async changeMissingPassword() {
    try {
      this.dialog.open(LoaderPopupComponent, {
        disableClose: true,
        hasBackdrop: true,
        height: '40%',
        panelClass: 'custom-modalbox',
        data: {
          text: 'Veuillez patienter ... ',
          isLoader: true,
        },
      });
      // Collect confirmation code and new password, then
      await Auth.forgotPasswordSubmit(
        this.userModel.Email,
        this.userModel.Otp_code.join(', ').replaceAll(', ', ''),
        this.userModel.Password_confirm
      )
        .then((data) => {
          console.log(data);
          this.dialog.closeAll();
          this.router.navigate(['/account/authentication']);
        })
        .catch((err) => {
          console.log('error forgotPasswordSubmit: ', err);
          console.log(err);
          this.dialog.closeAll();
          const errorDialog = this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: "Une erreur s'est produite",
              message: 'Nous sommes désolé, veuillez réessayer ultérieurement.',
            },
          });
        });
      return;
    } catch (error) {
      this.dialog.closeAll();
      const errorDialog = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        hasBackdrop: true,
        height: '70%',
        panelClass: 'custom-modalbox',
        data: {
          status: 'error',
          title: "Une erreur s'est produite",
          message: 'Nous sommes désolé, veuillez réessayer ultérieurement.',
        },
      });
    }
  }

  /**
   * Get address agency
   */
  public getAddressAgency() {
    this.googleService.getCoordinates("97213")
  }
}
