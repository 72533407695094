<button
  (click)="onClick($event)"
  class="outline-none rounded-full py-2 px-5 items-center flex"
  [ngClass]="[buttonClasses]"
  [attr.disabled]="disabled ? true : null"
  (mouseover)="firstImgSrc = secondImgSrc"
>
  <p *ngIf="textPosition === 'left'" [ngClass]="paragraphClasses">{{ text }}</p>
  <img *ngIf="icon_visibility" [src]="firstImgSrc" class="h-2 mx-3" />
  <p *ngIf="textPosition === 'right'" [ngClass]="paragraphClasses">
    {{ text }}
  </p>
</button>
