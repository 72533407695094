import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ParametersService {
  constructor(public http: HttpClient) {}

  public createParameter(
    professionnel: String,
    appointment_type: String,
    days: String,
    duration: String,
    frequency: String,
    time_slots_am: String,
    time_slots_pm: String
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          createParameter(
            input: {
              appointment_type: ${appointment_type}, 
              days: "${days}", 
              duration: "${duration}", 
              frequency: "${frequency}", 
              user: "${professionnel}", 
              time_slots_am: "${time_slots_am}",
              time_slots_pm: "${time_slots_pm}"
            }) {
            id
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public updateParameter(
    id: String,
    appointment_type: String,
    days: String,
    duration: String,
    frequency: String,
    time_slots_am: String,
    time_slots_pm: String
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          updateParameter(
            input: {
              id: "${id}", 
              appointment_type: ${appointment_type}, 
              frequency: "${frequency}", 
              duration: "${duration}", 
              days: "${days}", 
              time_slots_am: "${time_slots_am}",
              time_slots_pm: "${time_slots_pm}"
            }) {
            id
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public getParametersByProfessionnel(pro_id: String): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        query {
          ParameterByUser(
            user: "${pro_id}") {
            items {
              appointment_type
              createdAt
              days
              duration
              frequency
              id
              user
              time_slots_am
              time_slots_pm
            }
          }
        }
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }
}
