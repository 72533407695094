import { PropertyModel } from 'src/app/shared/models/property-model';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from './../../shared/services/local-storage.service';
import { CustomPopupComponent } from 'src/app/shared/components/custom-popup/custom-popup.component';
import { LoaderPopupComponent } from 'src/app/shared/components/loader-popup/loader-popup.component';
import { UploadPhotosS3Service } from './../../shared/services/upload-photos-s3.service';
import { MatDialog } from '@angular/material/dialog';
import { trigger, transition, style, animate } from '@angular/animations';
import { environment } from 'src/environments/environment';
import { PropertyService } from 'src/app/shared/services/property.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Renderer2, Input } from '@angular/core';
import SwiperCore, { EffectCreative, Pagination, Navigation } from 'swiper';
import { v4 as uuidv4 } from 'uuid';
import { MapsAPILoader } from '@agm/core';
import { Location } from '@angular/common';

// install Swiper modules
SwiperCore.use([EffectCreative, Pagination, Navigation]);

@Component({
  selector: 'app-property',
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0, transform: 'translateY(10px)' })),
      ]),
    ]),
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0, transform: 'translateY(10px)' })),
      ]),
    ]),
  ],
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss'],
})
export class PropertyComponent implements OnInit {
  public service_type: string;
  public property_id: string;
  public title: string;
  public prefix_url: string = environment.cloudfront_url;
  public array_property: any[] = [];
  public photos_array: any[] = [];
  public hasError: boolean = false;
  public current_step: string = 'MAIN';
  public property_step: string = 'CHARACTERISTICS';
  public published_date: string;
  public media: any;
  public file_name: string;
  public user_id: string;
  public edit_mode: boolean = false;
  public candidate_id: string;
  public Lat;
  public Lng;
  public user_type: string;
  public favorites_property_array: any[] = [];
  public IsFavorite: boolean = false;
  public my_property_id: string;
  @Input() projection_level: string;

  constructor(
    private router: Router,
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private uploadPhotosS3Service: UploadPhotosS3Service,
    private localStorageService: LocalStorageService,
    private renderer: Renderer2,
    private mapsAPILoader: MapsAPILoader,
    private http: HttpClient,
    private location: Location
  ) {
    this.my_property_id = this.route.snapshot.paramMap.get('property_id');
    this.user_type = this.localStorageService.getCurrentUser().user_type;
    this.renderer.setStyle(document.body, 'background-color', '#ffff');
    this.service_type = this.route.snapshot.paramMap.get('service_type');
    this.property_id = this.route.snapshot.paramMap.get('id');
    this.user_id = this.localStorageService.getCurrentUser().id;
    this.setHeaderTitle(this.service_type);
  }

  ngOnInit(): void {
    if (!this.my_property_id) {
      this.listFavoritesPropertys();
    }
    this.getOneProperty();
  }

  /**
   * back
   * Return to property list page
   */
  public back() {
    this.location.back();
    // this.router.navigate([`/property-list/${this.service_type}`]);
  }

  /**
   * SET Header title from url (rent or sale)
   */
  public async setHeaderTitle($service_type) {
    switch ($service_type) {
      case 'sale':
        this.title = 'Biens en vente';
        break;
      case 'rent':
        this.title = 'Biens en location';
        break;
      default:
        break;
    }
  }

  /**
   * getUser
   */
  public getUserInterested($event) {
    console.log($event.candidate_id);
    this.candidate_id = $event.candidate_id;
    this.projection_level = $event.projection_level;
    this.current_step = 'CANDIDATE_FILE';
  }

  /**
   * Return property by ID
   */
  public async getOneProperty() {
    if (!this.my_property_id) {
      await this.propertyService
        .getOneProperty(this.property_id)
        .subscribe((res) => {
          if (res && res.data.getOneProperty) {
            res.data.getOneProperty.forEach((element) => {
              const property = new PropertyModel().mapToModel(element);

              // Transform property type
              switch (property.Property_type) {
                case 'MAISON_VILLA':
                  property.Property_type = 'Maison';
                  break;
                case 'APPARTEMENT':
                  property.Property_type = 'Appartement';
                  break;
                case 'BUREAUX':
                  property.Property_type = 'Bureaux';
                  break;
                case 'TERRAIN':
                  property.Property_type = 'Terrain';
                  break;
                default:
                  //
                  break;
              }

              // Transform property state
              switch (property.Property_state) {
                case 'BIEN_ANCIEN_REFAIT_A_NEUF':
                  property.Property_state = 'Bien ancien refait à neuf';
                  break;
                case 'BIEN_ANCIEN_AVEC_TRAVAUX':
                  property.Property_state = 'Bien ancien avec travaux';
                  break;
                case 'BIEN_NEUF':
                  property.Property_state = 'Bien neuf';
                  break;
                case 'TERRAIN_VIABILISE':
                  property.Property_state = 'Terrain viabilisé';
                  break;
                case 'TERRAIN_NON_VIABILISE':
                  property.Property_state = 'Terrain non viabilisé';
                  break;
                case 'NON_RENSEIGNE':
                  property.Property_state = 'Non renseigné';
                  break;
                default:
                  //
                  break;
              }

              console.log(this.photos_array);

              // Get Location coordinates to display marker on map
              const location = property.City + ' ' + property.Postal_code;
              this.getAddressCoordinates(location.toString().toLowerCase());

              // Get photos key for remove function
              for (let i = 0; i < property.Photos.length; i++) {
                var key = property.Photos[i].indexOf('assets-upload');
                property.Photos_key.push(property.Photos[i].substr(key));
              }

              for (let i = 0; i < this.favorites_property_array.length; i++) {
                console.log(
                  this.favorites_property_array[i].property_id,
                  property.ID
                );
                if (
                  this.favorites_property_array[i].property_id === property.ID
                ) {
                  console.log('FAVORITE IS TRUE');
                  property.IsFavorite = true;
                  this.IsFavorite = true;
                }
                // Array Push method
                this.array_property.push(property);
              }
            });
            console.log('array_property', this.array_property);
          } else if (res.errors.message) {
            console.log('ERROR: ', res.errors.message);
            this.hasError = true;
          }
        });
    } else {
      await this.propertyService
        .getOneProperty(this.my_property_id)
        .subscribe((res) => {
          console.log('reeeeesss', res);

          if (res && res.data.getOneProperty) {
            res.data.getOneProperty.forEach((element) => {
              console.log('element', element);
              const property = new PropertyModel().mapToModel(element);

              // Transform property type
              switch (property.Property_type) {
                case 'MAISON_VILLA':
                  property.Property_type = 'Maison';
                  break;
                case 'APPARTEMENT':
                  property.Property_type = 'Appartement';
                  break;
                case 'BUREAUX':
                  property.Property_type = 'Bureaux';
                  break;
                case 'TERRAIN':
                  property.Property_type = 'Terrain';
                  break;
                default:
                  //
                  break;
              }

              // Transform property state
              switch (property.Property_state) {
                case 'BIEN_ANCIEN_REFAIT_A_NEUF':
                  property.Property_state = 'Bien ancien refait à neuf';
                  break;
                case 'BIEN_ANCIEN_AVEC_TRAVAUX':
                  property.Property_state = 'Bien ancien avec travaux';
                  break;
                case 'BIEN_NEUF':
                  property.Property_state = 'Bien neuf';
                  break;
                case 'TERRAIN_VIABILISE':
                  property.Property_state = 'Terrain viabilisé';
                  break;
                case 'TERRAIN_NON_VIABILISE':
                  property.Property_state = 'Terrain non viabilisé';
                  break;
                case 'NON_RENSEIGNE':
                  property.Property_state = 'Non renseigné';
                  break;
                default:
                  //
                  break;
              }

              // Get Location coordinates to display marker on map
              const location = property.City + ' ' + property.Postal_code;
              this.getAddressCoordinates(location.toString().toLowerCase());

              // Get photos key for remove function
              for (let i = 0; i < property.Photos.length; i++) {
                var key = property.Photos[i].indexOf('assets-upload');
                property.Photos_key.push(property.Photos[i].substr(key));
              }

              if (!this.my_property_id) {
                for (let i = 0; i < this.favorites_property_array.length; i++) {
                  console.log(
                    this.favorites_property_array[i].property_id,
                    property.ID
                  );
                  if (
                    this.favorites_property_array[i].property_id === property.ID
                  ) {
                    console.log('FAVORITE IS TRUE');
                    property.IsFavorite = true;
                    this.IsFavorite = true;
                  }
                }
              }
              // Array Push method
              this.array_property.push(property);
            });
            console.log(this.array_property);
          } else if (res.errors.message) {
            console.log('ERROR: ', res.errors.message);
            this.hasError = true;
          }
        });
    }
  }

  /**
   * getAddressCoordinates
   */
  public async getAddressCoordinates($address) {
    this.mapsAPILoader.load().then(() => {
      // const results = this.googleService.getCoordinates($address);
      // console.log('xxxxxx',results);

      this.http
        .get(
          'https://maps.googleapis.com/maps/api/geocode/json?address=' +
            $address +
            '&key=AIzaSyDTbRf4gD1raBonoP80Y9-WepL2uJZu7Vk'
        )
        .subscribe((results: any) => {
          this.Lat = results.results[0].geometry.location.lat;
          this.Lng = results.results[0].geometry.location.lng;

          // var map = new google.maps.Map(
          //   document.getElementById('map') as HTMLElement,
          //   {
          //     zoom: 4,
          //     center: results[0].geometry.location,
          //   }
          // );

          // new google.maps.Marker({
          //   position: results[0].geometry.location,
          //   map,
          //   title: 'Hello World!',
          // });
        });
    });
  }

  /**
   * SWITCH STEP
   */
  public switchStep($step) {
    switch ($step) {
      case 'MAIN':
        this.current_step = 'MAIN';
        break;
      case 'INTERESTED_LEVEL':
        this.current_step = 'INTERESTED_LEVEL';
        break;
      case 'STATS':
        this.current_step = 'STATS';
        break;
      case 'MANDAT':
        this.current_step = 'MANDAT';
        break;
      case 'CANDIDATS':
        this.current_step = 'CANDIDATS';
        break;
      case 'APPOINTMENT':
        this.current_step = 'APPOINTMENT';
        break;
      case 'DESCRIPTION':
        this.property_step = 'DESCRIPTION';
        break;
      case 'CHARACTERISTICS':
        this.property_step = 'CHARACTERISTICS';
        break;
      case 'MAKE_OFFER':
        this.current_step = 'MAKE_OFFER';
        break;
      case 'EDIT_MODE':
        if (this.property_step === 'DESCRIPTION') {
          this.edit_mode = !this.edit_mode;
        } else {
          this.router.navigate([
            `edit-property/${this.service_type}/${this.property_id}`,
          ]);
        }
        break;
      default:
        break;
    }
  }

  /**
   * REMOVE PHOTOS
   */
  public removePropertyPhoto($event: any) {
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '70%',
      panelClass: 'custom-modalbox',
      data: {
        status: 'delete',
        title: 'ATTENTION',
        message: 'Etes-vous sur de vouloir supprimer cette photo ?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dialog.closeAll();
        this.dialog.open(LoaderPopupComponent, {
          disableClose: true,
          hasBackdrop: true,
          height: '40%',
          panelClass: 'custom-modalbox',
          data: {
            text: 'Suppression en cours... ',
            isLoader: true,
          },
        });
        this.uploadPhotosS3Service
          .removePropertyPhoto($event)
          .subscribe((res: any) => {
            console.log(res);
            if (
              res &&
              res.data.removePropertyPhoto.code ===
                'PROPERTY_ASSETS_SUCCESSFULLY_REMOVED'
            ) {
              this.removeAssetsKey();
            } else if (res.errors.message) {
              this.dialog.closeAll();
              this.dialog.open(CustomPopupComponent, {
                disableClose: true,
                hasBackdrop: true,
                height: '70%',
                panelClass: 'custom-modalbox',
                data: {
                  status: 'error',
                  title: "Une erreur s'est produite",
                  message:
                    'Nous sommes désolé, un problème est survenu. Veuillez réessayer ultérieurement.',
                },
              });
            }
          });
      } else {
        this.dialog.closeAll();
      }
    });
  }

  /**
   * Upload file function
   */
  public uploadPhotos($event: any) {
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Téléchargement en cours ... ',
        isLoader: true,
      },
    });
    var file = $event.target.files[0]; // FileList object

    var end_file_name = file.name.split('.')[1]; // End of String file_name

    var extension = file.type; // Extension to upload in S3 Bucket

    this.file_name = 'assets-upload/photo-' + uuidv4() + '.' + end_file_name; // Set a random file_name

    // If size of file bigger than 3 mega bytes throw Error
    if (file.size > 3145728) {
      this.dialog.closeAll();
      const dialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        hasBackdrop: true,
        height: '70%',
        panelClass: 'custom-modalbox',
        data: {
          status: 'error',
          title: "Une erreur s'est produite",
          message:
            'La taille du fichier ne doit pas excéder 3 méga octets (3 Mo)',
        },
      });
      this.file_name = '';
    } else if (this.photos_array.length > 10) {
      this.dialog.closeAll();
      const dialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        hasBackdrop: true,
        height: '70%',
        panelClass: 'custom-modalbox',
        data: {
          status: 'error',
          title: "Une erreur s'est produite",
          message: 'Vous ne pouvez ajouter que 10 photos maximum.',
        },
      });
      this.file_name = '';
    } else {
      this.getBase64(file).then((data) => {
        this.media = data;
        console.log('xxxxxx', this.media);

        this.uploadPhotosS3Service
          .uploadPhoto(this.media, extension, this.file_name)
          .subscribe((res: any) => {
            console.log(res);
            if (
              res &&
              res.data.addPropertyPhoto.code ===
                'PROPERTY_ASSETS_SUCCESSFULLY_UPLOADED'
            ) {
              this.editAssetsArray();
            }
          });
      });
    }
  }

  /**
   * Function to transform file into base64
   */
  public getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  /**
   * editAssetsArray
   */
  public editAssetsArray() {
    this.propertyService
      .editPropertyPhoto(this.property_id, this.file_name)
      .subscribe((res) => {
        if (res.data && res.data.editPropertyPhoto.code) {
          this.dialog.closeAll();
          window.location.reload();
        } else if (res.errors.message) {
          console.log(res.errors.message);

          this.dialog.closeAll();
          const dialogRef = this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: 'ERREUR',
              message:
                "Nous sommes désolé, une erreur s'est produite lors du téléchargement de votre photo. Veuillez réessayer ultérieurement.",
            },
          });
          this.file_name = '';
        }
      });
  }

  /**
   * removeAssetsKey
   */
  public removeAssetsKey() {
    this.propertyService
      .removeProperty(this.property_id, this.user_id)
      .subscribe((res) => {
        if (res.data && res.data.editPropertyPhoto.code) {
          this.dialog.closeAll();
          window.location.reload();
        } else if (res.errors.message) {
          this.dialog.closeAll();
          const dialogRef = this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: 'ERREUR',
              message:
                "Nous sommes désolé, une erreur s'est produite lors du téléchargement de votre photo. Veuillez réessayer ultérieurement.",
            },
          });
          this.file_name = '';
        }
      });
  }

  /**
   * removeProperty
   */
  public removeProperty() {
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '70%',
      panelClass: 'custom-modalbox',
      data: {
        status: 'delete',
        title: 'ATTENTION',
        message: 'Etes-vous sur de vouloir supprimer ce bien ?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dialog.closeAll();
        this.dialog.open(LoaderPopupComponent, {
          disableClose: true,
          hasBackdrop: true,
          height: '40%',
          panelClass: 'custom-modalbox',
          data: {
            text: 'Suppression en cours... ',
            isLoader: true,
          },
        });
        this.propertyService
          .removeProperty(this.property_id, this.user_id)
          .subscribe((res) => {
            if (res.data && res.data.editPropertyPhoto.code) {
              this.dialog.closeAll();
              this.back();
            } else if (res.errors.message) {
              this.dialog.closeAll();
              const dialogRef = this.dialog.open(CustomPopupComponent, {
                disableClose: true,
                hasBackdrop: true,
                height: '70%',
                panelClass: 'custom-modalbox',
                data: {
                  status: 'error',
                  title: 'ERREUR',
                  message:
                    "Nous sommes désolé, une erreur s'est produite lors du téléchargement de votre photo. Veuillez réessayer ultérieurement.",
                },
              });
              this.file_name = '';
            }
          });
      } else {
        this.dialog.closeAll();
      }
    });
  }

  /**
   * updatePropertyDescription function
   */
  public updatePropertyDescription($description) {
    // Replace description function for keep the line break when we display it in front
    $description = $description.replaceAll('\n', '</br>');
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Modification en cours ... ',
        isLoader: true,
      },
    });
    this.propertyService
      .updatePropertyDescription(this.property_id, $description)
      .subscribe((res) => {
        if (res.data && res.data.updatePropertyDescription.code) {
          this.dialog.closeAll();
          window.location.reload();
          this.property_step = 'DESCRIPTION';
        } else if (res.errors.message) {
          this.dialog.closeAll();
          const dialogRef = this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: 'ERREUR',
              message:
                "Nous sommes désolé, une erreur s'est produite lors de la modification. Veuillez réessayer ultérieurement.",
            },
          });
        }
      });
  }

  /**
   * listFavoritesPropertys
   */
  public listFavoritesPropertys() {
    this.propertyService
      .getAllUserFavoritesProperty(this.user_id)
      .subscribe((res) => {
        console.log('res', res);
        if (res && res.data.listFavoritesPropertys.items) {
          this.favorites_property_array = res.data.listFavoritesPropertys.items;
        }
      });
  }

  /**
   * publishedOrUnpublishedProperty
   */
  public publishedOrUnpublishedProperty($state) {
    var stateToSet;
    console.log($state, 'state');
    
    if ($state !== 'PUBLIEE' ) {
      stateToSet = 'PUBLIEE';
    } else {
      stateToSet = 'DEPUBLIEE';
    }
    this.dialog.open(LoaderPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      height: '40%',
      panelClass: 'custom-modalbox',
      data: {
        text: 'Modification en cours ... ',
        isLoader: true,
      },
    });
    this.propertyService
      .editStateProperty(this.my_property_id, stateToSet)
      .subscribe((res) => {
        if (
          res.data &&
          res.data.editStateProperty.code ==
            'PROPERTY_STATE_SUCCESSFULLY_UPDATED'
        ) {
          this.dialog.closeAll();
          window.location.reload();
        } else if (res.errors.message) {
          this.dialog.closeAll();
          const dialogRef = this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: 'ERREUR',
              message:
                "Nous sommes désolé, une erreur s'est produite lors de la modification. Veuillez réessayer ultérieurement.",
            },
          });
        } else {
          this.dialog.closeAll();
          const dialogRef = this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            height: '70%',
            panelClass: 'custom-modalbox',
            data: {
              status: 'error',
              title: 'ERREUR',
              message:
                "Nous sommes désolé, une erreur s'est produite lors de la modification. Veuillez réessayer ultérieurement.",
            },
          });
        }
      });
  }
}
