export const environment = {
  production: true,
  userPoolId: 'eu-west-3_otgaIICGm',
  userPoolWebClientId: '6l1tkl9h1j7s6a20n0fdl8njri',
  url: 'https://dev.noprod.sasinfiny.com/',
  cloudfront_url: 'https://d2syntlnu2rlg9.cloudfront.net/',
  apiKey: 'da2-lb4mqv25ovcvxjdktsr3mbejna',
  baseUrl:
    'https://k7zlbj44kjhddi2ta6ppdbwiqy.appsync-api.eu-west-3.amazonaws.com/graphql',
  AWS_COGNITO_IDENTITY_POOL_ID:
    'eu-west-3:06a2bb57-91ec-4f82-b18e-cb9f76ddc687',
  AWS_COGNITO_REGION: 'eu-west-3',
  google_api_access_token: 'AIzaSyDTbRf4gD1raBonoP80Y9-WepL2uJZu7Vk',
};
