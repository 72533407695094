<div [@fade] class="w-full mx-auto bg-white overflow-auto pb-6">
  <div *ngIf="isOnAppointmentPage" class="border-b border-solid pb-2">
    <div class="mx-6">
      <app-secondary-header
        (IsClicked)="back()"
        headerClasses=" pt-4 flex justify-between items-center mb-2"
      ></app-secondary-header>
      <div class="mx-auto text-center">
        <p class="font-bold text-xl txt-bold-gray">Mes rendez-vous</p>
      </div>
    </div>
  </div>
  <div class="mx-6">
    <!-- My Appointments sub header -->
    <div *ngIf="!see_all_appointment">
      <app-text
        textClasses="txt-bold-gray font-bold mt-6 mb-3 text-xxs uppercase"
        [text]="today"
      ></app-text>
      <div class="flex justify-between items-center mb-6">
        <app-text
          textClasses="txt-bold-gray font-bold"
          text="Vos rendez-vous du jour"
        ></app-text>
        <app-text
          textClasses="txt-light-blue font-bold text-xs cursor-pointer"
          text="Voir tout"
          (IsClicked)="seeAllAppointment()"
        ></app-text>
      </div>
    </div>
    <div *ngIf="see_all_appointment">
      <app-text
        textClasses="txt-bold-gray text-center font-bold my-6"
        text="Tous mes rendez-vous"
      ></app-text>
      <div class="flex gap-3 items-center">
        <img src="assets/icons/svg/logo-1.svg" class="h-4" />
        <app-select-date
          form_control_name_month="month"
          form_control_name_year="year"
          selectClasses="items-center txt-bold-gray font-bold appearance-none bg-transparent"
          yearClasses="items-center text-xs txt-percian-gray bg-transparent"
          (Changed)="selectMonthAndYear($event)"
          value="DATE_SORT"
        ></app-select-date>
      </div>
      <div class="my-6 flex flex-nowrap gap-3 overflow-x-scroll no-scrollbar">
        <app-day-calendar
          *ngFor="let day of days"
          (IsClicked)="selectDate(day)"
          [day_name]="day.name"
          [day_number]="day.number"
          [date]="day.date"
          [daysClasses]="
            selectedDate !== day.date
              ? 'bg-white txt-light-blue border-light-blue'
              : 'bg-light-blue txt-white border-light-blue'
          "
        ></app-day-calendar>
      </div>
    </div>
    <!-- Appointments list -->
    <div class="mx-6 mt-6 mb-8 flex flex-col gap-4">
      <div
        *ngIf="!appointments_list_filtered.length && !hasError"
        class="mx-auto"
        id="waiting_data"
      >
        <mat-spinner diameter="50"></mat-spinner>
      </div>
      <!-- No Result text -->
      <p
        *ngIf="!appointments_list_filtered.length && hasError"
        class="text-center txt-light-blue mt-8"
      >
        Aucun résultats
      </p>
    </div>
    <div *ngIf="appointments_list_filtered.length" class="mx-auto">
      <app-appointment-card
        *ngFor="let appointment of appointments_list_filtered"
        [appointment_status]="appointment.Appointment_status"
        [appointment_hour]="appointment.Appointment_hour"
        [appointment_type]="appointment.Appointment_type"
        [appointment_reason]="appointment.Appointment_reason"
        [appointment_user]="username"
        [appointment_guest]="appointment.Appointment_guest"
        (IsClicked)="getUserAppointment(appointment.ID)"
      >
      </app-appointment-card>
    </div>
  </div>
</div>
