<div class="w-10/12 mx-auto my-auto bg-white rounded-3xl p-4 shadow-2xl popupPlace">
  <div class="w-full">
    <img
      draggable="false"
      src="assets/icons/svg/logo-1.svg"
      class="h-20 mx-auto mb-10"
    />
  </div>
  <div class="flex flex-col justify-center">
    <div *ngIf="isLoader">
      <mat-progress-spinner
        class="mx-auto mb-10"
        diameter="50"
        strokeWidth="5"
        mode="determinate"
        mode="indeterminate"
      ></mat-progress-spinner>
    </div>
    <div>
      <p
        class="txt-bold-gray text-xs w-11/12 mx-auto"
        [ngClass]="isLoader ? 'text-center' : ''"
      >
        {{ text }}
      </p>
    </div>
  </div>
</div>
