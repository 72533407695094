import { MandateModel } from './shared/models/mandate-model';
import { OfferModel } from './shared/models/offer-model';
import { ParametersService } from './shared/services/parameters.service';
import { AppointmentModel } from './shared/models/appointment-model';
import { PropertyService } from './shared/services/property.service';
import { UploadPhotosS3Service } from './shared/services/upload-photos-s3.service';
import { CognitoService } from 'src/app/shared/services/cognito.service';
import { AgencyModel } from './shared/models/agency-model';
import { AddressModel } from './shared/models/address-model';
import { UserModel } from 'src/app/shared/models/user-model';
import { HttpClientModule } from '@angular/common/http';
import { LocalStorageService } from './shared/services/local-storage.service';
import { DataService } from './shared/services/data.service';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/modules/material/material.module';
import { CookieBannerComponent } from './shared/components/cookie-banner/cookie-banner.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { TitleComponent } from './shared/components/title/title.component';
import { SubtitleComponent } from './shared/components/subtitle/subtitle.component';
import { TextComponent } from './shared/components/text/text.component';
import { PrimaryButtonComponent } from './shared/components/buttons/primary-button/primary-button.component';
import { PrimaryCardComponent } from './shared/components/cards/primary-card/primary-card.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { MenuComponent } from './shared/components/menu/menu.component';
import { CookieService } from './shared/services/cookie.service';
import { ImgComponent } from './shared/components/img/img.component';
import { AccountProfileComponent } from './pages/account-profile/account-profile.component';
import { AccountAuthenticationComponent } from './pages/account-authentication/account-authentication.component';
import { BackButtonComponent } from './shared/components/buttons/back-button/back-button.component';
import { NextButtonComponent } from './shared/components/buttons/next-button/next-button.component';
import { InputComponent } from './shared/components/input/input.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { TextCguComponent } from './shared/components/text-cgu/text-cgu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputOtpComponent } from './shared/components/input-otp/input-otp.component';
import { UserService } from './shared/services/user.service';
import { CustomPopupComponent } from './shared/components/custom-popup/custom-popup.component';
import { LoaderPopupComponent } from './shared/components/loader-popup/loader-popup.component';
import { InputSearchComponent } from './shared/components/input-search/input-search.component';
import { AgencyService } from './shared/services/agency.service';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { SecondaryCardComponent } from './shared/components/cards/secondary-card/secondary-card.component';
import { PersonalInformationsComponent } from './pages/personal-informations/personal-informations.component';
import { SecondaryHeaderComponent } from './shared/components/secondary-header/secondary-header.component';
import { TertiaryCardComponent } from './shared/components/cards/tertiary-card/tertiary-card.component';
import { SelectComponent } from './shared/components/select/select.component';
import { SelectDateComponent } from './shared/components/select-date/select-date.component';
import { PutAPostComponent } from './pages/put-a-post/put-a-post.component';
import { TextInnerHTMLComponent } from './shared/components/text-inner-html/text-inner-html.component';
import { FormCardComponent } from './shared/components/cards/form-card/form-card.component';
import { CheckboxComponent } from './shared/components/checkbox/checkbox.component';
import { ItemsComponent } from './shared/components/items/input-items.component';
import { InputFileComponent } from './shared/components/input-file/input-file.component';
import { TextareaComponent } from './shared/components/textarea/textarea.component';
import { InputTxtComponent } from './shared/components/input-txt/input-txt.component';
import { SwiperModule } from 'swiper/angular';
import { FilterComponent } from './pages/filter/filter.component';
import { FilterButtonComponent } from './shared/components/buttons/filter-button/filter-button.component';
import { PropertyListComponent } from './pages/property-list/property-list.component';
import { PropertyCardComponent } from './shared/components/cards/property-card/property-card.component';
import { PropertyComponent } from './pages/property/property.component';
import { MyPropertyComponent } from './pages/my-property/my-property.component';
import { AgmCoreModule } from '@agm/core';
import { AppointmentComponent } from './pages/appointment/appointment.component';
import { CreateAppointmentComponent } from './pages/create-appointment/create-appointment.component';
import { MyAppointmentsComponent } from './pages/my-appointments/my-appointments.component';
import { AppointmentTypeComponent } from './pages/appointment-type/appointment-type.component';
import { AppointmentReasonComponent } from './pages/appointment-reason/appointment-reason.component';
import { AppointmentDateComponent } from './pages/appointment-date/appointment-date.component';
import { DayCalendarComponent } from './shared/components/day-calendar/day-calendar.component';
import { AppointmentGuestComponent } from './pages/appointment-guest/appointment-guest.component';
import { AppointmentConfirmationComponent } from './pages/appointment-confirmation/appointment-confirmation.component';
import { AppointmentCardComponent } from './shared/components/cards/appointment-card/appointment-card.component';
import { SingleAppointmentComponent } from './pages/single-appointment/single-appointment.component';
import { PropertyCandidatesListComponent } from './pages/property-candidates-list/property-candidates-list.component';
import { UserInterestedComponent } from './pages/user-interested/user-interested.component';
import { PropertyMandateComponent } from './pages/property-mandate/property-mandate.component';
import { PropertyStatsComponent } from './pages/property-stats/property-stats.component';
import { SmallAppointmentCardComponent } from './shared/components/cards/small-appointment-card/small-appointment-card.component';
import { AppointmentManagementComponent } from './pages/appointment-management/appointment-management.component';
import { SearchPropertyComponent } from './pages/search-property/search-property.component';
import { SearchWelcomeComponent } from './pages/search-welcome/search-welcome.component';
import { LikePageComponent } from './pages/like-page/like-page.component';
import { FavoritesPropertyListComponent } from './pages/favorites-property-list/favorites-property-list.component';
import { CandidateFileComponent } from './shared/components/candidate-file/candidate-file.component';
import { FilePageComponent } from './pages/file-page/file-page.component';
import { MakeOfferComponent } from './pages/make-offer/make-offer.component';

@NgModule({
  declarations: [
    AppComponent,
    CookieBannerComponent,
    HeaderComponent,
    FooterComponent,
    TitleComponent,
    SubtitleComponent,
    TextComponent,
    PrimaryButtonComponent,
    PrimaryCardComponent,
    HomePageComponent,
    MenuComponent,
    ImgComponent,
    AccountProfileComponent,
    AccountAuthenticationComponent,
    BackButtonComponent,
    NextButtonComponent,
    InputComponent,
    TextCguComponent,
    InputOtpComponent,
    CustomPopupComponent,
    LoaderPopupComponent,
    InputSearchComponent,
    MyAccountComponent,
    SecondaryCardComponent,
    PersonalInformationsComponent,
    SecondaryHeaderComponent,
    TertiaryCardComponent,
    SelectComponent,
    SelectDateComponent,
    PutAPostComponent,
    TextInnerHTMLComponent,
    FormCardComponent,
    CheckboxComponent,
    ItemsComponent,
    InputFileComponent,
    TextareaComponent,
    InputTxtComponent,
    FilterComponent,
    FilterButtonComponent,
    PropertyListComponent,
    PropertyCardComponent,
    PropertyComponent,
    MyPropertyComponent,
    AppointmentComponent,
    CreateAppointmentComponent,
    MyAppointmentsComponent,
    AppointmentTypeComponent,
    AppointmentReasonComponent,
    AppointmentDateComponent,
    DayCalendarComponent,
    AppointmentGuestComponent,
    AppointmentConfirmationComponent,
    AppointmentCardComponent,
    SingleAppointmentComponent,
    PropertyCandidatesListComponent,
    UserInterestedComponent,
    PropertyMandateComponent,
    PropertyStatsComponent,
    SmallAppointmentCardComponent,
    AppointmentManagementComponent,
    SearchPropertyComponent,
    SearchWelcomeComponent,
    LikePageComponent,
    FavoritesPropertyListComponent,
    CandidateFileComponent,
    FilePageComponent,
    MakeOfferComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    SwiperModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDTbRf4gD1raBonoP80Y9-WepL2uJZu7Vk',
    }),
  ],
  providers: [
    DataService,
    LocalStorageService,
    CookieService,
    UserModel,
    UserService,
    LocalStorageService,
    CookieService,
    DataService,
    AddressModel,
    AgencyModel,
    AgencyService,
    CognitoService,
    UploadPhotosS3Service,
    PropertyService,
    AppointmentModel,
    ParametersService,
    OfferModel,
    MandateModel,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'auto' },
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
