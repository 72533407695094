import { ParametersService } from './../../shared/services/parameters.service';
import { CustomPopupComponent } from 'src/app/shared/components/custom-popup/custom-popup.component';
import { LocalStorageService } from './../../shared/services/local-storage.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Renderer2, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-appointment-management',
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0, transform: 'translateY(10px)' })),
      ]),
    ]),
  ],
  templateUrl: './appointment-management.component.html',
  styleUrls: ['./appointment-management.component.scss'],
})
export class AppointmentManagementComponent implements OnInit {
  public selectedType: string = 'APPEL_TELEPHONIQUE';

  public userId: string;
  public days: string[] = [];

  public parametersData = new FormGroup({
    frequency: new FormControl(''),
    days: new FormControl(''),
    duration: new FormControl(''),
  });
  public parametersData2 = new FormGroup({
    start_time_am: new FormControl(''),
    end_time_am: new FormControl(''),
    start_time_pm: new FormControl(''),
    end_time_pm: new FormControl(''),
  });

  public titles = {
    APPEL_TELEPHONIQUE: 'appel téléphonique',
    VISITE_DU_BIEN_PAR_UN_EXPERT: 'visite du bien par un expert',
    VISITE_DU_BIEN_AVEC_UN_ACHETEUR: 'visite du bien avec un acheteur',
    RENDEZ_VOUS_EN_AGENCE: 'rendez-vous en agence',
  };

  public savedData = {
    APPEL_TELEPHONIQUE: {},
    VISITE_DU_BIEN_PAR_UN_EXPERT: {},
    VISITE_DU_BIEN_AVEC_UN_ACHETEUR: {},
    RENDEZ_VOUS_EN_AGENCE: {},
  };
  public data_is_valid: boolean;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private parametersService: ParametersService,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef
  ) {
    this.renderer.setStyle(document.body, 'background-color', '#b9dbf1');
  }

  ngOnInit(): void {}

  /**
   * BACK FUNCTION
   */
  public back() {
    this.router.navigate(['/appointment']);
  }

  ngAfterViewInit(): void {
    this.userId = this.localStorageService.getCurrentUser().id;
    this.parametersService
      .getParametersByProfessionnel(this.userId)
      .subscribe((res) => {
        if (res.data && res.data.ParameterByUser) {
          const params = res.data.ParameterByUser.items;
          Array.from(params).forEach((param: any) => {
            if (param.appointment_type === 'APPEL_TELEPHONIQUE') {
              this.savedData.APPEL_TELEPHONIQUE = param;
              this.fillFormValues('APPEL_TELEPHONIQUE');
            } else if (
              param.appointment_type === 'VISITE_DU_BIEN_PAR_UN_EXPERT'
            ) {
              this.savedData.VISITE_DU_BIEN_PAR_UN_EXPERT = param;
            } else if (
              param.appointment_type === 'VISITE_DU_BIEN_AVEC_UN_ACHETEUR'
            ) {
              this.savedData.VISITE_DU_BIEN_AVEC_UN_ACHETEUR = param;
            } else if (param.appointment_type === 'RENDEZ_VOUS_EN_AGENCE') {
              this.savedData.RENDEZ_VOUS_EN_AGENCE = param;
            }
          });
        }
      });
  }

  onBack($event) {
    this.router.navigate(['appointment']);
  }

  fillFormValues = (type: string) => {
    const data = this.savedData[`${type}`];
    const hour = this.savedData[`${type}`];
    this.days = data.days.split('-');
    const timeSlotsAm = hour.time_slots_am.split('-');
    const timeSlotsPm = hour.time_slots_pm.split('-');
    this.parametersData = new FormGroup({
      frequency: new FormControl(data.frequency),
      days: new FormControl(data.days),
      duration: new FormControl(data.duration),
    });
    this.parametersData2 = new FormGroup({
      start_time_am: new FormControl(`${timeSlotsAm[0]}`),
      end_time_am: new FormControl(`${timeSlotsAm[1]}`),
      start_time_pm: new FormControl(`${timeSlotsPm[0]}`),
      end_time_pm: new FormControl(`${timeSlotsPm[1]}`),
    });
    console.log(this.parametersData.value);
    console.log(this.parametersData2.value);
  };

  changeParameterType = (type: string) => {
    if (this.selectedType !== type) {
      this.selectedType = type;
      if (Object.keys(this.savedData[`${type}`]).length > 0) {
        this.fillFormValues(type);
      } else {
        this.days = [];
        this.parametersData.reset();
        this.parametersData2.reset();
        this.data_is_valid = false;
      }
    }
  };

  onFrequencyChange = (frequency: string) => {
    if (this.parametersData.value.frequency === frequency) {
      this.parametersData.value.frequency = '';
    } else {
      this.parametersData.value.frequency = frequency;
    }
  };

  onTimeChange = (input: string) => {
    if (this.parametersData.value.duration === input) {
      this.parametersData.value.duration = '';
    } else {
      this.parametersData.value.duration = input;
    }
  };

  onDaysChange = (input: string) => {
    let days = this.days;
    if (days.includes(input)) {
      const index = days.indexOf(input);
      if (index > -1) days.splice(index, 1);
    } else {
      days.push(input);
    }
    this.parametersData.value.days = days.join('-');
  };

  isDaySelected = (day: string) => {
    return this.days.includes(day);
  };

  isFormDataValid = () => {
    if (
      this.parametersData.value.frequency &&
      this.parametersData.value.days &&
      this.parametersData.value.duration &&
      this.parametersData2.value.start_time_am &&
      this.parametersData2.value.end_time_am &&
      this.parametersData2.value.start_time_pm &&
      this.parametersData2.value.end_time_pm
    ) {
      this.data_is_valid = true;
    } else {
      this.data_is_valid = false;
    }
  };

  validateParameters = () => {
    const data = this.parametersData.value;
    const hour = this.parametersData2.value;
    const type = this.selectedType;
    this.isFormDataValid();
    if (
      Object.keys(this.savedData[`${type}`]).length > 0 &&
      this.data_is_valid
    ) {
      const saved = this.savedData[`${type}`];
      const time_slots_am = hour.start_time_am + '-' + hour.end_time_am;
      const time_slots_pm = hour.start_time_pm + '-' + hour.end_time_pm;
      this.parametersService
        .updateParameter(
          saved.id,
          type,
          data.days,
          data.duration,
          data.frequency,
          time_slots_am,
          time_slots_pm
        )
        .subscribe((res) => {
          if (res.data && res.data.updateParameter) {
            const dialogRef = this.dialog.open(CustomPopupComponent, {
              disableClose: true,
              hasBackdrop: true,
              height: '70%',
              panelClass: 'custom-modalbox',
              data: {
                id: null,
                status: 'success',
                message: `Le paramétrage de vos rendez-vous « ${
                  this.titles[this.selectedType]
                } » a bien été pris en compte`,
              },
            });
            dialogRef.afterClosed().subscribe(() => {
              this.router.navigate(['appointment']);
            });
          } else {
            this.dialog.open(CustomPopupComponent, {
              disableClose: true,
              hasBackdrop: true,
              height: '70%',
              panelClass: 'custom-modalbox',
              data: {
                id: null,
                status: 'error',
                message:
                  'Une erreur est survenue. Veuillez réessayer ultérieurement.',
              },
            });
          }
        });
    } else {
      const time_slots_am = hour.start_time_am + '-' + hour.end_time_am;
      const time_slots_pm = hour.start_time_pm + '-' + hour.end_time_pm;
      this.parametersService
        .createParameter(
          this.userId,
          type,
          data.days,
          data.duration,
          data.frequency,
          time_slots_am,
          time_slots_pm
        )
        .subscribe((res) => {
          console.log(res);
          if (res.data && res.data.createParameter) {
            const dialogRef = this.dialog.open(CustomPopupComponent, {
              disableClose: true,
              hasBackdrop: true,
              height: '70%',
              panelClass: 'custom-modalbox',
              data: {
                id: null,
                status: 'success',
                message: `Le paramétrage de vos rendez-vous « ${
                  this.titles[this.selectedType]
                } » a bien été pris en compte`,
              },
            });
            dialogRef.afterClosed().subscribe(() => {
              this.router.navigate(['appointment']);
            });
          } else {
            this.dialog.open(CustomPopupComponent, {
              disableClose: true,
              hasBackdrop: true,
              height: '70%',
              panelClass: 'custom-modalbox',
              data: {
                id: null,
                status: 'error',
                message:
                  'Une erreur est survenue. Veuillez réessayer ultérieurement.',
              },
            });
          }
        });
    }
  };
}
