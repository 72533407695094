import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-cgu',
  templateUrl: './text-cgu.component.html',
  styleUrls: ['./text-cgu.component.scss'],
})
export class TextCguComponent implements OnInit {
  @Input() cguClasses = '';
  constructor() {}

  ngOnInit(): void {}
}
