import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent implements OnInit {
  @Input() text = 'Text';
  @Input() textClasses = 'text-white';
  @Output() IsClicked = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  onClick(): void {
    this.IsClicked.emit();
  }
}
