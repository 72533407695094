import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-appointment-card',
  templateUrl: './appointment-card.component.html',
  styleUrls: ['./appointment-card.component.scss'],
})
export class AppointmentCardComponent implements OnInit {
  @Input() appointment_date: any = 'Statut';
  @Input() appointment_status: any = 'En attente';
  @Input() appointment_hour: any = '00:00';
  @Input() appointment_type: any = 'Visite du bien avec acheteur';
  @Input() appointment_reason: any = 'Motif du rendez-vous';
  @Input() appointment_user: any = 'Nom agent / responsable';
  @Input() appointment_guest: any = 'Nom acquéreur potentiel';
  @Output() IsClicked = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  /**
   * onClick
   */
  public onClick($event) {
    this.IsClicked.emit($event);
  }
}
