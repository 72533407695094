import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UploadPhotosS3Service {
  constructor(public http: HttpClient) {}

  public uploadPhoto(
    photo: String,
    extension: String,
    filename: String
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          addPropertyPhoto(
            photo: "${photo}", 
            extension: "${extension}", 
            filename: "${filename}"
            ) {
            code
          }
        }      
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }

  public removePropertyPhoto(file_key: String): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey,
    });
    const body = {
      query: `
        mutation {
          removePropertyPhoto(file_key: "${file_key}") {
            code
          }
        }      
      `,
    };
    return this.http.post(environment.baseUrl, body, { headers: header });
  }
}
