<div>
  <!-- ACHAT OU LOCATION BUTTONS -->
  <div class="flex gap-3 mb-6">
    <app-filter-button
      (IsClicked)="switchStep('ACHAT')"
      text="Achat"
      [textClasses]="
        current_step !== 'ACHAT'
          ? 'txt-bold-gray text-xs text-center px-3 py-2'
          : 'text-white text-xs text-center px-3 py-2'
      "
      [buttonClasses]="
        current_step !== 'ACHAT'
          ? 'rounded-3xl bg-ligth-gray w-max'
          : 'rounded-3xl w-max bg-bold-blue'
      "
    ></app-filter-button>
    <app-filter-button
      (IsClicked)="switchStep('LOCATION')"
      text="Location"
      [textClasses]="
        current_step !== 'LOCATION'
          ? 'txt-bold-gray text-xs text-center px-3 py-2'
          : 'text-white text-xs text-center px-3 py-2'
      "
      [buttonClasses]="
        current_step !== 'LOCATION'
          ? 'rounded-3xl bg-ligth-gray w-max'
          : 'rounded-3xl w-max bg-bold-blue'
      "
    ></app-filter-button>
  </div>
</div>

<!-- JUSTIFICATIF IDENTITE -->
<app-text
  *ngIf="current_step == 'LOCATION'"
  textClasses="txt-light-blue mb-3"
  text="Candidat"
></app-text>
<app-text
  textClasses="txt-percian-gray text-sm uppercase mb-3"
  text="identité"
></app-text>
<app-candidate-file
  text="Justificatif d'identité"
  [imgSrc]="
    proof_of_identity
      ? 'assets/icons/svg/remove-cross.svg'
      : 'assets/icons/svg/more-button.svg'
  "
  ImgClasses="w-6"
  (IsClicked)="getFile($event)"
  field_name="proof_of_identity"
  (Clicked)="addFile($event)"
  [file_key]="proof_of_identity"
></app-candidate-file>
<!-- JUSTIFICATIF DE SITUATION MATRIMONIALE -->
<div *ngIf="current_step !== 'LOCATION'">
  <app-text
    textClasses="txt-percian-gray text-sm uppercase mb-3"
    text="JUSTIFICATIF DE SITUATION MATRIMONIALE"
  ></app-text>
  <app-candidate-file
    text="Livret de famille"
    [imgSrc]="
      family_record_book
        ? 'assets/icons/svg/remove-cross.svg'
        : 'assets/icons/svg/more-button.svg'
    "
    ImgClasses="w-6"
    field_name="family_record_book"
    (IsClicked)="getFile($event)"
    (Clicked)="addFile($event)"
    [file_key]="family_record_book"
  ></app-candidate-file>
  <app-candidate-file
    text="Attestation mariage, pacs"
    [imgSrc]="
      marriage_or_pacs_certificate
        ? 'assets/icons/svg/remove-cross.svg'
        : 'assets/icons/svg/more-button.svg'
    "
    ImgClasses="w-6"
    field_name="marriage_or_pacs_certificate"
    (IsClicked)="getFile($event)"
    (Clicked)="addFile($event)"
    [file_key]="marriage_or_pacs_certificate"
  ></app-candidate-file>
</div>
<!-- JUSTIFICATIF DE DOMICILE -->
<app-text
  textClasses="txt-percian-gray text-sm uppercase mb-3"
  [text]="
    current_step !== 'LOCATION'
      ? 'JUSTIFICATIF DE DOMICILE'
      : 'JUSTIFICATIF DES RESSOURCES'
  "
></app-text>
<app-candidate-file
  text="Avis d’imposition"
  [imgSrc]="
    tax_notice
      ? 'assets/icons/svg/remove-cross.svg'
      : 'assets/icons/svg/more-button.svg'
  "
  ImgClasses="w-6"
  field_name="tax_notice"
  (IsClicked)="getFile($event)"
  (Clicked)="addFile($event)"
  [file_key]="tax_notice"
></app-candidate-file>
<app-candidate-file
  *ngIf="current_step !== 'LOCATION'"
  text="Facture EDF"
  [imgSrc]="
    electricity_bill
      ? 'assets/icons/svg/remove-cross.svg'
      : 'assets/icons/svg/more-button.svg'
  "
  ImgClasses="w-6"
  field_name="electricity_bill"
  (IsClicked)="getFile($event)"
  (Clicked)="addFile($event)"
  [file_key]="electricity_bill"
></app-candidate-file>
<div *ngIf="current_step === 'LOCATION'">
  <app-text
    textClasses="txt-percian-gray text-sm uppercase mb-3"
    text="JUSTIFICATIF D’ACTIVITÉ PROFESSIONNELLE"
  ></app-text>
  <app-candidate-file
    text="Contrat de travail"
    [imgSrc]="
      employment_contract
        ? 'assets/icons/svg/remove-cross.svg'
        : 'assets/icons/svg/more-button.svg'
    "
    field_name="employment_contract"
    ImgClasses="w-6"
    (IsClicked)="getFile($event)"
    (Clicked)="addFile($event)"
    [file_key]="employment_contract"
  ></app-candidate-file>
  <app-text
    textClasses="txt-percian-gray text-sm uppercase"
    text="JUSTIFICATIF DE DOMICILE"
  ></app-text>
  <app-text
    textClasses="txt-percian-gray text-xs mb-3"
    text="3 dernières quittances logement précédents"
  ></app-text>
  <app-candidate-file
    text="Première quittance"
    [imgSrc]="
      rent_receipt_1
        ? 'assets/icons/svg/remove-cross.svg'
        : 'assets/icons/svg/more-button.svg'
    "
    ImgClasses="w-6"
    field_name="rent_receipt_1"
    (IsClicked)="getFile($event)"
    (Clicked)="addFile($event)"
    [file_key]="rent_receipt_1"
  ></app-candidate-file>
  <app-candidate-file
    text="Deuxième quittance"
    [imgSrc]="
      rent_receipt_2
        ? 'assets/icons/svg/remove-cross.svg'
        : 'assets/icons/svg/more-button.svg'
    "
    ImgClasses="w-6"
    field_name="rent_receipt_2"
    (IsClicked)="getFile($event)"
    (Clicked)="addFile($event)"
    [file_key]="rent_receipt_2"
  ></app-candidate-file>
  <app-candidate-file
    text="Troisième quittance"
    [imgSrc]="
      rent_receipt_3
        ? 'assets/icons/svg/remove-cross.svg'
        : 'assets/icons/svg/more-button.svg'
    "
    ImgClasses="w-6"
    field_name="rent_receipt_3"
    (IsClicked)="getFile($event)"
    (Clicked)="addFile($event)"
    [file_key]="rent_receipt_3"
  ></app-candidate-file>
</div>
<div *ngIf="current_step !== 'LOCATION'" class="mb-6">
  <!-- ATTESTATION DE FINANCEMENT -->
  <app-text
    textClasses="txt-percian-gray text-sm uppercase mb-3"
    text="ATTESTATION DE FINANCEMENT"
  ></app-text>
  <app-candidate-file
    text="Justificatif des fonds composant l’apport"
    [imgSrc]="
      proof_of_the_funds_making_up_the_contribution
        ? 'assets/icons/svg/remove-cross.svg'
        : 'assets/icons/svg/more-button.svg'
    "
    ImgClasses="w-6"
    field_name="proof_of_the_funds_making_up_the_contribution"
    (IsClicked)="getFile($event)"
    (Clicked)="addFile($event)"
    [file_key]="proof_of_the_funds_making_up_the_contribution"
  ></app-candidate-file>
  <app-candidate-file
    text="Conditions suspensives si emprunt"
    [imgSrc]="
      conditions_precedent_if_loan
        ? 'assets/icons/svg/remove-cross.svg'
        : 'assets/icons/svg/more-button.svg'
    "
    ImgClasses="w-6"
    field_name="conditions_precedent_if_loan"
    (IsClicked)="getFile($event)"
    (Clicked)="addFile($event)"
    [file_key]="conditions_precedent_if_loan"
  ></app-candidate-file>
  <!-- JUSTIFICATIF ENTREPRISE -->
  <app-text
    textClasses="txt-percian-gray text-sm uppercase mb-3"
    text="JUSTIFICATIF ENTREPRISE"
  ></app-text>
  <app-candidate-file
    text="Kbis ou extrait de K"
    [imgSrc]="
      kbis_or_extract
        ? 'assets/icons/svg/remove-cross.svg'
        : 'assets/icons/svg/more-button.svg'
    "
    ImgClasses="w-6"
    field_name="kbis_or_extract"
    (IsClicked)="getFile($event)"
    (Clicked)="addFile($event)"
    [file_key]="kbis_or_extract"
  ></app-candidate-file>
  <!-- FINALITÉ DE L’ACHAT -->
  <app-text
    textClasses="txt-percian-gray text-sm uppercase mb-3"
    text="FINALITÉ DE L’ACHAT"
  ></app-text>
  <app-candidate-file
    text="Investissement locatif"
    [imgSrc]="
      rental_investment
        ? 'assets/icons/svg/remove-cross.svg'
        : 'assets/icons/svg/more-button.svg'
    "
    ImgClasses="w-6"
    field_name="rental_investment"
    (IsClicked)="getFile($event)"
    (Clicked)="addFile($event)"
    [file_key]="rental_investment"
  ></app-candidate-file>
</div>
<!-- GARANT -->
<div *ngIf="current_step === 'LOCATION'" class="my-6">
  <app-text textClasses="txt-light-blue mb-3" text="Garant"></app-text>
  <!-- JUSTIFICATIF IDENTITE GARANT -->
  <app-text
    textClasses="txt-percian-gray text-sm uppercase mb-3"
    text="identité"
  ></app-text>
  <app-candidate-file
    text="Justificatif d'identité"
    [imgSrc]="
      proof_of_identity_guarantor
        ? 'assets/icons/svg/remove-cross.svg'
        : 'assets/icons/svg/more-button.svg'
    "
    ImgClasses="w-6"
    field_name="proof_of_identity_guarantor"
    (IsClicked)="getFile($event)"
    (Clicked)="addFile($event)"
    [file_key]="proof_of_identity_guarantor"
  ></app-candidate-file>
  <!-- IDENTITE MORAL -->
  <app-text
    textClasses="txt-percian-gray text-sm uppercase mb-3"
    text="identité moral"
  ></app-text>
  <app-candidate-file
    text="Kbis ou extrait de K"
    [imgSrc]="
      kbis_or_extract
        ? 'assets/icons/svg/remove-cross.svg'
        : 'assets/icons/svg/more-button.svg'
    "
    ImgClasses="w-6"
    field_name="kbis_or_extract"
    (IsClicked)="getFile($event)"
    (Clicked)="addFile($event)"
    [file_key]="kbis_or_extract"
  ></app-candidate-file>
  <!-- JUSTIFICATIF DES RESSOURCES -->
  <app-text
    textClasses="txt-percian-gray text-sm uppercase mb-3"
    text="JUSTIFICATIF DES RESSOURCES"
  ></app-text>
  <app-candidate-file
    text="Avis d’imposition"
    [imgSrc]="
      tax_notice_guarantor
        ? 'assets/icons/svg/remove-cross.svg'
        : 'assets/icons/svg/more-button.svg'
    "
    ImgClasses="w-6"
    field_name="tax_notice_guarantor"
    (IsClicked)="getFile($event)"
    (Clicked)="addFile($event)"
    [file_key]="tax_notice_guarantor"
  ></app-candidate-file>
  <!-- JUSTIFICATIF D’ACTIVITÉ PROFESSIONNELLE -->
  <app-text
    textClasses="txt-percian-gray text-sm uppercase mb-3"
    text="JUSTIFICATIF D’ACTIVITÉ PROFESSIONNELLE"
  ></app-text>
  <app-candidate-file
    text="Contrat de travail"
    [imgSrc]="
      employment_contract_guarantor
        ? 'assets/icons/svg/remove-cross.svg'
        : 'assets/icons/svg/more-button.svg'
    "
    ImgClasses="w-6"
    field_name="employment_contract_guarantor"
    (IsClicked)="getFile($event)"
    (Clicked)="addFile($event)"
    [file_key]="employment_contract_guarantor"
  ></app-candidate-file>
  <!-- JUSTIFICATIF DE DOMICILE -->
  <app-text
    textClasses="txt-percian-gray text-sm uppercase mb-3"
    text="JUSTIFICATIF DE DOMICILE"
  ></app-text>
  <app-candidate-file
    text="Facture EDF"
    [imgSrc]="
      electricity_bill
        ? 'assets/icons/svg/remove-cross.svg'
        : 'assets/icons/svg/more-button.svg'
    "
    ImgClasses="w-6"
    field_name="electricity_bill"
    (IsClicked)="getFile($event)"
    (Clicked)="addFile($event)"
    [file_key]="electricity_bill"
  ></app-candidate-file>
</div>
