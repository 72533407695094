export class AddressModel {
  ID: any;
  Address_location: any;
  Postal_code: any;
  City: any;
  Address_type: any;

  public mapToModel(data: any): AddressModel {
    const addressModel = new AddressModel();
    addressModel.ID = data.id;
    addressModel.Address_type = data.address_type;
    addressModel.Address_location = data.address_location;
    addressModel.Postal_code = data.postal_code;
    addressModel.City = data.city;

    return addressModel;
  }

  addressTypeValid(): boolean {
    if (this.Address_type !== '' && this.Address_type != null) {
      return true;
    } else {
      return false;
    }
  }
  addressLocationValid(): boolean {
    if (this.Address_location !== '' && this.Address_location != null) {
      return true;
    } else {
      return false;
    }
  }
  postalCodeValid(): boolean {
    if (this.Postal_code !== '' && this.Postal_code != null) {
      return true;
    } else {
      return false;
    }
  }
  cityValid(): boolean {
    if (this.City !== '' && this.City != null) {
      return true;
    } else {
      return false;
    }
  }
}
